import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
  label: ReactNode;
}

export const NavBrandImage: React.FC<Props> = (props) => (
  <div className="navbar-header">
    <button type="button" className="navbar-toggler" id="mobile-nav" data-toggle="collapse" data-target="#main-navbar-collapse">
      <span className="sr-only">
        {props.label}
      </span>
      <i className="togo-icon togo-icon-hamburger" />
    </button>
    {props.children}
  </div>
);

(NavBrandImage as any).propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired
};

export default NavBrandImage;
