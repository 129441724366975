import { paymentMethodDefaultIsExpired } from 'modules/payment-methods-module';

// ------------------------------------
// Constants
// ------------------------------------

// Authentication Alerts
export const ALERT_AUTHENTICATION_WARNING_TOGGLE = 'ALERT_AUTHENTICATION_WARNING_TOGGLE';
export const ALERT_AUTHENTICATION_EXPIRED_TOGGLE = 'ALERT_AUTHENTICATION_EXPIRED_TOGGLE';

// Fraud Alerts
export const ALERT_FRAUD_SHOW = 'ALERT_FRAUD_SHOW';
export const ALERT_FRAUD_HIDE = 'ALERT_FRAUD_HIDE';

// Configuration Alerts
export const ALERT_PRECONFIGURATION_SHOW = 'ALERT_PRECONFIGURATION_SHOW';
export const ALERT_PRECONFIGURATION_HIDE = 'ALERT_PRECONFIGURATION_HIDE';

// Restrict Payment Method Alerts
export const ALERT_RESTRICT_PAYMENT_METHOD_SHOW = 'ALERT_RESTRICT_PAYMENT_METHOD_SHOW';
export const ALERT_RESTRICT_PAYMENT_METHOD_HIDE = 'ALERT_RESTRICT_PAYMENT_METHOD_HIDE';

// Invalid address Alerts
export const ALERT_ADDRESS_INVALID_SHOW = 'ALERT_ADDRESS_INVALID_SHOW';
export const ALERT_ADDRESS_INVALID_HIDE = 'ALERT_ADDRESS_INVALID_HIDE';

// Payment Info alerts
export const ALERT_EXPIRED_PAYMENT_METHOD_TOGGLE = 'ALERT_EXPIRED_PAYMENT_METHOD_TOGGLE';
export const ALERT_WARNING_PAYMENT_METHOD_SHOW = 'ALERT_WARNING_PAYMENT_METHOD_SHOW';
export const ALERT_WARNING_PAYMENT_METHOD_HIDE = 'ALERT_WARNING_PAYMENT_METHOD_HIDE';

// Unmatched product alerts
export const ALERT_PRODUCT_KEY_UNMATCHED_SHOW = 'ALERT_PRODUCT_KEY_UNMATCHED_SHOW';
export const ALERT_PRODUCT_KEY_UNMATCHED_HIDE = 'ALERT_PRODUCT_KEY_UNMATCHED_HIDE';

// Unmatched product family alerts
export const ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_SHOW = 'ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_SHOW';
export const ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_HIDE = 'ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_HIDE';

// SEPA Setup success alert
export const ALERT_SEPA_SETUP_SUCCESS_SHOW = 'ALERT_SEPA_SETUP_SUCCESS_SHOW';
export const ALERT_SEPA_SETUP_SUCCESS_HIDE = 'ALERT_SEPA_SETUP_SUCCESS_HIDE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  addressInvalid: false,
  authWarning: false,
  authExpired: false,
  fraud: false,
  preconfiguration: false,
  restrictPaymentMethod: false,
  paymentMethodExpired: false,
  paymentMethodWarning: false,
  unmatchedProductKey: '',
  unmatchedProductFamilyKey: '',
  sepaSetupSuccess: false
};

// ------------------------------------
// Selectors
// ------------------------------------
export const alertTree = (state) => state.alert || {};
export const alertAuthenticationWarningVisible = (state) => alertTree(state).authWarning;
export const alertAuthenticationWarningTime = (state) => alertTree(state).authWarningTime;
export const alertAuthenticationExpiredVisible = (state) => alertTree(state).authExpired;
export const alertFraudVisible = (state) => alertTree(state).fraud || false;
export const alertPreconfigurationVisible = (state) => alertTree(state).preconfiguration || false;
export const alertRestrictPaymentMethodVisible = (state) => alertTree(state).restrictPaymentMethod || false;
export const alertExpiredPaymentMethodMessageVisible = (state) => !!(alertTree(state).paymentMethodExpired && paymentMethodDefaultIsExpired(state));
export const alertWarningPaymentMethodVisible = (state) => !!(alertTree(state).paymentMethodWarning);
export const alertWarningAddressInvalidVisible = (state) => alertTree(state).addressInvalid;
export const alertProductUnmatchedVisible = (state) => !!alertTree(state).unmatchedProductKey || !!alertTree(state).unmatchedProductFamilyKey;
export const alertSepaSetupSuccessVisible = (state) => alertTree(state).sepaSetupSuccess;
export const alertProductUnmatchedKey = (state) => {
  if (alertTree(state).unmatchedProductKey) {
    return {productKey: alertTree(state).unmatchedProductKey};
  }
  if (alertTree(state).unmatchedProductFamilyKey) {
    return {productFamilyKey: alertTree(state).unmatchedProductFamilyKey};
  }
  return {};
};

// ------------------------------------
// Actions
// ------------------------------------
export const alertAuthenticationWarningToggle = (payload = {}) => ({
  type: ALERT_AUTHENTICATION_WARNING_TOGGLE,
  payload
});

export const alertAuthenticationExpiredToggle = () => ({
  type: ALERT_AUTHENTICATION_EXPIRED_TOGGLE
});

export const alertExpiredPaymentMethodToggle = () => ({
  type: ALERT_EXPIRED_PAYMENT_METHOD_TOGGLE
});

export const alertWarningPaymentMethodShow = () => ({
  type: ALERT_WARNING_PAYMENT_METHOD_SHOW
});

export const alertWarningPaymentMethodHide = () => ({
  type: ALERT_WARNING_PAYMENT_METHOD_HIDE
});

export const alertFraudShow = () => ({
  type: ALERT_FRAUD_SHOW
});

export const alertFraudHide = () => ({
  type: ALERT_FRAUD_HIDE
});

export const alertPreconfigurationShow = () => ({
  type: ALERT_PRECONFIGURATION_SHOW
});

export const alertPreconfigurationHide = () => ({
  type: ALERT_PRECONFIGURATION_HIDE
});

export const alertRestrictPaymentMethodShow = () => ({
  type: ALERT_RESTRICT_PAYMENT_METHOD_SHOW
});

export const alertRestrictPaymentMethodHide = () => ({
  type: ALERT_RESTRICT_PAYMENT_METHOD_HIDE
});

export const alertAddressInvalidShow = () => ({
  type: ALERT_ADDRESS_INVALID_SHOW
});

export const alertAddressInvalidHide = () => ({
  type: ALERT_ADDRESS_INVALID_HIDE
});

export const alertUnmatchedProductShow = (payload = {}) => ({
  type: ALERT_PRODUCT_KEY_UNMATCHED_SHOW,
  payload
});

export const alertUnmatchedProductHide = () => ({
  type: ALERT_PRODUCT_KEY_UNMATCHED_HIDE
});

export const alertUnmatchedProductFamilyShow = (payload = {}) => ({
  type: ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_SHOW,
  payload
});

export const alertUnmatchedProductFamilyHide = () => ({
  type: ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_HIDE
});

export const alertSepaSetupSuccessShow = (payload = {}) => ({
  type: ALERT_SEPA_SETUP_SUCCESS_SHOW,
  payload
});

export const alertSepaSetupSuccessHide = () => ({
  type: ALERT_SEPA_SETUP_SUCCESS_HIDE
});


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [ALERT_FRAUD_SHOW]: (state) => ({
    ...state,
    fraud: true
  }),
  [ALERT_FRAUD_HIDE]: (state) => ({
    ...state,
    fraud: false
  }),
  [ALERT_PRECONFIGURATION_SHOW]: (state) => ({
    ...state,
    preconfiguration: true
  }),
  [ALERT_PRECONFIGURATION_HIDE]: (state) => ({
    ...state,
    preconfiguration: false
  }),
  [ALERT_RESTRICT_PAYMENT_METHOD_SHOW]: (state) => ({
    ...state,
    restrictPaymentMethod: true
  }),
  [ALERT_RESTRICT_PAYMENT_METHOD_HIDE]: (state) => ({
    ...state,
    restrictPaymentMethod: false
  }),
  [ALERT_ADDRESS_INVALID_SHOW]: (state) => ({
    ...state,
    addressInvalid: true
  }),
  [ALERT_ADDRESS_INVALID_HIDE]: (state) => ({
    ...state,
    addressInvalid: false
  }),
  [ALERT_EXPIRED_PAYMENT_METHOD_TOGGLE]: (state) => ({
    ...state,
    paymentMethodExpired: !state.paymentMethodExpired
  }),
  [ALERT_WARNING_PAYMENT_METHOD_SHOW]: (state) => ({
    ...state,
    paymentMethodWarning: true
  }),
  [ALERT_WARNING_PAYMENT_METHOD_HIDE]: (state) => ({
    ...state,
    paymentMethodWarning: false
  }),
  [ALERT_AUTHENTICATION_WARNING_TOGGLE]: (state, {payload}) => ({
    ...state,
    authWarning: !state.authWarning,
    authWarningTime: payload.warningTime
  }),
  [ALERT_AUTHENTICATION_EXPIRED_TOGGLE]: (state) => ({
    ...state,
    authWarning: false,
    authExpired: !state.authExpired
  }),
  [ALERT_PRODUCT_KEY_UNMATCHED_SHOW]: (state, {payload}) => ({
    ...state,
    unmatchedProductKey: payload.productKey
  }),
  [ALERT_PRODUCT_KEY_UNMATCHED_HIDE]: (state) => ({
    ...state,
    unmatchedProductKey: ''
  }),
  [ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_SHOW]: (state, {payload}) => ({
    ...state,
    unmatchedProductFamilyKey: payload.productFamilyKey
  }),
  [ALERT_PRODUCT_FAMILY_KEY_UNMATCHED_HIDE]: (state) => ({
    ...state,
    unmatchedProductFamilyKey: ''
  }),
  [ALERT_SEPA_SETUP_SUCCESS_SHOW]: (state) => ({
    ...state,
    sepaSetupSuccess: true
  }),
  [ALERT_SEPA_SETUP_SUCCESS_HIDE]: (state) => ({
    ...state,
    sepaSetupSuccess: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const alertReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default alertReducer;
