import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AvatarView from './avatar-view';
import { meKey } from 'modules/me-module';
import environment from 'lib/environment';

export const mapStateToProps = (state) => ({
  userKey: meKey(state)
});

export class AvatarContainer extends Component {
  customizeAvatarUrl = () => `${environment.get('avatar.url')}/${this.props.userKey}_small.jpg`;

  render = () => <AvatarView avatarUrl={this.customizeAvatarUrl()} id={this.props.id} className={this.props.className} />;
}

AvatarContainer.propTypes = {
  userKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string
};

export default connect(mapStateToProps)(AvatarContainer);
