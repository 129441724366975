// ------------------------------------
// Constants
// ------------------------------------
// Subscription
export const SUBSCRIPTION_REQUEST_GET = 'SUBSCRIPTION_REQUEST_GET';
export const SUBSCRIPTION_RECEIVE_GET_SUCCESS = 'SUBSCRIPTION_RECEIVE_GET_SUCCESS';
export const SUBSCRIPTION_RECEIVE_GET_FAILURE = 'SUBSCRIPTION_RECEIVE_GET_FAILURE';

// Subscription Action - Delete (only works for PendingAction of type CANCEL, turns auto-renew back on)
export const SUBSCRIPTION_ACTION_REQUEST_DELETE = 'SUBSCRIPTION_ACTION_REQUEST_DELETE';
export const SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS = 'SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS';
export const SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE = 'SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE';

// Subscription Action - Post (turn auto-renew off)
export const SUBSCRIPTION_ACTION_REQUEST_POST = 'SUBSCRIPTION_ACTION_REQUEST_POST';
export const SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS = 'SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS';
export const SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE = 'SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE';

// Subscription Groups
export const SUBSCRIPTION_GROUPS_REQUEST_GET = 'SUBSCRIPTION_GROUPS_REQUEST_GET';
export const SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS = 'SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS';
export const SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE = 'SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE';

export const SUBSCRIPTION_EDIT_START = 'SUBSCRIPTION_EDIT_START';
export const SUBSCRIPTION_EDIT_UPDATE = 'SUBSCRIPTION_EDIT_UPDATE';
export const SUBSCRIPTION_EDIT_END = 'SUBSCRIPTION_EDIT_END';

export const SUBSCRIPTION_CHANGES_LOADING_KEYS_START = 'SUBSCRIPTION_CHANGES_LOADING_KEYS_START';
export const SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH = 'SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH';

// Loading keys that represent which part of the subscription can be updated
export const SUBSCRIPTION_CHANGES_LOADING_KEY_SWITCH_TO_ANNUAL = 'SWITCH_TO_ANNUAL';
export const SUBSCRIPTION_CHANGES_LOADING_KEY_QUANTITY = 'QUANTITY';
