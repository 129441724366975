import React from 'react';
import PropTypes from 'prop-types';
import { ReduxFormField } from 'modules/form';
import classes from './form-field.scss';

export const FormFieldView = (props) => (
  <div className="form-group">
    <label
      id={`${props.id}-label`}
      className={props.labelClasses}
      htmlFor={props.id}
    >
      {props.label}
    </label>
    {
      !props.label &&
        <div className={classes['spacer']} />
    }
    <div>
      <ReduxFormField
        className={`input-lg form-control ${props.fieldClasses}`}
        component={props.component}
        disabled={props.disabled}
        id={props.id}
        maxLength={props.maxLength}
        name={props.name}
        normalize={props.normalize}
        placeholder={props.label}
        type={props.type}
        validate={props.validate}
        value={props.value}
      >
        {props.children}
      </ReduxFormField>
    </div>
  </div>
);

FormFieldView.defaultProps = {
  type: 'text',
  className: '',
  disabled: false
};

FormFieldView.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClasses: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  normalize: PropTypes.func,
  type: PropTypes.any,
  validate: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func
  ]),
  value: PropTypes.string
};

export default FormFieldView;
