import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import st from 'components/shared-translations';

export const AlertProductUnmatchedView = (props) => (
  <Alert
    title={
      <FormattedMessage
        {...st[`alert.product.unmatched${props.experimentIsMultiproduct ? '' : '.singleproduct'}`]}
        values={{
          productName: props.productName
        }}
      />
    }
    type="warning"
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertProductUnmatchedView.propTypes = {
  productName: PropTypes.string.isRequired,
  handleAlertDismiss: PropTypes.func.isRequired,
  experimentIsMultiproduct: PropTypes.bool.isRequired
};

export default AlertProductUnmatchedView;
