// ------------------------------------
// ACTION TYPES
// ------------------------------------
export const INVOICES_REQUEST_GET = 'INVOICES_REQUEST_GET';
export const INVOICES_RECEIVE_GET_SUCCESS = 'INVOICES_RECEIVE_GET_SUCCESS';
export const INVOICES_RECEIVE_GET_FAILURE = 'INVOICES_RECEIVE_GET_FAILURE';
export const INVOICE_REQUEST_GET = 'INVOICE_REQUEST_GET';
export const INVOICE_RECEIVE_GET_SUCCESS = 'INVOICE_RECEIVE_GET_SUCCESS';
export const INVOICE_RECEIVE_GET_FAILURE = 'INVOICE_RECEIVE_GET_FAILURE';
export const INVOICE_PREVIEW_REQUEST_GET = 'INVOICE_PREVIEW_REQUEST_GET';
export const INVOICE_PREVIEW_RECEIVE_GET_SUCCESS = 'INVOICE_PREVIEW_RECEIVE_GET_SUCCESS';
export const INVOICE_PREVIEW_RECEIVE_GET_FAILURE = 'INVOICE_PREVIEW_RECEIVE_GET_FAILURE';
