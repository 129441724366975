import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';

export const AlertFraudView = (props) => (
  <Alert
    type="warning"
    title={<FormattedMessage {...st['alert.fraud.title']} />}
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertFraudView.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertFraudView;
