import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';
import errorImage from 'static/images/error-robot.png';
import classes from './not-found.scss';

interface Props {
  rootUrl: string;
}

export const NotFoundView: React.FC<Props> = (props) => (
  <div id="not-found-view" className={classes['not-found-view']}>
    <div className="row">
      <div className="col-12">
        <img src={errorImage} alt="" />
      </div>
    </div>
    <div className="row">
      <div className="col-12 mt-2">
        <h2>
          <FormattedMessage {...st['common.notfound.header']} />
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-12 mt-3">
        <h5>
          <FormattedMessage
            {...st['common.notfound.link']}
            values={{
              'home-link': (msg: string) => <a href={props.rootUrl} id="my-subscriptions-overview">{msg}</a>
            }}
          />
        </h5>
      </div>
    </div>
  </div>
);

(NotFoundView as any).propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default NotFoundView;
