import { FC, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
import auth from 'lib/commerce-auth';
import { ExternalLink } from 'modules/tracking-module';
import { track as tracking } from 'lib/tracking';

interface LogoutProps extends RouteProps {
  track: (payload: any) => (void);
}

/**
 * Logout component allows the application to use an url to invoke the logout
 * process.
 * @param props logout props
 */
const Logout: FC<LogoutProps> = (props) => {
  const { location, track } = props;

  useEffect(() => {
    if (location && location.pathname) {
      track({ [ExternalLink]: { href: location.pathname } });
      auth.logout();
    }
  }, [location, track]);

  return null;
};

const mapDispatchToProps = {
  track: tracking
};

export default connect(null, mapDispatchToProps)(Logout);
