import environment from 'lib/environment';
import getVersion from 'lib/version';
import amplitudeJS from 'amplitude-js';

/**
 * @class AmplitudeWrapper
 * See {@link https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference Amplitude Documentation} for usage.
 */
export class AmplitudeWrapper {
  constructor(env = environment, consoleObj = console) {
    this.env = env;
    this.consoleObj = consoleObj;
    this.revenueInstance = new amplitudeJS.Revenue();
    this.amplitudeInstance = amplitudeJS.getInstance(this.env.get('amplitude.project'));
    // NOTE: The flag `amplitude.disabled` in config.json controls whether logs are sent to Amplitude or appear in the console.
    // It is true by default to prevent excessive Amplitude logging when in development. Simply edit to false to log to Amplitude.
    if (this.env.get('amplitude.disabled')) {
      this.stub('reason: Disabled');
    }
  }

  initialize = ({userKey}) => {
    this.amplitudeInstance.init(
      this.env.get('amplitude.apiKey'),
      null,
      {
        includeReferrer: true
      }
    );
    this.amplitudeInstance.setUserId(userKey);
    this.amplitudeInstance.setVersionName(getVersion());
  };

  /**
   * Stub out the Amplitude methods that would fire off network calls
   * @param {string} reason - describes the reason behind stubbing the amplitude's method
   * @returns {Promise<void>}
   */
  stub = (reason) => {
    this.amplitudeInstance.logEvent = (eventName, payload) => this.consoleObj.log(`[Amplitude Stubbed - logEvent - ${reason}] - Would have sent ${eventName}`, this.sanitize(payload));
    this.amplitudeInstance.logRevenueV2 = (revenue) => this.consoleObj.log(`[Amplitude Stubbed - logRevenueV2 - ${reason}] - Would have sent logRevenueV2`, revenue);
    this.amplitudeInstance.setUserProperties = (userData) => this.consoleObj.log(`[Amplitude Stubbed - setUserProperties - ${reason}] - Would have sent userData`, userData);
  };

  /**
   * Recursively stringify collections, but respecting documented Amplitude limitations here: https://developers.amplitude.com/#setting-event-properties
   * @param {any} value
   */
  serialize = (value) => {
    if (Array.isArray(value)) {
      if (value.some((val) => Array.isArray(val) || typeof val === 'object')) {
        return value.map(this.serialize)
          .reduce((acc, v, index) => {
            acc[index] = v;
            return acc;
          }, {});
      }
      return value.map(this.serialize);
    }
    if (typeof value === 'object') {
      return Object.keys(value)
        .reduce((acc, key) => {
          acc[key] = this.serialize(value[key]);
          return acc;
        }, {});
    }
    return value;
  };

  /**
   * Sanitizes data for use in Amplitude. Amplitude does not like the following:
   * - undefined as a value
   * - collections (arrays of objects)
   * @param {object} payload Standard event payload used elsewhere
   * @returns {object} Amplitude-ready data
   */
  sanitize = (payload) => {
    const cleanData = {};
    Object.keys(payload)
      .forEach((key) => {
        const value = payload[key];
        // ignore undefined/null values
        if (value != null) {
          cleanData[key] = this.serialize(value);
        }
      });
    return cleanData;
  };

  /**
   * Adds more identifying information about a user. Used to associate a user in Amplitude with their LD experiments, for example
   * @param {object} userData
   */
  identify = (userData) => this.amplitudeInstance.setUserProperties(userData);

  /**
   * It tracks the revenue once an order is completed
   * @param {object} payload
   */
  trackRevenue = (payload) => {
    const revenue = this.revenueInstance
      .setProductId(payload.productId)
      .setRevenueType(payload.orderType)
      .setPrice(payload.orderItemAmount)
      .setEventProperties({
        currencyCode: payload.currencyCode
      });
    this.amplitudeInstance.logRevenueV2(revenue);
  };

  track = (eventName, payload) => this.amplitudeInstance.logEvent(eventName, this.sanitize(payload))
}

export default __TEST__ ? {} : new AmplitudeWrapper();
