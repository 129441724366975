import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';
import errorImage from 'static/images/error-robot.png';
import classes from './error.scss';

interface Error {
  status: string;
  data: any;
}

interface ErrorData {
  code?: number;
  field?: string;
  value?: string;
  debug?: string;
}

interface Props {
  errorUrl: string;
  error?: Error;
}

const statusCodes = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  409: 'Conflict',
  415: 'Unsupported Media Type',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout Error',
  507: 'Insufficient Storage'
};

export const ErrorView: React.FC<Props> = (props) => (
  <div id="error-view" className={classes['error-view']}>
    <div className="row">
      <div className="offset-sm-2 col-sm-8 col-12">
        <img src={errorImage} alt="" />
      </div>
    </div>
    <div className="row">
      <div className="offset-sm-2 col-sm-8 col-12">
        <h2><FormattedMessage {...st['common.error.header']} /></h2>
      </div>
    </div>
    <div className="row">
      <div className="offset-sm-3 col-sm-6 col-12">
        <h5>
          <FormattedMessage
            {...st['common.error.link']}
            values={{
              'my-account-link': (msg: string) => <a href={props.errorUrl} id="my-account-link">{msg}</a>
            }}
          />
        </h5>
      </div>
    </div>
    {
      (props.error && props.error.data) &&
        <div className={`row ${classes['error-details']}`}>
          <div className="col-12 col-sm-8 offset-sm-2">
            <h5><FormattedMessage {...st['common.error.details']} /></h5>
            <hr />
            <div className="row">
              <div className="col-4 text-right">
                <FormattedMessage {...st['common.error.id']} />
              </div>
              <div className="col-8 text-left">
                <strong>{props.error.data.id}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right">
                <FormattedMessage {...st['common.error.status']} />
              </div>
              <div className="col-8 text-left">
                <strong id="error-status-code">{(statusCodes as any)[props.error.status] || props.error.status}</strong>
              </div>
            </div>
            {Array.isArray(props.error.data.errors) && props.error.data.errors.map((errorData: ErrorData, index: number) => (
              <div id={`errors-${index}`} key={`${errorData.code}-${index}`}>
                {
                  errorData.code &&
                    <div className="row">
                      <div className="col-4 text-right">
                        <FormattedMessage {...st['common.error.code']} />
                      </div>
                      <div className="col-8 text-left">
                        <strong className="error-code">{errorData.code}</strong>
                      </div>
                    </div>
                }
                {
                  errorData.field &&
                    <div className="row">
                      <div className="col-4 text-right">
                        <FormattedMessage {...st['common.error.field']} />
                      </div>
                      <div className="col-8 text-left">
                        <strong className="error-field">{errorData.field}</strong>
                      </div>
                    </div>
                }
                {
                  errorData.value &&
                    <div className="row">
                      <div className="col-4 text-right">
                        <FormattedMessage {...st['common.error.value']} />
                      </div>
                      <div className="col-8 text-left">
                        <strong className="error-value">{errorData.value}</strong>
                      </div>
                    </div>
                }
                {
                  errorData.debug &&
                    <div className="row">
                      <div className="col-4 text-right">
                        <FormattedMessage {...st['common.error.debug']} />
                      </div>
                      <div className="col-8 text-left">
                        <strong className="error-debug">{errorData.debug}</strong>
                      </div>
                    </div>
                }
              </div>
            ))}
            <hr />
          </div>
        </div>
    }
  </div>
);

(ErrorView as any).propTypes = {
  errorUrl: PropTypes.string.isRequired,
  error: PropTypes.object
};

export default ErrorView;
