import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import Button from 'components/button';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';

export const AlertAuthWarning = (props) => (
  <Alert
    title={<FormattedMessage {...st['alert.auth.warning.header']} />}
    body={
      <span>
        <FormattedMessage {...st['alert.auth.warning.description']} />&nbsp;
        {
          props.timeLeft && <FormattedMessage
            {...st['alert.auth.warning.time']}
            values={{
              timeLeft: <strong>{props.timeLeft}</strong>
            }}
          />
        }
      </span>
    }
    buttons={
      <Button theme="link" id="auth-warning-reload" onClick={props.handleReload}>
        <FormattedMessage {...st['alert.auth.warning.buttonReload']} />
      </Button>
    }
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertAuthWarning.propTypes = {
  timeLeft: PropTypes.number,
  handleAlertDismiss: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired
};

export default AlertAuthWarning;
