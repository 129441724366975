import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
}

export const NavContent: React.FC<Props> = (props) => (
  <div className="collapse navbar-collapse" id="main-navbar-collapse">
    {props.children}
  </div>
);

(NavContent as any).propTypes = {
  children: PropTypes.node.isRequired
};

export default NavContent;
