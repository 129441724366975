import {
  customClient as apiV2,
  default as api
} from 'dao/providers/axios';
import config from 'config/appconfig';

const paymentMethodsEndpoint = config.cog_endpoints.paymentMethods;

export const daoPaymentMethodsGet = (billingAccountKey) => api.get(paymentMethodsEndpoint(billingAccountKey, 'v1'));
export const daoPaymentMethodsPost = (billingAccountKey, payload) => apiV2.post(paymentMethodsEndpoint(billingAccountKey, 'v3'), payload);
export const daoPaymentMethodsPatch = (billingAccountKey, paymentMethodKey, payload) => apiV2.patch(`${paymentMethodsEndpoint(billingAccountKey, 'v3')}/${paymentMethodKey}`, payload);

export default {
  daoPaymentMethodsGet,
  daoPaymentMethodsPost,
  daoPaymentMethodsPatch
};
