import en from 'locales/external_en_US.json';
import de from 'locales/external_de_DE.json';
import es from 'locales/external_es_ES.json';
import fr from 'locales/external_fr_FR.json';
import it from 'locales/external_it_IT.json';
import ja from 'locales/external_ja_JP.json';
import ko from 'locales/external_ko_KR.json';
import pt from 'locales/external_pt_BR.json';
import zh from 'locales/external_zh_CN.json';

// Show text telling the user to use a modern browser since their browser is not supported
// @IMPROVEMENT - In IE, you can detect the user's language using this code const  language = window.navigator.userLanguage
// (http://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference)
// We could use that to display the user's language first in the list
const languages = [en, de, es, fr, it, ja, ko, pt, zh].map((lang) => ({headerText: lang['ie9.header'], linkText: lang['ie9.link']}));

const bootstrapInvalidBrowser = () => {
  const centerContentContainer = document.getElementById('root');
  centerContentContainer.innerHTML = ''; // Clear out the loading spinner since we aren't going to load the app

  languages.forEach((language) => {
    const div = document.createElement('div');
    const headerTag = document.createElement('h4');
    headerTag.innerHTML = language.headerText;
    div.appendChild(headerTag);
    div.style.paddingBottom = '50px';
    centerContentContainer.appendChild(div);
  });
};

export default bootstrapInvalidBrowser;
