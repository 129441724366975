/**
 * About import():
 * The import() statement relies on a concept called context module. It's a bundle that Webpack generates for a given directory,
 * in order to make it possible to dynamically load any file inside that directory. For instance, at the run time, the Javascript engine will
 * ignore any variables inside the provided pathname, and it will just take the static piece of the module name(i.e react-intl/locale-data/), and create a
 * context module which then will allow the dynamic loading
 *
 * @param {webpackChunkName[magic comment]/ webpackMode[magic comment]/ module}
 * @returns {Promise}
 */

// ------------------------------------
// Constants
// ------------------------------------
const SUPPORTED_LOCALES = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'ja_JP', 'ko_KR', 'pt_BR', 'zh_CN'];

// ------------------------------------
// Actions
// ------------------------------------

/**
 * Loads the Intl Polyfill, and then loads the needed locale file based on the user locale
 * This is a Polyfill for browsers that don't support window.Intl
 *
 * @param {string} userLanguageCode - i.e en, ja, ko, etc...
 * @returns {Promise} - returns a promise which dynamically imports the Intl polyfill module, and then imports the
 *                     needed intl locale data based on the user's locale
 */
export const intlLocaleDataPolyfill = (userLanguageCode) => {
  if (window.Intl) {
    return Promise.resolve(userLanguageCode);
  }

  return new Promise(async (resolve) => {
    await import('intl');
    let intlLocaleData;
    switch (userLanguageCode) {
      case 'de':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-de" */ 'intl/locale-data/jsonp/de.js');
        break;
      case 'es':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-es" */ 'intl/locale-data/jsonp/es.js');
        break;
      case 'fr':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-fr" */ 'intl/locale-data/jsonp/fr.js');
        break;
      case 'it':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-it" */ 'intl/locale-data/jsonp/it.js');
        break;
      case 'ja':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-ja" */ 'intl/locale-data/jsonp/ja.js');
        break;
      case 'ko':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-ko" */ 'intl/locale-data/jsonp/ko.js');
        break;
      case 'pt':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-pt" */ 'intl/locale-data/jsonp/pt.js');
        break;
      case 'zh':
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-zh" */ 'intl/locale-data/jsonp/zh.js');
        break;
      default:
        intlLocaleData = import(/* webpackChunkName: "intl-locale-data-en" */ 'intl/locale-data/jsonp/en.js');
    }

    intlLocaleData.then(() => {
      // @NOTE passed the userLanguageCode for testing purposes
      resolve(`polyfilled Intl and loaded ${userLanguageCode}`);
    });
  });
};

/**
 * Loads the Intl Plural Rules polyfill, and then loads the needed locale file based on the user locale
 * This is a Polyfill for browsers that don't support Intl.PluralRules
 * @param {string} userLanguageCode - i.e en, ja, ko, etc...
 * @returns {Promise} - returns a promise which dynamically imports the Intl PluralRules polyfill module, and then imports the
 *                     needed Intl PluralRules locale data based on the user's locale
 */
export const intlPluralrulesPolyfill = (userLanguageCode) => {
  if (window.Intl.PluralRules) {
    return Promise.resolve(userLanguageCode);
  }

  return new Promise(async (resolve) => {
    await import('@formatjs/intl-pluralrules/polyfill');
    let intlPluralrulesLocaleData;
    switch (userLanguageCode) {
      case 'de': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-de" */ '@formatjs/intl-pluralrules/dist/locale-data/de');
        break;
      }
      case 'es': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-es" */ '@formatjs/intl-pluralrules/dist/locale-data/es');
        break;
      }
      case 'fr': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-fr" */ '@formatjs/intl-pluralrules/dist/locale-data/fr');
        break;
      }
      case 'it': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-it" */ '@formatjs/intl-pluralrules/dist/locale-data/it');
        break;
      }
      case 'ja': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-ja" */ '@formatjs/intl-pluralrules/dist/locale-data/ja');
        break;
      }
      case 'ko': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-ko" */ '@formatjs/intl-pluralrules/dist/locale-data/ko');
        break;
      }
      case 'pt': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-pt" */ '@formatjs/intl-pluralrules/dist/locale-data/pt');
        break;
      }
      case 'zh': {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-zh" */ '@formatjs/intl-pluralrules/dist/locale-data/zh');
        break;
      }
      default: {
        intlPluralrulesLocaleData = import(/* webpackChunkName: "intl-pluralrules-locale-data-en" */ '@formatjs/intl-pluralrules/dist/locale-data/en');
      }
    }

    intlPluralrulesLocaleData.then(() => {
      // @NOTE passed the userLanguageCode for testing purposes
      resolve(`polyfilled Intl PluralRules and loaded ${userLanguageCode}`);
    });
  });
};

/**
 * Loads the Intl Relative Time Format polyfill, and then loads the needed locale file based on the user locale
 * This is a Polyfill for browsers that don't support Intl.RelativeTimeFormat
 * @param {string} userLanguageCode - i.e en, ja, ko, etc...
 * @returns {Promise} - returns a promise which dynamically imports the Intl RelativeTimeFormat polyfill module, and then imports the
 *                     needed Intl RelativeTimeFormat locale data based on the user's locale
 */
export const intlRelativetimeformatPolyfill = (userLanguageCode) => {
  if (window.Intl.RelativeTimeFormat) {
    return Promise.resolve(userLanguageCode);
  }

  return new Promise(async (resolve) => {
    await import('@formatjs/intl-relativetimeformat/polyfill');
    let intlRelativetimeformatLocaleData;
    switch (userLanguageCode) {
      case 'de': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-de" */ '@formatjs/intl-relativetimeformat/dist/locale-data/de');
        break;
      }
      case 'es': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-es" */ '@formatjs/intl-relativetimeformat/dist/locale-data/es');
        break;
      }
      case 'fr': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-fr" */ '@formatjs/intl-relativetimeformat/dist/locale-data/fr');
        break;
      }
      case 'it': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-it" */ '@formatjs/intl-relativetimeformat/dist/locale-data/it');
        break;
      }
      case 'ja': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-ja" */ '@formatjs/intl-relativetimeformat/dist/locale-data/ja');
        break;
      }
      case 'ko': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-ko" */ '@formatjs/intl-relativetimeformat/dist/locale-data/ko');
        break;
      }
      case 'pt': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-pt" */ '@formatjs/intl-relativetimeformat/dist/locale-data/pt');
        break;
      }
      case 'zh': {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-zh" */ '@formatjs/intl-relativetimeformat/dist/locale-data/zh');
        break;
      }
      default: {
        intlRelativetimeformatLocaleData = import(/* webpackChunkName: "intl-relativetimeformat-locale-data-en" */ '@formatjs/intl-relativetimeformat/dist/locale-data/en');
      }
    }

    intlRelativetimeformatLocaleData.then(() => {
      // @NOTE passed the userLanguageCode for testing purposes
      resolve(`polyfilled Intl Relativetimeformat and loaded ${userLanguageCode}`);
    });
  });
};

/**
 * Loads the React Intl Messages based on the logged in user's locale
 * (packaged as a separate bundle and loaded on demand by webpack)
 *
 * @param {string} userLanguageCode - i.e en, ja, ko, etc...
 * @param {string} userLocale - i.e en_US, de_DE, es_ES, etc...
 * @returns {Promise} - returns a promise that loads the locale resource file
 */
export const loadReactIntlMessages = (userLanguageCode, userLocale) => {
  // Verify if the currentLocale is supported, if not fallback to the initial two-letter language of the locale
  const currentLocale = SUPPORTED_LOCALES.includes(userLocale) ? userLocale : userLanguageCode;

  // Setup the locale files as separate webpack bundles that get requested/loaded on demand
  return new Promise((resolve) => {
    let localeMessages;
    switch (currentLocale) {
      case 'de_DE':
      case 'de':
        localeMessages = import(/* webpackChunkName: "locales-de_DE" */ 'locales/de_DE');
        break;
      case 'es_ES':
      case 'es':
        localeMessages = import(/* webpackChunkName: "locales-es_ES" */ 'locales/es_ES');
        break;
      case 'fr_FR':
      case 'fr':
        localeMessages = import(/* webpackChunkName: "locales-fr_FR" */ 'locales/fr_FR');
        break;
      case 'it_IT':
      case 'it':
        localeMessages = import(/* webpackChunkName: "locales-it_IT" */ 'locales/it_IT');
        break;
      case 'ja_JP':
      case 'ja':
        localeMessages = import(/* webpackChunkName: "locales-ja_JP" */ 'locales/ja_JP');
        break;
      case 'ko_KR':
      case 'ko':
        localeMessages = import(/* webpackChunkName: "locales-ko_KR" */ 'locales/ko_KR');
        break;
      case 'pt_BR':
      case 'pt':
        localeMessages = import(/* webpackChunkName: "locales-pt_BR" */ 'locales/pt_BR');
        break;
      case 'zh_CN':
      case 'zh':
        localeMessages = import(/* webpackChunkName: "locales-zh_CN" */ 'locales/zh_CN');
        break;
      default:
        localeMessages = import(/* webpackChunkName: "locales-en_US" */ 'locales/en_US');
    }

    localeMessages.then(({ default: messages }) => {
      resolve(messages);
    });
  });
};

/**
 * Loads the various window.Intl Polyfills. I.e window.Intl, window.Intl.PluralRules, and window.Intl.RelativeTimeFormat
 *
 * @param {string} userLanguageCode - i.e en, ja, ko, etc...
 */
export const loadIntlPolyfills = async (userLanguageCode) => {
  // First load the window.Intl Polyfill
  await intlLocaleDataPolyfill(userLanguageCode);
  // Then, load window.Intl.PluralRules or window.Intl.RelativeTimeFormat Polyfills in parallel
  intlPluralrulesPolyfill(userLanguageCode);
  intlRelativetimeformatPolyfill(userLanguageCode);
};

export default loadIntlPolyfills;
