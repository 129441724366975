import React from 'react';
import PropTypes from 'prop-types';
import AppLink from 'components/app-link';
import Loader from 'components/loader';
import Badge from 'components/badge';
import classes from './main-nav-cart-button.scss';
import { useIntl } from 'react-intl';
import st from 'components/shared-translations';

export const MainNavCartButtonView = (props) => {
  const intl = useIntl();
  return (
    <AppLink to="order.cart" className={classes['cart-button']} id="nav-cart">
      {
        props.cartIsLoading
          ? <Loader size="xs" absolute={false} />
          : <div>
            <i className="togo-icon togo-icon-cart" />
            <span className="sr-only">{intl.formatMessage(st['mainnavcart.label'])}</span>
            {
              props.cartItemCount > 0 &&
                <Badge classNames={classes['cart-icon-badge']}>
                  {props.cartItemCount}
                </Badge>
            }
          </div>
      }
    </AppLink>
  );
};

MainNavCartButtonView.propTypes = {
  cartIsLoading: PropTypes.bool.isRequired,
  cartItemCount: PropTypes.number.isRequired
};

export default MainNavCartButtonView;
