import { connect } from 'react-redux';
import { billingAccountActiveKey } from 'modules/billing-account';
import AppLinkView from './app-link-view';

interface StateProps {
  billingAccountKey: string;
}

export const mapStateToProps = (state: ApplicationShape) => ({
  billingAccountKey: billingAccountActiveKey(state) || ''
});

export default connect<StateProps, {}, {}, ApplicationShape>(mapStateToProps)(AppLinkView);
