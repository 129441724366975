import differenceBy from 'lodash/differenceBy';
import {
  SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS,
  SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE,
  SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE,
  SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS,
  SUBSCRIPTION_ACTION_REQUEST_DELETE,
  SUBSCRIPTION_ACTION_REQUEST_POST,
  SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH,
  SUBSCRIPTION_CHANGES_LOADING_KEYS_START,
  SUBSCRIPTION_EDIT_END,
  SUBSCRIPTION_EDIT_START,
  SUBSCRIPTION_EDIT_UPDATE,
  SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE,
  SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS,
  SUBSCRIPTION_GROUPS_REQUEST_GET,
  SUBSCRIPTION_RECEIVE_GET_FAILURE,
  SUBSCRIPTION_RECEIVE_GET_SUCCESS,
  SUBSCRIPTION_REQUEST_GET
} from './subscription-action-types';
import {
  PRODUCT_RELATIONSHIP_RECEIVE_GET_SUCCESS,
  PRODUCT_RELATIONSHIP_RECEIVE_GET_FAILURE,
  ENHANCED_AUDIO_FLAT_RATE
} from 'modules/product-module';
import SubscriptionModel from './subscription-model';

// ------------------------------------
// Action Handlers Helpers
// ------------------------------------
const subscriptionsSorter = (subscriptions = []) => subscriptions.sort((subscriptionA, subscriptionB) => {
  if (subscriptionA.status === SubscriptionModel.SUBSCRIPTION_STATUS_ACTIVE && subscriptionB.status !== SubscriptionModel.SUBSCRIPTION_STATUS_ACTIVE) {
    return -1;
  }
  if (subscriptionB.status === SubscriptionModel.SUBSCRIPTION_STATUS_ACTIVE && subscriptionA.status !== SubscriptionModel.SUBSCRIPTION_STATUS_ACTIVE) {
    return 1;
  }
  if (subscriptionA.isTrial && !subscriptionB.isTrial) {
    return -1;
  }
  if (subscriptionB.isTrial && !subscriptionA.isTrial) {
    return 1;
  }
  if (subscriptionA.isPaid && !subscriptionB.isPaid) {
    return -1;
  }
  if (subscriptionB.isPaid && !subscriptionA.isPaid) {
    return 1;
  }
  if (subscriptionA.productDescription < subscriptionB.productDescription) {
    return -1;
  }
  if (subscriptionA.productDescription > subscriptionB.productDescription) {
    return 1;
  }

  return 0;
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [SUBSCRIPTION_REQUEST_GET]: (state) => ({
    ...state,
    isLoading: true
  }),
  [SUBSCRIPTION_RECEIVE_GET_SUCCESS]: (state, { payload }) => {
    const mergedSubscriptionsV2 = Array.prototype.concat(differenceBy(state.subscriptionsV2, [payload], 'key'), [SubscriptionModel.buildFromDto(payload)]);

    const nextState = {
      ...state,
      isLoading: false,
      subscriptionsV2: subscriptionsSorter(mergedSubscriptionsV2)
    };

    // when one subscription potentially changes or is added, we need to recompute the subscription models for all the subscriptions
    nextState.subscriptionsV2 = nextState.subscriptionsV2.map(SubscriptionModel.buildAppModel(nextState));
    return nextState;
  },
  [SUBSCRIPTION_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_ACTION_REQUEST_DELETE]: (state) => ({
    ...state,
    isLoading: true
  }),
  [SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_ACTION_REQUEST_POST]: (state) => ({
    ...state,
    isLoading: true
  }),
  [SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_GROUPS_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS]: (state, { payload }) => {
    // @TODO (BPOR-329) handle multiple subscriptionGroups
    const subscriptionGroups = Array.isArray(payload) ? payload : [];
    const subscriptions = Array.isArray((subscriptionGroups[0] && subscriptionGroups[0].subscriptions)) ? subscriptionGroups[0].subscriptions : [];
    const hasVisibleProductOperation = (subscription) => !!(subscription.operations || {}).visible;
    const filteredSubscriptions = subscriptions.filter(hasVisibleProductOperation);

    const nextState = {
      ...state,
      accountKey: subscriptionGroups[0] ? subscriptionGroups[0].accountKey : undefined,
      subscriptionsV2: subscriptionsSorter(filteredSubscriptions.map(SubscriptionModel.buildFromDto)),
      isLoading: false
    };
    nextState.subscriptionsV2 = nextState.subscriptionsV2.map(SubscriptionModel.buildAppModel(nextState));
    return nextState;
  },
  [SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [SUBSCRIPTION_EDIT_START]: (state, { payload }) => ({
    ...state,
    cards: {
      ...state.cards,
      [payload.subscriptionKey]: {}
    }
  }),
  [SUBSCRIPTION_EDIT_UPDATE]: (state, { payload }) => ({
    ...state,
    cards: {
      ...state.cards,
      [payload.subscriptionKey]: {
        ...payload.data
      }
    }
  }),
  [SUBSCRIPTION_EDIT_END]: (state, { payload }) => {
    const filteredCards = Object.keys(state.cards).reduce((list, subscriptionKey) => {
      const updatedList = {...list};
      if (subscriptionKey !== payload.subscriptionKey) {
        updatedList[subscriptionKey] = state.cards[subscriptionKey];
      }
      return updatedList;
    }, {});

    return {
      ...state,
      cards: filteredCards
    };
  },
  [SUBSCRIPTION_CHANGES_LOADING_KEYS_START]: (state, { payload }) => ({
    ...state,
    loading: {
      ...state.loading,
      [payload.subscriptionKey]: state.loading[payload.subscriptionKey] ? state.loading[payload.subscriptionKey].concat(payload.loadingKeys) : payload.loadingKeys
    }
  }),
  [SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH]: (state, { payload }) => {
    if (!state.loading[payload.subscriptionKey]) {
      return state;
    }

    return {
      ...state,
      loading: {
        ...state.loading,
        [payload.subscriptionKey]: state.loading[payload.subscriptionKey].filter((loadingKey) => !payload.loadingKeys.includes(loadingKey))
      }
    };
  },
  // TODO: Needs to be removed once FlatRate is migrated to Global Call me.
  [PRODUCT_RELATIONSHIP_RECEIVE_GET_SUCCESS]: (state, { payload }) => {
    const subscriptionsV2 = [...state.subscriptionsV2];
    const subscriptionsV2Updated = subscriptionsV2.map((subscription) => {
      if (subscription.productKey === ENHANCED_AUDIO_FLAT_RATE) {
        return {
          ...subscription,
          relationships: payload
        };
      }
      return subscription;
    });
    return { ...state, subscriptionsV2: subscriptionsV2Updated };
  },
  [PRODUCT_RELATIONSHIP_RECEIVE_GET_FAILURE]: (state) => ({...state })
});

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  subscriptionsV2: [], // @NOTE new version of subscriptions redux tree; @TODO move this to be the `subscriptions` subtree once everything uses the subscription model
  // this is a generic flag that indicates if any of the subscriptions are loading
  isLoading: false,
  /**
   * Loading: an object that will hold a subscription's loading keys
   * Example: loading: {
   *       "09938": ['SWITCH_TO_ANNUAL'],
   *       "09949": ['QUANTITY']
   *     }
   */
  loading: {},
  cards: {}
};

// ------------------------------------
// Reducer
// ------------------------------------
export const subscriptionReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};
