import domHelpers from 'lib/dom-helpers';
import environment from 'lib/environment';
import { v1 as uuidv1 } from 'uuid';

let libLoadedPromise;

export default class CybersourceFingerprint {
  /**
  * Create a UUID, remove hyphens and convert to uppercase
  */
  static id = uuidv1()
    .replace(new RegExp(/-/g), '')
    .toUpperCase();

  /**
   * @param {enum} cybersourceRegion one of 'AU', 'CA', 'GB', or 'US'
   */
  constructor({cybersourceRegion}) {
    this.regionData = environment.get(`cybersource.regions.${cybersourceRegion}`);
    this.baseData = environment.get('cybersource');
  }

  /**
   * Load the Cybersource fingerprinting JS
   *
   * @param {string} src: source of the script to be created
   * @returns {Promise} Promise that resolves when the script is successfully loaded; rejects otherwise
   */
  load = () => {
    // only load the library once
    if (libLoadedPromise) {
      return libLoadedPromise;
    }

    libLoadedPromise = domHelpers.createScript(this.trackingUrl('javascript'));

    return libLoadedPromise;
  };


  /**
   * Builds the URL for CyberSource fingerprinting across multiple different delivery endpoints (image, flash, js)
   * @param {string} type - The type of resource to get a URL for  (image, flash, js)
   * @returns {string}
   */
  trackingUrl = (type) => {
    const url = new URL(this.baseData[type].url);
    url.search = `org_id=${this.baseData.org_id}&session_id=${this.regionData.session_id}`;
    return `${url}${CybersourceFingerprint.id}`;
  };
}
