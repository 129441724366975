// ------------------------------------
// Constants
// ------------------------------------

// Shared fields between the Credit card and SEPA forms
export const FORM_FIELD_ADDRESS_LINE_1 = 'addressLine1';
export const FORM_FIELD_ADDRESS_LINE_2 = 'addressLine2';
export const FORM_FIELD_CITY = 'city';
export const FORM_FIELD_COUNTRY = 'country';
export const FORM_FIELD_FIRST_NAME = 'firstName';
export const FORM_FIELD_LAST_NAME = 'lastName';
export const FORM_FIELD_POSTAL_CODE = 'postalCode';
export const FORM_FIELD_PHONE_NUMBER = 'phoneNumber';
export const FORM_FIELD_STATE = 'state';

// Fields that are related to Credit card
export const FORM_FIELD_CARD_NUMBER = 'number';
export const FORM_FIELD_CVN = 'securityCode';
export const FORM_FIELD_EXPIRATION_MONTH = 'expirationMonth';
export const FORM_FIELD_EXPIRATION_YEAR = 'expirationYear';

// Fields that are related to SEPA
export const FORM_FIELD_IBAN = 'iban';

export const billingInformationFieldsNames = {
  FORM_FIELD_ADDRESS_LINE_1,
  FORM_FIELD_ADDRESS_LINE_2,
  FORM_FIELD_CITY,
  FORM_FIELD_COUNTRY,
  FORM_FIELD_FIRST_NAME,
  FORM_FIELD_LAST_NAME,
  FORM_FIELD_POSTAL_CODE,
  FORM_FIELD_PHONE_NUMBER,
  FORM_FIELD_STATE
};

export const creditCardFieldsNames = {
  ...billingInformationFieldsNames,
  FORM_FIELD_CARD_NUMBER,
  FORM_FIELD_CVN,
  FORM_FIELD_EXPIRATION_MONTH,
  FORM_FIELD_EXPIRATION_YEAR
};

export const sepaFieldsNames = {
  ...billingInformationFieldsNames,
  FORM_FIELD_IBAN
};

/**
 * This relationship describes the side effects that a field can have on other fields which in this case is touch.
 * The object key is a form field and the key's value is an array that contains a list of form fields that should be touched
 */
export const BILLING_INFORMATION_FORM_FIELD_RELATIONSHIP_TOUCH = {
  [FORM_FIELD_ADDRESS_LINE_1]: [],
  [FORM_FIELD_ADDRESS_LINE_2]: [],
  [FORM_FIELD_CITY]: [],
  [FORM_FIELD_COUNTRY]: [FORM_FIELD_STATE],
  [FORM_FIELD_FIRST_NAME]: [],
  [FORM_FIELD_LAST_NAME]: [],
  [FORM_FIELD_POSTAL_CODE]: [],
  [FORM_FIELD_PHONE_NUMBER]: [],
  [FORM_FIELD_STATE]: []
};

// A list of fields whose values are arrays that contain the countries that require them
export const formFieldsRequiredCountries = {
  [FORM_FIELD_STATE]: ['AU', 'CA', 'US'],
  [FORM_FIELD_POSTAL_CODE]: ['CA', 'FR', 'US']
};
