import { eeClient as api } from 'dao/providers/axios';
import appconfig from 'config/appconfig';

// @NOTE The productFamilyKey param should be sent in lowercase; otherwise, an error will be returned
export const daoEeCancelGet = (entitlementAccountId, productFamilyKey, subscriptionKey, locale) => (
  api.get(appconfig.ee_endpoints.cancel(entitlementAccountId, productFamilyKey.toLowerCase(), subscriptionKey, locale))
);

export default {
  daoEeCancelGet
};
