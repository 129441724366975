import { extAdminClient as api } from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const accountEndpoint = appconfig.extAdmin_endpoints.account;

export const daoAccountGet = (accountKey) => api.get(accountEndpoint(accountKey));

export const daoAccountPut = (accountKey, payload) => api.put(`${accountEndpoint(accountKey)}?async=true`, payload);

export default {
  daoAccountGet,
  daoAccountPut
};
