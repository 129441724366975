/**
* This is a key value pair object where:
* {key} represents the country key
* {value} represents the list of states code
* @NOTE Those values were given to us by COG
*/
export default {
  US: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
  ],
  GB: [],
  CA: [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT'
  ],
  AU: [
    'ACT',
    'NSW',
    'NT',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA'
  ],
  FR: [],
  DE: [],
  VC: [],
  AL: [],
  DZ: [],
  AD: [],
  AO: [],
  AI: [],
  AQ: [],
  AG: [],
  AR: [],
  AM: [],
  AT: [],
  AZ: [],
  BS: [],
  BH: [],
  BB: [],
  BY: [],
  BE: [],
  BZ: [],
  BJ: [],
  BT: [],
  BO: [],
  BA: [],
  BW: [],
  BR: [],
  BN: [],
  BG: [],
  BF: [],
  BI: [],
  CM: [],
  CV: [],
  KY: [],
  TD: [],
  CN: [],
  CX: [],
  CO: [],
  KM: [],
  CK: [],
  CR: [],
  CI: [],
  HR: [],
  CZ: [],
  DK: [],
  DJ: [],
  DM: [],
  DO: [],
  EC: [],
  EG: [],
  SV: [],
  GQ: [],
  ER: [],
  ET: [],
  FK: [],
  FO: [],
  FJ: [],
  GF: [],
  PF: [],
  GA: [],
  GE: [],
  GH: [],
  GR: [],
  GL: [],
  GD: [],
  GP: [],
  GT: [],
  GW: [],
  GY: [],
  HT: [],
  HN: [],
  HK: [],
  IS: [],
  IN: [],
  ID: [],
  IL: [],
  IT: [],
  JM: [],
  JP: [],
  KZ: [],
  KE: [],
  KI: [],
  KR: [],
  KG: [],
  LA: [],
  LV: [],
  LS: [],
  LR: [],
  LI: [],
  LU: [],
  MO: [],
  MK: [],
  MG: [],
  MY: [],
  MV: [],
  ML: [],
  MH: [],
  MQ: [],
  MR: [],
  YT: [],
  MX: [],
  FM: [],
  MD: [],
  MN: [],
  MS: [],
  MA: [],
  MZ: [],
  NA: [],
  NR: [],
  NP: [],
  AN: [],
  NC: [],
  NZ: [],
  NI: [],
  NE: [],
  NG: [],
  NF: [],
  NO: [],
  OM: [],
  PK: [],
  PA: [],
  PG: [],
  PY: [],
  PE: [],
  PH: [],
  PL: [],
  PT: [],
  QA: [],
  RE: [],
  RU: [],
  RW: [],
  KN: [],
  LC: [],
  SM: [],
  ST: [],
  SA: [],
  SN: [],
  RS: [],
  SC: [],
  SG: [],
  SK: [],
  SI: [],
  SB: [],
  ZA: [],
  ES: [],
  LK: [],
  SH: [],
  SZ: [],
  SE: [],
  CH: [],
  TW: [],
  TZ: [],
  TH: [],
  TG: [],
  TK: [],
  TT: [],
  TN: [],
  TR: [],
  TV: [],
  UG: [],
  AE: [],
  UY: [],
  VU: [],
  VA: [],
  VE: [],
  VN: [],
  YE: [],
  ZM: [],
  AS: [],
  AW: [],
  BD: [],
  BM: [],
  BV: [],
  KH: [],
  CL: [],
  CG: [],
  CY: [],
  TL: [],
  EE: [],
  FI: [],
  GM: [],
  GI: [],
  GN: [],
  HU: [],
  IE: [],
  JO: [],
  KW: [],
  LB: [],
  LT: [],
  MW: [],
  MT: [],
  MU: [],
  MC: [],
  MM: [],
  NL: [],
  NU: [],
  PW: [],
  PN: [],
  RO: [],
  WS: [],
  SL: [],
  SO: [],
  SR: [],
  TJ: [],
  TO: [],
  TM: [],
  UZ: [],
  EH: [],
  UA: [],
  GU: [],
  PR: [],
  VI: [],
  AX: [],
  BL: [],
  BQ: [],
  CC: [],
  CW: [],
  GG: [],
  GS: [],
  HM: [],
  IM: [],
  IO: [],
  IQ: [],
  JE: [],
  ME: [],
  MF: [],
  SX: [],
  PM: [],
  SJ: [],
  TC: [],
  TF: [],
  WF: [],
  ZW: []
};
