import { useEffect, FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { updateLangAttribute } from 'lib/window-helpers';

const crownPropTypes = {
  children: PropTypes.any.isRequired,
  userLanguage: PropTypes.string.isRequired
};

export type CrownProps = InferProps<typeof crownPropTypes>

/**
 * The main purpose of this component is to perform some side effects, such as updating the user language and document title dynamically.
 */
const Crown: FC<CrownProps> = (props) => {
  const { userLanguage, children } = props;
  // @NOTE useEffect's second argument is used to simply tell react to rerender the component when the value of userLanguage changes only
  useEffect(() => {
    updateLangAttribute(userLanguage);
  }, [userLanguage]);

  return children;
};

Crown.propTypes = crownPropTypes;

export default Crown;
