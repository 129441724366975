import {
  daoPaymentFormGet
} from 'dao/payment-form-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_FORM_REQUEST_GET = 'PAYMENT_FORM_REQUEST_GET';
export const PAYMENT_FORM_RECEIVE_GET_SUCCESS = 'PAYMENT_FORM_RECEIVE_GET_SUCCESS';
export const PAYMENT_FORM_RECEIVE_GET_FAILURE = 'PAYMENT_FORM_RECEIVE_GET_FAILURE';
export const CREDIT_CARD_TYPE = 'CREDIT_CARD_TYPE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  isLoading: false,
  cardType: ''
};

// ------------------------------------
// Selectors
// ------------------------------------
export const paymentFormTree = (state) => state.paymentForm || {};
export const paymentFormIsLoading = (state) => paymentFormTree(state).isLoading;
export const paymentFormKeyId = (state) => paymentFormTree(state).keyId || '';
export const paymentFormMId = (state) => paymentFormTree(state).mid || '';
export const creditCardType = (state) => paymentFormTree(state).cardType || '';

// ------------------------------------
// Actions
// ------------------------------------
export const paymentFormReceiveGetSuccess = (payload = {}) => ({
  type: PAYMENT_FORM_RECEIVE_GET_SUCCESS,
  payload
});

export const paymentFormReceiveGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PAYMENT_FORM_RECEIVE_GET_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const paymentFormRequestGet = (country) => (dispatch) => {
  dispatch({
    type: PAYMENT_FORM_REQUEST_GET
  });

  return daoPaymentFormGet(country)
    .then(
      (response) => dispatch(paymentFormReceiveGetSuccess(response.data)),
      (ex) => dispatch(paymentFormReceiveGetFailure(ex))
    );
};

export const setCardType = (payload = '') => ({
  type: CREDIT_CARD_TYPE,
  payload
});

export const actions = {
  paymentFormRequestGet,
  paymentFormReceiveGetSuccess,
  paymentFormReceiveGetFailure,
  setCardType
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [PAYMENT_FORM_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [PAYMENT_FORM_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        keyId: action.payload.keyId,
        mid: action.payload.mid,
        isLoading: false
      };
    }
    return {...state, isLoading: false};
  },
  [PAYMENT_FORM_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [CREDIT_CARD_TYPE]: (state, action) => ({
    ...state,
    cardType: action.payload
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const paymentFormReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default paymentFormReducer;
