import environment from 'lib/environment';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import pickBy from 'lodash/pickBy';
import forEach from 'lodash/forEach';
import {
  ProductFamilyNotFoundException,
  ProductNotFoundException
} from 'lib/exceptions';

/**
 * The complete Product detail object
 *
 * @typedef {object} Product
 *
 * @property {string} family - The family of the product. Currently only supports a one-to-one relationship.
 * @property {boolean} [default] - Whether the product is the default product to select on new/conversion orders
 * @property {string} [paidUser] - the ID for the message that should be used for this product's paid user
 * @property {string} [unitDescriptionKey] - the ID for the message that should be used for describing this product unit
 * @property {boolean} [skipMarketingFlow] - Whether subscriptions of this product should skip the marketing flow (stay in Billing Center)
 */

// productType
export const PRODUCT_TYPE_TRIAL = 'TRIAL';
export const PRODUCT_TYPE_FREE = 'FREEMIUM';
export const PRODUCT_TYPE_PAID = 'PAID';

/**
 * The list of product families
 * @type {object}
 */
export const families = Object.freeze({
  G2M: 'G2M',
  G2W: 'G2W',
  G2T: 'G2T',
  G2AX: 'G2AX',
  G2ASD: 'G2ASD',
  G2MGR: 'G2MGR',
  G2ASEEIT: 'G2ASEEIT',
  OPENVOICE: 'OPENVOICE',
  EA: 'EA',
  PROMPT: 'PROMPT',
  // unsupported families
  G2MFREE: 'G2MFREE',
  CONCIERGE: 'CONCIERGE',
  G2WSESSION: 'G2WSESSION'
});

/**
 * The list of product family names
 * @type {object}
 */
export const familyNames = Object.freeze({
  G2M: 'GoTo Meeting',
  G2W: 'GoTo Webinar',
  G2T: 'GoTo Training',
  G2AX: 'GoToAssist Express',
  G2ASD: 'GoToAssist Service Desk',
  G2MGR: 'GoToAssist Remote Support',
  G2ASEEIT: 'GoToAssist Seeit',
  OPENVOICE: 'OpenVoice',
  EA: 'Enhanced Audio',
  PROMPT: 'Prompt',
  other: 'other',
  G2WSESSION: 'G2WSESSION Usage'
});

/**
 * The list of emphasized product family names which are used as regular expressions
 * @NOTE likely will not be in ProductCatalog service
 * @type {object}
 */
export const emphasizedFamilyNames = Object.freeze({
  G2M: 'GoTo',
  G2W: 'GoTo',
  G2T: 'GoTo',
  G2AX: 'GoTo',
  G2ASD: 'GoTo',
  G2MGR: 'GoTo',
  G2ASEEIT: 'GoTo',
  OPENVOICE: 'Open',
  EA: 'Enhanced Audio',
  PROMPT: 'Prompt',
  G2WSESSION: 'G2WSESSION Usage'
});

/**
 * Transform scim product family keys to billing portal product family keys
 * @NOTE likely will not be in ProductCatalog service
 * @type {object}
 */
export const scimFamilyToCommerceFamily = Object.freeze({
  g2m: families.G2M,
  g2w: families.G2W,
  g2t: families.G2T,
  g2ars: families.G2MGR,
  g2asd: families.G2ASD,
  g2aseeit: families.G2ASEEIT,
  openvoice: families.OPENVOICE,
  prompt: families.PROMPT
});

/**
 * The react-intl IDs for the family description copy
 * @type {object}
 */
export const productFamilyDescriptions = Object.freeze({
  [families.G2M]: 'family.description.g2m',
  [families.G2W]: 'family.description.g2w',
  [families.G2T]: 'family.description.g2t',
  [families.OPENVOICE]: 'family.description.ov',
  [families.G2MGR]: 'family.description.g2ars',
  [families.G2ASD]: 'family.description.g2asd',
  [families.G2ASEEIT]: 'family.description.g2aseeit'
});

/**
 * Looks up the emphasized product family name based on the product family key
 * @param {string} productFamilyKey
 * @returns {string}
 */
export const productEmphasizedFamilyNameByKey = (productFamilyKey) => emphasizedFamilyNames[productFamilyKey] || '';

/**
 * Looks up the product family name based on the product family key
 * @param {string} productFamilyKey
 * @returns {string}
 */
export const productFamilyNameByKey = (productFamilyKey) => familyNames[productFamilyKey] || '';

/**
* This is a feature list placeholder for the cancellation retention campaigns
*
* In some of the cancellation flows, we display some new features for the product that's being cancelled in order to convince a customer to keep his/her subscription.
* And sometimes those new features are different than the existing ones for the subscription that's being cancelled
*/
const productsCancellationFeaturesList = Object.freeze({
  [families.G2M]: {
    g2m_ProPlus_flatRate12: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlus_flatRate_12: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlusGBP_flatRate12: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlusEUR_flatRate12: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlus_flatRate14: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlus_flatRate10: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_ProPlus_flatRate6: ['unlimitedrecordings', 'personalroom', 'hdvideowithwebcams25', 'businessmessaging', 'dialinphonenumbers'],
    g2m_Starter_flatRate8: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_Starter_flatRate_8: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_StarterGBP_flatRate8: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_StarterEUR_flatRate8: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_Starter_flatRate10: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_Starter_flatRate6: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_Starter_flatRate4: ['participants10', 'hdvideowithwebcams11', 'dialinconferenceline', 'customercare', 'unlimitedmeetings'],
    g2m_flatRate8: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_GBP_flatRate8: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_EUR_flatRate8: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_Professional_flatRate_8: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_Professional_flatRate10: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_Professional_flatRate6: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_Professional_flatRate4: ['participants150', 'notimelimits', 'calendarintegrations', 'meetinglock'],
    g2m_flatRate12: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_GBP_flatRate12: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_EUR_flatRate12: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_Business_flatRate_12: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_Business_flatRate14: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_Business_flatRate10: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock'],
    g2m_Business_flatRate6: ['participants250', 'notimelimits', 'calendarintegrations', 'unlimitedcloudrecording', 'meetinglock']
  },
  [families.G2W]: {
    flatRate49_59: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    flatRateGBP_39_49: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    flatRateEUR_41_49: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    flatRateCAD_64_72: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    flatRateAUD_65_73: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_lowtier_flatRate39_49: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_lowtierGBP_flatRate29_39: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_lowtierEUR_flatRate33_41: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_lowtierCAD_flatRate51_65: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_lowtierAUD_flatRate52_66: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_midtier_flatRate129: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_midtierGBP_flatRate99: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_midtierEUR_flatRate108: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_midtierCAD_flatRate155: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_midtierAUD_flatRate164: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_hightier_flatRate300: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_hightierGBP_flatRate200: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_hightierEUR_flatRate251: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_hightierCAD_flatRate390: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage'],
    g2w_hightierAUD_flatRate398: ['fullserviceregistration', 'pollshandouts', 'reportinganalytics', 'custombranding', 'automatedemails', 'unlimitedstorage']
  },
  [families.G2T]: {
    flatrate87_95: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    flatrate209_279: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    flatrateCAD_87_95: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    flatrateAUD_92_123: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    flatrateGBP_55_75: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    g2t_lowtier_flatrate87_95: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    g2t_lowtierAUD_92_123: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    g2t_lowtierEUR_87_95: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    g2t_lowtierGBP_55_75: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'reportsandmetrics'],
    g2t_midtier_flatrate127_143: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_midtierCAD_159_183: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_midtierAUD_172_191: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_midtierEUR_135_151: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_midtierGBP_107_119: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_hightier_flatrate209_279: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_hightierEUR_252_279: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms'],
    g2t_hightierGBP_199_223: ['pollandtests', 'materialmanagement', 'trainingrecordings', 'breakoutrooms']
  },
  [families.OPENVOICE]: {},
  [families.EA]: {},
  [families.G2AX]: {},
  [families.G2ASD]: {},
  [families.G2MGR]: {
    gtars_flatRate46_55: ['unattendedaccess', 'notes', 'chat', 'filetransfer'],
    gtars_flatRate36_45: ['unattendedaccess', 'notes', 'chat', 'filetransfer']
  },
  [families.G2ASEEIT]: {},
  [families.PROMPT]: {},
  G2W_Plus_Flex: ['events', 'access', 'payperevents', 'recording']
});

/**
 * The IDs that are used to describe a family's paid unit noun (e.g., organizer, technician, expert)
 * @type {object}
 */
export const paidUser = Object.freeze({
  [families.G2M]: 'organizer',
  [families.G2W]: 'organizer',
  [families.G2T]: 'organizer',
  [families.EA]: 'organizer',
  [families.G2WSESSION]: 'organizer',
  [families.OPENVOICE]: 'attendee',
  [families.G2AX]: 'agent',
  [families.G2ASD]: 'agent',
  [families.G2MGR]: 'agent',
  [families.G2ASEEIT]: 'expert',
  [families.PROMPT]: 'employee'
});

/**
 * The IDs that are used to describe a family's unpaid unit noun (e.g., participant, attendee)
 * @type {object}
 */
export const unpaidUser = Object.freeze({
  [families.G2M]: 'participant',
  [families.G2W]: 'participant',
  [families.G2T]: 'attendee',
  [families.OPENVOICE]: 'participant',
  [families.EA]: 'participant',
  [families.G2WSESSION]: 'participant',
  [families.G2AX]: false,
  [families.G2ASD]: false,
  [families.G2MGR]: false,
  [families.G2ASEEIT]: false,
  [families.PROMPT]: false
});

/**
 * The IDs that are used to describe a family's unit description
 * @type {object}
 */
export const unitDescriptionKey = Object.freeze({
  [families.G2M]: 'g2m',
  [families.G2W]: 'g2w',
  [families.G2T]: 'g2t',
  [families.OPENVOICE]: false,
  [families.EA]: false,
  [families.G2AX]: false,
  [families.G2ASD]: false,
  [families.G2MGR]: false,
  [families.G2ASEEIT]: false,
  [families.PROMPT]: false,
  [families.G2WSESSION]: false
});

/**
 * The list of products. This is the front-end managed Product Catalog. Object keys are productKeys.
 * Eventually as much of this information as possible should come from a backend ProductCatalog service
 * @type {object<Product>}
 */
export const products = Object.freeze({
  // Enhanced Audio - current product offering
  Enhanced_Audio_PayGo: {
    family: families.EA
  },
  Enhanced_Audio_Flat_Rate: {
    family: families.EA
  },
  Enhanced_Audio_Flat_Rate_global_callme: {
    family: families.EA
  },

  // GoToMeeting Free - blacklisted products
  G2M_Voice_Free_2: {
    family: families.G2MFREE
  },
  G2M_Voice_Free0: {
    family: families.G2MFREE
  },
  G2M_Voice_Free: {
    family: families.G2MFREE
  },
  // GoToMeeting - legacy
  G2M_5: {
    family: families.G2M
  },
  G2M_Essentials: {
    family: families.G2M
  },
  G2M_Trial_5: {
    family: families.G2M
  },
  G2M_Trial_5_Essentials: {
    family: families.G2M
  },
  G2M_25: {
    family: families.G2M
  },
  G2M_Trial_25: {
    family: families.G2M
  },
  G2M_100: {
    family: families.G2M
  },
  G2M_Trial_100: {
    family: families.G2M
  },
  G2M_100_RETIRED: {
    family: families.G2M
  },
  G2M_Voice_Paid: {
    family: families.G2M
  },
  G2M_INCLUDED: {
    family: families.G2M
  },
  G2M_Starter: {
    family: families.G2M
  },
  G2M_Starter2: {
    family: families.G2M
  },
  G2M_Pro: {
    family: families.G2M
  },
  G2M_Plus: {
    family: families.G2M
  },
  // GoToMeeting - in test mode
  G2M_UNLIMITED_TEST: {
    family: families.G2M
  },
  // GoToMeeting - current product offering
  G2M_Business_Trial: {
    family: families.G2M
  },
  G2M_Trial_Beta: {
    family: families.G2M
  },
  G2M_Starter3: {
    family: families.G2M
  },
  G2M_Pro2: {
    family: families.G2M,
    default: true
  },
  G2M_Pro_Trial: {
    family: families.G2M
  },
  G2M_Plus2: {
    family: families.G2M
  },
  G2M_Trial_Interim_Test: {
    family: families.G2M
  },
  G2M_Professional_Test: {
    family: families.G2M
  },
  G2M_Business_Test: {
    family: families.G2M
  },
  // GoToWebinar - legacy
  G2W_Trial_100: {
    family: families.G2W
  },
  G2W_Trial_500: {
    family: families.G2W
  },
  G2W_100: {
    family: families.G2W
  },
  G2W_500: {
    family: families.G2W
  },
  G2W_500_Pro2: {
    // G2W_500_Pro2 not used in live environment (https://jira.ops.expertcity.com/browse/CPS-480)
    family: families.G2W
  },
  G2W_1000: {
    family: families.G2W
  },
  G2W_1000_Plus: {
    family: families.G2W
  },
  G2W_1000_Legacy: {
    family: families.G2W
  },
  G2W_INDIVIDUAL: {
    family: families.G2W
  },
  G2W_ENTERPRISE: {
    family: families.G2W
  },
  // GoToWebinar - legacy (Backend TEST mode)
  G2W_Trial: {
    family: families.G2W
  },
  G2W_TEAM_TRIAL: {
    family: families.G2W,
    paidUser: 'team',
    unitDescriptionKey: false
  },
  G2W_Lite: {
    family: families.G2W
  },
  G2W_Lite2: {
    family: families.G2W
  },
  G2W_Standard: {
    family: families.G2W
  },
  G2W_TEAM: {
    family: families.G2W,
    paidUser: 'team',
    unitDescriptionKey: false
  },
  G2W_TEAM3: {
    family: families.G2W,
    paidUser: 'team',
    unitDescriptionKey: false
  },
  G2W_Lite_3: {
    family: families.G2W
  },
  G2W_Standard_2: {
    family: families.G2W
  },
  G2W_TEAM3_2: {
    family: families.G2W,
    paidUser: 'team',
    unitDescriptionKey: false
  },
  G2W_Pro_2: {
    family: families.G2W
  },
  G2W_ENTERPRISE_2: {
    family: families.G2W
  },
  // GoToWebinar - COVID-19 Response Products (Backend TEST mode)
  G2W_INDIVIDUAL2: {
    family: families.G2W
  },
  G2W_PROFESSIONAL: {
    family: families.G2W
  },
  G2W_BUSINESS: {
    family: families.G2W
  },
  // GoToWebinar - current product offering
  G2W_Trial100: {
    family: families.G2W
  },
  G2W_100_Starter: {
    family: families.G2W
  },
  G2W_500_Pro: {
    family: families.G2W,
    default: true
  },
  G2W_1000_Plus2: {
    family: families.G2W
  },
  G2W_Plus_Flex: {
    family: families.G2W
  },
  // GoToWebinar - not supported
  G2W_Plus_Flex_Usage: {
    family: families.G2WSESSION
  },
  // GoToTraining Legacy
  G2T_10: {
    family: families.G2T
  },
  G2T_25: {
    family: families.G2T
  },
  G2T_200: {
    family: families.G2T
  },
  G2T_Trial_10: {
    family: families.G2T
  },
  G2T_Trial_25: {
    family: families.G2T
  },
  G2T_Trial_200: {
    family: families.G2T
  },
  G2T_200_Plus_Trial: {
    family: families.G2T
  },
  G2T_25_Starter_Trial: {
    family: families.G2T
  },
  G2T_Free_3: {
    family: families.G2T
  },
  // GoToTraining - current product offering
  G2T_25_Starter: {
    family: families.G2T
  },
  G2T_50_Pro_Trial: {
    family: families.G2T
  },
  G2T_50_Pro: {
    family: families.G2T,
    default: true
  },
  G2T_200_Plus: {
    family: families.G2T
  },
  // OpenVoice - current product offering
  OV_PAYG: {
    family: families.OPENVOICE
  },
  OVI_DIALOUT: {
    family: families.OPENVOICE
  },
  // OpenVoice - in test mode
  OVI_FLAT_RATE_TEST: {
    family: families.OPENVOICE
  },
  OVI_COMMIT_TEST: {
    family: families.OPENVOICE
  },
  OVI_FLAT_RATE_INTERIM_TEST: {
    family: families.OPENVOICE
  },
  // GoToAssist Seeit - legacy
  G2ASI: {
    family: families.G2ASEEIT
  },
  G2A_SEEIT_FREE: {
    family: families.G2ASEEIT
  },
  // GoToAssist Seeit - current product offering
  G2ASI_TRIAL2: {
    family: families.G2ASEEIT
  },
  G2ASI_TRIAL: {
    family: families.G2ASEEIT
  },
  G2ASI2: {
    family: families.G2ASEEIT,
    default: true
  },
  FREE_PLAN: {
    family: families.G2ASEEIT
  },
  // GoToAssist Service Desk - current product offering
  G2ASD_1Seat_Trial: {
    family: families.G2ASD
  },
  G2ASD_1Seat: {
    family: families.G2ASD,
    default: true
  },
  G2ASD_Concurrent_Trial: {
    family: families.G2ASD
  },
  G2ASD_Concurrent: {
    family: families.G2ASD
  },
  // GoToAssist Remote Support - legacy
  G2MGR_Free: {
    family: families.G2MGR
  },
  G2ARS_Concurrent: {
    family: families.G2MGR
  },
  G2A_RS_Day_Pass: {
    family: families.G2MGR
  },
  // RescueAssist - current product offering
  G2ARS_Concurrent2: {
    family: families.G2MGR
  },
  G2ARS_Concurrent_Trial: {
    family: families.G2MGR
  },
  G2MGR_Trial_1Seat: {
    family: families.G2MGR
  },
  G2MGR_1Seat: {
    family: families.G2MGR,
    default: true
  },
  G2MGR_1Seat_Migrated: {
    family: families.G2MGR
  },
  RA_Mobile: {
    family: families.G2MGR,
    paidUser: 'mobileaddon',
    unpaidUser: 'mobileaddon',
    unitDescriptionKey: 'mobileaddon'
  },
  RA_Mobile_Concurrent: {
    family: families.G2MGR,
    paidUser: 'mobileaddon',
    unpaidUser: 'mobileaddon',
    unitDescriptionKey: 'mobileaddon'
  },
  RA_Mobile_Trial: {
    family: families.G2MGR,
    paidUser: 'mobileaddon',
    unpaidUser: 'mobileaddon',
    unitDescriptionKey: 'mobileaddon'
  },
  RA_Mobile_Concurrent_Trial: {
    family: families.G2MGR,
    paidUser: 'mobileaddon',
    unpaidUser: 'mobileaddon',
    unitDescriptionKey: 'mobileaddon'
  },
  // GoToAssist Express
  G2AX_30_day_trial: {
    family: families.G2AX
  },
  'G2AX Day Pass': {
    family: families.G2AX
  },
  // Prompt - legacy
  prompt_beta: {
    family: families.PROMPT
  },
  // Prompt - current product offering
  PROMPT_TRIAL: {
    family: families.PROMPT
  },
  PROMPT_STARTER: {
    family: families.PROMPT,
    default: true
  },
  // Concierge - blacklisted products
  CONCIERGE_FREE: {
    family: families.CONCIERGE
  },
  // Fallback product
  UNKNOWN: {
    family: 'UNKNOWN'
  }
});

export const productFamilyEndpoints = Object.freeze({
  G2M: environment.get('products.G2M.defaults.productUrl'),
  G2W: environment.get('products.G2W.defaults.productUrl'),
  G2T: environment.get('products.G2T.defaults.productUrl'),
  OPENVOICE: environment.get('products.OPENVOICE.defaults.productUrl'),
  G2MGR: environment.get('products.G2MGR.defaults.productUrl'),
  G2ASD: environment.get('products.G2ASD.defaults.productUrl'),
  G2ASEEIT: environment.get('products.G2ASEEIT.defaults.productUrl'),
  G2AX: environment.get('products.G2AX.defaults.productUrl'),
  PROMPT: environment.get('products.PROMPT.defaults.productUrl')
});

export const productFamilyHubEndpoint = Object.freeze({
  G2M: environment.get('products.G2M.defaults.goToHubUrl')
});

/**
 * This is a feature message placeholder for the cancellation retention campaigns.
 * NOTE: Get the participant count from the EE.
 */
const productParticipantsCount = Object.freeze({
  [families.G2W]: {
    g2w_lowtier_flatRate39_49: '100',
    g2w_lowtierGBP_flatRate29_39: '100',
    g2w_lowtierEUR_flatRate33_41: '100',
    g2w_midtier_flatRate129: '500',
    g2w_midtierGBP_flatRate99: '500',
    g2w_midtierEUR_flatRate108: '500',
    g2w_hightier_flatRate300: '1000',
    g2w_hightierGBP_flatRate200: '1000',
    g2w_hightierEUR_flatRate251: '1000'
  }
});

/**
 * Gets the cancellation feature list based on the product family key and the cancellation campaign name
 * @param {string} productFamilyKey
 * @param {string} campaignName
 * @returns {array}
 */
export const getProductCancellationFeaturesByProductFamilyKey = (productFamilyKey, campaignName) => (productsCancellationFeaturesList[productFamilyKey] || {})[campaignName] || [];


export const getProductCancellationFeaturesByProductKey = (productKey) => productsCancellationFeaturesList[productKey];
/**
 * Gets the family endpoint URL given a family key or throws an exception if not found
 * @param {string} familyKey
 * @returns {string}
 */
export const getProductFamilyEndpointByKey = (familyKey) => {
  if (!Object.prototype.hasOwnProperty.call(productFamilyEndpoints, familyKey)) {
    throw new ProductFamilyNotFoundException(familyKey);
  }
  return productFamilyEndpoints[familyKey];
};

/**
 * Gets the marketing product URL for a specified product or product family.
 *
 * @param {string} productFamilyKey
 * @param {string} productKey
 * @returns {string} url to the marketing resource for this product.
 */
export const getProductMarketingUrl = (productFamilyKey, productKey = null) => {
  const productFamilySettings = environment.get(`products.${productFamilyKey}`);

  if (!productFamilySettings) {
    return '';
  }

  // prioritize finding a URL by the productKey
  if (productFamilySettings[productKey]) {
    return productFamilySettings[productKey].marketingProductUrl || '';
  }

  // then try to find the URL by the product family's defaults
  if (productFamilySettings.defaults) {
    return productFamilySettings.defaults.marketingProductUrl || '';
  }

  return '';
};

/**
 * Gets the family given a family key
 * If throwOnFail is true then it will throw an exception (default) otherwise it will return null (useful for lookups)
 * @param {string} familyKey
 * @param {bool} [throwOnFail=true] Whether to throw exception if family is not found
 * @throws {ProductFamilyNotFoundException} Will throw if product family is not found
 * @returns {string|null}
 */
export const getFamilyByKey = (familyKey, throwOnFail = true) => {
  if (!Object.prototype.hasOwnProperty.call(families, familyKey)) {
    if (throwOnFail) {
      throw new ProductFamilyNotFoundException(familyKey);
    }
    return null;
  }
  return families[familyKey];
};

/**
 * Gets the details of a product
 * @param productKey
 * @returns {Product}
 */
export const getProductByKey = (productKey) => {
  if (!productKey) {
    return products.UNKNOWN;
  }

  const foundProduct = find(products, (product, key) => key === productKey);

  if (!foundProduct) {
    return products.UNKNOWN;
  }

  return foundProduct;
};

/**
 * Looks up the family based on the productKey
 * @param {string} productKey
 * @returns {string}
 */
export const getProductFamily = (productKey) => {
  const product = getProductByKey(productKey) || {};
  return product.family || '';
};

/**
 * Gets a collection of all products that exist for a provided product family key
 * @returns {object} products
 */
export const getProductsInFamily = (familyKey) => {
  const family = getFamilyByKey(familyKey);
  return pickBy(products, (product) => product.family === family);
};

/**
 * Gets an array of product families
 * @returns {string[]} families
 */
export const getFamilies = () => Object.keys(families).map((key) => families[key]);

/**
 * Gets the react-intl ID for a provided family key's description
 * @returns {string[]} families
 */
export const getFamilyDescription = (productFamily) => productFamilyDescriptions[productFamily];

/**
 * Get the collaboration families in their appropriate display order
 */
export const getCollaborationFamilies = () => [
  families.G2M,
  families.G2W,
  families.G2T,
  families.OPENVOICE
];

/**
 * Get the support families in their appropriate display order
 */
export const getSupportFamilies = () => [
  families.G2MGR,
  families.G2ASD,
  families.G2ASEEIT
];

/**
 * Return the unit which describes the product units (paid or unpaid).
 * E.g., GoToMeeting Pro has paid units called "organizers" and unpaid units called "participants"
 *
 * @param {string} productKey
 * @param {string} [unitType="paid"] - Either "paid" or "unpaid"
 * @returns {string}
 */
export const getUnitByProductKey = (productKey, unitType = 'paid') => {
  const unitProp = (unitType === 'paid') ? 'paidUser' : 'unpaidUser';

  // the product has specified its own unit name
  if ((products[productKey] || {})[unitProp]) {
    return products[productKey][unitProp];
  }
  // fallback to the product family unit type
  if (unitType === 'paid') {
    return paidUser[(products[productKey] || {}).family] || 'default';
  }
  return unpaidUser[(products[productKey] || {}).family] || 'default';
};

/**
 * Return the unit description keyword which describes the product units.
 *
 * @param {string} productKey
 * @returns {string || boolean}
 */
export const getUnitDescriptionKeyByProductKey = (productKey) => {
  if (!Object.prototype.hasOwnProperty.call(products, productKey)) {
    throw new ProductNotFoundException(productKey);
  }

  // the product has its own unit description key
  if (Object.prototype.hasOwnProperty.call(products[productKey], 'unitDescriptionKey')) {
    return products[productKey].unitDescriptionKey;
  }

  // fallback to the product family unit description key
  return unitDescriptionKey[getProductFamily(productKey)] || '';
};


/**
 * Billing Period - DAY
 * @type {string}
 */
export const BILLING_PERIOD_DAY = 'DAY';
/**
 * Billing Period - MONTH
 * @type {string}
 */
export const BILLING_PERIOD_MONTH = 'MONTH';
/**
 * Billing Period - YEAR
 * @type {string}
 */
export const BILLING_PERIOD_YEAR = 'YEAR';

/**
 * Pending Action - REDUCE
 * {@link https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=BP&title=Commerce+Orchestration+Gateway+REST+API#CommerceOrchestrationGatewayRESTAPI-PendingActionPendingAction|Documentation}
 * @type {string}
 */
export const PENDING_ACTION_REDUCE = 'REDUCE';
/**
 * Pending Action - DOWNGRADE
 * {@link https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=BP&title=Commerce+Orchestration+Gateway+REST+API#CommerceOrchestrationGatewayRESTAPI-PendingActionPendingAction|Documentation}
 * @type {string}
 */
export const PENDING_ACTION_DOWNGRADE = 'DOWNGRADE';
/**
 * Pending Action - CANCEL
 * {@link https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=BP&title=Commerce+Orchestration+Gateway+REST+API#CommerceOrchestrationGatewayRESTAPI-PendingActionPendingAction|Documentation}
 * @type {string}
 */
export const PENDING_ACTION_CANCEL = 'CANCEL';
/**
 * Pending Action - CONVERT
 * {@link https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=BP&title=Commerce+Orchestration+Gateway+REST+API#CommerceOrchestrationGatewayRESTAPI-PendingActionPendingAction|Documentation}
 * @type {string}
 */
export const PENDING_ACTION_CONVERT = 'CONVERT';

/**
 * Returns a collection of all products
 * @returns {object} All {@link Product} objects
 */
export const getProducts = () => {
  const mappedProducts = {};
  forEach(products, (product, key) => {
    mappedProducts[key] = getProductByKey(key);
  });
  return mappedProducts;
};

/**
 * Returns a product key from the specified product family where the default flag is set
 * @returns {string} product key for the product in the family set as default: true
 */
export const getDefaultProductKeyByFamilyKey = (productFamilyKey) => {
  const productsInFamily = getProductsInFamily(productFamilyKey);
  return findKey(productsInFamily, (product) => product.default);
};

/**
 * Checks that the pending action is an upgrade
 * @param {string} action - The pending action string identifier
 * @returns {boolean}
 */
export const isPendingActionUpgrade = (action) => {
  const upgrade = [PENDING_ACTION_CONVERT];
  return upgrade.includes(action);
};

/**
 * Determines if the productType is a TRIAL
 * @param productType
 * @returns {boolean}
 */
export const productTypeIsTrial = (productType) => productType === PRODUCT_TYPE_TRIAL;

/**
 * Determines if the productType is FREEMIUM
 * @param productType
 * @returns {boolean}
 */
export const productTypeIsFree = (productType) => productType === PRODUCT_TYPE_FREE;

/**
 * Determines if the productType is FREEMIUM or TRIAL
 * @param productType
 * @returns {boolean}
 */
export const productTypeIsFreeOrTrial = (productType) => productType === PRODUCT_TYPE_FREE || productType === PRODUCT_TYPE_TRIAL;

/**
 * Determines if the productType is PAID
 * @param productType
 * @returns {boolean}
 */
export const productTypeIsPaid = (productType) => productType === PRODUCT_TYPE_PAID;

/**
 * Determines if the action is PENDING_ACTION_CANCEL
 * @param action
 * @returns {boolean}
 */
export const isPendingActionCancel = (action) => action === PENDING_ACTION_CANCEL;

/**
 * Return whether the new billing period an upgrade from the current billing period
 * @param {string} newBillingPeriod
 * @param {string} currentBillingPeriod
 * @returns {boolean}
 */
export const isBillingPeriodUpgrade = (newBillingPeriod, currentBillingPeriod) => {
  // a collection of all possible billing periods, in "upgrade" order
  // @NOTE do not change the order of this array unless you intend to change which billing periods are considered upgrades
  //       or downgrades of each other
  const billingPeriods = [
    BILLING_PERIOD_DAY,
    BILLING_PERIOD_MONTH,
    BILLING_PERIOD_YEAR
  ];
  return billingPeriods.indexOf(newBillingPeriod) > billingPeriods.indexOf(currentBillingPeriod);
};

/**
 * Determines if the billingPeriod is DAY
 * @param billingPeriod
 * @returns {boolean}
 */
export const productBillingPeriodIsDay = (billingPeriod) => billingPeriod === BILLING_PERIOD_DAY;

/**
 * Determines if the billingPeriod is MONTH
 * @param billingPeriod
 * @returns {boolean}
 */
export const productBillingPeriodIsMonth = (billingPeriod) => billingPeriod === BILLING_PERIOD_MONTH;

/**
 * Determines if the billingPeriod is YEAR
 * @param billingPeriod
 * @returns {boolean}
 */
export const productBillingPeriodIsYear = (billingPeriod) => billingPeriod === BILLING_PERIOD_YEAR;

/**
 * Determines if the productFamilyKey is OPENVOICE
 * @param productFamilyKey
 * @returns {boolean}
 */
export const productFamilyKeyIsOpenVoice = (productFamilyKey) => productFamilyKey === families.OPENVOICE;

/**
 * Is the productFamilyKey G2M
 * @param productFamilyKey
 * @returns {boolean}
 */
export const productFamilyKeyIsG2M = (productFamilyKey) => productFamilyKey === families.G2M;

/**
 * Is the productFamilyKey G2MGR
 * @param productFamilyKey
 * @returns {boolean}
 */
export const productFamilyKeyIsG2MGR = (productFamilyKey) => productFamilyKey === families.G2MGR;

/**
 * Is the productFamilyKey G2W
 * @param productFamilyKey
 * @returns {boolean} This returns true if the productFamily is equal to G2W
 */
export const productFamilyKeyIsG2W = (productFamilyKey) => productFamilyKey === families.G2W;

/**
 * Is the productFamilyKey G2T
 * @param productFamilyKey
 * @returns {boolean} This returns true if the productFamily is equal to G2T
 */
export const productFamilyKeyIsG2T = (productFamilyKey) => productFamilyKey === families.G2T;

/**
 * Is the productFamilyKey G2W
 * @param productFamilyKey
 * @returns {boolean}
 */
export const productFamilyKeyIsInEeCancelRetentionCampaign = (productFamilyKey) => productFamilyKeyIsG2M(productFamilyKey) || productFamilyKeyIsG2W(productFamilyKey)
  || productFamilyKeyIsG2MGR(productFamilyKey) || productFamilyKeyIsG2T(productFamilyKey);

/**
 * Determines if the product by productKey is OPENVOICE product family
 * @param productKey
 * @returns {boolean}
 */
export const productByProductKeyIsOpenVoiceFamily = (productKey) => getProductFamily(productKey) === families.OPENVOICE;

/**
 * Return whether the specified productKey is G2W_TEAM3, G2W_TEAM or G2W_TEAM_TRIAL (there are some special checks for G2W Team since its purchasable unit is
 * a "team" which corresponds to 5 organizer seats (first product like this; other products are 1-to-1 purchasable units to seats)
 *
 * @param productKey
 * @returns {boolean}
 */
export const productIsG2WTeam = (productKey) => ['G2W_TEAM3', 'G2W_TEAM', 'G2W_TEAM_TRIAL', 'G2W_TEAM3_2'].includes(productKey); // these need to match the key in products array

/**
 * Return whether the specified productKey has "skipMarketingFlow" set
 *
 * @param {string} productKey
 * @returns {boolean}
 */
export const productShouldSkipMarketingFlow = (productKey) => !!(products[productKey] || {}).skipMarketingFlow;

/**
 * Return the correct classes needed to create a togo-icon based on that product key
 *
 * @param {string} productKey
 * @returns {string}
 */
export const productIconClassesByProductKey = (productKey) => {
  const iconClasses = {
    G2ASD: 'togo-icon-gotoassist togo-color-g2a',
    G2ASEEIT: 'togo-icon-seeit togo-color-g2a',
    OPENVOICE: 'togo-icon-openvoice togo-color-openvoice',
    G2MGR: 'togo-icon-gotoassist togo-color-g2a',
    PROMPT: 'togo-icon-prompt togo-color-prompt'
  };
  return iconClasses[productKey] ? iconClasses[productKey] : productKey;
};

/**
 * Determine if the product should use the CallMe addon language
 *
 * @param {string} productKey
 * @returns {boolean}
 */
export const productShouldUseCallMeAddon = (productKey) => !!['G2M_Pro2', 'G2M_Plus2', 'G2M_Starter3'].includes(productKey);

/**
 * Gets the participant count by the product family key and the campaign name
 * TODO: Get the participant count from the EE.
 */
export const getParticipantCountByProductFamilyKey = (productFamilyKey, campaignName) => (productParticipantsCount[productFamilyKey] || {})[campaignName] || '';

/**
 * list of addons
 */
export const allAddons = Object.freeze([
  'Enhanced_Audio_PayGo',
  'Enhanced_Audio_Flat_Rate',
  'Enhanced_Audio_Flat_Rate_global_callme',
  'OVI_DIALOUT',
  'OVI_COMMIT_TEST',
  'OVI_FLAT_RATE_INTERIM_TEST',
  'RA_Mobile',
  'RA_Mobile_Concurrent'
]);

export default {
  PENDING_ACTION_CANCEL,
  BILLING_PERIOD_DAY,
  BILLING_PERIOD_MONTH,
  BILLING_PERIOD_YEAR,
  PRODUCT_TYPE_TRIAL,
  PRODUCT_TYPE_FREE,
  PRODUCT_TYPE_PAID,
  PENDING_ACTION_CONVERT,
  PENDING_ACTION_REDUCE,
  PENDING_ACTION_DOWNGRADE,
  emphasizedFamilyNames,
  families,
  familyNames,
  getCollaborationFamilies,
  getDefaultProductKeyByFamilyKey,
  getFamilies,
  getFamilyByKey,
  getFamilyDescription,
  getProductByKey,
  getProductCancellationFeaturesByProductFamilyKey,
  getProductCancellationFeaturesByProductKey,
  getProductFamily,
  getProductFamilyEndpointByKey,
  getProductMarketingUrl,
  getProducts,
  getProductsInFamily,
  getSupportFamilies,
  getUnitByProductKey,
  getUnitDescriptionKeyByProductKey,
  isBillingPeriodUpgrade,
  isPendingActionCancel,
  isPendingActionUpgrade,
  paidUser,
  productBillingPeriodIsDay,
  productBillingPeriodIsMonth,
  productBillingPeriodIsYear,
  productByProductKeyIsOpenVoiceFamily,
  productEmphasizedFamilyNameByKey,
  productFamilyEndpoints,
  productFamilyHubEndpoint,
  productFamilyKeyIsG2M,
  productFamilyKeyIsG2W,
  productFamilyKeyIsG2MGR,
  productFamilyKeyIsInEeCancelRetentionCampaign,
  productFamilyKeyIsOpenVoice,
  productFamilyNameByKey,
  productIconClassesByProductKey,
  productIsG2WTeam,
  products,
  allAddons,
  productShouldSkipMarketingFlow,
  productShouldUseCallMeAddon,
  productTypeIsFree,
  productTypeIsFreeOrTrial,
  productTypeIsPaid,
  productTypeIsTrial,
  scimFamilyToCommerceFamily,
  unpaidUser,
  getParticipantCountByProductFamilyKey
};
