import { combineReducers } from 'redux';
import { reduxFormReducer } from 'modules/form';
import alert from 'modules/alert-module';
import { billingAccountReducer } from 'modules/billing-account';
import cart from 'modules/cart-module';
import errors from 'modules/errors-module';
import { experimentsReducer } from 'modules/experiments';
import { importantDatesReducer } from 'modules/important-dates';
import me from 'modules/me-module';
import account from 'modules/account-module';
import modal from 'modules/modal-module';
import paymentForm from 'modules/payment-form-module';
import paymentInfo from 'modules/payment-info-module';
import paymentMethods from 'modules/payment-methods-module';
import product from 'modules/product-module';
import { quoteReducer } from 'modules/quote';
import { subscriptionReducer } from 'modules/subscription';
import taxForm from 'modules/tax-form-module';
import { uiReducer } from 'modules/ui';
import { invoiceReducer } from 'modules/invoice';
import { scaToken } from 'modules/payment/sca-token';
import { payerAuth } from 'modules/payment/payer-auth';

// eslint-disable-next-line no-shadow
export const makeRootReducerFactory = ({combineReducers, ...reducers}) => (asyncReducers) => combineReducers({
  // Add sync reducers here
  ...reducers,
  ...asyncReducers
});

export const makeRootReducer = makeRootReducerFactory({
  combineReducers,
  billingAccount: billingAccountReducer,
  cart,
  quote: quoteReducer,
  me,
  modal,
  paymentMethods,
  paymentForm,
  paymentInfo,
  product,
  subscription: subscriptionReducer,
  taxForm,
  ui: uiReducer,
  invoice: invoiceReducer,
  importantDates: importantDatesReducer,
  form: reduxFormReducer,
  errors,
  experiments: experimentsReducer,
  alert,
  scaToken,
  payerAuth,
  account
});

export default makeRootReducer;
