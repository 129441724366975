import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlProps } from 'modules/intl';
import st from 'components/shared-translations';

export const AvatarView = (props) => <img
  className={props.className}
  id={props.id}
  src={props.avatarUrl}
  alt={props.intl.formatMessage(st['avatar.alt'])}
/>;

AvatarView.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  intl: intlProps.isRequired
};

export default injectIntl(AvatarView);
