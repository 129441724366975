import React from 'react';
import {
  FormattedMessage,
  IntlProvider
} from 'react-intl';
import ExternalAnchor from 'components/external-anchor';
import errorImage from 'static/images/error-robot.png';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import st from 'components/shared-translations';

export const BootstrapFailView = ({store, myAccountUrl, reload, reactIntlMessages, languageCode}) => (
  <Provider store={store}>
    <IntlProvider
      locale={languageCode}
      messages={reactIntlMessages}
      // We need to set the textComponent to span in order to keep the old behavior where the various react-intl APIs method would wrap the output with a span elemnent
      textComponent="span"
    >
      <div id="bootstrap-fail-view" className="container text-center" style={{transform: 'translateY(50%)'}}>
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 col-xs-12">
            <img src={errorImage} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 col-xs-12">
            <h1><FormattedMessage {...st['bootstrap.fail.header']} /></h1>
            <h5><FormattedMessage {...st['bootstrap.fail.options']} /></h5>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4">
            <button
              id="bootstrap-fail-view-reload"
              className="btn btn-primary"
              onClick={reload}
              style={{marginRight: '25px'}}
            >
              <FormattedMessage {...st['bootstrap.fail.reload']} />
            </button>
            <ExternalAnchor id="bootstrap-fail-view-myaccount" href={myAccountUrl}>
              <button className="btn btn-primary">
                <FormattedMessage {...st['bootstrap.fail.myaccount']} />
              </button>
            </ExternalAnchor>
          </div>
        </div>
      </div>
    </IntlProvider>
  </Provider>
);

BootstrapFailView.propTypes = {
  languageCode: PropTypes.string.isRequired,
  myAccountUrl: PropTypes.string.isRequired,
  reactIntlMessages: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired
};

export default BootstrapFailView;
