import {
  QUOTE_ORDER_RECEIVE_POST_FAILURE,
  QUOTE_ORDER_RECEIVE_POST_SUCCESS,
  QUOTE_ORDER_REQUEST_POST,
  QUOTE_RECEIVE_GET_FAILURE,
  QUOTE_RECEIVE_GET_SUCCESS,
  QUOTE_REQUEST_GET
} from './quote-action-types';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  quoteKey: '',
  items: [],
  order: {},
  isLoading: false
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [QUOTE_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [QUOTE_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      const { payload } = action;
      return {
        ...state,
        quoteKey: payload.quoteKey,
        items: payload.items,
        isLoading: false
      };
    }
    return state;
  },
  [QUOTE_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [QUOTE_ORDER_REQUEST_POST]: (state) => ({
    ...state,
    isLoading: true
  }),
  [QUOTE_ORDER_RECEIVE_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        isLoading: false,
        order: {
          ...state.order,
          orderKey: action.payload.key
        }
      };
    }
    return state;
  },
  [QUOTE_ORDER_RECEIVE_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const quoteReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default quoteReducer;
