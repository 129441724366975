import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  alertAuthenticationWarningTime,
  alertAuthenticationWarningToggle
} from 'modules/alert-module';
import AlertAuthWarningView from './alert-auth-warning-view';
import Session from 'lib/session';
import config from 'config/appconfig';

export const mapStateToProps = (state) => ({
  timeInMs: alertAuthenticationWarningTime(state)
});

export const mapDispatchToProps = {
  handleAlertDismiss: alertAuthenticationWarningToggle
};

export const mergeProps = (stateProps, dispatchProps) => ({
  timeInMs: stateProps.timeInMs,
  handleAlertDismiss: dispatchProps.handleAlertDismiss
});

export class AlertAuthWarningContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeftInMs: props.timeInMs
    };
  }

  handleReload = () => window.location.reload();

  updateTime = () => {
    this.setState({...this.state, timeLeftInMs: this.state.timeLeftInMs - 1000});
  }

  timeRemainingInSeconds = () => Math.round(this.state.timeLeftInMs / 1000);

  intervalStep = () => {
    this.updateTime();
    if (this.state.timeLeftInMs <= 0) {
      this.stopCountdown();
    }
  }

  /**
   * Using session.removeItem here allows us to short-circuit the
   * session lifecycle and get rid of the annoying expiry ribbon.
   */
  clearSession = () => Session.removeItem(config.storage.oauth);

  startCountdown = () => {
    this.countdown = window.setInterval(this.intervalStep.bind(this), 1000);
  }

  stopCountdown = () => window.clearInterval(this.countdown);

  componentDidMount = () => {
    this.clearSession();
    this.startCountdown();
  };

  componentWillUnmount = () => this.stopCountdown();

  render = () => (
    <AlertAuthWarningView
      handleAlertDismiss={this.props.handleAlertDismiss}
      handleReload={this.handleReload}
      timeLeft={this.timeRemainingInSeconds()}
    />
  );
}

AlertAuthWarningContainer.propTypes = {
  timeInMs: PropTypes.number,
  handleAlertDismiss: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AlertAuthWarningContainer);
