import {
  meKey,
  meLanguage,
  meTimezone
} from 'modules/me-module';
import { billingAccountCountryCode } from 'modules/billing-account';
import {
  subscriptionsAllProductKeys,
  subscriptionsAllProductFamilies,
  subscriptionsAllProductBillingPeriods,
  subscriptionsAllProductBillingDurations,
  subscriptionsAllProductLongNames,
  subscriptionByKeyProductKey,
  subscriptionByKeyProductFamily,
  subscriptionByKeyProductName,
  subscriptionByKeyQuantity,
  subscriptionByKeySubscriptionPricePerUnit,
  subscriptionByKeyBillingDuration,
  subscriptionByKeyBillingPeriod,
  subscriptionsAccountKey
} from 'modules/subscription';
import {
  uiSubsConfigProductFamilyKey,
  uiSubsConfigSelectedProductKey
} from 'modules/ui';
import {
  quoteAllProductKeys,
  quoteAllProductNames,
  quoteAllProductQuantities,
  quoteAllProductBillingPeriods,
  quoteAllProductBillingDurations,
  quoteAllProductFamilies,
  quoteAllProductPricesPerUnit,
  quoteAllProductActions,
  quoteAllProductCurrencyCodes,
  quoteOrderKey,
  quoteItemNewSubscriptionQuantity,
  quoteItemNewSubscriptionBillingDuration,
  quoteItemNewSubscriptionBillingPeriod,
  quoteItemNewSubscriptionEffectivePrice,
  quoteItemNewSubscriptionProductKey,
  quoteItemNewSubscriptionProductName,
  quoteItemNewSubscriptionCurrencyCode
} from 'modules/quote';
import {
  productByKeyFamily
} from 'modules/product-module';
import { paymentMethodsDefaultProvider } from 'modules/payment-methods-module';
import { experimentLDFlags } from 'modules/experiments';
import {
  FlowStart,
  DefaultCancellationConfirm,
  CancellationComplete,
  RemoveCancelStart,
  RemoveCancelComplete,
  RetentionComplete,
  AddToCart,
  BillingInfo,
  Cart,
  CartRemove,
  CartUpdate,
  Checkout,
  Err,
  Invoices,
  Order,
  OrderConfirmation,
  ProceedToCheckout,
  ProductFamilies,
  StartTrial,
  SubscriptionGroups,
  TrialExpirationReminder
} from './tracking-constants';
import moment from 'moment-timezone';
import TealiumTracker from 'lib/tealium-tracker';
import Selectors from 'store/selectors';

/**
 * @typedef {object} TrackingTealiumBaseline
 *
 * This is the baseline information populated in all Tealium utag objects.
 * Other selectors will start with this object and augment it with their own properties.
 * Refer to and update the documentation for these events here: https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=BP&title=Tealium+Tracking
 *
 * @property {string} product - 'ngc' (stands for Next Gen Commerce, a deprecated name for the project)
 * @property {string} section - 'billing-center'
 * @property {string} language - the locale setting for the current user
 * @property {string} region - the country code of the billing account
 * @property {string} website_country - the country code of the billing account (@NOTE probably should double check with Sara, should this be same as region?)
 * @property {string} pageset - 'default'
 * @property {string} account_id - the account key for the subscriptionGroup (this is the "entitlement account key" as opposed to "billing account key"
 * @property {string} user_key - the user key
 * @property {string} user_timezone - the timezone setting for the user
 * @property {string} system_date_utc - current date in UTC (as formatted by moment)
 * @property {object} experiments - object whose fields are the LaunchDarkly active feature-flags and their values from LD
 */

/**
 * The baseline object definition that gets included in every tealium request.
 * Intended to be used in other selectors, not directly used by any container/module.
 *
 * @param state
 * @returns {TrackingTealiumBaseline}
 */
export const trackingTealiumBaseline = (state) => ({
  product: 'ngc',
  section: 'billing-center',
  language: meLanguage(state),
  region: billingAccountCountryCode(state),
  website_country: billingAccountCountryCode(state),
  pageset: 'default',
  account_id: subscriptionsAccountKey(state),
  user_key: meKey(state),
  user_timezone: meTimezone(state),
  system_date_utc: moment.utc().format(),
  experiments: experimentLDFlags(state)
});

export const trackingTealiumSubscriptionGroups = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'subscriptions',
  template: 'home',
  product_family: subscriptionsAllProductFamilies(state),
  product_id: subscriptionsAllProductKeys(state),
  product_duration: subscriptionsAllProductBillingDurations(state),
  product_period: subscriptionsAllProductBillingPeriods(state),
  product_name: subscriptionsAllProductLongNames(state)
});

export const trackingTealiumInvoices = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'invoice-history',
  template: 'home'
});

export const trackingTealiumProductFamilies = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'products'
});

export const trackingTealiumCartAdd = (state, payload) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'price-estimate',
  event_type: 'scAdd',
  product_id: [payload.productKey],
  product_family: [payload.productFamilyKey],
  product_name: [payload.productName],
  promo: payload.promoCodes
});

export const trackingTealiumProceedToCheckout = (state, payload) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'price-estimate',
  event_type: 'scCheckout',
  product_id: [payload.productKey],
  product_family: [payload.productFamilyKey],
  product_name: [payload.productName],
  promo: payload.promoCodes
});

export const trackingTealiumStartTrial = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'price-estimate',
  event_type: 'scTrial',
  product_id: [uiSubsConfigSelectedProductKey(state)],
  product_family: [uiSubsConfigProductFamilyKey(state)],
  product_name: [Selectors.uiSubsConfigSelectedProductName(state)],
  promo: Selectors.productByKeyPromotionCodes(state, uiSubsConfigSelectedProductKey(state))
});

export const trackingTealiumCart = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'cart-overview',
  event_type: 'scView',
  product_id: quoteAllProductKeys(state),
  product_family: quoteAllProductFamilies(state),
  product_name: quoteAllProductNames(state),
  product_quantity: quoteAllProductQuantities(state),
  product_unit_price: quoteAllProductPricesPerUnit(state),
  product_duration: quoteAllProductBillingDurations(state),
  product_period: quoteAllProductBillingPeriods(state),
  product_currency_code: quoteAllProductCurrencyCodes(state)
});

export const trackingTealiumCartUpdate = (state) => ({
  ...trackingTealiumCart(state),
  event_type: 'scUpdate'
});

export const trackingTealiumCartRemove = (state, cartItemKey) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'cart-overview',
  event_type: 'scRemove',
  product_id: [quoteItemNewSubscriptionProductKey(state, cartItemKey)],
  product_family: [productByKeyFamily(state, quoteItemNewSubscriptionProductKey(state, cartItemKey))],
  product_name: [quoteItemNewSubscriptionProductName(state, cartItemKey)],
  product_quantity: [quoteItemNewSubscriptionQuantity(state, cartItemKey)],
  product_unit_price: [quoteItemNewSubscriptionEffectivePrice(state, cartItemKey)],
  product_duration: [quoteItemNewSubscriptionBillingDuration(state, cartItemKey)],
  product_period: [quoteItemNewSubscriptionBillingPeriod(state, cartItemKey)],
  product_currency_code: [quoteItemNewSubscriptionCurrencyCode(state, cartItemKey)]
});

export const trackingTealiumCheckout = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'checkout',
  product_id: quoteAllProductKeys(state),
  product_family: quoteAllProductFamilies(state),
  product_name: quoteAllProductNames(state),
  product_quantity: quoteAllProductQuantities(state),
  product_unit_price: quoteAllProductPricesPerUnit(state),
  product_duration: quoteAllProductBillingDurations(state),
  product_period: quoteAllProductBillingPeriods(state),
  product_action: quoteAllProductActions(state),
  product_currency_code: quoteAllProductCurrencyCodes(state)
});

export const trackingTealiumBillingInfo = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'billing-information'
});

/**
 * Return payload for Tealium "order" event
 * @param {object} state - redux state
 * @param {PayloadForOrderEvent} payload
 * @returns {object}
 */
export const trackingTealiumOrder = (state, payload) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'subscribe-processed',
  product_id: payload.allProductKeys,
  product_family: payload.allProductFamilies,
  product_name: payload.allProductNames,
  product_quantity: payload.allProductQuantities,
  product_unit_price: payload.allProductPricesPerUnit,
  product_duration: payload.allProductBillingDurations,
  product_period: payload.allProductBillingPeriods,
  product_action: payload.allProductActions,
  product_currency_code: payload.allProductCurrencyCodes,
  order_id: payload.orderKey,
  cc_type: paymentMethodsDefaultProvider(state)
});

export const trackingTealiumOrderConfirmation = (state) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'cart',
  template: 'order-confirmation',
  order_id: quoteOrderKey(state)
});

export const trackingTealiumError = (state, errorState) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'error',
  template: 'error page',
  error_id: errorState.data.id,
  error_status: errorState.status,
  error_codes: errorState.data.errors ? errorState.data.errors.map((error) => error.code) : []
});

export const trackingTealiumCancelBaseline = (state, subscriptionKey) => ({
  ...trackingTealiumBaseline(state),
  sub_section: 'subscriptions',
  product_id: [subscriptionByKeyProductKey(state, subscriptionKey)],
  product_family: [subscriptionByKeyProductFamily(state, subscriptionKey)],
  product_name: [subscriptionByKeyProductName(state, subscriptionKey)],
  product_quantity: [subscriptionByKeyQuantity(state, {subscriptionKey})],
  product_unit_price: [subscriptionByKeySubscriptionPricePerUnit(state, subscriptionKey)],
  product_duration: [subscriptionByKeyBillingDuration(state, subscriptionKey)],
  product_period: [subscriptionByKeyBillingPeriod(state, subscriptionKey)],
  product_currency_code: [billingAccountCountryCode(state, subscriptionKey)]
});

const CANCEL_TYPES = [
  'trial-expiration-reminder',
  'cancel-retention-complete',
  'cancel-winback-flat-rate',
  'cancel-winback-seasonality',
  'cancel-winback-usage',
  'cancel-winback',
  'cancel-confirm',
  'cancel-success',
  'remove-confirm',
  'remove-success'
];

/**
 * trackingTealiumGenerateCancelPayload
 * @param {object} state
 * @param {string} subscriptionKey
 * @param {string} cancelType - a value from the CANCEL_TYPES array
 */
export const trackingTealiumGenerateCancelPayload = (state, subscriptionKey, cancelType) => {
  if (!CANCEL_TYPES.includes(cancelType)) {
    throw new Error(`Unrecognized cancelType ${cancelType}`);
  }
  const payload = {
    ...trackingTealiumCancelBaseline(state, subscriptionKey),
    template: cancelType
  };
  if (cancelType === 'cancel-success') {
    payload.product_action = ['CANCEL'];
  }
  if (cancelType === 'remove-success') {
    payload.product_action = ['REMOVE_CANCEL'];
  }
  return payload;
};

/**
 * Sends a Tealium event with the `view` type
 * View events are used for when the user views a page/modal. Since pages aren't relevant in SPAs this
 * will typically be dispatched within the componentDidMount in whichever component best represents a logical page.
 * @param payload
 */
export const trackingTealiumViewEvent = (payload = {}) => {
  TealiumTracker.view(payload);
};

/**
 * Sends a Tealium event with the `link` type
 * Link events are used for actions/operations (remove from cart, submit order, etc.)
 * @param payload
 */
export const trackingTealiumLinkEvent = (payload = {}) => {
  TealiumTracker.link(payload);
};

export const handleCancelEvent = (type) => (eventName, {payload: {subscriptionKey, cancelType}, state}) => (
  trackingTealiumViewEvent(trackingTealiumGenerateCancelPayload(state, subscriptionKey, cancelType || type))
);

/**
 * The event details which will consist of the state and payload properties
 *
 * @typedef {object} EventDetails
 *
 * @property {object} state
 * @property {object/array} payload
 */

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, {payload, state}) => {
  switch (eventName) {
    // Shared events
    case FlowStart: {
      handleCancelEvent()(eventName, {payload, state});
      break;
    }
    case CancellationComplete: {
      handleCancelEvent('cancel-success')(eventName, {payload, state});
      break;
    }
    case RemoveCancelStart: {
      handleCancelEvent('remove-confirm')(eventName, {payload, state});
      break;
    }
    case RemoveCancelComplete: {
      handleCancelEvent('remove-success')(eventName, {payload, state});
      break;
    }
    case RetentionComplete: {
      handleCancelEvent('cancel-retention-complete')(eventName, {payload, state});
      break;
    }
    // Tealium-specific events for now
    case AddToCart: {
      trackingTealiumLinkEvent(trackingTealiumCartAdd(state, payload));
      break;
    }
    case BillingInfo: {
      trackingTealiumViewEvent(trackingTealiumBillingInfo(state));
      break;
    }
    case Cart: {
      trackingTealiumViewEvent(trackingTealiumCart(state));
      break;
    }
    case CartRemove: {
      trackingTealiumLinkEvent(trackingTealiumCartRemove(state, payload));
      break;
    }
    case CartUpdate: {
      trackingTealiumLinkEvent(trackingTealiumCartUpdate(state));
      break;
    }
    case Checkout: {
      trackingTealiumViewEvent(trackingTealiumCheckout(state));
      break;
    }
    case DefaultCancellationConfirm: {
      handleCancelEvent('cancel-confirm')(eventName, {payload, state});
      break;
    }
    case Err: {
      trackingTealiumViewEvent(trackingTealiumError(state, payload.errorState));
      break;
    }
    case Invoices: {
      trackingTealiumViewEvent(trackingTealiumInvoices(state));
      break;
    }
    case Order: {
      trackingTealiumLinkEvent(trackingTealiumOrder(state, payload));
      break;
    }
    case OrderConfirmation: {
      trackingTealiumViewEvent(trackingTealiumOrderConfirmation(state));
      break;
    }
    case ProceedToCheckout: {
      trackingTealiumLinkEvent(trackingTealiumProceedToCheckout(state, payload));
      break;
    }
    case ProductFamilies: {
      trackingTealiumViewEvent(trackingTealiumProductFamilies(state));
      break;
    }
    case StartTrial: {
      trackingTealiumLinkEvent(trackingTealiumStartTrial(state));
      break;
    }
    case SubscriptionGroups: {
      trackingTealiumViewEvent(trackingTealiumSubscriptionGroups(state));
      break;
    }
    case TrialExpirationReminder: {
      trackingTealiumViewEvent(trackingTealiumGenerateCancelPayload(state, payload.subscriptionKey, 'trial-expiration-reminder'));
      break;
    }
    default: {
      break;
    }
  }
};
