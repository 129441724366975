import {
  PAYMENT_METHODS_TYPE_CREDIT_CARD,
  PAYMENT_METHODS_TYPE_SEPA
} from 'modules/payment-methods-module';

// Payment Info
export const PAYMENT_INFO_SET_TYPE_CREDIT = 'PAYMENT_INFO_SET_TYPE_CREDIT';
export const PAYMENT_INFO_SET_TYPE_SEPA = 'PAYMENT_INFO_SET_TYPE_SEPA';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {};

// ------------------------------------
// Selectors
// ------------------------------------
// payment method selectors
export const paymentInfoTree = (state) => state.paymentInfo || {};
export const paymentInfoSelectedType = (state) => paymentInfoTree(state).type || '';
export const paymentInfoSelectedTypeIsCreditCard = (state) => paymentInfoSelectedType(state) === PAYMENT_METHODS_TYPE_CREDIT_CARD;
export const paymentInfoSelectedTypeIsSepa = (state) => paymentInfoSelectedType(state) === PAYMENT_METHODS_TYPE_SEPA;

// ------------------------------------
// Actions
// ------------------------------------
export const paymentInfoSetTypeCredit = () => ({
  type: PAYMENT_INFO_SET_TYPE_CREDIT
});

export const paymentInfoSetTypeSepa = () => ({
  type: PAYMENT_INFO_SET_TYPE_SEPA
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [PAYMENT_INFO_SET_TYPE_CREDIT]: (state) => ({
    ...state,
    type: PAYMENT_METHODS_TYPE_CREDIT_CARD
  }),
  [PAYMENT_INFO_SET_TYPE_SEPA]: (state) => ({
    ...state,
    type: PAYMENT_METHODS_TYPE_SEPA
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const paymentInfoReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default paymentInfoReducer;
