import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import clamp from 'lodash/clamp';
import environment from 'lib/environment';
import ProductCatalog from 'lib/product-catalog';
import {
  BillingPeriodTypes,
  ProductTypes,
  ImportantDateActionTypes
} from 'lib/cog-enums';
import {
  pricesFilteredByTargetOrHigher,
  pricesSortedByFrequencyDescending
} from 'lib/prices';
import { modalData } from 'modules/modal-module';
import {
  billingAccountRestrictPaymentMethod,
  billingAccountIsLoading
} from 'modules/billing-account';
import {
  cartItemBySubscriptionKeyQuantity,
  cartItemCount,
  cartItemHasSubscriptionKey,
  cartItems,
  cartItemsByProductKeyQuantity
} from 'modules/cart-module';
import { importantDateMessages } from 'modules/important-dates';
import {
  RELATIONSHIP_CROSS_SELL_NEW_ONLY,
  productByKey,
  productByKeyAddons,
  productByKeyDescription,
  productByKeyFamily,
  productByKeyLessOrEqualQuantityProductKeys,
  productByKeyOperationsProductIsEditable,
  productByKeyPrices,
  productFamiliesByProductFamilyKeyIsMultiproductEnabled,
  productFamiliesData,
  productFamilyHasQuantity,
  productIsLoading,
  productsByFamilyFreemium,
  productsByFamilyPaid,
  productsByFamilyTrial,
  productsPriceZone,
  productByKeyCurrencyCode,
  productByKeyMinQuantity,
  productByKeyDefaultBillingPeriod,
  productByKeyGroup
} from 'modules/product-module';
import {
  subscriptionsActiveHavePaidProducts,
  subscriptionByKeyExists,
  subscriptionByKeyProductFamily,
  subscriptionByKeyQuantity,
  subscriptionsActiveByProductKey,
  subscriptionsAreLoading,
  subscriptionModels,
  subscriptionByKeyIsActive,
  subscriptionsAddonProductKeys,
  subscriptionModelByKey
} from 'modules/subscription';
import {
  quoteAllProductActions,
  quoteAllProductBillingDurations,
  quoteAllProductBillingPeriods,
  quoteAllProductCurrencyCodes,
  quoteAllProductFamilies,
  quoteAllProductKeys,
  quoteAllProductNames,
  quoteAllProductPricesPerUnit,
  quoteAllProductQuantities,
  quoteItemNewSubscriptionProductKey,
  quoteItemNewSubscriptionBillingPeriod,
  quoteItemNewSubscriptionBillingDuration,
  quoteItemNewSubscriptionQuantity,
  quoteItemNewSubscriptionEffectivePrice,
  quoteItemNewSubscriptionCurrencyCode,
  quoteModels,
  quoteItemsByProductKeyTypeChangeNewQuantity,
  quoteItemTypeIsChange,
  quoteItemTypeIsConvertTrial,
  quoteItemTypeIsNew,
  quoteItemTypeIsReactivate,
  quoteOrderKey,
  quoteItemEffectiveTotalDifference,
  quoteItemPromoCodes,
  quoteItemNewSubscriptionProductType
} from 'modules/quote';
import { invoiceGrandTotal, invoiceTotalTaxes } from 'modules/invoice';
import {
  EXPERIMENT_LD_DEFAULT_FLAGS,
  experimentByFamilyKeyIsMultiproduct,
  experimentCancelInRetentionFlowCampaignFlatRateProductKeys
} from 'modules/experiments';
import {
  paymentMethodsExist,
  paymentMethodsIsLoading,
  paymentMethodsPostIsLoading,
  paymentMethodByKeyIsExpired,
  paymentMethodsDefaultProvider,
  paymentMethodsDefaultType
} from 'modules/payment-methods-module';
import {
  uiSubsConfig,
  uiSubsConfigAddonIsSelected,
  uiSubsConfigAddons,
  uiSubsConfigHasSelectedProduct,
  uiSubsConfigIsAddingNewSubscription,
  uiSubsConfigProductFamilyKey,
  uiSubsConfigSelectedProductKey,
  uiSubsConfigSubscriptionKey,
  uiSubsConfigUserSelectedBillingDuration,
  uiSubsConfigUserSelectedBillingPeriod
} from 'modules/ui';
import { paymentFormIsLoading } from 'modules/payment-form-module';
import { scaTokenIsLoading } from 'modules/payment/sca-token';
import { payerAuthIsLoading } from 'modules/payment/payer-auth';
import { meKey } from 'modules/me-module';

/**
 * Used in components from the shopping cart view when removing an item from the cart.
 * Returns an array of objects with the add-on product description and cart item key that should be removed from
 * the shopping cart since there is no supporting base product in the cart on as active subscription.
 *
 * @param {object} state application state
 * @param {object} cart item being deleted from the cart
 * @returns {array} object
 */
const addOnsToBeRemovedFromCart = (state, { cartItem }) => {
  const allQuoteItems = quoteModels(state);
  const activeSubscriptionsAddonProductKeys = subscriptionsAddonProductKeys(state);
  // Get the product keys of relationship for a cart item if cart item is not an already existing subscription
  // OR if cart item is a trial conversion or reactivation.
  let cartItemCrossSellRelationshipProductKeys = [];
  if (!cartItem.subscriptionKey || quoteItemTypeIsConvertTrial(state, cartItem.cartItemKey) || quoteItemTypeIsReactivate(state, cartItem.cartItemKey)) {
    cartItemCrossSellRelationshipProductKeys = (cartItem.newSubscription.product.relationships.find((rel) => rel.type === RELATIONSHIP_CROSS_SELL_NEW_ONLY) || {}).productKeys;
  }

  // Get the product keys of each item in the quote.
  const quoteItemsProductKeys = allQuoteItems.map((item) => item.newSubscription.productKey);

  // Get the product keys for products under RELATIONSHIP_CROSS_SELL_NEW_ONLY for each item in cart
  // filtering out the cart item that is being deleted.
  const filteredQuoteItemsAddOnsProductKeys = allQuoteItems
    .filter((quoteItem) => quoteItem.newSubscription.productKey !== cartItem.newSubscription.productKey)
    .map((quoteItem) => quoteItem.newSubscription.product.relationships.find((rel) => rel.type === RELATIONSHIP_CROSS_SELL_NEW_ONLY) || {})
    .map((rel) => rel.productKeys)
    .flat();

  // If a product key in quoteItems matches a product key in cartItemRelationshipProductKeys
  // AND no existing active subscription has the product key as a possible addon in order to support it,
  // AND there is no other item in cart that can support the addon
  // push an object with the addon product description and its cart item key.
  return quoteItemsProductKeys.reduce((acc, key) => {
    if (cartItemCrossSellRelationshipProductKeys.includes(key)
      && !activeSubscriptionsAddonProductKeys.includes(key)
      && !filteredQuoteItemsAddOnsProductKeys.includes(key)
    ) {
      const cartItemKey = allQuoteItems.find((item) => item.newSubscription.productKey === key).cartItemKey;
      const productDescription = productByKeyDescription(state, key);
      acc.push({
        productDescription,
        cartItemKey
      });
      return acc;
    }
    return acc;
  }, []);
};

const uiSubsConfigProductIsEditable = (state, {productKey}) => uiSubsConfigIsAddingNewSubscription(state) || productByKeyOperationsProductIsEditable(state, productKey);
const uiSubsConfigSelectedProduct = (state) => productByKey(state, uiSubsConfigSelectedProductKey(state)) || {};
const uiSubsConfigSelectedProductCurrencyCode = (state) => productByKeyCurrencyCode(state, uiSubsConfigSelectedProductKey(state)) || '';
const uiSubsConfigSelectedProductName = (state) => productByKeyDescription(state, uiSubsConfigSelectedProductKey(state)) || '';
const uiSubsConfigSelectedProductType = (state) => uiSubsConfigSelectedProduct(state).productType;
const uiSubsConfigSelectedProductIsFreemium = (state) => ProductCatalog.productTypeIsFree(uiSubsConfigSelectedProductType(state));
const uiSubsConfigSelectedProductIsTrial = (state) => ProductCatalog.productTypeIsTrial(uiSubsConfigSelectedProductType(state));
const uiSubsConfigSelectedProductIsPaid = (state) => ProductCatalog.productTypeIsPaid(uiSubsConfigSelectedProductType(state));
const uiSubsConfigCanSkipToThankYouPage = (state) => !cartItemCount(state) && (uiSubsConfigSelectedProductIsFreemium(state) || uiSubsConfigSelectedProductIsTrial(state));
const uiSubsConfigCanSkipToInvoicePage = (state) => !cartItemCount(state) && uiSubsConfigSelectedProductIsPaid(state);
const uiSubsConfigCanAddToCartPage = (state) => !uiSubsConfigCanSkipToInvoicePage(state) && !uiSubsConfigCanSkipToThankYouPage(state);

const productByQuoteItemKeyFamily = (state, quoteItemKey) => (
  productByKeyFamily(state, quoteItemNewSubscriptionProductKey(state, quoteItemKey))
);

/**
 * Determines whether the prerequisites for displaying subscription
 * overview page have been met.
 *
 * @param {object} state application state
 * @returns {boolean}
 */
const subscriptionOverviewIsLoading = (state) => {
  const subsAreLoading = subscriptionsAreLoading(state);
  const prodsAreLoading = productIsLoading(state);
  return subsAreLoading || prodsAreLoading;
};

const productFamilyByModalId = (state) => (modalId) => {
  const data = modalData(state, modalId);
  const { subscriptionKey } = data;
  return subscriptionByKeyProductFamily(state, subscriptionKey);
};

const subscriptionKeyByModalId = (state) => (modalId) => {
  const data = modalData(state, modalId);
  return data.subscriptionKey;
};

const subscriptionModelByModalId = (state, ownProps) => {
  const { id } = ownProps;
  const { subscriptionKey } = modalData(state, id);
  const comparator = ({ key }) => key === subscriptionKey;
  return subscriptionModels(state).find(comparator);
};

const quantityBySubsKeyCartOrSubscription = (state, subscriptionKey) => {
  if (cartItemHasSubscriptionKey(state, subscriptionKey)) {
    return cartItemBySubscriptionKeyQuantity(state, subscriptionKey);
  }
  if (subscriptionByKeyExists(state, subscriptionKey)) {
    return subscriptionByKeyQuantity(state, {subscriptionKey});
  }
  return 0;
};

const quantityOfSubsNotInCartByProductKey = (state, {productKey}) => (
  subscriptionsActiveByProductKey(state, productKey)
    .filter(({key}) => !cartItemHasSubscriptionKey(state, key))
    .reduce((acc, {quantity}) => acc + quantity, 0)
);

/**
 * Used only in productByKeyMaxQuantity to get the cart or subscription quantity of the base in order
 * to limit the addon's quantity.
 * @param {object} state
 * @param {object} ownProps
 */
const quantityByProductKeyCartOrSubscription = (state, {productKey}) => {
  let total = 0;
  // Add up all subs not in the cart
  total += quantityOfSubsNotInCartByProductKey(state, {productKey});
  // Add up all matching cart items
  total += cartItemsByProductKeyQuantity(state, productKey);

  return total;
};

const quantityByProductKeyActiveCartOrSubscription = (state, {productKey}) => {
  let total = 0;
  // Add up all subs not in the cart
  total += quantityOfSubsNotInCartByProductKey(state, {productKey});
  // Add up all matching cart items
  total += quoteItemsByProductKeyTypeChangeNewQuantity(state, {productKey});

  return total;
};


/**
 * Used [in subs card and in quantity selection container (subs config page)] to determine the max
 * quantity. For normal products, this is just the maxQuantity value from COG. For products with the
 * LESS_OR_EQUAL_QUANTITY relationship, we need to look up their LESS_OR_EQUAL relationships, tally
 * the totals for each, and then add those totals up. Then, we need to subtract any other products
 * with the same productKey on the account.
 *
 * A product with the LESS_OR_EQUAL_QUANTITY relationship must have fewer total quantity than the
 * sum of its base products' total quantity.
 *
 * @param {object} state
 * @param {object} ownProps
 */
const productByKeyMaxQuantity = (state, ownProps) => {
  const {subscriptionKey, productKey} = ownProps;
  const normalMax = productByKey(state, productKey).maxQuantity || 1;
  const lessOrEqualQuantityProductKeys = productByKeyLessOrEqualQuantityProductKeys(state, productKey);

  if (!lessOrEqualQuantityProductKeys.length) {
    // Most products will just use this value, which comes from COG
    return normalMax;
  }

  // Products with the LESS_OR_EQUAL_QUANTITY relationship, however, need some special handling.
  // Now add up all the other less or equal products, and ensure that the total quantity of less or equal products
  // is always less than the total quantity of base products
  // Ensure the maximum is equal to the lowest quantity of all the products in the less or equal relationship
  let maxFromBase = lessOrEqualQuantityProductKeys.reduce((accumulator, baseKey) => {
    const currentMinimum = quantityByProductKeyCartOrSubscription(state, {productKey: baseKey});
    return accumulator + currentMinimum;
  }, 0);
  const currentQuantity = quantityBySubsKeyCartOrSubscription(state, subscriptionKey) || 0;
  // Since `currentQuantity` counts only active subscriptions, `totalQuantityForProductKey` should also ignore new subscriptions
  const totalQuantityForProductKey = quantityByProductKeyActiveCartOrSubscription(state, {productKey});
  // It's easier to just add currentQuantity in and then subtract out the total quantity from cart or subs.
  maxFromBase = maxFromBase + currentQuantity - totalQuantityForProductKey;
  return maxFromBase < normalMax ? maxFromBase : normalMax;
};

// quantity selection
const uiSubsConfigSelectedProductMinQuantity = (state) => uiSubsConfigSelectedProduct(state).minQuantity || 1;
const uiSubsConfigSelectedProductMaxQuantity = (state) => productByKeyMaxQuantity(
  state,
  {
    productKey: uiSubsConfigSelectedProductKey(state),
    subscriptionKey: uiSubsConfigSubscriptionKey(state)
  }
) || 1;

/*
 * If the user has not selected a product and the productFamily has products in it that have configurable quantities, return true
 * If the user selects a product which doesn't have a configurable quantity (min = max), return false
 */
const uiSubsConfigQuantityConfigurable = (state) => (
  (productFamilyHasQuantity(state, uiSubsConfigProductFamilyKey(state)) && !uiSubsConfigHasSelectedProduct(state)) ||
  uiSubsConfigSelectedProductMinQuantity(state) !== uiSubsConfigSelectedProductMaxQuantity(state)
);

/*
 * uiSubsConfigSelectedQuantity is passed to the increment / decrement counter (IDC). The IDC will update the selectedQuantity value
 * if the current value is less than minQuantity or greater than the maxQuantity (see the IDC container).
 */
const uiSubsConfigSelectedQuantity = (state) => {
  // If quantity is not configurable, we return the minQuantity (which is the same as maxQuantity in this case)
  if (!uiSubsConfigQuantityConfigurable(state)) {
    return uiSubsConfigSelectedProductMinQuantity(state);
  }
  return uiSubsConfig(state).selectedQuantity || 0;
};

/*
 * All consumers of the quantity on the sub config page should use this selector for the reason described below.
 *
 * We can't rely on uiSubsConfigSelectedQuantity being updated by the Increment Decrement Counter (IDC) all the time since it is
 * sometimes hidden due to the accordion functionality on the sub config page. For example, the user updates the product selection
 * while the quantity section is collapsed and the IDC is hidden, the IDC would not update uiSubsConfigSelectedQuantity, which leaves
 * the possibility that uiSubsConfigSelectedQuantity is now invalid (less than minQuantity or greater than maxQuantity).
 * Therefore, all consumers of the quantity (except for IDC, which needs selectedQuantity from the store) should rely on
 * uiSubsConfigQuantity instead of uiSubsConfigSelectedQuantity.
 */
const uiSubsConfigQuantity = (state) => clamp(
  uiSubsConfigSelectedQuantity(state), uiSubsConfigSelectedProductMinQuantity(state), uiSubsConfigSelectedProductMaxQuantity(state)
);

const queryStringProp = (propName) => (state, ownProps) => {
  const { location: { search } } = ownProps;
  const qsProps = queryString.parse(search);
  return qsProps[propName];
};

const multiproductEnabledProductFamilyKeys = (state) => (
  productFamiliesData(state)
    .map(({key}) => key)
    .filter((key) => {
      const familyHasActiveExperiment = Object.prototype.hasOwnProperty.call(EXPERIMENT_LD_DEFAULT_FLAGS, `${key.toLowerCase()}Multiproduct`);
      const cogFlagIsMultiproduct = productFamiliesByProductFamilyKeyIsMultiproductEnabled(state, key);
      // Check if there is an expected overwrite of the multiproduct (for instance G2MGR) capability for the family.
      if (familyHasActiveExperiment) {
        // If there is we will allow the experiments module AND the COG to drive the decision if multiproduct is enabled or not.
        const familyExperimentIsMultiproduct = experimentByFamilyKeyIsMultiproduct(state, {productFamilyKey: key});
        return familyExperimentIsMultiproduct && cogFlagIsMultiproduct;
      }
      // If there is no LD experiment, we will allow the COG to drive the decision if multiproduct is enabled or not.
      return cogFlagIsMultiproduct;
    })
);

/**
 * @typedef {object} PayloadForOrderEventItem
 *
 * An object with quote information as needed by consumers of the "Order" app events
 *
 * @property {boolean} typeIsNew - result of quoteItemTypeIsNew()
 * @property {boolean} typeIsConvertTrial - result of quoteItemTypeIsConvertTrial()
 * @property {boolean} typeIsReactivate - result of quoteItemTypeIsReactivate()
 * @property {boolean} typeIsChange - result of quoteItemTypeIsChange()
 * @property {string}  productKey -- the product key for the quote item's 'newSubscription'
 * @property {string}  familyKey -- the product family key for the quote item's 'newSubscription'
 * @property {number}  itemFinalAmountDifference -- result of quoteItemEffectiveTotalDifference()
 */

/**
 * @typedef {object} PayloadForOrderEvent
 *
 * The quote and invoice related (owned by `quote-module` and `invoice-module`) data fields that should be supplied to all "Order" app events
 * (i.e. whenever `track(Order)` is called).
 *
 * @property {PayloadForOrderEventItem[]} quoteItems
 * @property {string[]} allProductKeys - all product keys found in all quote items
 * @property {string[]} allProductFamilies - all product families for every product in all quote items
 * @property {string[]} allProductNames - all product names for every product in all quote items
 * @property {number[]} allProductQuantities - all quantities for every product in all quote items
 * @property {string[]} allProductPricesPerUnit - all effective prices for every product in all quote items
 * @property {number[]} allProductBillingDurations - all billing durations for every product in all quote items
 * @property {string[]} allProductBillingPeriods - all billing periods for every product in all quote items
 * @property {string[]} allProductActions - all actions (as defined by Tealium team) for every product in all quote items
 * @property {string[]} allProductCurrencyCodes - all currency codes for every product in all quote items
 * @property {string}   orderKey - the key for the submitted order
 * @property {string}   orderGrandTotal - the invoice grand total amount for the submitted order
 */

/**
 * @param {object} state
 * @returns {PayloadForOrderEvent}
 */
const payloadForOrderEvent = (state) => ({
  quoteItems: quoteModels(state).map((item) => ({
    typeIsNew: quoteItemTypeIsNew(state, item.cartItemKey),
    typeIsConvertTrial: quoteItemTypeIsConvertTrial(state, item.cartItemKey),
    typeIsReactivate: quoteItemTypeIsReactivate(state, item.cartItemKey),
    typeIsChange: quoteItemTypeIsChange(state, item.cartItemKey),
    productKey: quoteItemNewSubscriptionProductKey(state, item.cartItemKey),
    familyKey: productByQuoteItemKeyFamily(state, item.cartItemKey),
    itemFinalAmountDifference: quoteItemEffectiveTotalDifference(state, item.cartItemKey)
  })),
  allProductKeys: quoteAllProductKeys(state),
  allProductFamilies: quoteAllProductFamilies(state),
  allProductNames: quoteAllProductNames(state),
  allProductQuantities: quoteAllProductQuantities(state),
  allProductPricesPerUnit: quoteAllProductPricesPerUnit(state),
  allProductBillingDurations: quoteAllProductBillingDurations(state),
  allProductBillingPeriods: quoteAllProductBillingPeriods(state),
  allProductActions: quoteAllProductActions(state),
  allProductCurrencyCodes: quoteAllProductCurrencyCodes(state),
  orderKey: quoteOrderKey(state),
  orderGrandTotal: invoiceGrandTotal(state)
});

const subscriptionByKeyShouldRedirectToMarketing = (state, ownProps) => {
  const { subscription } = ownProps;
  // If the subscription's product family is G2MGR and it's a trial, then this is an exemption and we should always redirect users to marketing
  if (ProductCatalog.productFamilyKeyIsG2MGR(subscription.productFamilyKey) && subscription.isTrial) {
    return true;
  }
  const subscriptionIsPaid = ({ productType, productFamilyKey }) => (
    ProductCatalog.productTypeIsPaid(productType)
    && !ProductCatalog.productFamilyKeyIsOpenVoice(productFamilyKey)
  );
  const hasPaidNonUsageProducts = subscriptionModels(state).some(subscriptionIsPaid);
  const hasProductMarketingUrl = !!ProductCatalog.getProductMarketingUrl(subscription.productFamilyKey, subscription.productKey);
  const shouldSkipRedirect = ProductCatalog.productShouldSkipMarketingFlow(subscription.productKey);

  return environment.get('marketingPurchaseFlowEnabled')
    && !hasPaidNonUsageProducts
    && subscription.isTrial
    && hasProductMarketingUrl
    && !shouldSkipRedirect;
};

const subscriptionsByProductFamilyKeyShouldRedirectToMarketing = (state, {productFamilyKey}) => {
  const subscriptions = subscriptionModels(state);
  const byProductFamily = (subscription) => subscription.productFamilyKey === productFamilyKey;
  const shouldRedirect = (subscription) => subscriptionByKeyShouldRedirectToMarketing(state, { subscription })
    && subscription.isActive;

  return subscriptions
    .filter(byProductFamily)
    .some(shouldRedirect);
};

const showCustomerCareLinkOnPaymentMethod = (state) => (
  subscriptionsActiveHavePaidProducts(state) && paymentMethodsExist(state)
);

const editBillingAddressIsRestricted = (state, {paymentMethodKey}) => (
  billingAccountRestrictPaymentMethod(state) || paymentMethodByKeyIsExpired(state, paymentMethodKey)
);

const experimentByUnmatchedKeyIsMultiproduct = (state, ownProps) => {
  const { unmatchedKey: { productFamilyKey, productKey }} = ownProps;
  const productFamily = productFamilyKey || ProductCatalog.getProductFamily(productKey);
  return multiproductEnabledProductFamilyKeys(state).includes(productFamily);
};

const paymentMethodsOverviewIsLoading = (state) => paymentMethodsIsLoading(state)
  || billingAccountIsLoading(state);

const creditCardFormIsSubmitting = (state) => paymentMethodsIsLoading(state)
  || paymentMethodsPostIsLoading(state) || scaTokenIsLoading(state)
  || paymentFormIsLoading(state) || payerAuthIsLoading(state);

const sepaFormIsSubmitting = (state) => !!(paymentMethodsIsLoading(state)
  || paymentMethodsPostIsLoading(state));


const isEligibleForCancellationSurvey = (state, ownProps) => {
  const {
    subscription: {
      product: {
        cancelSurvey
      }
    }
  } = ownProps;

  return cancelSurvey;
};

const productRateSheet = (state, ownProps) => {
  const { productKey } = ownProps;
  const { productFamilyKey } = productByKey(state, productKey);
  let priceZone = productsPriceZone(state);

  switch (priceZone) {
    case 'europe':
      priceZone = 'EU';
      break;
    default:
      break;
  }

  return environment.get(`products.${productFamilyKey}.${productKey}.rateSheet.${priceZone}`) || '';
};

const productCountrySheet = (state, ownProps) => {
  const { productKey } = ownProps;
  const { productFamilyKey } = productByKey(state, productKey);
  const priceZone = 'other'; // It will be modified once more pdfs are provided for different price zone.
  return environment.get(`products.${productFamilyKey}.${productKey}.countryList.${priceZone}`) || '';
};


const productMarketingPricingUrlBySubscription = (state, ownProps) => {
  const { subscription: { productFamilyKey } } = ownProps;
  const productFamilySettings = environment.get(`products.${productFamilyKey}`);
  if (productFamilySettings && productFamilySettings.defaults) {
    return productFamilySettings.defaults.marketingPricingUrl;
  }
  return '';
};

/**
 *
 * @param {object} state
 * @param {object} ownProps
 * @returns {array}
 */
const productsByEditability = (state, ownProps) => {
  const { productKey, products = [] } = ownProps;
  const productIsEditable = uiSubsConfigProductIsEditable(state, {productKey});

  if (!productIsEditable) {
    // If the current subscription isn't editable, then only return the current subscription's product
    return products.filter(({ key }) => key === productKey);
  }
  return products;
};

/**
 *
 * @param {object} state
 * @param {object} ownProps
 * @returns {array} - an array of paid products that are related to a specific product family. This list contains the products that a customer is allowed to purchase
 */
const purchasableProductsByFamilyPaid = (state, ownProps) => {
  const {
    productFamilyKey,
    productKey
  } = ownProps;

  const paidProducts = productsByFamilyPaid(state, productFamilyKey).concat(productsByFamilyFreemium(state, productFamilyKey));

  return productsByEditability(state, { productKey, products: paidProducts });
};

/**
 *
 * @param {object} state
 * @param {object} ownProps
 * @returns {array} - an array of trial products that are related to a specific product family. This list contains the products that a customer is allowed to trial
 */
const purchasableProductsByFamilyTrial = (state, ownProps) => {
  const {
    productFamilyKey,
    productKey
  } = ownProps;

  const trialProducts = productsByFamilyTrial(state, productFamilyKey);

  return productsByEditability(state, { productKey, products: trialProducts });
};

export const uiSubsConfigSelectedProductPrices = (state) => {
  const prices = uiSubsConfigSelectedProduct(state).prices || [];
  const sortedPrices = pricesSortedByFrequencyDescending(prices);
  let availablePrices = sortedPrices;
  if (!uiSubsConfigIsAddingNewSubscription(state)) {
    const subscriptionKey = uiSubsConfigSubscriptionKey(state);
    const subscriptionModel = subscriptionModelByKey(state, { subscriptionKey });
    availablePrices = pricesFilteredByTargetOrHigher(sortedPrices, subscriptionModel);
  }
  return availablePrices || [];
};

const uiSubsConfigBillingPeriodVisible = (state) => uiSubsConfigSelectedProductIsPaid(state) && uiSubsConfigSelectedProductPrices(state).length > 1;

const uiSubsConfigSelectedProductPrice = (state, pricesArr = []) => {
  // Allow override of prices array. This is used for ease of selection in addons
  const prices = pricesArr.length ? pricesArr : uiSubsConfigSelectedProductPrices(state);
  if (prices.length === 0) {
    return {};
  }
  if (prices.length === 1) {
    return prices[0];
  }

  // If the billingDuration and billingPeriod are the same as the previously selected product
  const sameBillingFrequency = prices
    .find((price) => price.billingPeriod === uiSubsConfigUserSelectedBillingPeriod(state) && price.billingDuration === uiSubsConfigUserSelectedBillingDuration(state));
  if (sameBillingFrequency) {
    return sameBillingFrequency;
  }

  // These prices should be ordered from longest to shortest billing frequency (0 index longest, last index shortest)
  // We need to choose the default product price (the one with the longest duration)
  return prices[0];
};

const uiSubsConfigSelectedBillingDuration = (state) => uiSubsConfigSelectedProductPrice(state).billingDuration || 0;
const uiSubsConfigSelectedProductPriceSaveAmountPerUnit = (state, prices) => uiSubsConfigSelectedProductPrice(state, prices).saveAmount || 0;
const uiSubsConfigSelectedBillingPeriod = (state) => uiSubsConfigSelectedProductPrice(state).billingPeriod || '';
const uiSubsConfigBillingPeriodIsAnnual = (state) => ProductCatalog.productBillingPeriodIsYear(uiSubsConfigSelectedBillingPeriod(state));
const uiSubsConfigBillingPeriodIsMonthly = (state) => ProductCatalog.productBillingPeriodIsMonth(uiSubsConfigSelectedBillingPeriod(state));

const uiSubsConfigTotalSaved = (state) => {
  const quantity = uiSubsConfigQuantity(state);
  const baseSaveAmount = parseFloat(uiSubsConfigSelectedProductPriceSaveAmountPerUnit(state));
  const totalSaveAmount = uiSubsConfigAddons(state).reduce((total, addonProductKey) => {
    const addonPrice = parseFloat(uiSubsConfigSelectedProductPriceSaveAmountPerUnit(state, productByKey(state, addonProductKey).prices));
    return total + addonPrice;
  }, baseSaveAmount);
  return (totalSaveAmount * quantity).toString();
};

// product price
const uiSubsConfigAddonPerMonthEffectivePrice = (state, addonProductKey) => (
  parseFloat(uiSubsConfigSelectedProductPrice(state, productByKey(state, addonProductKey).prices).perMonthEffectivePrice) || 0
);
const uiSubsConfigAddonPerMonthSubscriptionPrice = (state, addonProductKey) => (
  parseFloat(uiSubsConfigSelectedProductPrice(state, productByKey(state, addonProductKey).prices).perMonthSubscriptionPrice) || 0
);
const uiSubsConfigSelectedProductPricePerUnit = (state) => uiSubsConfigSelectedProductPrice(state).effectivePrice || '0.00';
const uiSubsConfigSelectedProductPricePerMonthEffectivePrice = (state) => uiSubsConfigSelectedProductPrice(state).perMonthEffectivePrice || '0.00';
const uiSubsConfigSelectedProductPriceMonthlyTotal = (state) => {
  const quantity = uiSubsConfigQuantity(state);
  const basePrice = parseFloat(uiSubsConfigSelectedProductPricePerMonthEffectivePrice(state));
  const totalPrice = uiSubsConfigAddons(state).reduce((total, addonProductKey) => {
    const addonPrice = uiSubsConfigAddonPerMonthEffectivePrice(state, addonProductKey);
    return total + addonPrice;
  }, basePrice);
  return (totalPrice * quantity).toFixed(2);
};
const uiSubsConfigSelectedProductPriceTotal = (state) => {
  const quantity = uiSubsConfigQuantity(state) || 1;
  const basePrice = parseFloat(uiSubsConfigSelectedProductPricePerUnit(state));
  const totalPrice = uiSubsConfigAddons(state).reduce((total, addonProductKey) => {
    const addonPrice = parseFloat(uiSubsConfigSelectedProductPrice(state, productByKey(state, addonProductKey).prices).effectivePrice);
    return total + addonPrice;
  }, basePrice);
  return (quantity * totalPrice).toString();
};

// collects the parameters that will be passed to the cart item post call
const uiSubsConfigAllSelectionsForCartItem = (state) => ({
  subscriptionKey: uiSubsConfigSubscriptionKey(state),
  productKey: uiSubsConfigSelectedProductKey(state),
  billingPeriod: uiSubsConfigSelectedBillingPeriod(state),
  billingDuration: uiSubsConfigSelectedBillingDuration(state),
  quantity: uiSubsConfigQuantity(state)
});

const productByKeyPromotionCodes = (state, productKey) => {
  const currentProductPrice = productByKeyPrices(state, productKey).find((price) => isEqual(price, uiSubsConfigSelectedProductPrice(state)));
  if (currentProductPrice) {
    return compact(currentProductPrice.promotionCodes.map((promoCode) => promoCode.promotionCode));
  }
  return [];
};

/**
 * Used in components from the subscription configuration page that need to send a payload to Tealium
 * when a subscription or product that is being added to the shopping cart.
 *
 * @param {object} state application state
 * @returns {object} Tealium payload
 */
const subscriptionConfigurationTealiumPayload = (state) => ({
  productKey: uiSubsConfigSelectedProductKey(state),
  productFamilyKey: uiSubsConfigProductFamilyKey(state),
  productName: uiSubsConfigSelectedProductName(state),
  promoCodes: productByKeyPromotionCodes(state, uiSubsConfigSelectedProductKey(state))
});


/**
 * Return the subscription data in specified form for GA.
 * @param {ApplicationShape} state current (redux) state
 */
const subscriptionDataForGTMEvents = (state) => (
  subscriptionModels(state).map((subscription) => ({
    item_brand: subscription.productGroup,
    item_category: subscription.productGroup,
    item_category2: subscription.productKey,
    item_category3: subscription.billingPeriod,
    item_category4: subscription.billingDuration,
    item_name: `${subscription.productKey}_${subscription.billingPeriod.toLowerCase()}`,
    item_id: `${subscription.productKey}_${subscription.billingPeriod.toLowerCase()}`,
    item_variant: ProductCatalog.allAddons.includes(subscription.productKey)
      ? 'Add-on' : 'Product',
    price: parseFloat(subscription.effectiveTotal),
    quantity: parseInt(subscription.quantity, 10)
  }))
);

/**
 * Get the data GTM event format for ecommerce https://developers.google.com/tag-manager/enhanced-ecommerce
 * Get all price and billing format in comma separated.
 * @param {*} item product
 * @param {*} variant product / add-on
 */
const itemPayloadForGTMEvents = (item, variant, state) => {
  const payload = {
    item_brand: item.productGroup,
    item_category: item.productGroup,
    item_variant: variant,
    quantity: uiSubsConfigQuantity(state) || 1
  };
  return item.prices?.map((val) => ({
    ...payload,
    item_category2: item.key,
    item_category3: val.billingPeriod,
    item_category4: val.billingDuration,
    item_name: `${item.key}_${val.billingPeriod.toLowerCase()}`,
    item_id: `${item.key}_${val.billingPeriod.toLowerCase()}`,
    price: parseFloat(val.effectivePrice)
  }));
};

/**
 * Get the product data shown in the subscription configuration page
 * @param {object} payload products object
 * @param {object} state application state
*/
const productDataForGTMevents = (payload, state) => {
  const eventData = [];
  payload.proudcts.forEach((product) => {
    eventData.push(...itemPayloadForGTMEvents(product, 'Product', state));
  });
  // add-on data
  const addons = productByKeyAddons(state, payload.productKey);
  addons.forEach((addon) => {
    const product = productByKey(state, addon.productKey);
    eventData.push(...itemPayloadForGTMEvents(product, 'Add-on', state));
  });
  return eventData;
};


/**
 * payload of the item added from cart for GTM events
 * @param {*} state
 */
const cartAddPayload = (state) => {
  const selectedProduct = uiSubsConfigSelectedProduct(state);
  let billingPeriod = uiSubsConfigSelectedBillingPeriod(state);
  const eventData = [{
    item_brand: selectedProduct.productGroup,
    item_category: selectedProduct.productGroup,
    item_category2: selectedProduct.key,
    item_category3: billingPeriod,
    item_category4: uiSubsConfigSelectedBillingDuration(state),
    item_variant: 'Product',
    item_id: `${selectedProduct.key}_${billingPeriod.toLowerCase()}`,
    item_name: `${selectedProduct.key}_${billingPeriod.toLowerCase()}`,
    quantity: uiSubsConfigQuantity(state),
    currency: uiSubsConfigSelectedProductCurrencyCode(state),
    price: parseFloat(uiSubsConfigSelectedProductPricePerUnit(state))
  }];

  // add any add-ons
  const addOnSelected = uiSubsConfigAddons(state);
  addOnSelected.forEach((addOn) => {
    const product = productByKey(state, addOn);
    let quantity = productByKeyMinQuantity(state, product.key);
    billingPeriod = productByKeyDefaultBillingPeriod(state, product.key);
    const lessOrEqualQuantityProductKeys = productByKeyLessOrEqualQuantityProductKeys(state, product.key);
    if (lessOrEqualQuantityProductKeys.length) {
      quantity = uiSubsConfigQuantity(state);
      billingPeriod = uiSubsConfigSelectedBillingPeriod(state);
    }
    const priceObj = product.prices.filter((val) => val.billingPeriod === billingPeriod)[0];

    eventData.push({
      item_brand: product.productGroup,
      item_category: product.productGroup,
      item_category2: product.key,
      item_category3: billingPeriod,
      item_category4: priceObj.billingDuration,
      item_variant: 'Add-on',
      item_id: `${product.key}_${billingPeriod.toLowerCase()}`,
      item_name: `${product.key}_${billingPeriod.toLowerCase()}`,
      quantity,
      price: parseFloat(priceObj.effectivePrice),
      currency: uiSubsConfigSelectedProductCurrencyCode(state)
    });
  });
  return eventData;
};

const getProductFromQuote = (state, cartItemKey) => {
  const productKey = quoteItemNewSubscriptionProductKey(state, cartItemKey);
  const billingPeriod = quoteItemNewSubscriptionBillingPeriod(state, cartItemKey);
  return {
    item_brand: productByKeyGroup(state, productKey),
    item_category: productByKeyGroup(state, productKey),
    item_category2: productKey,
    item_category3: billingPeriod,
    item_category4: quoteItemNewSubscriptionBillingDuration(state, cartItemKey),
    item_name: `${productKey}_${billingPeriod.toLowerCase()}`,
    item_id: `${productKey}_${billingPeriod.toLowerCase()}`,
    item_variant: ProductCatalog.allAddons.includes(productKey)
      ? 'Add-on' : 'Product',
    quantity: parseInt(quoteItemNewSubscriptionQuantity(state, cartItemKey), 10),
    price: parseFloat(quoteItemNewSubscriptionEffectivePrice(state, cartItemKey))
  };
};

/**
 * payload of the item removed from cart for GTM events
 * @param {*} state
 */
const cartAddOrRemovePayload = (state, cartItemKey) => {
  if (quoteItemNewSubscriptionProductType(state, cartItemKey) !== 'TRIAL') {
    const payload = getProductFromQuote(state, cartItemKey);
    return {
      ...payload,
      currency: quoteItemNewSubscriptionCurrencyCode(state, cartItemKey)
    };
  }
  return '';
};

/**
 * payload of checkout item for begin_checkout event
 * @param {*} state
 */
const getCheckoutItemFromQuote = (state) => (
  cartItems(state).filter((item) => quoteItemNewSubscriptionProductType(state, item.key) !== 'TRIAL'
  ).map((item) => getProductFromQuote(state, item.key))
);

const getPurchaseData = (state) => {
  const payload = {
    ecommerce: {
      items: quoteModels(state).filter(
        (item) => quoteItemNewSubscriptionProductType(state, item.cartItemKey) !== 'TRIAL'
      ).map((item) => ({
        ...getProductFromQuote(state, item.cartItemKey),
        coupon: quoteItemPromoCodes(state, item.cartItemKey).reduce((acm, val) => `${acm} ${val.priceModifierKey},`, '')
      }))
    },
    actionField: {
      transaction_id: quoteOrderKey(state),
      shipping: 0,
      tax: parseFloat(invoiceTotalTaxes(state).reduce((acm, tax) => acm + parseFloat(tax.amount), 0).toFixed(2)),
      revenue: parseFloat(invoiceGrandTotal(state))
    },
    account_id: meKey(state),
    user_id: meKey(state),
    conversion_type: 'purchase',
    buy_type: 'add-on',
    payment_type: paymentMethodsDefaultProvider(state) || paymentMethodsDefaultType(state)
  };
  return payload;
};

/**
 * Determines whether user has made any changes to the existing subscription on
 * the subscription configuration page.
 *
 * @param {object} state application state
 * @returns {boolean}
 */
const subscriptionUnchanged = (state, ownProps) => {
  const { subscriptionKey, product } = ownProps;
  const subscriptionModel = subscriptionModelByKey(state, { subscriptionKey });
  const uiSelectedProductDetails = {
    ...uiSubsConfigAllSelectionsForCartItem(state)
  };

  const details = {
    subscriptionKey: subscriptionModel.key,
    ...subscriptionModel
  };

  const selectedBillingPeriod = uiSubsConfigUserSelectedBillingPeriod(state);

  const selectedPrice = (product.prices || [])
    .find((price) => price.billingPeriod === selectedBillingPeriod) || {};

  return Object
    .keys(uiSelectedProductDetails)
    .every((field) => uiSelectedProductDetails[field] === details[field])
    && selectedPrice.effectivePrice === selectedPrice.subscriptionPrice;
};

/**
 * Determines whether user has made any changes to the existing subscription on the subscription
 * configuration page or if an add-on was added for that base product.
 *
 * @param {object} state application state
 * @returns {boolean}
 */
const subscriptionConfigurationIsUnchanged = (state, ownProps) => {
  const subscriptionKey = uiSubsConfigSubscriptionKey(state);
  const addOnIsSelected = productByKeyAddons(state, uiSubsConfigSelectedProductKey(state))
    .some(({productKey}) => uiSubsConfigAddonIsSelected(state, {productKey}));

  return !uiSubsConfigIsAddingNewSubscription(state)
    && subscriptionByKeyIsActive(state, subscriptionKey)
    && subscriptionUnchanged(state, { subscriptionKey, product: ownProps.product })
    && !addOnIsSelected;
};

/**
 *
 * @param {object} state
 * @param {object} ownProps
 * @returns {array} - an array of a product's prices that's available to customers. It could contain either monthly prices, annual prices, or both
 */
const uiSubsConfigSelectedProductPricesAvailable = (state, ownProps) => {
  const { productKey } = ownProps;
  const selectedProductPrices = uiSubsConfigSelectedProductPrices(state);
  const selectedBillingPeriod = uiSubsConfigUserSelectedBillingPeriod(state);
  const productIsEditable = uiSubsConfigProductIsEditable(state, {productKey});

  if (!productIsEditable) {
    // If the selected product(current subscription) isn't editable, then only return the current subscription's billing period prices
    return selectedProductPrices.filter((price) => price.billingPeriod === selectedBillingPeriod);
  }
  return selectedProductPrices;
};

const showRenewalImpactMessage = (state, ownProps) => {
  /**
   * This important date message is added to inform customers about the co-term behavior that happens under the hood by COG which is
   * only enforced on subscriptions whose billing frequency are the same.
   * For example, we will show the message when you are purchasing a paid subscription with monthly billing frequency and
   * you already have a active paid subscription with monthly billing frequency on the account.
   *
   * Summary about how the co-term works:
   *   COG will align the pre-existing subscription's renewal dates with the newly purchased subscription's renewal date on the pre-existing subscription's next renewal date.
   *   For example:
   *     - today is November 13, 2019
   *     - you already have an active paid annual G2M_Pro2 subscription on the account whose renewal date is October 13, 2020
   *     - you purchase a paid annual G2W_100_Starter product today (November 13, 2019), so its next renewal date is November 13, 2020
   *     - on October 13, 2020, the G2M Pro will be charged a special 30-day period to align it to November 13, 2020.
   *     - on November 13, 2020, both subscriptions are renewed for the next year, and both will next renew on November 13, 2021
   */
  const {
    quoteItems = [],
    subscriptionModels: subscriptions = []
  } = ownProps;

  const subscriptionComparator = (billingPeriod) => (subscription) => subscription.isActive
    && subscription.billingPeriod === billingPeriod
    && subscription.productType === ProductTypes.PAID;

  const quoteItemComparator = (billingPeriod) => ({ newSubscription = {} }) => newSubscription
    .billingPeriod === billingPeriod
    && newSubscription.product.productType === ProductTypes.PAID;

  const hasMonthlyPaidSubscription = subscriptions.some(subscriptionComparator(BillingPeriodTypes.MONTH));
  const hasAnnualPaidSubscription = subscriptions.some(subscriptionComparator(BillingPeriodTypes.YEAR));
  const hasMonthlyPaidNewSubscription = quoteItems.some(quoteItemComparator(BillingPeriodTypes.MONTH));
  const hasAnnualPaidNewSubscription = quoteItems.some(quoteItemComparator(BillingPeriodTypes.YEAR));

  return (
    (hasMonthlyPaidSubscription && hasMonthlyPaidNewSubscription)
    || (hasAnnualPaidSubscription && hasAnnualPaidNewSubscription)
  );
};

/**
 * Returns a list of important date message models that omits any COG messages
 * that might be obviated by a retention campaign message
 *
 * @param {ApplicationShape} state current (redux) state of the application
 * @param {object} ownProps input props for the selector component
 */
const importantMessageModels = (state, ownProps) => {
  const retentionFlowProductKeys = experimentCancelInRetentionFlowCampaignFlatRateProductKeys(state, ownProps);
  // comparator used to filter messages that are either NOT PENDING_ACTION_EFFECTIVE
  // or ARE PENDING_ACTION_EFFECTIVE AND not in retention messages.
  const comparator = ({ productKey, action }) => action !== ImportantDateActionTypes.PENDING_ACTION_EFFECTIVE
    || (action === ImportantDateActionTypes.PENDING_ACTION_EFFECTIVE
    && !retentionFlowProductKeys.includes(productKey));

  return importantDateMessages(state)
    .filter(({ visible }) => visible)
    .filter(comparator);
};

export default {
  addOnsToBeRemovedFromCart,
  creditCardFormIsSubmitting,
  editBillingAddressIsRestricted,
  experimentByUnmatchedKeyIsMultiproduct,
  importantMessageModels,
  isEligibleForCancellationSurvey,
  multiproductEnabledProductFamilyKeys,
  payloadForOrderEvent,
  paymentMethodsOverviewIsLoading,
  productByKeyMaxQuantity,
  productByKeyPromotionCodes,
  productByQuoteItemKeyFamily,
  productFamilyByModalId,
  productMarketingPricingUrlBySubscription,
  productRateSheet,
  productCountrySheet,
  productsByEditability,
  purchasableProductsByFamilyPaid,
  purchasableProductsByFamilyTrial,
  queryStringProp,
  sepaFormIsSubmitting,
  showCustomerCareLinkOnPaymentMethod,
  showRenewalImpactMessage,
  subscriptionByKeyShouldRedirectToMarketing,
  subscriptionConfigurationIsUnchanged,
  subscriptionConfigurationTealiumPayload,
  subscriptionDataForGTMEvents,
  productDataForGTMevents,
  cartAddPayload,
  cartAddOrRemovePayload,
  getCheckoutItemFromQuote,
  getPurchaseData,
  subscriptionKeyByModalId,
  subscriptionModelByModalId,
  subscriptionOverviewIsLoading,
  subscriptionsByProductFamilyKeyShouldRedirectToMarketing,
  subscriptionUnchanged,
  uiSubsConfigAddonPerMonthEffectivePrice,
  uiSubsConfigAddonPerMonthSubscriptionPrice,
  uiSubsConfigAllSelectionsForCartItem,
  uiSubsConfigBillingPeriodIsAnnual,
  uiSubsConfigBillingPeriodIsMonthly,
  uiSubsConfigBillingPeriodVisible,
  uiSubsConfigCanAddToCartPage,
  uiSubsConfigCanSkipToInvoicePage,
  uiSubsConfigCanSkipToThankYouPage,
  uiSubsConfigProductIsEditable,
  uiSubsConfigQuantity,
  uiSubsConfigQuantityConfigurable,
  uiSubsConfigSelectedBillingDuration,
  uiSubsConfigSelectedBillingPeriod,
  uiSubsConfigSelectedProduct,
  uiSubsConfigSelectedProductCurrencyCode,
  uiSubsConfigSelectedProductIsPaid,
  uiSubsConfigSelectedProductIsTrial,
  uiSubsConfigSelectedProductMaxQuantity,
  uiSubsConfigSelectedProductMinQuantity,
  uiSubsConfigSelectedProductName,
  uiSubsConfigSelectedProductPrice,
  uiSubsConfigSelectedProductPriceMonthlyTotal,
  uiSubsConfigSelectedProductPricePerMonthEffectivePrice,
  uiSubsConfigSelectedProductPricePerUnit,
  uiSubsConfigSelectedProductPriceSaveAmountPerUnit,
  uiSubsConfigSelectedProductPriceTotal,
  uiSubsConfigSelectedProductPrices,
  uiSubsConfigSelectedProductPricesAvailable,
  uiSubsConfigSelectedProductType,
  uiSubsConfigSelectedProductIsFreemium,
  uiSubsConfigSelectedQuantity,
  uiSubsConfigTotalSaved
};
