import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import st from 'components/shared-translations';
import AppLink from 'components/app-link';
import ExternalAnchor from 'components/external-anchor';

const AlertPaymentExisting9sView = () => (
  <Alert
    type="danger"
    title={<FormattedMessage
      {...st['alert.payment.existing.9s']}
      values={{
        'support-link': (msg) => (
          <ExternalAnchor id="support-link" href="https://support.goto.com/resolve/help/troubleshoot-billing-issues">
            {msg}
          </ExternalAnchor>
        ),
        'cc-link': (msg) => (
          <AppLink to="paymentmethods.manage" id="cc-link">
            {msg}
          </AppLink>
        ),
        br: <br />
      }} />}
  />
);

export default AlertPaymentExisting9sView;
