// Tree Object selectors
import ProductCatalog from 'lib/product-catalog';

export const uiTree = (state) => state.ui || {};
export const uiSubsConfig = (state) => uiTree(state).subsConfig || {};
export const uiSubsOverview = (state) => uiTree(state).subsOverview || {};

// Configuration
export const uiSubsConfigIsConfiguring = (state) => uiSubsConfig(state).configuring || false;
export const uiSubsConfigProductFamilyKey = (state) => uiSubsConfig(state).productFamilyKey || '';
// @NOTE The default value should be always null for this selector since its value used to post to cart,
// and null must be provided when adding new products.
export const uiSubsConfigSubscriptionKey = (state) => uiSubsConfig(state).subscriptionKey || null;
export const uiSubsConfigIsAddingNewSubscription = (state) => uiSubsConfig(state).isAddingNewSubscription;
export const uiSubsConfigAddons = (state) => uiSubsConfig(state).addons || [];
export const uiSubsConfigAddonIsSelected = (state, { productKey }) => uiSubsConfigAddons(state).includes(productKey);

// Product selection
export const uiSubsConfigCompareFeaturesViewExpanded = (state) => uiSubsConfig(state).compareFeaturesViewExpanded;
export const uiSubsConfigSelectedProductKey = (state) => uiSubsConfig(state).selectedProductKey || '';
export const uiSubsConfigPreviousSelectedProductKey = (state) => uiSubsConfig(state).previousSelectedProductKey || '';
export const uiSubsConfigCurrentProductKey = (state) => uiSubsConfig(state).subscriptionProductKey || '';
export const uiSubsConfigHasSelectedProduct = (state) => uiSubsConfigSelectedProductKey(state).length > 0;
export const uiSubsConfigTrialFlowSelected = (state) => uiSubsConfig(state).trialFlowSelected;

// quantity selection
export const uiSubsConfigQuantitySelectionViewEnabled = (state) => uiSubsConfigHasSelectedProduct(state) && uiSubsConfig(state).quantitySelectionViewEnabled;

// billing period selection
export const uiSubsConfigUserSelectedBillingPeriod = (state) => uiSubsConfig(state).userSelectedBillingPeriod;
export const uiSubsConfigSelectedBillingPeriodIsAnnual = (state) => ProductCatalog.productBillingPeriodIsYear(uiSubsConfigUserSelectedBillingPeriod(state));
export const uiSubsConfigSelectedBillingPeriodIsMonthly = (state) => ProductCatalog.productBillingPeriodIsMonth(uiSubsConfigUserSelectedBillingPeriod(state));
export const uiSubsConfigSelectedBillingPeriodIsDaily = (state) => ProductCatalog.productBillingPeriodIsDay(uiSubsConfigUserSelectedBillingPeriod(state));
export const uiSubsConfigUserSelectedBillingDuration = (state) => uiSubsConfig(state).userSelectedBillingDuration;

// overview Selectors
export const uiSubsOverviewProductFamiliesVisible = (state) => uiSubsOverview(state).showProductFamilies || false;

// openvoice selectors
export const uiSubsConfigProductFamilyKeyIsOpenVoice = (state) => ProductCatalog.productFamilyKeyIsOpenVoice(uiSubsConfigProductFamilyKey(state));
