import {
  subscriptionsAccountKey
} from 'modules/subscription';
import {
  CancellationStart,
  CancellationConfirm,
  CancellationComplete,
  FlowStart,
  FlowExit,
  RemoveCancelStart,
  RemoveCancelConfirm,
  RemoveCancelComplete,
  RemoveCancelExit,
  RetentionStart,
  RetentionComplete
} from './tracking-constants';
import {
  experimentCancelCampaignBySubscriptionKey,
  experimentCancelTestGroupBySubscriptionKey,
  experimentCancelInRetentionFlowBySubscriptionKeyCampaign,
  experimentCancelInRetentionFlowTestGroupBySubscriptionKey
} from 'modules/experiments';
import Telemetry from 'lib/telemetry';

/**
 * @typedef {object} TrackingTelemetryCancellationBaseline
 *
 * This is the baseline information populated in all telemetry events for the ML cancellation engagement.
 * Other selectors and/or action creators will start with this object and augment it with their own properties.
 *
 * @property {string} ga_accountId: the entitlement account ID for this account.
 * @property {string} ml_engagement: always "cancellation" for this engagement/experiment
 */


/**
 * The baseline object definition that gets included in every ML cancellation experiment telemetry event.
 *
 * @param state
 * @returns {TrackingTelemetryCancellationBaseline}
 */
export const trackingTelemetryCancellationBaseline = (state) => ({
  ga_accountId: subscriptionsAccountKey(state),
  ml_engagement: 'cancellation'
});

export const trackingTelemetryEvent = (eventName, payload = {}) => {
  Telemetry.emit(eventName, payload);
};

export const trackingTelemetryRemoveCancel = (eventName, {state, payload: {productFamilyKey, subscriptionKey}}) => trackingTelemetryEvent('subsRemoveCancel', {
  ...trackingTelemetryCancellationBaseline(state),
  sa_action: eventName,
  subscription_key: subscriptionKey,
  product_family: productFamilyKey
});

export const trackingTelemetryCancel = (eventName, {state, payload: {productFamilyKey, subscriptionKey}}) => {
  const payload = {
    subscription_key: subscriptionKey,
    product_family: productFamilyKey,
    ml_retentionCampaign: experimentCancelCampaignBySubscriptionKey(state, productFamilyKey, subscriptionKey)
      || experimentCancelInRetentionFlowBySubscriptionKeyCampaign(state, subscriptionKey),
    sa_action: eventName
  };
  const testGroup = experimentCancelTestGroupBySubscriptionKey(state, productFamilyKey, subscriptionKey)
    || experimentCancelInRetentionFlowTestGroupBySubscriptionKey(state, subscriptionKey);

  if (testGroup) {
    payload.ml_testGroup = testGroup;
  }

  return trackingTelemetryEvent('subsCancel', {
    ...trackingTelemetryCancellationBaseline(state),
    ...payload
  });
};

/**
 * The event details which will consist of the state and payload properties
 *
 * @typedef {object} EventDetails
 *
 * @property {object} state
 * @property {object/array} payload
 */

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, event) => {
  switch (eventName) {
    case CancellationStart:
    case CancellationConfirm:
    case CancellationComplete:
    case FlowStart:
    case FlowExit:
    case RetentionStart:
    case RetentionComplete: {
      trackingTelemetryCancel(eventName, event);
      break;
    }
    case RemoveCancelStart:
    case RemoveCancelConfirm:
    case RemoveCancelComplete:
    case RemoveCancelExit: {
      trackingTelemetryRemoveCancel(eventName, event);
      break;
    }
    default: {
      break;
    }
  }
};
