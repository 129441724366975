import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import classes from './alert.scss';

export const AlertView = (props) => (
  <div>
    <Alert color={props.type} toggle={props.handleDismiss}>
      <div className={classes['alert-container']}>
        <div className={`row ${classes['content']}`}>
          {
            props.title &&
              <div className="col-12">
                <h4 className={classes['alert-heading']}>
                  {props.title}
                </h4>
              </div>
          }
          {
            props.body &&
              <div className="col-12">
                {props.body}
              </div>
          }
          {
            props.buttons &&
              <div className={`col-12 ${classes['alert-buttons']}`}>
                <div className="btn-group">
                  {props.buttons}
                </div>
              </div>
          }
        </div>
      </div>
    </Alert>
  </div>
);

AlertView.defaultProps = {
  type: 'warning'
};

AlertView.propTypes = {
  type: PropTypes.oneOf([
    'warning',
    'danger',
    'success',
    'info'
  ]),
  title: PropTypes.any.isRequired,
  body: PropTypes.any,
  buttons: PropTypes.any,
  handleDismiss: PropTypes.func
};

export default AlertView;
