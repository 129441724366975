/**
 * Important date action types organized into an object literal
 */
export const ImportantDateActionTypes: Record<string, ImportantDateActionType> = {
  PENDING_ACTION_CANCEL: 'PENDING_ACTION_CANCEL',
  PENDING_ACTION_EFFECTIVE: 'PENDING_ACTION_EFFECTIVE',
  RENEWAL: 'RENEWAL',
  START_PRODUCT_PRORATED: 'START_PRODUCT_PRORATED',
  START_PRODUCT: 'START_PRODUCT'
};

/**
 * Billing period types organized into an object literal
 */
export const BillingPeriodTypes: Record<string, BillingPeriodType> = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR'
};

/**
 * Product types organized into an object literal
 */
export const ProductTypes: Record<string, ProductType> = {
  BETA: 'BETA',
  FREEMIUM: 'FREEMIUM',
  PAID: 'PAID',
  TRIAL: 'TRIAL'
};

export const QuoteItemChangeTypes: Record<string, QuoteItemChangeType> = {
  ADD_ON: 'ADD_ON',
  CHANGE: 'CHANGE',
  CONVERT_TRIAL: 'CONVERT_TRIAL',
  NEW: 'NEW',
  REACTIVATE: 'REACTIVATE'
};
