import isInvalid from 'redux-form/lib/isInvalid';
import isPristine from 'redux-form/lib/isPristine';
import getFormValues from 'redux-form/lib/getFormValues';
import getFormMeta from 'redux-form/lib/getFormMeta';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {
  FORM_FIELD_ADDRESS_LINE_1,
  FORM_FIELD_CITY,
  FORM_FIELD_COUNTRY,
  FORM_FIELD_FIRST_NAME,
  FORM_FIELD_LAST_NAME,
  FORM_FIELD_PHONE_NUMBER,
  FORM_FIELD_ADDRESS_LINE_2,
  FORM_FIELD_POSTAL_CODE,
  FORM_FIELD_STATE,
  FORM_FIELD_IBAN,
  formFieldsRequiredCountries
} from './form-constants';

// ------------------------------------
// Payment form Helpers
// ------------------------------------

// @NOTE Only letters, numbers, and hyphens are allowed
const postalCodeValid = (value) => /^[\d\w]+([\s-\d\w])*$/.test(value.trim());
const required = (currentFieldValue) => !currentFieldValue ? 'shared.validation.required' : undefined;
const notRequired = () => undefined;
const numericNormalizer = (currentFieldValue) => !currentFieldValue ? currentFieldValue : currentFieldValue.replace(/\D/g, '');
const alphanumericNormalizer = (currentFieldValue) => !currentFieldValue ? currentFieldValue : currentFieldValue.replace(/[^a-zA-Z0-9]/g, '');

/**
 * @NOTE the return value should either a valid react intl error id or undefined if the field is optional.
 * If you return an invalid error id, there will be no error message displayed.
 * @param {string} value - The current field's value
 * @param {object} currentFormFieldsValues - all of the form's values
 * @param {object} props - any props passed to the form
 * @param {string} name - the name of the current rendered field
 * @returns {string|undefined}
 */
export const paymentFormFieldsValidation = (value, currentFormFieldsValues = {}, props, name) => {
  switch (name) {
    case FORM_FIELD_ADDRESS_LINE_1:
    case FORM_FIELD_CITY:
    case FORM_FIELD_COUNTRY:
    case FORM_FIELD_FIRST_NAME:
    case FORM_FIELD_LAST_NAME:
    case FORM_FIELD_PHONE_NUMBER: {
      return required(value);
    }
    case FORM_FIELD_ADDRESS_LINE_2: {
      return notRequired();
    }
    case FORM_FIELD_POSTAL_CODE: {
      // If postalCode field is empty and the selected country requires a postal code or payment method selected is SEPA, then mark the field as required
      if (!value && (formFieldsRequiredCountries[FORM_FIELD_POSTAL_CODE].includes(currentFormFieldsValues[FORM_FIELD_COUNTRY]) || props.paymentInfoSelectedTypeIsSepa)) {
        return 'shared.validation.required';
      }
      // If postalCode field has some data in it, then validate the input regardless whether it's required or not
      if (value && !postalCodeValid(value)) {
        return 'shared.validation.postalcode';
      }
      return undefined;
    }
    case FORM_FIELD_STATE: {
      if (formFieldsRequiredCountries[FORM_FIELD_STATE].includes(currentFormFieldsValues[FORM_FIELD_COUNTRY]) && !value) {
        return 'shared.validation.required';
      }
      return undefined;
    }
    case FORM_FIELD_IBAN: {
      // IBAN must be between 15 and 34 characters in length
      if (value && value.length >= 15 && value.length <= 34) {
        return undefined;
      }
      return 'shared.validation.iban';
    }
    default: {
      return undefined;
    }
  }
};

/**
 * @returns {function} a normalize function that is passed to redux-form
 */
export const paymentFormFieldsNormalize = (name) => {
  switch (name) {
    case FORM_FIELD_PHONE_NUMBER: {
      return numericNormalizer;
    }
    case FORM_FIELD_IBAN: {
      return alphanumericNormalizer;
    }
    default: {
      return undefined;
    }
  }
};

export const paymentFormStateFieldIsRequired = (currentFormFieldsValues = {}) => (
  formFieldsRequiredCountries[FORM_FIELD_STATE].includes(currentFormFieldsValues[FORM_FIELD_COUNTRY])
);

// ------------------------------------
// Selectors
// ------------------------------------


export const formValues = (state, formId) => getFormValues(formId)(state) || {};
export const fieldsMeta = (state, formId) => getFormMeta(formId)(state) || {};

/**
 * Determines if any of the forms have errors.
 * @returns {boolean} true if any form has an error, false otherwise.
 */
export const formsHaveErrors = (state) => {
  const keys = Object.keys(state.form);
  return keys.length > 0 && keys.some((key) => isInvalid(key)(state));
};

export const formByIdHasErrors = (state, formId) => isInvalid(formId)(state);

export const formsHaveChanged = (state) => {
  const keys = Object.keys(state.form);
  return keys.length > 0 && keys.some((key) => !isPristine(key)(state));
};

export const formsInCart = (state) => {
  const keys = Object.keys(state.form);
  return keys.length > 0 && keys.filter((key) => key.match(/(cart_.*)/));
};

export const formsHaveChangedForCartItem = (state, itemKey) => {
  const keys = Object.keys(state.form);
  const cartItemFormRegex = new RegExp(`cart_${itemKey}.*`);
  const cartItemForms = Array.isArray(keys) && keys.filter((key) => key.match(cartItemFormRegex));
  return Array.isArray(cartItemForms) && cartItemForms.some((itemForm) => !isPristine(itemForm)(state));
};

export const formChangedValuesForCartItem = (state, itemKey) => {
  const keys = Object.keys(state.form);
  const cartItemFormRegex = new RegExp(`cart_${itemKey}.*`);
  const cartItemForms = Array.isArray(keys) && keys.filter((key) => key.match(cartItemFormRegex));
  if (Array.isArray(cartItemForms)) {
    let changedValues = {};
    cartItemForms.forEach((itemForm) => {
      if (!isPristine(itemForm)(state)) {
        changedValues = {...changedValues, ...formValueSelector(itemForm)(state, 'billingPeriod', 'quantity')};
      }
    });
    return changedValues;
  }
  return false;
};
