import { daoQuoteInvoiceGet } from 'dao/quote-dao';
import {
  daoInvoicesGet,
  daoInvoiceGet
} from 'dao/invoice-dao';
import {
  INVOICES_RECEIVE_GET_FAILURE,
  INVOICES_RECEIVE_GET_SUCCESS,
  INVOICES_REQUEST_GET,
  INVOICE_PREVIEW_RECEIVE_GET_FAILURE,
  INVOICE_PREVIEW_RECEIVE_GET_SUCCESS,
  INVOICE_PREVIEW_REQUEST_GET,
  INVOICE_RECEIVE_GET_FAILURE,
  INVOICE_RECEIVE_GET_SUCCESS,
  INVOICE_REQUEST_GET
} from './invoice-action-types';

// ------------------------------------
// Actions
// ------------------------------------
export const invoicesReceiveGetSuccess = (payload = {}) => ({
  type: INVOICES_RECEIVE_GET_SUCCESS,
  payload
});

export const invoicesReceiveGetFailure = () => ({
  type: INVOICES_RECEIVE_GET_FAILURE
});

export const invoicesRequestGet = (billingAccountKey) => (dispatch) => {
  dispatch({
    type: INVOICES_REQUEST_GET
  });

  return daoInvoicesGet(billingAccountKey)
    .then(
      (response) => dispatch(invoicesReceiveGetSuccess(response.data)),
      () => dispatch(invoicesReceiveGetFailure())
    );
};

export const invoiceReceiveGetSuccess = (payload = {}) => ({
  type: INVOICE_RECEIVE_GET_SUCCESS,
  payload
});

export const invoiceReceiveGetFailure = (payload = {}) => ({
  type: INVOICE_RECEIVE_GET_FAILURE,
  payload
});

export const invoiceRequestGet = (billingAccountKey, invoiceKey) => (dispatch) => {
  dispatch({
    type: INVOICE_REQUEST_GET
  });

  return daoInvoiceGet(billingAccountKey, invoiceKey)
    .then(
      (response) => dispatch(invoiceReceiveGetSuccess(response.data)),
      (ex) => dispatch(invoiceReceiveGetFailure(ex))
    );
};

export const invoicePreviewReceiveGetSuccess = (payload = {}) => ({
  type: INVOICE_PREVIEW_RECEIVE_GET_SUCCESS,
  payload
});

export const invoicePreviewReceiveGetFailure = (payload = {}) => ({
  type: INVOICE_PREVIEW_RECEIVE_GET_FAILURE,
  payload
});

export const invoicePreviewRequestGet = (billingAccountKey, quoteKey) => (dispatch) => {
  dispatch({
    type: INVOICE_PREVIEW_REQUEST_GET
  });

  return daoQuoteInvoiceGet(billingAccountKey, quoteKey)
    .then(
      (response) => dispatch(invoicePreviewReceiveGetSuccess(response.data)),
      (ex) => dispatch(invoicePreviewReceiveGetFailure(ex))
    );
};
