export const TRACKING = 'TRACKING';

/**
 * The meta property details
 *
 * @typedef {object} MetaDetails
 *
 * @property {object} tracking - an object whose keys are app event names, and whose values are optional payloads for that event.
 *  If a payload is present on an event, it is an object containing key-value pairs.
 *  If no payload is needed for a particular event, then the payload is expected to be an empty object.
 *  For example:
 *   {
 *     EventA: {
 *       testA: 'testA'
 *     },
 *     EventB: {
 *       testB: 'testB'
 *     }
 *   }
 */

/**
 * The track details
 *
 * @typedef {object} TrackAction
 *
 * @property {string} type - the action type name
 * @property {object/array} [payload]
 * @property {MetaDetails} meta - a property that can contain the meta data, such as the tracking data
 */

/**
 * Construct the track action
 *
 * @param {object} eventDetails
 * @returns {TrackAction}
 */
export const track = (eventDetails = {}) => ({
  type: TRACKING,
  meta: {
    tracking: eventDetails
  }
});

/**
 * A helper function that takes an action and adds the meta data to it which will contain the tracking data
 *
 * @param {object} action - the action which the meta data will be added to it
 * @param {object} eventDetails
 * @returns {TrackAction}
 */
export const withTracking = (action, eventDetails = {}) => ({
  ...action,
  meta: {
    ...action.meta,
    tracking: eventDetails
  }
});

export default track;
