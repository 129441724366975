import {
  UI_SUBS_CONFIG_RESET_STATE,
  UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION,
  UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION,
  UI_SUBS_CONFIG_START_TRIAL_CONVERSION,
  UI_SUBS_CONFIG_SELECT_PRODUCT,
  UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW,
  UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY,
  UI_SUBS_CONFIG_UPDATE_QUANTITY,
  UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION,
  UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY,
  UI_SUBS_CONFIG_CONFIGURE_START,
  UI_SUBS_CONFIG_CONFIGURE_FINISH,
  UI_SUBS_CONFIG_TOGGLE_ADDON,
  UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW,
  UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE
} from './ui-action-types';

export const uiSubsConfigResetState = () => ({
  type: UI_SUBS_CONFIG_RESET_STATE
});

export const uiSubsConfigStartEditSubscription = () => ({
  type: UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION
});

export const uiSubsConfigStartTrialConversion = () => ({
  type: UI_SUBS_CONFIG_START_TRIAL_CONVERSION
});

export const uiSubsConfigStartNewSubscription = () => ({
  type: UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION
});

export const uiSubsConfigEnableSectionQuantity = () => ({
  type: UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY
});

export const uiSubsConfigSelectProduct = (payload = {}) => ({
  type: UI_SUBS_CONFIG_SELECT_PRODUCT,
  payload
});

export const uiSubsConfigConfigureStart = () => ({
  type: UI_SUBS_CONFIG_CONFIGURE_START
});

export const uiSubsConfigConfigureFinish = () => ({
  type: UI_SUBS_CONFIG_CONFIGURE_FINISH
});

export const uiSubsConfigToggleCompareFeaturesView = () => ({
  type: UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW
});

export const uiSubsConfigUpdateProductFamilyKey = (payload = {}) => ({
  type: UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY,
  payload
});

export const uiSubsConfigUpdateQuantity = (payload = {}) => ({
  type: UI_SUBS_CONFIG_UPDATE_QUANTITY,
  payload
});

export const uiSubsConfigSelectBillingPeriodAndDuration = (payload = {}) => ({
  type: UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION,
  payload
});

export const uiSubsConfigToggleAddon = (payload = {}) => ({
  type: UI_SUBS_CONFIG_TOGGLE_ADDON,
  payload
});

export const uiSubsOverviewProductFamiliesShow = () => ({
  type: UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW
});

export const uiSubsOverviewProductFamiliesHide = () => ({
  type: UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE
});
