import {
  customClient as api
} from 'dao/providers/axios';
import config from 'config/appconfig';

const payerAuthEndpoint = config.cog_endpoints.payerAuth;

export const daoPayerAuthPost = (
  paymentAccountKey,
  paymentMethodKey,
  billingAccountKey,
  payload
) => api.post(payerAuthEndpoint(paymentAccountKey, paymentMethodKey, billingAccountKey), payload);

export default {
  daoPayerAuthPost
};
