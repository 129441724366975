import { RouteNotFoundException } from 'lib/exceptions';
import appconfig from 'config/appconfig';

interface RouteResolverOptions {
  billingAccountKey?: string;
  routeParams?: string[];
  search?: string;
  hash?: string;
}

/**
 * @typedef {object} RouteResolverOptions
 *
 * Configures how a given route should resolve.
 *
 * @property {string} [billingAccountKey]: If set, and the matching appconfig route is "keyed", use this as the billing account key
 *                                         when resolving the route. Routes in appconfig are "keyed" by default, and are not keyed if
 *                                         they reside as a child of the "noKey" property.
 * @property {string[]} [routeParams=[]]: Parameters used to generate the resolved URL (order matters). These are applied, in order, as parameters
 *                                        to the matched route function from appconfig.
 * @property {string} [search='']: If set, the query string to append to the route.
 * @property {string} [hash='']: If set, the hash string to append to the route.
 */

/**
 * Creates a fully qualified route path string using routes defined in config/appconfig.js
 *
 * @param {string} routeName The name of the route you want to use (defined in config/appconfig.js)
 * @param {RouteResolverOptions} [options={}] configure what the specified route resolves to
 * @throws {RouteNotFoundException} Will throw if routeName does not match any route defined in appconfig
 * @returns {string}
 */
export const resolve = (routeName: string, options: RouteResolverOptions = {}) => {
  const { billingAccountKey, routeParams = [], search = '', hash = '' } = options;
  const cleanRouteParams = (!Array.isArray(routeParams)) ? [] : routeParams;
  const cleanSearch = (search.length > 0 && search[0] !== '?') ? `?${search}` : search;
  const cleanHash = (hash.length > 0 && hash[0] !== '#') ? `#${hash}` : hash;

  if (Object.prototype.hasOwnProperty.call(appconfig.routes, routeName)) {
    // matching route that needs a billing account key
    return billingAccountKey
      ? `/account/${billingAccountKey}${(appconfig as any).routes[routeName](...cleanRouteParams)}${cleanSearch}${cleanHash}`
      : `${(appconfig as any).routes[routeName](...cleanRouteParams)}${cleanSearch}${cleanHash}`;
  }
  if (Object.prototype.hasOwnProperty.call(appconfig.routes.noKey, routeName)) {
    // matching route that does not use a billing account key
    return `${(appconfig as any).routes.noKey[routeName](...cleanRouteParams)}${cleanSearch}${cleanHash}`;
  }
  throw new RouteNotFoundException(routeName);
};

export default { resolve };
