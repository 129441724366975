import {
  EXPERIMENT_INIT,
  EXPERIMENT_SET_FLAGS,
  EE_REQUEST_GET,
  EE_RECEIVE_GET_SUCCESS,
  EE_CANCEL_RETENTION_FLOW_SET
} from './experiments-action-types';
import {
  EE_CANCEL,
  EXPERIMENT_LD_DEFAULT_FLAGS
} from './experiments-constants';
// ------------------------------------
// Initial State
// ------------------------------------

export const initialState = {};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [EXPERIMENT_INIT]: (state, action) => ({
    ...EXPERIMENT_LD_DEFAULT_FLAGS,
    [EE_CANCEL]: {
      isLoading: {},
      inRetentionFlow: action.payload
    }
  }),
  [EXPERIMENT_SET_FLAGS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [EE_REQUEST_GET]: (state, action) => ({
    ...state,
    [action.experimentId]: {
      ...state[action.experimentId],
      isLoading: {
        ...state[action.experimentId].isLoading,
        [action.productFamilyKey]: {
          ...state[action.experimentId].isLoading[action.productFamilyKey],
          [action.subscriptionKey]: true
        }
      }
    }
  }),
  [EE_RECEIVE_GET_SUCCESS]: (state, action) => ({
    ...state,
    [action.experimentId]: {
      ...state[action.experimentId],
      [action.productFamilyKey]: {
        ...state[action.experimentId][action.productFamilyKey],
        ...action.payload
      },
      isLoading: {
        ...state[action.experimentId].isLoading,
        [action.productFamilyKey]: {
          ...state[action.experimentId].isLoading[action.productFamilyKey],
          [action.subscriptionKey]: false
        }
      }
    }
  }),
  [EE_CANCEL_RETENTION_FLOW_SET]: (state, action) => ({
    ...state,
    [EE_CANCEL]: {
      ...state[EE_CANCEL],
      inRetentionFlow: action.inRetentionFlow
    }
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const experimentsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};
