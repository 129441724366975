import {
  PAYMENT_SCA_TOKEN_REQUEST_POST,
  PAYMENT_SCA_TOKEN_REQUEST_POST_SUCCESS,
  PAYMENT_SCA_TOKEN_REQUEST_POST_FAILURE,
  PAYMENT_SETUP_COMPLETE
} from './sca-token-action-types';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  isLoading: false,
  tokenResp: {}
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [PAYMENT_SCA_TOKEN_REQUEST_POST]: (state) => ({...state, isLoading: true}),
  // isLoading is not turned off in the succcess of jwt token
  // It`s being turned off in PAYMENT_SETUP_COMPLETE, which gets
  // fired once the payment setup complete is fired from cardinal
  [PAYMENT_SCA_TOKEN_REQUEST_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      const { payload } = action;
      return {
        ...state,
        tokenResp: payload
      };
    }
    return {...state, isLoading: false};
  },
  [PAYMENT_SETUP_COMPLETE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [PAYMENT_SCA_TOKEN_REQUEST_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const scaToken = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default scaToken;
