import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextFieldView from './text-field-view';

export class TextFieldContainer extends Component {
  fieldClasses = () => (this.props.meta.touched && this.props.meta.error) ? `${this.props.className} border border-danger` : this.props.className;

  render = () => (
    <TextFieldView
      fieldClasses={this.fieldClasses()}
      meta={this.props.meta}
      id={this.props.id}
      input={{...this.props.input, placeholder: this.props.placeholder}}
      type={this.props.type}
      maxLength={this.props.maxLength}
      disabled={this.props.disabled}
    />
  );
}

TextFieldContainer.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  placeholder: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool
};

export default connect()(TextFieldContainer);
