import {
  daoSCATokenPost
} from 'dao/sca-token-dao';

import {
  PAYMENT_SCA_TOKEN_REQUEST_POST,
  PAYMENT_SCA_TOKEN_REQUEST_POST_SUCCESS,
  PAYMENT_SCA_TOKEN_REQUEST_POST_FAILURE,
  PAYMENT_SETUP_COMPLETE
} from './sca-token-action-types';

// ------------------------------------
// Actions
// ------------------------------------
export const paymentSCATokenReceivePostSuccess = (payload = {}) => ({
  type: PAYMENT_SCA_TOKEN_REQUEST_POST_SUCCESS,
  payload
});

export const paymentSCATokenReceivePostFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PAYMENT_SCA_TOKEN_REQUEST_POST_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const paymentSCATokenRequestPost = (payload = {}) => (dispatch) => {
  dispatch({
    type: PAYMENT_SCA_TOKEN_REQUEST_POST
  });

  return daoSCATokenPost(payload)
    .then(
      (response) => dispatch(paymentSCATokenReceivePostSuccess(response.data)),
      (ex) => dispatch(paymentSCATokenReceivePostFailure(ex))
    );
};

export const cardinalSetupComplete = () => (dispatch) => (
  dispatch({
    type: PAYMENT_SETUP_COMPLETE
  })
);

export const actions = {
  paymentSCATokenRequestPost,
  paymentSCATokenReceivePostSuccess,
  paymentSCATokenReceivePostFailure,
  cardinalSetupComplete
};
