import {
  PAYER_AUTH_REQUEST_POST_SUCCESS,
  PAYER_AUTH_REQUEST_POST_FAILURE
} from './payer-auth-action-types';

// ------------------------------------
// Actions
// ------------------------------------
export const payerAuthRequestPostSuccess = (payload = {}) => ({
  type: PAYER_AUTH_REQUEST_POST_SUCCESS,
  payload
});

export const payerAuthRequestPostFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PAYER_AUTH_REQUEST_POST_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const actions = {
  payerAuthRequestPostSuccess,
  payerAuthRequestPostFailure
};
