import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import st from 'components/shared-translations';
import { FormattedMessage } from 'react-intl';

export const AlertErrorView = (props) => (
  <div id={`alert-error-${props.msg.key}`}>
    <Alert
      type="danger"
      title={
        <span><FormattedMessage {...(props.msg.msgObj ? props.msg.msgObj : st[props.msg.key])} /></span>
      }
      handleDismiss={props.handleAlertDismiss}
    />
  </div>
);

AlertErrorView.propTypes = {
  msg: PropTypes.shape({
    key: PropTypes.string.isRequired,
    msgObj: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  }).isRequired,
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertErrorView;
