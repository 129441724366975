import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import thunk from 'redux-thunk';
import makeRootReducer from './reducers';

// eslint-disable-next-line no-shadow
export const createStoreFactory = ({applyMiddleware, compose, reduxCreateStore, thunk, makeRootReducer}) => (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  /* istanbul ignore next */
  if (__DEV__) {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  if (__TEST__) {
    const actionCacheMiddleware = require('./middlewares/action-cache-middleware').default.actionCacheMiddleware; // eslint-disable-line global-require, import/newline-after-import
    middleware.push(actionCacheMiddleware);
  }

  if (!__TEST__) {
    const handleTrackingEvent = require('modules/tracking-module').default; // eslint-disable-line global-require, import/newline-after-import
    const trackingMiddleware = require('./middlewares/tracking-middleware').default; // eslint-disable-line global-require, import/newline-after-import
    middleware.push(trackingMiddleware(handleTrackingEvent));
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = reduxCreateStore(
    makeRootReducer(),
    initialState,
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
  store.asyncReducers = {};

  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default; // eslint-disable-line global-require, import/newline-after-import
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};

const store = createStoreFactory({
  applyMiddleware,
  compose,
  reduxCreateStore,
  thunk,
  makeRootReducer
})();

export default store;
