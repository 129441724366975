import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  Link
} from 'react-router-dom';
import resolveRoute from 'lib/resolve-route';

interface Props {
  activeClassName?: string;
  billingAccountKey: string;
  children?: any;
  className?: string;
  disabled?: boolean;
  hash?: string;
  role?: string;
  routeParams?: string[];
  search?: string;
  to: string;
  id?: string;
}

export const AppLink: React.FC<Props> = ({to, billingAccountKey, routeParams, activeClassName, className, children, disabled, search, hash, role, id}) => {
  if (disabled) {
    return <span className={className}>{children}</span>;
  }
  if (activeClassName) {
    return (
      <NavLink
        to={resolveRoute.resolve(to, {billingAccountKey, routeParams, search, hash})}
        className={className}
        id={id}
        activeClassName={activeClassName}
        role={role}
      >
        {children}
      </NavLink>
    );
  }
  return <Link id={id} to={resolveRoute.resolve(to, {billingAccountKey, routeParams, search, hash})} className={className} role={role}>{children}</Link>;
};

(AppLink as any).propTypes = {
  activeClassName: PropTypes.string,
  billingAccountKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hash: PropTypes.string,
  role: PropTypes.string,
  routeParams: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.string,
  to: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default AppLink;
