import { defineMessages } from 'react-intl';

const t = defineMessages({
  'alert.auth.expired.buttonLogin': {
    defaultMessage: 'LOG IN TO YOUR ACCOUNT',
    id: 'alert.auth.expired.buttonLogin'
  },
  'alert.auth.expired.description': {
    defaultMessage: 'You will need to log in to your account to continue.',
    id: 'alert.auth.expired.description'
  },
  'alert.auth.expired.header': {
    defaultMessage: 'Your session has expired',
    id: 'alert.auth.expired.header'
  },
  'alert.auth.warning.buttonReload': {
    defaultMessage: 'RELOAD',
    id: 'alert.auth.warning.buttonReload'
  },
  'alert.auth.warning.description': {
    defaultMessage: 'You should save your work and then reload the page.',
    id: 'alert.auth.warning.description'
  },
  'alert.auth.warning.header': {
    defaultMessage: 'Your session is about to expire',
    id: 'alert.auth.warning.header'
  },
  'alert.auth.warning.time': {
    defaultMessage: '{timeLeft} seconds remaining.',
    id: 'alert.auth.warning.time'
  },
  'alert.error.order.accepttos': {
    defaultMessage: 'Please accept the Terms of Service, Privacy Policy, and Anti-Spam Policy in order to proceed.',
    id: 'alert.error.order.accepttos'
  },
  'alert.error.order.assignedseats': {
    defaultMessage: 'We apologize, but this order is unable to be processed. Please remove user(s) from seats in the <admin-link>Admin Center</admin-link> in order to proceed.',
    id: 'alert.error.order.assignedseats'
  },
  'alert.error.order.cannotbeprocessed': {
    defaultMessage: 'We are unable to process your order at this time.',
    id: 'alert.error.order.cannotbeprocessed'
  },
  'alert.error.order.duplicate.items': {
    defaultMessage: 'Please remove any duplicate items in your cart and try again.',
    id: 'alert.error.order.duplicate.items'
  },
  'alert.error.order.invalid.cartquote': {
    defaultMessage: 'We apologize, but it appears your Shopping Cart has experienced an error. Please refresh the page to proceed.',
    id: 'alert.error.order.invalid.cartquote'
  },
  'alert.error.order.missingitems': {
    defaultMessage: 'Please add an item to your shopping cart in order to proceed.',
    id: 'alert.error.order.missingitems'
  },
  'alert.error.order.missingrequiredproduct': {
    defaultMessage: 'Purchasing an add-on requires the corresponding base product. Please review your order and try again.',
    id: 'alert.error.order.missingrequiredproduct'
  },
  'alert.error.order.requirelessorequalquantity': {
    defaultMessage: 'It appears you have more quantity of add-on than the base plan. Please review your order and try again.',
    id: 'alert.error.order.requirelessorequalquantity'
  },
  'alert.error.order.seatsnotreducible': {
    defaultMessage: 'We apologize, but you are unable to reduce the quantity at this time. Please contact customer care.',
    id: 'alert.error.order.seatsnotreducible'
  },
  'alert.error.paymentmethod.invalid.general': {
    defaultMessage: 'There was a problem with your SEPA information. Please try again.',
    id: 'alert.error.paymentmethod.invalid.general'
  },
  'alert.error.permissiondenied': {
    defaultMessage: 'You don\'t have permission to execute the requested operation.',
    id: 'alert.error.permissiondenied'
  },
  'alert.expired.payment.method.button': {
    defaultMessage: 'UPDATE PAYMENT METHOD',
    id: 'alert.expired.payment.method.button'
  },
  'alert.expired.payment.method.cc.message': {
    defaultMessage: 'Your credit card is expired, please update your card.',
    id: 'alert.expired.payment.method.cc.message'
  },
  'alert.expired.payment.method.title': {
    defaultMessage: 'Expired Payment Method',
    id: 'alert.expired.payment.method.title'
  },
  'alert.fraud.title': {
    defaultMessage: 'We apologize, but we’re processing your account and can’t change it right now. Please try again later.',
    id: 'alert.fraud.title'
  },
  'alert.payment.existing.9s': {
    defaultMessage: 'To comply with the <support-link>Strong Customer Authentication regulation</support-link> in Europe, we need you to update your payment method to ensure continuity in your service.{br}Please <cc-link>update your credit card details</cc-link>, either by verifying and re-entering your existing payment info or providing a new card. Follow any authentication steps required by the card issuer.',
    id: 'alert.payment.existing.9s'
  },
  'alert.preconfiguration.body': {
    defaultMessage: 'We were unable to find a product that matches your request.',
    id: 'alert.preconfiguration.body'
  },
  'alert.preconfiguration.title': {
    defaultMessage: 'Ineligible product',
    id: 'alert.preconfiguration.title'
  },
  'alert.product.unmatched': {
    defaultMessage: 'Please select an existing {productName} subscription to edit, or add a new {productName} subscription.',
    id: 'alert.product.unmatched'
  },
  'alert.product.unmatched.singleproduct': {
    defaultMessage: 'Please select an existing {productName} subscription to edit.',
    id: 'alert.product.unmatched.singleproduct'
  },
  'alert.restrictpaymentmethod': {
    defaultMessage: 'We apologize, but your payment method cannot be modified at this time. Please contact {careLink} for assistance.',
    id: 'alert.restrictpaymentmethod'
  },
  'alert.restrictpaymentmethod.link': {
    defaultMessage: 'Customer Care',
    id: 'alert.restrictpaymentmethod.link'
  },
  'alert.sepa.setup.success.body': {
    defaultMessage: '<strong>LogMeIn Inc.</strong> will appear on your statement when this SEPA Direct Debit is used. Your mandate reference number is {mandateReference}.',
    id: 'alert.sepa.setup.success.body'
  },
  'alert.sepa.setup.success.title': {
    defaultMessage: 'SEPA Direct Debit setup successful',
    id: 'alert.sepa.setup.success.title'
  },
  'alert.warning.address.invalid.message': {
    defaultMessage: 'We apologize, but your address is unable to be validated at this time. Please review your address to ensure uninterrupted service.',
    id: 'alert.warning.address.invalid.message'
  },
  'alert.warning.payment.method.link': {
    defaultMessage: 'update your payment method',
    id: 'alert.warning.payment.method.link'
  },
  'alert.warning.payment.method.message': {
    defaultMessage: 'We are unable to process the payment method you have on file. Please {updatePaymentMethodLink}.',
    id: 'alert.warning.payment.method.message'
  },
  'alert.try.switch.message': {
    defaultMessage: 'Starting October, all admins will be transitioned over to the new GoTo Admin experience.',
    id: 'alert.try.switch.message'
  },
  'alert.try.switch.learn.more': {
    defaultMessage: 'LEARN MORE',
    id: 'alert.try.switch.learn.more'
  },
  'alert.try.switch.modal.title': {
    defaultMessage: 'The new GoTo Admin ',
    id: 'alert.try.switch.modal.title'
  },
  'alert.try.switch.modal.sub.title': {
    defaultMessage: 'A new look and improved admin experience',
    id: 'alert.try.switch.modal.sub.title'
  },
  'alert.try.switch.modal.look.message': {
    defaultMessage: '<title>Refreshed look and feel</title><body>A simplified navigation and modern, accessible user interface.</body>',
    id: 'alert.try.switch.modal.look.message'
  },
  'alert.try.switch.modal.tools.message': {
    defaultMessage: '<title>More tools for your team</title><body>Need a cloud phone system? Now you can manage all your employee collaboration needs in one place.</body>',
    id: 'alert.try.switch.modal.tools.message'
  },
  'alert.try.switch.modal.features.message': {
    defaultMessage: '<title>New features built for admins</title><body>GoTo offers a dark mode, more ways to group users, and reusable customized roles for Admins.</body>',
    id: 'alert.try.switch.modal.features.message'
  },
  'alert.try.switch.modal.body': {
    defaultMessage: 'Once you switch to GoTo Admin, it will be the default for all admins on your account. You can switch your account back to legacy admin anytime before our official GoTo Admin rollout.',
    id: 'alert.try.switch.modal.body'
  },
  'alert.try.switch.modal.primary.button': {
    defaultMessage: 'Switch to GoTo Admin',
    id: 'alert.try.switch.modal.primary.button'
  },
  'alert.try.switch.modal.something.wrong': {
    defaultMessage: 'Something went wrong. Try again, or try the GoTo Admin preview.',
    id: 'alert.try.switch.modal.something.wrong'
  },
  'alert.try.switch.modal.secondary.button': {
    defaultMessage: 'Preview GoTo Admin',
    id: 'alert.try.switch.modal.secondary.button'
  },
  'avatar.alt': {
    defaultMessage: 'Your actions menu',
    id: 'avatar.alt'
  },
  'billing-address-info.button.canceledit': {
    defaultMessage: 'Cancel Edit Billing Address',
    id: 'billing-address-info.button.canceledit'
  },
  'bootstrap.fail.header': {
    defaultMessage: 'We\'re unable to load your account information.',
    description: 'The title/header of the bootstrap failure page',
    id: 'bootstrap.fail.header'
  },
  'bootstrap.fail.myaccount': {
    defaultMessage: 'Go to my account',
    description: 'Button which will take user to their My Account site',
    id: 'bootstrap.fail.myaccount'
  },
  'bootstrap.fail.options': {
    defaultMessage: 'Try reloading again, or you can go to your account home.',
    description: 'Description of options for the user when app fails to bootstrap',
    id: 'bootstrap.fail.options'
  },
  'bootstrap.fail.reload': {
    defaultMessage: 'Reload the page',
    description: 'Button which will reload the web page when clicked',
    id: 'bootstrap.fail.reload'
  },
  'cart.button.addanother': {
    defaultMessage: 'Add another product',
    description: 'Button to add another product',
    id: 'cart.button.addanother'
  },
  'cart.button.nothanks': {
    defaultMessage: 'No thanks',
    description: 'Button on empty cart page the user clicks when they don\'t want to add a subscription',
    id: 'cart.button.nothanks'
  },
  'cart.button.revert': {
    defaultMessage: 'Revert changes',
    id: 'cart.button.revert'
  },
  'cart.button.save': {
    defaultMessage: 'Update cart',
    id: 'cart.button.save'
  },
  'cart.empty': {
    defaultMessage: 'Your shopping cart is empty. Would you like to add a subscription?',
    description: 'Text displayed when the cart is empty',
    id: 'cart.empty'
  },
  'cart.item.current': {
    defaultMessage: 'CURRENT',
    id: 'cart.item.current'
  },
  'cart.item.new': {
    defaultMessage: 'NEW',
    id: 'cart.item.new'
  },
  'cart.overview.header.caps': {
    defaultMessage: 'SHOPPING CART',
    id: 'cart.overview.header.caps'
  },
  'cart.savecart': {
    defaultMessage: 'To checkout please update your cart.',
    id: 'cart.savecart'
  },
  'common.contact-admin': {
    defaultMessage: 'You need to be a Billing Administrator for your account in order to access the Billing Center to make changes to the subscription(s) on your account. Please contact your account administrator if you believe you should have access to the Billing Center. View your personal <account-link>account profile</account-link>.',
    id: 'common.contact-admin'
  },
  'common.error.code': {
    defaultMessage: 'Code',
    id: 'common.error.code'
  },
  'common.error.debug': {
    defaultMessage: 'Debug',
    id: 'common.error.debug'
  },
  'common.error.details': {
    defaultMessage: 'Error Details',
    id: 'common.error.details'
  },
  'common.error.field': {
    defaultMessage: 'Field',
    id: 'common.error.field'
  },
  'common.error.header': {
    defaultMessage: 'Error! We’ve encountered a problem!',
    id: 'common.error.header'
  },
  'common.error.id': {
    defaultMessage: 'Error ID',
    id: 'common.error.id'
  },
  'common.error.link': {
    defaultMessage: 'Please try again later or go back to <my-account-link>your account</my-account-link>.',
    id: 'common.error.link'
  },
  'common.error.status': {
    defaultMessage: 'Status',
    id: 'common.error.status'
  },
  'common.error.value': {
    defaultMessage: 'Value',
    id: 'common.error.value'
  },
  'common.forbidden': {
    defaultMessage: 'You seem to have landed in the wrong place.',
    id: 'common.forbidden'
  },
  'common.notfound.header': {
    defaultMessage: 'Sorry! We couldn’t find the page you were looking for.',
    id: 'common.notfound.header'
  },
  'common.notfound.link': {
    defaultMessage: 'Please go back to <home-link>your subscriptions overview</home-link>.',
    id: 'common.notfound.link'
  },
  'confirmation.email.message': {
    defaultMessage: 'We’ve sent you an email confirmation.',
    id: 'confirmation.email.message'
  },
  'confirmation.gotoproduct.message': {
    defaultMessage: 'If you want to start using your product, go to',
    id: 'confirmation.gotoproduct.message'
  },
  'confirmation.header.thankyou': {
    defaultMessage: 'THANK YOU FOR YOUR ORDER',
    id: 'confirmation.header.thankyou'
  },
  'confirmation.help.support': {
    defaultMessage: 'SUPPORT',
    id: 'confirmation.help.support'
  },
  'confirmation.myaccount.button': {
    defaultMessage: 'MY ACCOUNT',
    id: 'confirmation.myaccount.button'
  },
  'confirmation.myinvoices.button': {
    defaultMessage: 'MY INVOICES',
    id: 'confirmation.myinvoices.button'
  },
  'creditcard.name.amex': {
    defaultMessage: 'Amex',
    id: 'creditcard.name.amex'
  },
  'creditcard.name.discover': {
    defaultMessage: 'Discover',
    id: 'creditcard.name.discover'
  },
  'creditcard.name.mastercard': {
    defaultMessage: 'Mastercard',
    id: 'creditcard.name.mastercard'
  },
  'creditcard.name.visa': {
    defaultMessage: 'Visa',
    id: 'creditcard.name.visa'
  },
  'creditcardform.allowed.creditcard.types': {
    defaultMessage: 'Allowed credit card types',
    id: 'creditcardform.allowed.creditcard.types'
  },
  'creditcardform.field.validation.errormessage': {
    defaultMessage: 'Enter a valid card number',
    id: 'creditcardform.field.validation.errormessage'
  },
  'creditcardform.paymentdetails.cvn': {
    defaultMessage: 'CVN',
    id: 'creditcardform.paymentdetails.cvn'
  },
  'creditcardform.paymentdetails.cvn.message': {
    defaultMessage: 'This code is a three or four digit number printed on the back or front of credit cards.',
    id: 'creditcardform.paymentdetails.cvn.message'
  },
  'creditcardform.paymentdetails.expirationmonth': {
    defaultMessage: 'Expiration Month',
    id: 'creditcardform.paymentdetails.expirationmonth'
  },
  'creditcardform.paymentdetails.expirationyear': {
    defaultMessage: 'Expiration Year',
    id: 'creditcardform.paymentdetails.expirationyear'
  },
  'dropdown.family.category.collaboration': {
    defaultMessage: 'Meet, Collaborate & Communicate',
    id: 'dropdown.family.category.collaboration'
  },
  'dropdown.family.category.support': {
    defaultMessage: 'Access & Support',
    id: 'dropdown.family.category.support'
  },
  'dropdown.family.description.g2ars': {
    defaultMessage: 'Provide live & unattended remote support.',
    id: 'dropdown.family.description.g2ars'
  },
  'dropdown.family.description.g2asd': {
    defaultMessage: 'Simple IT service management.',
    id: 'dropdown.family.description.g2asd'
  },
  'dropdown.family.description.g2aseeit': {
    defaultMessage: 'See what your customer sees via their mobile device.',
    id: 'dropdown.family.description.g2aseeit'
  },
  'dropdown.family.description.g2m': {
    defaultMessage: 'Meet & collaborate effortlessly.',
    id: 'dropdown.family.description.g2m'
  },
  'dropdown.family.description.g2t': {
    defaultMessage: 'Train your organization more effectively.',
    id: 'dropdown.family.description.g2t'
  },
  'dropdown.family.description.g2w': {
    defaultMessage: 'Easily host engaging events.',
    id: 'dropdown.family.description.g2w'
  },
  'dropdown.family.description.ov': {
    defaultMessage: 'Conference calls made easy.',
    id: 'dropdown.family.description.ov'
  },
  'footer.aboutus': {
    defaultMessage: 'About us',
    description: 'The about us link',
    id: 'footer.aboutus'
  },
  'footer.copyright': {
    defaultMessage: 'Copyright © {year} GoTo Group, Inc. All rights reserved.',
    description: 'The copyright text',
    id: 'footer.copyright'
  },
  'footer.legalinfo': {
    defaultMessage: 'Legal info',
    description: 'The terms of service link',
    id: 'footer.legalinfo'
  },
  'footer.openvoice': {
    defaultMessage: 'OpenVoice audio conferencing services in the United States are provided by GoTo Audio LLC which is the telecommunications provider and is responsible for the rates and Terms of Service of the audio conferencing services.',
    description: 'The OpenVoice text',
    id: 'footer.openvoice'
  },
  'importantdates.header': {
    defaultMessage: 'Important Information',
    id: 'importantdates.header'
  },
  'importantdates.item.delayedchange': {
    defaultMessage: 'Your {productName} change(s) will go through on your next renewal date: {renewalDate}.',
    id: 'importantdates.item.delayedchange'
  },
  'importantdates.item.delayedchangeandcharge': {
    defaultMessage: 'Your {productName} change(s) will go through on your next renewal date: {renewalDate}. Your card will be charged {amount} (excluding taxes).',
    id: 'importantdates.item.delayedchangeandcharge'
  },
  'importantdates.item.delayedchangeandrefund': {
    defaultMessage: 'Your {productName} change(s) will go through on your next renewal date: {renewalDate}. Your card will be credited {amount} (excluding taxes).',
    id: 'importantdates.item.delayedchangeandrefund'
  },
  'importantdates.item.notfound': {
    defaultMessage: 'Not found',
    id: 'importantdates.item.notfound'
  },
  'importantdates.item.paidstart': {
    defaultMessage: 'Your {productName} subscription will begin today.',
    id: 'importantdates.item.paidstart'
  },
  'importantdates.item.renewal': {
    defaultMessage: 'Your card may be charged for any outstanding usage balance owed on your {productName} on {renewalDate}.',
    id: 'importantdates.item.renewal'
  },
  'importantdates.item.renewal.usage': {
    defaultMessage: 'Adding this product may impact the renewal dates of your existing product subscriptions.',
    id: 'importantdates.item.renewal.usage'
  },
  'importantdates.item.retentionflow.flatrate.renewalinformation': {
    defaultMessage: 'Your monthly per organizer rate switches from {currentPrice} to the new rate of {newPrice} on {startDate} and will be billed {billingPeriodAdverb} for a total of {totalAmount}/{billingPeriod}.',
    id: 'importantdates.item.retentionflow.flatrate.renewalinformation'
  },
  'importantdates.item.retentionflow.flatrate.savepercent': {
    defaultMessage: 'The {savePercent} discount for your {productName} plan will be effective on your next renewal date, {renewalDate}.',
    id: 'importantdates.item.retentionflow.flatrate.savepercent'
  },
  'importantdates.item.trialstart': {
    defaultMessage: 'Once you\'ve placed your order, your {productName} will start right away.',
    id: 'importantdates.item.trialstart'
  },
  'incrementdecrementquantity.error.max': {
    defaultMessage: 'You may only add {minQuantity}-{maxQuantity} {unit}.',
    description: 'Messaging for user about min and max value allowed',
    id: 'incrementdecrementquantity.error.max'
  },
  'incrementdecrementquantity.error.min': {
    defaultMessage: 'Please add {minQuantity} or more {unit}.',
    description: 'Messaging for user about min value allowed',
    id: 'incrementdecrementquantity.error.min'
  },
  'info.bar.active': {
    defaultMessage: 'ACTIVE',
    id: 'info.bar.active'
  },
  'info.bar.cancellation': {
    defaultMessage: 'PENDING CANCELLATION',
    id: 'info.bar.cancellation'
  },
  'info.bar.current': {
    defaultMessage: 'CURRENT PLAN',
    id: 'info.bar.current'
  },
  'info.bar.inactive': {
    defaultMessage: 'INACTIVE',
    id: 'info.bar.inactive'
  },
  'info.bar.popular': {
    defaultMessage: 'MOST POPULAR',
    id: 'info.bar.popular'
  },
  'info.bar.selected': {
    defaultMessage: 'SELECTED',
    id: 'info.bar.selected'
  },
  'invoices.history.amount': {
    defaultMessage: 'Invoice Amount',
    id: 'invoices.history.amount'
  },
  'invoices.history.date': {
    defaultMessage: 'Invoice Date',
    id: 'invoices.history.date'
  },
  'invoices.history.number': {
    defaultMessage: 'Invoice Number',
    id: 'invoices.history.number'
  },
  'invoices.invoicehistory': {
    defaultMessage: 'INVOICE HISTORY',
    id: 'invoices.invoicehistory'
  },
  'invoices.none': {
    defaultMessage: 'You have no invoices.',
    id: 'invoices.none'
  },
  'loader.processing': {
    defaultMessage: 'Processing',
    id: 'loader.processing'
  },
  'loader.waittime': {
    defaultMessage: 'Please wait. This may take some time.',
    id: 'loader.waittime'
  },
  'mainnavcart.label': {
    defaultMessage: 'View and edit cart',
    id: 'mainnavcart.label'
  },
  'modal.cancel.body.confirm': {
    defaultMessage: 'Cancellation will take place at the end of your subscription billing date on {date}. You will not receive a prorated refund.',
    id: 'modal.cancel.body.confirm'
  },
  'modal.cancel.body.success': {
    defaultMessage: 'Your {product} subscription will end on {date}. We have sent you a confirmation email.',
    id: 'modal.cancel.body.success'
  },
  'modal.cancel.button.keep': {
    defaultMessage: 'Don’t cancel',
    id: 'modal.cancel.button.keep'
  },
  'modal.cancel.button.remove': {
    defaultMessage: 'Yes, cancel',
    id: 'modal.cancel.button.remove'
  },
  'modal.cancel.header.confirm': {
    defaultMessage: 'Are you sure you want to cancel?',
    id: 'modal.cancel.header.confirm'
  },
  'modal.cancel.header.processed': {
    defaultMessage: 'Your request has been processed.',
    id: 'modal.cancel.header.processed'
  },
  'modal.cancelwinback.betterfit': {
    defaultMessage: 'Just meeting up to {maxAttendees} other people? {productName} might be a better fit.',
    id: 'modal.cancelwinback.betterfit'
  },
  'modal.cancelwinback.buttontext': {
    defaultMessage: 'Get {productName}',
    id: 'modal.cancelwinback.buttontext'
  },
  'modal.cancelwinback.header': {
    defaultMessage: 'We\'re Sorry You Want to Go',
    id: 'modal.cancelwinback.header'
  },
  'modal.cancelwinback.perunit': {
    defaultMessage: 'per {unit}',
    id: 'modal.cancelwinback.perunit'
  },
  'modal.cancelwinback.pricepermonth': {
    defaultMessage: '{price} /month',
    id: 'modal.cancelwinback.pricepermonth'
  },
  'modal.cancelwinback.productdetails.paidannually': {
    defaultMessage: 'paid annually',
    id: 'modal.cancelwinback.productdetails.paidannually'
  },
  'modal.cancelwinback.productdetails.paidmonthly': {
    defaultMessage: 'paid monthly',
    id: 'modal.cancelwinback.productdetails.paidmonthly'
  },
  'modal.cancelwinback.save': {
    defaultMessage: 'Save with {productName}!',
    id: 'modal.cancelwinback.save'
  },
  'modal.cart.addon.alert.header': {
    id: 'modal.cart.addon.alert.header',
    defaultMessage: 'Please Note:'
  },
  'modal.cart.addon.alert.body': {
    id: 'modal.cart.addon.alert.body',
    defaultMessage: 'Looks like you are removing {baseProductName} from the cart. If you do that, we will also remove {addOnsProductNames}'
  },
  'modal.cart.addon.alert.button': {
    id: 'modal.cart.addon.alert.button',
    defaultMessage: 'CONTINUE'
  },
  'modal.removecancel.body.confirm': {
    defaultMessage: 'Are you sure you want to remove your pending cancellation?',
    id: 'modal.removecancel.body.confirm'
  },
  'modal.removecancel.body.renewal': {
    defaultMessage: 'Your subscription will renew on {date}.',
    id: 'modal.removecancel.body.renewal'
  },
  'modal.removecancel.body.success': {
    defaultMessage: 'Your pending cancellation was successfully removed.',
    id: 'modal.removecancel.body.success'
  },
  'modal.removecancel.button.keep': {
    defaultMessage: 'Don’t remove cancel',
    id: 'modal.removecancel.button.keep'
  },
  'modal.removecancel.button.remove': {
    defaultMessage: 'Yes, remove cancel',
    id: 'modal.removecancel.button.remove'
  },
  'modal.removecancel.header': {
    defaultMessage: 'Remove Cancel',
    id: 'modal.removecancel.header'
  },
  'modal.subscriptions.overview.canceladdon.autocancel': {
    defaultMessage: 'If you cancel {baseSubscriptionLongName}, your {allAddonsLongName} will cancel on {allAddonsQuantity, plural, one {its} other {their}} next renewal date after your {baseSubscriptionLongName} subscription ends.',
    id: 'modal.subscriptions.overview.canceladdon.autocancel'
  },
  'modal.subscriptions.overview.canceladdon.header': {
    defaultMessage: 'Please note:',
    id: 'modal.subscriptions.overview.canceladdon.header'
  },
  'modal.subscriptions.overview.canceladdon.message': {
    defaultMessage: 'Looks like you are cancelling your subscription of {baseSubscriptionLongName}. {cancelableAddonsQuantity, plural, one {We recommend that you also cancel your {cancelableAddons} subscription.} other {We recommend that you also cancel your {cancelableAddons} subscriptions.}} {allAddonsQuantity, plural, one {However, we\'ve got you covered - if you do nothing, your {allAddonsLongName} subscription will cancel on its next renewal date} other {However, we\'ve got you covered - if you do nothing, your {allAddonsLongName} subscriptions will cancel on their next renewal date}}. You will be assessed any outstanding {allAddonsLongName} charges at that time.',
    id: 'modal.subscriptions.overview.canceladdon.message'
  },
  'modal.subscriptions.overview.cancellation.complete.header': {
    defaultMessage: 'Your request has been processed and a confirmation has been sent to your email.',
    id: 'modal.subscriptions.overview.cancellation.complete.header'
  },
  'modal.subscriptions.overview.cancellation.complete.message': {
    defaultMessage: 'After your plan expires on {expDate}, all your {productName} users will switch to a {plan}. You may renew your plan at any time.',
    id: 'modal.subscriptions.overview.cancellation.complete.message'
  },
  'modal.subscriptions.overview.cancellation.complete.plan': {
    defaultMessage: 'free plan',
    id: 'modal.subscriptions.overview.cancellation.complete.plan'
  },
  'modal.subscriptions.overview.expiringtrial.days': {
    defaultMessage: '{days, plural, one {day} other {days}}',
    id: 'modal.subscriptions.overview.expiringtrial.days'
  },
  'modal.subscriptions.overview.expiringtrial.header': {
    defaultMessage: 'You have {days} remaining on your {productName}.',
    id: 'modal.subscriptions.overview.expiringtrial.header'
  },
  'modal.subscriptions.overview.expiringtrial.lastday.header': {
    defaultMessage: 'Your {productName} expires today.',
    id: 'modal.subscriptions.overview.expiringtrial.lastday.header'
  },
  'modal.subscriptions.overview.expiringtrial.message': {
    defaultMessage: 'Subscribe now to continue using all the features of {productFamilyName}',
    id: 'modal.subscriptions.overview.expiringtrial.message'
  },
  'modal.subscriptions.overview.g2m.flatrate.button': {
    defaultMessage: 'STAY AND SAVE',
    id: 'modal.subscriptions.overview.g2m.flatrate.button'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.businessmessaging': {
    defaultMessage: 'Business Messaging',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.businessmessaging'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.customercare': {
    defaultMessage: '24/7 Customer Care',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.customercare'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.dialinconferenceline': {
    defaultMessage: 'Dial-in Conference Line',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.dialinconferenceline'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.dialinphonenumbers': {
    defaultMessage: 'Dial-in phone numbers',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.dialinphonenumbers'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.hdvideowithwebcams11': {
    defaultMessage: 'HD Video with 11 Webcams',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.hdvideowithwebcams11'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.hdvideowithwebcams25': {
    defaultMessage: 'HD Video with 25 webcams',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.hdvideowithwebcams25'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants10': {
    defaultMessage: '10 Participants',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants10'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.personalroom': {
    defaultMessage: 'Personal meeting room',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.personalroom'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedmeetings': {
    defaultMessage: 'Unlimited Meetings',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedmeetings'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedrecordings': {
    defaultMessage: 'Unlimited recordings and transcripts',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedrecordings'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants150': {
    defaultMessage: '150 participants',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants150'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants250': {
    defaultMessage: '250 participants',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.participants250'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.notimelimits': {
    defaultMessage: 'No meeting time limits',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.notimelimits'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.calendarintegrations': {
    defaultMessage: 'Calendar integrations',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.calendarintegrations'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.meetinglock': {
    defaultMessage: 'Meeting lock',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.meetinglock'
  },
  'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedcloudrecording': {
    defaultMessage: 'Unlimited cloud recording',
    id: 'modal.subscriptions.overview.g2m.flatrate.cancellationfeature.unlimitedcloudrecording'
  },
  'modal.subscriptions.overview.g2m.flatrate.features.message': {
    defaultMessage: 'Don’t lose access to these premium features:',
    id: 'modal.subscriptions.overview.g2m.flatrate.features.message'
  },
  'modal.subscriptions.overview.g2m.flatrate.savepercent': {
    defaultMessage: 'Stick around and save {percent}',
    id: 'modal.subscriptions.overview.g2m.flatrate.savepercent'
  },
  'modal.subscriptions.overview.g2m.flatrate.specialoffer': {
    defaultMessage: 'Special offer!',
    id: 'modal.subscriptions.overview.g2m.flatrate.specialoffer'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.headermessage': {
    defaultMessage: 'Stay and explore the new GoTo App for Meetings, complete with features like:',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.headermessage'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.breakoutrooms': {
    defaultMessage: 'Breakout rooms',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.breakoutrooms'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.handraisingreactions': {
    defaultMessage: 'Hand raising & Reactions',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.handraisingreactions'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.enhanceddrawingtools': {
    defaultMessage: 'Enhanced Drawing Tools',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.enhanceddrawingtools'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.allinonecollaborationapp': {
    defaultMessage: 'All-in-one Collaboration App',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.allinonecollaborationapp'
  },
  'modal.subscriptions.overview.g2m.cancellationfeature.footermessage': {
    defaultMessage: '<goto-eap-link>Click here</goto-eap-link> to learn more about the new experience and stay tuned for more features to come!',
    id: 'modal.subscriptions.overview.g2m.cancellationfeature.footermessage'
  },
  'modal.subscriptions.overview.g2w.flatrate.button': {
    defaultMessage: 'KEEP SUBSCRIPTION AND SAVE',
    id: 'modal.subscriptions.overview.g2w.flatrate.button'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.automatedemails': {
    defaultMessage: 'Automated Emails',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.automatedemails'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.custombranding': {
    defaultMessage: 'Custom Branding',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.custombranding'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.fullserviceregistration': {
    defaultMessage: 'Full Service Registration',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.fullserviceregistration'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.pollshandouts': {
    defaultMessage: 'Polls, Handouts and Q&A',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.pollshandouts'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.reportinganalytics': {
    defaultMessage: 'Reporting and Analytics',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.reportinganalytics'
  },
  'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.unlimitedstorage': {
    defaultMessage: 'Unlimited Storage & Recordings',
    id: 'modal.subscriptions.overview.g2w.flatrate.cancellationfeature.unlimitedstorage'
  },
  'modal.subscriptions.overview.g2w.flatrate.features.message': {
    defaultMessage: 'Don’t lose access to these premium features:',
    id: 'modal.subscriptions.overview.g2w.flatrate.features.message'
  },
  'modal.subscriptions.overview.g2w.flatrate.features.participant.message': {
    defaultMessage: 'Don’t lose access, continue hosting up to {participantCount} participants with these premium features:',
    id: 'modal.subscriptions.overview.g2w.flatrate.features.participant.message'
  },
  'modal.subscriptions.overview.g2w.flatrate.reducedprice': {
    defaultMessage: 'REDUCED PRICE',
    id: 'modal.subscriptions.overview.g2w.flatrate.reducedprice'
  },
  'modal.subscriptions.overview.g2w.flatrate.regularprice': {
    defaultMessage: 'REG PRICE',
    id: 'modal.subscriptions.overview.g2w.flatrate.regularprice'
  },
  'modal.subscriptions.overview.g2w.flatrate.savepercent': {
    defaultMessage: 'You save {percent}',
    id: 'modal.subscriptions.overview.g2w.flatrate.savepercent'
  },
  'modal.subscriptions.overview.g2w.flatrate.specialoffer': {
    defaultMessage: 'SPECIAL OFFER!',
    id: 'modal.subscriptions.overview.g2w.flatrate.specialoffer'
  },
  'modal.subscriptions.overview.g2a.flatrate.renewaloffer': {
    defaultMessage: 'Your special renewal offer!',
    id: 'modal.subscriptions.overview.g2a.flatrate.renewaloffer'
  },
  'modal.subscriptions.overview.g2a.flatrate.feature.message': {
    defaultMessage: 'Don’t lose access to your favorite features:',
    id: 'modal.subscriptions.overview.g2a.flatrate.feature.message'
  },
  'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.unattendedaccess': {
    defaultMessage: 'Unattended Access',
    id: 'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.unattendedaccess'
  },
  'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.notes': {
    defaultMessage: 'Notes',
    id: 'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.notes'
  },
  'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.chat': {
    defaultMessage: 'Chat',
    id: 'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.chat'
  },
  'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.filetransfer': {
    defaultMessage: 'File Transfer',
    id: 'modal.subscriptions.overview.g2a.flatrate.cancellationfeature.filetransfer'
  },
  'modal.subscriptions.overview.g2t.flatrate.button': {
    defaultMessage: 'STAY AND SAVE',
    id: 'modal.subscriptions.overview.g2t.flatrate.button'
  },
  'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.pollandtests': {
    defaultMessage: 'Polls and tests',
    id: 'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.pollandtests'
  },
  'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.materialmanagement': {
    defaultMessage: 'Material management',
    id: 'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.materialmanagement'
  },
  'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.trainingrecordings': {
    defaultMessage: 'Training recordings',
    id: 'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.trainingrecordings'
  },
  'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.reportsandmetrics': {
    defaultMessage: 'Reports and metrics',
    id: 'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.reportsandmetrics'
  },
  'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.breakoutrooms': {
    defaultMessage: 'Breakout rooms',
    id: 'modal.subscriptions.overview.g2t.flatrate.cancellationfeature.breakoutrooms'
  },
  'modal.subscriptions.overview.g2t.flatrate.features.message': {
    defaultMessage: 'Don’t lose access to these premium features:',
    id: 'modal.subscriptions.overview.g2t.flatrate.features.message'
  },
  'modal.subscriptions.overview.g2t.flatrate.features.participant.message': {
    defaultMessage: 'Don’t lose access, continue hosting up to {participantCount} participants with these premium features:',
    id: 'modal.subscriptions.overview.g2t.flatrate.features.participant.message'
  },
  'modal.subscriptions.overview.g2t.flatrate.savepercent': {
    defaultMessage: 'You save {percent}',
    id: 'modal.subscriptions.overview.g2t.flatrate.savepercent'
  },
  'modal.subscriptions.overview.g2t.flatrate.specialoffer': {
    defaultMessage: 'SPECIAL OFFER!',
    id: 'modal.subscriptions.overview.g2t.flatrate.specialoffer'
  },
  'modal.subscriptions.overview.seasonality.button': {
    defaultMessage: 'VIEW SAVINGS IN CART',
    id: 'modal.subscriptions.overview.seasonality.button'
  },
  'modal.subscriptions.overview.seasonality.header.message1': {
    defaultMessage: 'Before you cancel, we would like to offer you',
    id: 'modal.subscriptions.overview.seasonality.header.message1'
  },
  'modal.subscriptions.overview.seasonality.header.message2': {
    defaultMessage: '20% OFF',
    id: 'modal.subscriptions.overview.seasonality.header.message2'
  },
  'modal.subscriptions.overview.seasonality.header.message3': {
    defaultMessage: 'when you move to an annual plan',
    id: 'modal.subscriptions.overview.seasonality.header.message3'
  },
  'modal.subscriptions.overview.usage.button': {
    defaultMessage: 'KEEP SUBSCRIPTION',
    id: 'modal.subscriptions.overview.usage.button'
  },
  'modal.subscriptions.overview.usage.header': {
    defaultMessage: 'It looks like organizers on your account have used GoToMeeting {meetingCount} times in the last month. Are you sure you want to cancel?',
    id: 'modal.subscriptions.overview.usage.header'
  },
  'modal.subscriptions.overview.usage.retention.balloons.alt': {
    defaultMessage: 'Thank you!',
    id: 'modal.subscriptions.overview.usage.retention.balloons.alt'
  },
  'modal.subscriptions.overview.usage.retention.complete.header': {
    defaultMessage: 'Thank you for keeping your subscription!',
    id: 'modal.subscriptions.overview.usage.retention.complete.header'
  },
  'modal.subscriptions.overview.usage.retention.complete.message': {
    defaultMessage: 'We hope you continue to get value out of your GoToMeeting subscription. If you have any questions, please {contact}',
    id: 'modal.subscriptions.overview.usage.retention.complete.message'
  },
  'modal.subscriptions.overview.usage.retention.support.link': {
    defaultMessage: 'contact us',
    id: 'modal.subscriptions.overview.usage.retention.support.link'
  },
  'modal.subscriptions.overview.g2w.downgrade.header': {
    defaultMessage: 'Once you’ve cancelled, your account data and recordings will{br}still be available for <highlight>up to 90 days</highlight>.',
    id: 'modal.subscriptions.overview.g2w.downgrade.header'
  },
  'modal.subscriptions.overview.g2w.downgrade.message': {
    defaultMessage: 'Might use {productName} again?{br}Switch to our Flex plan to <b>keep your account data active</b> and only pay for events you need!',
    id: 'modal.subscriptions.overview.g2w.downgrade.message'
  },
  'modal.subscriptions.overview.g2w.downgrade.header.switch': {
    defaultMessage: 'Switch to {productName} & only pay for what you need:',
    id: 'modal.subscriptions.overview.g2w.downgrade.header.switch'
  },
  'modal.subscriptions.overview.g2w.downgrade.feature.events': {
    defaultMessage: 'Run simulated live events at no additional cost',
    id: 'modal.subscriptions.overview.g2w.downgrade.feature.events'
  },
  'modal.subscriptions.overview.g2w.downgrade.feature.payperevents': {
    defaultMessage: 'Pay a 1-time fee for each live event, starting at just {currencySign}{price} for {participantCount} attendees',
    id: 'modal.subscriptions.overview.g2w.downgrade.feature.payperevents'
  },
  'modal.subscriptions.overview.g2w.downgrade.feature.access': {
    defaultMessage: 'Access enterprise level features like attendee source tracking & analytics',
    id: 'modal.subscriptions.overview.g2w.downgrade.feature.access'
  },
  'modal.subscriptions.overview.g2w.downgrade.feature.recording': {
    defaultMessage: 'Keep all your previously recorded webinars',
    id: 'modal.subscriptions.overview.g2w.downgrade.feature.recording'
  },
  'modal.subscriptions.overview.g2w.downgrade.learn.more': {
    defaultMessage: 'Learn more about our {productName} Plan',
    id: 'modal.subscriptions.overview.g2w.downgrade.learn.more'
  },
  'modal.subscriptions.overview.g2w.downgrade.read.more': {
    defaultMessage: 'Have you heard about the new version of {productName}? It has a sleek design plus new features like breakout rooms and hand raising. Read more about it <link>here</link>.',
    id: 'modal.subscriptions.overview.g2w.downgrade.read.more'
  },
  'modal.subscriptions.overview.g2w.downgrade.price.for': {
    defaultMessage: 'For',
    id: 'modal.subscriptions.overview.g2w.downgrade.price.for'
  },
  'modal.subscriptions.overview.g2w.downgrade.price.you.can': {
    defaultMessage: 'you can:',
    id: 'modal.subscriptions.overview.g2w.downgrade.price.you.can'
  },
  'modal.subscriptions.overview.g2w.downgrade.button': {
    defaultMessage: 'Make the switch and save today!',
    id: 'modal.subscriptions.overview.g2w.downgrade.button'
  },
  'nav.contactsales': {
    defaultMessage: 'contact sales',
    id: 'nav.contactsales'
  },
  'nav.contactsupport': {
    defaultMessage: 'Contact Support',
    id: 'nav.contactsupport'
  },
  'nav.dropdown.admincenter': {
    defaultMessage: 'Admin Center',
    description: 'The navbar admin center link',
    id: 'nav.dropdown.admincenter'
  },
  'nav.dropdown.billing': {
    defaultMessage: 'Billing',
    id: 'nav.dropdown.billing'
  },
  'nav.dropdown.myaccount': {
    defaultMessage: 'My Account',
    id: 'nav.dropdown.myaccount'
  },
  'nav.dropdown.personalinfo': {
    defaultMessage: 'Personal Info',
    id: 'nav.dropdown.personalinfo'
  },
  'nav.dropdown.signin.security': {
    defaultMessage: 'Sign In & Security',
    id: 'nav.dropdown.signin.security'
  },
  'nav.dropdown.signout': {
    defaultMessage: 'Sign Out',
    description: 'The nav dropdown sign out link',
    id: 'nav.dropdown.signout'
  },
  'nav.hamburgertoggle': {
    defaultMessage: 'Toggle navigation',
    description: 'The nav hamburger toggle which shows and hides nav menu for mobile devices',
    id: 'nav.hamburgertoggle'
  },
  'nav.help': {
    defaultMessage: 'Help',
    id: 'nav.help'
  },
  'payment.cta.cancel': {
    defaultMessage: 'Cancel',
    id: 'payment.cta.cancel'
  },
  'payment.cta.savechanges': {
    defaultMessage: 'Save changes',
    id: 'payment.cta.savechanges'
  },
  'payment.link.update.payment.method.g2a': {
    defaultMessage: 'Update in GoTo Admin',
    id: 'payment.link.update.payment.method.g2a'
  },
  'payment.method.update.g2a.info': {
    defaultMessage: 'Want to use a different card? Set the card you want to pay with as your default payment method in GoTo Admin.',
    id: 'payment.method.update.g2a.info'
  },
  'payment-info.backtocart': {
    defaultMessage: 'Return to shopping cart',
    description: 'Button that take you back to the shopping cart',
    id: 'payment-info.backtocart'
  },
  'payment-info.button.canceladd': {
    defaultMessage: 'Cancel Add Payment Method',
    id: 'payment-info.button.canceladd'
  },
  'payment-info.button.canceledit': {
    defaultMessage: 'Cancel Edit Payment Method',
    id: 'payment-info.button.canceledit'
  },
  'payment-info.header.add': {
    defaultMessage: 'ADD PAYMENT METHOD',
    id: 'payment-info.header.add'
  },
  'payment-info.header.edit': {
    defaultMessage: 'EDIT PAYMENT METHOD',
    id: 'payment-info.header.edit'
  },
  'payment-info.paymentmethod.dropdown.label': {
    defaultMessage: 'Payment Method',
    id: 'payment-info.paymentmethod.dropdown.label'
  },
  'payment-info.type.creditcard': {
    defaultMessage: 'Credit Card',
    id: 'payment-info.type.creditcard'
  },
  'payment-info.type.sepa': {
    defaultMessage: 'Collection Authorisation SEPA Direct Debit',
    id: 'payment-info.type.sepa'
  },
  'paymentmethods.list.address': {
    defaultMessage: 'Billing address',
    id: 'paymentmethods.list.address'
  },
  'paymentmethods.list.button.add': {
    defaultMessage: 'Add payment method',
    id: 'paymentmethods.list.button.add'
  },
  'paymentmethods.list.creditcard.expirationdate': {
    defaultMessage: '{month}/{year}',
    id: 'paymentmethods.list.creditcard.expirationdate'
  },
  'paymentmethods.list.creditcard.expired': {
    defaultMessage: 'Expired',
    id: 'paymentmethods.list.creditcard.expired'
  },
  'paymentmethods.list.expires': {
    defaultMessage: 'Expires',
    id: 'paymentmethods.list.expires'
  },
  'paymentmethods.list.none': {
    defaultMessage: 'You have no payment methods.',
    id: 'paymentmethods.list.none'
  },
  'paymentmethods.list.type': {
    defaultMessage: 'Payment type',
    id: 'paymentmethods.list.type'
  },
  'paymentmethods.list.type.sepadd': {
    defaultMessage: 'SEPA Direct Debit',
    id: 'paymentmethods.list.type.sepadd'
  },
  'paymentmethods.list.type.sepadd.mandatereference': {
    defaultMessage: 'Mandate Ref. Number: {mandateReference}',
    id: 'paymentmethods.list.type.sepadd.mandatereference'
  },
  'preview.button.editorder': {
    defaultMessage: 'Edit order',
    description: 'Button to edit order',
    id: 'preview.button.editorder'
  },
  'preview.button.placeorder': {
    defaultMessage: 'Place order',
    description: 'Button to navigate to place order',
    id: 'preview.button.placeorder'
  },
  'preview.overview.expired.paymentmethod': {
    defaultMessage: 'Your payment method has expired. Please update now.',
    id: 'preview.overview.expired.paymentmethod'
  },
  'preview.overview.header.caps': {
    defaultMessage: 'CHECKOUT',
    description: 'Checkout header',
    id: 'preview.overview.header.caps'
  },
  'preview.overview.method-notify': {
    defaultMessage: 'Your payment method will be charged',
    id: 'preview.overview.method-notify'
  },
  'preview.overview.paymentmethod': {
    defaultMessage: 'Pay using payment method',
    id: 'preview.overview.paymentmethod'
  },
  'preview.overview.paymentmethod.declined': {
    defaultMessage: 'We apologize, but your payment method has been declined. Please review your payment method and try again.',
    id: 'preview.overview.paymentmethod.declined'
  },
  'preview.overview.paymentmethod.invalid': {
    defaultMessage: 'We apologize, but the payment method information you entered does not match the information on your credit card. Please review your payment method and try again.',
    id: 'preview.overview.paymentmethod.invalid'
  },
  'preview.overview.paymentmethod.missing': {
    defaultMessage: 'Please provide a valid payment method in order to proceed.',
    id: 'preview.overview.paymentmethod.missing'
  },
  'preview.overview.paymentmethodrefund': {
    defaultMessage: 'Original payment method will be refunded',
    id: 'preview.overview.paymentmethodrefund'
  },
  'preview.overview.sepa': {
    defaultMessage: 'SEPA',
    id: 'preview.overview.sepa'
  },
  'preview.overview.subtotal': {
    defaultMessage: 'Subtotal excl tax',
    id: 'preview.overview.subtotal'
  },
  'preview.overview.subtotalcredit': {
    defaultMessage: 'Subtotal excl tax (credit)',
    id: 'preview.overview.subtotalcredit'
  },
  'preview.overview.taxes.learnmore': {
    defaultMessage: 'Learn more about taxes',
    id: 'preview.overview.taxes.learnmore'
  },
  'preview.overview.totaldue': {
    defaultMessage: 'Total due now',
    id: 'preview.overview.totaldue'
  },
  'preview.overview.totaltaxes-info-gst': {
    defaultMessage: 'Goods and Services Tax ({percentage}):',
    id: 'preview.overview.totaltaxes-info-gst'
  },
  'preview.overview.totaltaxes-info-gst_in': {
    defaultMessage: 'Indian Goods and Services Tax ({percentage}):',
    id: 'preview.overview.totaltaxes-info-gst_in'
  },
  'preview.overview.totaltaxes-info-gst_in-credit': {
    defaultMessage: 'Indian Goods and Services Tax (credit):',
    id: 'preview.overview.totaltaxes-info-gst_in-credit'
  },
  'preview.overview.totaltaxes-info-gst-credit': {
    defaultMessage: 'Goods and Services Tax (credit):',
    id: 'preview.overview.totaltaxes-info-gst-credit'
  },
  'preview.overview.totaltaxes-info-tax': {
    defaultMessage: 'Sales Tax ({percentage}):',
    id: 'preview.overview.totaltaxes-info-tax'
  },
  'preview.overview.totaltaxes-info-tax-credit': {
    defaultMessage: 'Sales Tax (credit):',
    id: 'preview.overview.totaltaxes-info-tax-credit'
  },
  'preview.overview.totaltaxes-info-telecom_tax': {
    defaultMessage: 'Audio Tax ({percentage}):',
    id: 'preview.overview.totaltaxes-info-telecom_tax'
  },
  'preview.overview.totaltaxes-info-telecom_tax-credit': {
    defaultMessage: 'Audio Tax (credit):',
    id: 'preview.overview.totaltaxes-info-telecom_tax-credit'
  },
  'preview.overview.totaltaxes-info-usf': {
    defaultMessage: 'USF Fee ({percentage}):',
    id: 'preview.overview.totaltaxes-info-usf'
  },
  'preview.overview.totaltaxes-info-usf-credit': {
    defaultMessage: 'USF Fee (credit):',
    id: 'preview.overview.totaltaxes-info-usf-credit'
  },
  'preview.overview.totaltaxes-info-vat': {
    defaultMessage: 'Value Added Tax ({percentage}):',
    id: 'preview.overview.totaltaxes-info-vat'
  },
  'preview.overview.totaltaxes-info-vat-credit': {
    defaultMessage: 'Value Added Tax (credit):',
    id: 'preview.overview.totaltaxes-info-vat-credit'
  },
  'preview.overview.totaltaxes-info-rfc': {
    defaultMessage: 'Mexican VAT ({percentage}):',
    id: 'preview.overview.totaltaxes-info-rfc'
  },
  'preview.overview.totaltaxes-info-rfc-credit': {
    defaultMessage: 'Mexican VAT (credit):',
    id: 'preview.overview.totaltaxes-info-rfc-credit'
  },
  'preview.table.billingperiod': {
    defaultMessage: 'Billing period',
    description: 'The billing period of the invoice item',
    id: 'preview.table.billingperiod'
  },
  'preview.table.totalprice': {
    defaultMessage: 'Total',
    description: 'The total of the invoice item',
    id: 'preview.table.totalprice'
  },
  'preview.warning.basequantity': {
    defaultMessage: 'Please note: If you place this order you will have more {addonFamilyName} {addonUnits} than {baseShortName} {baseUnits}. You should separately reduce your {addonFamilyName} {addonUnits} to match the number of {baseShortName} {baseUnits}.',
    id: 'preview.warning.basequantity'
  },
  'preview.important.info.thankyou': {
    defaultMessage: 'Thank you for purchasing LogMeIn’s new {productName} solution.',
    id: 'preview.important.info.thankyou'
  },
  'preview.important.info.tnc': {
    defaultMessage: 'Standard LogMeIn Terms of Service apply',
    id: 'preview.important.info.tnc'
  },
  'preview.important.info.charge': {
    defaultMessage: 'As part of this offering, you will be charged the following:',
    id: 'preview.important.info.charge'
  },
  'preview.important.info.charge.standard': {
    defaultMessage: 'Standard: Recurring subscription fee (charged monthly or annually).',
    id: 'preview.important.info.charge.standard'
  },
  'preview.important.info.charge.variable': {
    defaultMessage: 'Variable: A per event fee for each live event hosted. Price points vary based on number of attendees per event:',
    id: 'preview.important.info.charge.variable'
  },
  'preview.important.info.charge.attendees': {
    defaultMessage: '{currencySign}{price} ({participantCountFrom}-{participantCountTo} attendees)',
    id: 'preview.important.info.charge.attendees'
  },
  'preview.important.info.event': {
    defaultMessage: 'A live event is constituted as:',
    id: 'preview.important.info.event'
  },
  'preview.important.info.event.section1': {
    defaultMessage: '5 People or more in a live session (Organizers, Panelists & Attendees).',
    id: 'preview.important.info.event.section1'
  },
  'preview.important.info.event.section2': {
    defaultMessage: 'Live session must also last at least 15 minutes.',
    id: 'preview.important.info.event.section2'
  },
  'preview.important.info.event.section3': {
    defaultMessage: 'Practice mode sessions will not be charged.',
    id: 'preview.important.info.event.section3'
  },
  'preview.important.info.eligible': {
    defaultMessage: 'You are eligible to host events of multiple sizes every month without restrictions.',
    id: 'preview.important.info.eligible'
  },
  'preview.important.info.minevent': {
    defaultMessage: 'There is no minimum number of live events that needs to be met monthly.',
    id: 'preview.important.info.minevent'
  },
  'preview.important.info.maxevent': {
    defaultMessage: 'There is no set maximum number of events that can be hosted monthly.',
    id: 'preview.important.info.maxevent'
  },
  'productfamilyitem.alreadyonaccount': {
    defaultMessage: 'Already on account',
    id: 'productfamilyitem.alreadyonaccount'
  },
  'productfamilyitem.notavailableincountry': {
    defaultMessage: 'Not available in your country',
    id: 'productfamilyitem.notavailableincountry'
  },
  'sepa.form.iban': {
    defaultMessage: 'IBAN',
    id: 'sepa.form.iban'
  },
  'sepa.mandate.accountholdername': {
    defaultMessage: 'Account holder name',
    id: 'sepa.mandate.accountholdername'
  },
  'sepa.mandate.address.europeanpaymentcouncil': {
    defaultMessage: 'Your address',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.address.europeanpaymentcouncil'
  },
  'sepa.mandate.agreement': {
    defaultMessage: 'By confirming, you are agreeing to be pre-notified 3 calendar days before a charge.',
    id: 'sepa.mandate.agreement'
  },
  'sepa.mandate.authorisation': {
    defaultMessage: 'Authorisation. Check this to agree to the below terms.',
    id: 'sepa.mandate.authorisation'
  },
  'sepa.mandate.aware': {
    defaultMessage: 'We use GoCardless to process your Direct Debit payments. More information on how GoCardless processes your personal data and your data protection rights, including your right to object, is available <gocardless-link>here</gocardless-link>.',
    id: 'sepa.mandate.aware'
  },
  'sepa.mandate.awaremessage': {
    defaultMessage: 'Powered by GoCardless',
    id: 'sepa.mandate.awaremessage'
  },
  'sepa.mandate.city.europeanpaymentcouncil': {
    defaultMessage: 'City',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.city.europeanpaymentcouncil'
  },
  'sepa.mandate.country.europeanpaymentcouncil': {
    defaultMessage: 'Country',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.country.europeanpaymentcouncil'
  },
  'sepa.mandate.creditor.europeanpaymentcouncil': {
    defaultMessage: 'Creditor identifier',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.creditor.europeanpaymentcouncil'
  },
  'sepa.mandate.customercompanyname': {
    defaultMessage: 'Customer or company Name',
    id: 'sepa.mandate.customercompanyname'
  },
  'sepa.mandate.iban.europeanpaymentcouncil': {
    defaultMessage: 'Account number — IBAN',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.iban.europeanpaymentcouncil'
  },
  'sepa.mandate.legaltext.europeanpaymentcouncil': {
    defaultMessage: `
      By signing this mandate form, you authorise (A) LogMeIn Inc. to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instruction from LogMeIn Inc.
      As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank.
    `,
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.legaltext.europeanpaymentcouncil'
  },
  'sepa.mandate.mandatereceiveddate.europeanpaymentcouncil': {
    defaultMessage: 'Date',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.mandatereceiveddate.europeanpaymentcouncil'
  },
  'sepa.mandate.mandatereference.europeanpaymentcouncil': {
    defaultMessage: 'Mandate reference',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.mandatereference.europeanpaymentcouncil'
  },
  'sepa.mandate.mandatereference.unavailablemessage': {
    defaultMessage: 'Available after confirmation',
    id: 'sepa.mandate.mandatereference.unavailablemessage'
  },
  'sepa.mandate.name.europeanpaymentcouncil': {
    defaultMessage: 'Creditor name',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.name.europeanpaymentcouncil'
  },
  'sepa.mandate.postalcode.europeanpaymentcouncil': {
    defaultMessage: 'Postal code',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.postalcode.europeanpaymentcouncil'
  },
  'sepa.mandate.recurrentpayment.europeanpaymentcouncil': {
    defaultMessage: 'Recurrent payment',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.recurrentpayment.europeanpaymentcouncil'
  },
  'sepa.mandate.streetname.europeanpaymentcouncil': {
    defaultMessage: 'Street name and number',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.streetname.europeanpaymentcouncil'
  },
  'sepa.mandate.subtitle.europeanpaymentcouncil': {
    defaultMessage: 'SEPA Core Direct Debit Mandate',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.subtitle.europeanpaymentcouncil'
  },
  'sepa.mandate.title': {
    defaultMessage: 'Verify your details',
    id: 'sepa.mandate.title'
  },
  'sepa.mandate.typeofpayment.europeanpaymentcouncil': {
    defaultMessage: 'Type of payment',
    description: 'The translation for this message is provided by the European payment council and should not be translated by Global Localization Services(GLS)',
    id: 'sepa.mandate.typeofpayment.europeanpaymentcouncil'
  },
  'shared.addnewsubscription': {
    defaultMessage: 'ADD NEW SUBSCRIPTION',
    id: 'shared.addnewsubscription'
  },
  'shared.addpaymentmethod': {
    defaultMessage: 'ADD PAYMENT METHOD',
    id: 'shared.addpaymentmethod'
  },
  'shared.addtocart': {
    defaultMessage: 'Add to cart',
    id: 'shared.addtocart'
  },
  'shared.addtocart.caps': {
    defaultMessage: 'ADD TO CART',
    id: 'shared.addtocart.caps'
  },
  'shared.addtocart.error': {
    defaultMessage: 'Select valid number of {unit}.',
    id: 'shared.addtocart.error'
  },
  'shared.alert.error.cart.patch.general': {
    defaultMessage: 'We apologize, but this page appears to have encountered an error. Please try again.',
    id: 'shared.alert.error.cart.patch.general'
  },
  'shared.alert.error.cartitem.duplicate': {
    defaultMessage: 'We apologize, please remove the duplicate item(s) from your shopping cart and try again.',
    id: 'shared.alert.error.cartitem.duplicate'
  },
  'shared.alert.error.cartitem.general': {
    defaultMessage: 'We apologize, but this page appears to have encountered an error. Please refresh your browser and try again.',
    id: 'shared.alert.error.cartitem.general'
  },
  'shared.alert.error.cartitem.invalid.existing.subs': {
    defaultMessage: 'We apologize, but it appears this product already exists on your account. Please edit your existing subscription from the Subscriptions page.',
    id: 'shared.alert.error.cartitem.invalid.existing.subs'
  },
  'shared.alert.error.cartitem.invalid.field': {
    defaultMessage: 'We apologize, but it appears an invalid field has been submitted. Please check your submission and try again.',
    id: 'shared.alert.error.cartitem.invalid.field'
  },
  'shared.alert.error.cartitem.invalid.quantity': {
    defaultMessage: 'Please enter a valid quantity and try again.',
    id: 'shared.alert.error.cartitem.invalid.quantity'
  },
  'shared.alert.error.cartitem.invalid.trial': {
    defaultMessage: 'We apologize, but it appears you are ineligible for this trial product. Please contact Customer Care for assistance.',
    id: 'shared.alert.error.cartitem.invalid.trial'
  },
  'shared.alert.error.general.invalid.billingaccount': {
    defaultMessage: 'We apologize, but your account is currently being processed and cannot be modified at this time. Please try again later.',
    id: 'shared.alert.error.general.invalid.billingaccount'
  },
  'shared.alert.error.general.refreshtryagain': {
    defaultMessage: 'We apologize, but this page appears to have encountered an error. Please refresh your browser and try again.',
    id: 'shared.alert.error.general.refreshtryagain'
  },
  'shared.alert.error.order.cannotbeprocessed.tryagain': {
    defaultMessage: 'We apologize, but your order is unable to be processed at this time. Please try again later.',
    id: 'shared.alert.error.order.cannotbeprocessed.tryagain'
  },
  'shared.alert.error.paymentmethod.general.failure': {
    defaultMessage: 'We apologize, but we experienced a problem saving your payment method details. Please try again.',
    id: 'shared.alert.error.paymentmethod.general.failure'
  },
  'shared.alert.error.paymentmethod.invalid.billinginformation.general': {
    defaultMessage: 'There was a problem with your billing information. Please try again.',
    id: 'shared.alert.error.paymentmethod.invalid.billinginformation.general'
  },
  'shared.alert.error.paymentmethod.invalid.decline': {
    defaultMessage: 'Please provide a valid payment method in order to proceed.',
    id: 'shared.alert.error.paymentmethod.invalid.decline'
  },
  'shared.alert.error.products.encounterederror.tryagain': {
    defaultMessage: 'We apologize, but this page appears to have encountered an error. Please try again.',
    id: 'shared.alert.error.products.encounterederror.tryagain'
  },
  'shared.alert.error.products.general': {
    defaultMessage: 'We apologize, but this page appears to have encountered an error. Please refresh your browser and try again.',
    id: 'shared.alert.error.products.general'
  },
  'shared.alert.error.products.unabletoload.tryagain': {
    defaultMessage: 'We apologize, but it appears some products were unable to be loaded. Please refresh your browser and try again.',
    id: 'shared.alert.error.products.unabletoload.tryagain'
  },
  'shared.annual': {
    defaultMessage: 'Annual',
    id: 'shared.annual'
  },
  'shared.annually.lowercase': {
    defaultMessage: 'annually',
    id: 'shared.annually.lowercase'
  },
  'shared.annualplan': {
    defaultMessage: 'Annual Plan',
    id: 'shared.annualplan'
  },
  'shared.app.title': {
    defaultMessage: 'Billing Center',
    id: 'shared.app.title'
  },
  'shared.billed.annual': {
    defaultMessage: 'Billed Annually',
    id: 'shared.billed.annual'
  },
  'shared.billed.daily': {
    defaultMessage: 'Billed Daily',
    id: 'shared.billed.daily'
  },
  'shared.billed.monthly': {
    defaultMessage: 'Billed Monthly',
    id: 'shared.billed.monthly'
  },
  'shared.billingcountry.customercare': {
    defaultMessage: 'Please <care-link>contact</care-link> our Care team if you need to update your billing country',
    id: 'shared.billingcountry.customercare'
  },
  'shared.billingfrequency': {
    defaultMessage: 'billing frequency',
    id: 'shared.billingfrequency'
  },
  'shared.billingfrequency.capitalized': {
    defaultMessage: 'Billing frequency',
    id: 'shared.billingfrequency.capitalized'
  },
  'shared.button.close': {
    defaultMessage: 'Close',
    id: 'shared.button.close'
  },
  'shared.button.next': {
    defaultMessage: 'NEXT',
    id: 'shared.button.next'
  },
  'shared.button.reactivate': {
    defaultMessage: 'Reactivate',
    id: 'shared.button.reactivate'
  },
  'shared.button.subscribe': {
    defaultMessage: 'Subscribe Now',
    id: 'shared.button.subscribe'
  },
  'shared.proceedtocancellation': {
    defaultMessage: 'Proceed to cancellation',
    id: 'shared.proceedtocancellation'
  },
  'shared.change': {
    defaultMessage: 'Change',
    id: 'shared.change'
  },
  'shared.close': {
    defaultMessage: 'CLOSE',
    id: 'shared.close'
  },
  'shared.continue': {
    defaultMessage: 'Continue',
    id: 'shared.continue'
  },
  'shared.continuecancel': {
    defaultMessage: 'CONTINUE TO CANCEL',
    id: 'shared.continuecancel'
  },
  'shared.country.AD': {
    defaultMessage: 'Andorra',
    id: 'shared.country.AD'
  },
  'shared.country.AE': {
    defaultMessage: 'United Arab Emirates',
    id: 'shared.country.AE'
  },
  // List of country names, and initials
  'shared.country.AF': {
    defaultMessage: 'Afghanistan',
    id: 'shared.country.AF'
  },
  'shared.country.AG': {
    defaultMessage: 'Antigua and Barbuda',
    id: 'shared.country.AG'
  },
  'shared.country.AI': {
    defaultMessage: 'Anguilla',
    id: 'shared.country.AI'
  },
  'shared.country.AL': {
    defaultMessage: 'Albania',
    id: 'shared.country.AL'
  },
  'shared.country.AM': {
    defaultMessage: 'Armenia',
    id: 'shared.country.AM'
  },
  'shared.country.AN': {
    defaultMessage: 'Netherlands Antilles',
    id: 'shared.country.AN'
  },
  'shared.country.AO': {
    defaultMessage: 'Angola',
    id: 'shared.country.AO'
  },
  'shared.country.AQ': {
    defaultMessage: 'Antarctica',
    id: 'shared.country.AQ'
  },
  'shared.country.AR': {
    defaultMessage: 'Argentina',
    id: 'shared.country.AR'
  },
  'shared.country.AS': {
    defaultMessage: 'American Samoa',
    id: 'shared.country.AS'
  },
  'shared.country.AT': {
    defaultMessage: 'Austria',
    id: 'shared.country.AT'
  },
  'shared.country.AU': {
    defaultMessage: 'Australia',
    id: 'shared.country.AU'
  },
  // AU states
  'shared.country.AU.state.ACT': {
    defaultMessage: 'Australian Capital Territory',
    id: 'shared.country.AU.state.ACT'
  },
  'shared.country.AU.state.NSW': {
    defaultMessage: 'New South Wales',
    id: 'shared.country.AU.state.NSW'
  },
  'shared.country.AU.state.NT': {
    defaultMessage: 'Northern Territory',
    id: 'shared.country.AU.state.NT'
  },
  'shared.country.AU.state.QLD': {
    defaultMessage: 'Queensland',
    id: 'shared.country.AU.state.QLD'
  },
  'shared.country.AU.state.SA': {
    defaultMessage: 'South Australia',
    id: 'shared.country.AU.state.SA'
  },
  'shared.country.AU.state.TAS': {
    defaultMessage: 'Tasmania',
    id: 'shared.country.AU.state.TAS'
  },
  'shared.country.AU.state.VIC': {
    defaultMessage: 'Victoria',
    id: 'shared.country.AU.state.VIC'
  },
  'shared.country.AU.state.WA': {
    defaultMessage: 'Western Australia',
    id: 'shared.country.AU.state.WA'
  },
  'shared.country.AW': {
    defaultMessage: 'Aruba',
    id: 'shared.country.AW'
  },
  'shared.country.AX': {
    defaultMessage: 'Aland Islands',
    id: 'shared.country.AX'
  },
  'shared.country.AZ': {
    defaultMessage: 'Azerbaijan',
    id: 'shared.country.AZ'
  },
  'shared.country.BA': {
    defaultMessage: 'Bosnia-Herzegovina',
    id: 'shared.country.BA'
  },
  'shared.country.BB': {
    defaultMessage: 'Barbados',
    id: 'shared.country.BB'
  },
  'shared.country.BD': {
    defaultMessage: 'Bangladesh',
    id: 'shared.country.BD'
  },
  'shared.country.BE': {
    defaultMessage: 'Belgium',
    id: 'shared.country.BE'
  },
  'shared.country.BF': {
    defaultMessage: 'Burkina Faso',
    id: 'shared.country.BF'
  },
  'shared.country.BG': {
    defaultMessage: 'Bulgaria',
    id: 'shared.country.BG'
  },
  'shared.country.BH': {
    defaultMessage: 'Bahrain',
    id: 'shared.country.BH'
  },
  'shared.country.BI': {
    defaultMessage: 'Burundi',
    id: 'shared.country.BI'
  },
  'shared.country.BJ': {
    defaultMessage: 'Benin',
    id: 'shared.country.BJ'
  },
  'shared.country.BL': {
    defaultMessage: 'Saint Barthélemy',
    id: 'shared.country.BL'
  },
  'shared.country.BM': {
    defaultMessage: 'Bermuda',
    id: 'shared.country.BM'
  },
  'shared.country.BN': {
    defaultMessage: 'Brunei Darussalam',
    id: 'shared.country.BN'
  },
  'shared.country.BO': {
    defaultMessage: 'Bolivia',
    id: 'shared.country.BO'
  },
  'shared.country.BQ': {
    defaultMessage: 'Bonaire, Sint Eustatius and Saba',
    id: 'shared.country.BQ'
  },
  'shared.country.BR': {
    defaultMessage: 'Brazil',
    id: 'shared.country.BR'
  },
  'shared.country.BS': {
    defaultMessage: 'Bahamas',
    id: 'shared.country.BS'
  },
  'shared.country.BT': {
    defaultMessage: 'Bhutan',
    id: 'shared.country.BT'
  },
  'shared.country.BV': {
    defaultMessage: 'Bouvet Island',
    id: 'shared.country.BV'
  },
  'shared.country.BW': {
    defaultMessage: 'Botswana',
    id: 'shared.country.BW'
  },
  'shared.country.BY': {
    defaultMessage: 'Belarus',
    id: 'shared.country.BY'
  },
  'shared.country.BZ': {
    defaultMessage: 'Belize',
    id: 'shared.country.BZ'
  },
  'shared.country.CA': {
    defaultMessage: 'Canada',
    id: 'shared.country.CA'
  },
  // CA states
  'shared.country.CA.state.AB': {
    defaultMessage: 'Alberta',
    id: 'shared.country.CA.state.AB'
  },
  'shared.country.CA.state.BC': {
    defaultMessage: 'British Columbia',
    id: 'shared.country.CA.state.BC'
  },
  'shared.country.CA.state.MB': {
    defaultMessage: 'Manitoba',
    id: 'shared.country.CA.state.MB'
  },
  'shared.country.CA.state.NB': {
    defaultMessage: 'New Brunswick',
    id: 'shared.country.CA.state.NB'
  },
  'shared.country.CA.state.NL': {
    defaultMessage: 'Newfoundland and Labrador',
    id: 'shared.country.CA.state.NL'
  },
  'shared.country.CA.state.NS': {
    defaultMessage: 'Nova Scotia',
    id: 'shared.country.CA.state.NS'
  },
  'shared.country.CA.state.NT': {
    defaultMessage: 'Northwest Territories',
    id: 'shared.country.CA.state.NT'
  },
  'shared.country.CA.state.NU': {
    defaultMessage: 'Nunavut',
    id: 'shared.country.CA.state.NU'
  },
  'shared.country.CA.state.ON': {
    defaultMessage: 'Ontario',
    id: 'shared.country.CA.state.ON'
  },
  'shared.country.CA.state.PE': {
    defaultMessage: 'Prince Edward Island',
    id: 'shared.country.CA.state.PE'
  },
  'shared.country.CA.state.QC': {
    defaultMessage: 'Quebec',
    id: 'shared.country.CA.state.QC'
  },
  'shared.country.CA.state.SK': {
    defaultMessage: 'Saskatchewan',
    id: 'shared.country.CA.state.SK'
  },
  'shared.country.CA.state.YT': {
    defaultMessage: 'Yukon',
    id: 'shared.country.CA.state.YT'
  },
  'shared.country.CC': {
    defaultMessage: 'Cocos (Keeling) Islands',
    id: 'shared.country.CC'
  },
  'shared.country.CD': {
    defaultMessage: 'The Democratic Republic of the Congo',
    id: 'shared.country.CD'
  },
  'shared.country.CF': {
    defaultMessage: 'Central African Republic',
    id: 'shared.country.CF'
  },
  'shared.country.CG': {
    defaultMessage: 'Congo',
    id: 'shared.country.CG'
  },
  'shared.country.CH': {
    defaultMessage: 'Switzerland',
    id: 'shared.country.CH'
  },
  'shared.country.CI': {
    defaultMessage: 'Cote d\'Ivoire',
    id: 'shared.country.CI'
  },
  'shared.country.CK': {
    defaultMessage: 'Cook Islands',
    id: 'shared.country.CK'
  },
  'shared.country.CL': {
    defaultMessage: 'Chile',
    id: 'shared.country.CL'
  },
  'shared.country.CM': {
    defaultMessage: 'Cameroon',
    id: 'shared.country.CM'
  },
  'shared.country.CN': {
    defaultMessage: 'China',
    id: 'shared.country.CN'
  },
  'shared.country.CO': {
    defaultMessage: 'Colombia',
    id: 'shared.country.CO'
  },
  'shared.country.CR': {
    defaultMessage: 'Costa Rica',
    id: 'shared.country.CR'
  },
  'shared.country.CU': {
    defaultMessage: 'Cuba',
    id: 'shared.country.CU'
  },
  'shared.country.CV': {
    defaultMessage: 'Cape Verde',
    id: 'shared.country.CV'
  },
  'shared.country.CW': {
    defaultMessage: 'Curacao',
    id: 'shared.country.CW'
  },
  'shared.country.CX': {
    defaultMessage: 'Christmas Island',
    id: 'shared.country.CX'
  },
  'shared.country.CY': {
    defaultMessage: 'Cyprus',
    id: 'shared.country.CY'
  },
  'shared.country.CZ': {
    defaultMessage: 'Czech Republic',
    id: 'shared.country.CZ'
  },
  'shared.country.DE': {
    defaultMessage: 'Germany',
    id: 'shared.country.DE'
  },
  'shared.country.DJ': {
    defaultMessage: 'Djibouti',
    id: 'shared.country.DJ'
  },
  'shared.country.DK': {
    defaultMessage: 'Denmark',
    id: 'shared.country.DK'
  },
  'shared.country.DM': {
    defaultMessage: 'Dominica',
    id: 'shared.country.DM'
  },
  'shared.country.DO': {
    defaultMessage: 'Dominican Republic',
    id: 'shared.country.DO'
  },
  'shared.country.DZ': {
    defaultMessage: 'Algeria',
    id: 'shared.country.DZ'
  },
  'shared.country.EC': {
    defaultMessage: 'Ecuador',
    id: 'shared.country.EC'
  },
  'shared.country.EE': {
    defaultMessage: 'Estonia',
    id: 'shared.country.EE'
  },
  'shared.country.EG': {
    defaultMessage: 'Egypt',
    id: 'shared.country.EG'
  },
  'shared.country.EH': {
    defaultMessage: 'Western Sahara',
    id: 'shared.country.EH'
  },
  'shared.country.ER': {
    defaultMessage: 'Eritrea',
    id: 'shared.country.ER'
  },
  'shared.country.ES': {
    defaultMessage: 'Spain',
    id: 'shared.country.ES'
  },
  'shared.country.ET': {
    defaultMessage: 'Ethiopia',
    id: 'shared.country.ET'
  },
  'shared.country.FI': {
    defaultMessage: 'Finland',
    id: 'shared.country.FI'
  },
  'shared.country.FJ': {
    defaultMessage: 'Fiji',
    id: 'shared.country.FJ'
  },
  'shared.country.FK': {
    defaultMessage: 'Falkland Islands',
    id: 'shared.country.FK'
  },
  'shared.country.FM': {
    defaultMessage: 'Micronesia',
    id: 'shared.country.FM'
  },
  'shared.country.FO': {
    defaultMessage: 'Faroe Islands',
    id: 'shared.country.FO'
  },
  'shared.country.FR': {
    defaultMessage: 'France',
    id: 'shared.country.FR'
  },
  'shared.country.GA': {
    defaultMessage: 'Gabon',
    id: 'shared.country.GA'
  },
  'shared.country.GB': {
    defaultMessage: 'United Kingdom',
    id: 'shared.country.GB'
  },
  'shared.country.GD': {
    defaultMessage: 'Grenada',
    id: 'shared.country.GD'
  },
  'shared.country.GE': {
    defaultMessage: 'Georgia',
    id: 'shared.country.GE'
  },
  'shared.country.GF': {
    defaultMessage: 'French Guiana',
    id: 'shared.country.GF'
  },
  'shared.country.GG': {
    defaultMessage: 'Guernsey',
    id: 'shared.country.GG'
  },
  'shared.country.GH': {
    defaultMessage: 'Ghana',
    id: 'shared.country.GH'
  },
  'shared.country.GI': {
    defaultMessage: 'Gibraltar',
    id: 'shared.country.GI'
  },
  'shared.country.GL': {
    defaultMessage: 'Greenland',
    id: 'shared.country.GL'
  },
  'shared.country.GM': {
    defaultMessage: 'Gambia',
    id: 'shared.country.GM'
  },
  'shared.country.GN': {
    defaultMessage: 'Guinea',
    id: 'shared.country.GN'
  },
  'shared.country.GP': {
    defaultMessage: 'Guadeloupe',
    id: 'shared.country.GP'
  },
  'shared.country.GQ': {
    defaultMessage: 'Equatorial Guinea',
    id: 'shared.country.GQ'
  },
  'shared.country.GR': {
    defaultMessage: 'Greece',
    id: 'shared.country.GR'
  },
  'shared.country.GS': {
    defaultMessage: 'South Georgia and the South Sandwich Islands',
    id: 'shared.country.GS'
  },
  'shared.country.GT': {
    defaultMessage: 'Guatemala',
    id: 'shared.country.GT'
  },
  'shared.country.GU': {
    defaultMessage: 'Guam (US)',
    id: 'shared.country.GU'
  },
  'shared.country.GW': {
    defaultMessage: 'Guinea-Bissau',
    id: 'shared.country.GW'
  },
  'shared.country.GY': {
    defaultMessage: 'Guyana',
    id: 'shared.country.GY'
  },
  'shared.country.HK': {
    defaultMessage: 'Hong Kong',
    id: 'shared.country.HK'
  },
  'shared.country.HM': {
    defaultMessage: 'Heard Island and McDonald Islands',
    id: 'shared.country.HM'
  },
  'shared.country.HN': {
    defaultMessage: 'Honduras',
    id: 'shared.country.HN'
  },
  'shared.country.HR': {
    defaultMessage: 'Croatia',
    id: 'shared.country.HR'
  },
  'shared.country.HT': {
    defaultMessage: 'Haiti',
    id: 'shared.country.HT'
  },
  'shared.country.HU': {
    defaultMessage: 'Hungary',
    id: 'shared.country.HU'
  },
  'shared.country.ID': {
    defaultMessage: 'Indonesia',
    id: 'shared.country.ID'
  },
  'shared.country.IE': {
    defaultMessage: 'Ireland',
    id: 'shared.country.IE'
  },
  'shared.country.IL': {
    defaultMessage: 'Israel',
    id: 'shared.country.IL'
  },
  'shared.country.IM': {
    defaultMessage: 'Isle of Man',
    id: 'shared.country.IM'
  },
  'shared.country.IN': {
    defaultMessage: 'India',
    id: 'shared.country.IN'
  },
  'shared.country.IO': {
    defaultMessage: 'British Indian Ocean Territory',
    id: 'shared.country.IO'
  },
  'shared.country.IQ': {
    defaultMessage: 'Iraq',
    id: 'shared.country.IQ'
  },
  'shared.country.IR': {
    defaultMessage: 'Islamic Republic of Iran',
    id: 'shared.country.IR'
  },
  'shared.country.IS': {
    defaultMessage: 'Iceland',
    id: 'shared.country.IS'
  },
  'shared.country.IT': {
    defaultMessage: 'Italy',
    id: 'shared.country.IT'
  },
  'shared.country.JE': {
    defaultMessage: 'Jersey',
    id: 'shared.country.JE'
  },
  'shared.country.JM': {
    defaultMessage: 'Jamaica',
    id: 'shared.country.JM'
  },
  'shared.country.JO': {
    defaultMessage: 'Jordan',
    id: 'shared.country.JO'
  },
  'shared.country.JP': {
    defaultMessage: 'Japan',
    id: 'shared.country.JP'
  },
  'shared.country.KE': {
    defaultMessage: 'Kenya',
    id: 'shared.country.KE'
  },
  'shared.country.KG': {
    defaultMessage: 'Kyrgyzstan',
    id: 'shared.country.KG'
  },
  'shared.country.KH': {
    defaultMessage: 'Cambodia',
    id: 'shared.country.KH'
  },
  'shared.country.KI': {
    defaultMessage: 'Kiribati',
    id: 'shared.country.KI'
  },
  'shared.country.KM': {
    defaultMessage: 'Comoros',
    id: 'shared.country.KM'
  },
  'shared.country.KN': {
    defaultMessage: 'Saint Kitts and Nevis',
    id: 'shared.country.KN'
  },
  'shared.country.KP': {
    defaultMessage: 'Democratic People\'s Republic of Korea',
    id: 'shared.country.KP'
  },
  'shared.country.KR': {
    defaultMessage: 'Korea, Republic of (South)',
    id: 'shared.country.KR'
  },
  'shared.country.KW': {
    defaultMessage: 'Kuwait',
    id: 'shared.country.KW'
  },
  'shared.country.KY': {
    defaultMessage: 'Cayman Islands',
    id: 'shared.country.KY'
  },
  'shared.country.KZ': {
    defaultMessage: 'Kazakhstan',
    id: 'shared.country.KZ'
  },
  'shared.country.LA': {
    defaultMessage: 'Laos',
    id: 'shared.country.LA'
  },
  'shared.country.LB': {
    defaultMessage: 'Lebanon',
    id: 'shared.country.LB'
  },
  'shared.country.LC': {
    defaultMessage: 'Saint Lucia',
    id: 'shared.country.LC'
  },
  'shared.country.LI': {
    defaultMessage: 'Liechtenstein',
    id: 'shared.country.LI'
  },
  'shared.country.LK': {
    defaultMessage: 'Sri Lanka',
    id: 'shared.country.LK'
  },
  'shared.country.LR': {
    defaultMessage: 'Liberia',
    id: 'shared.country.LR'
  },
  'shared.country.LS': {
    defaultMessage: 'Lesotho',
    id: 'shared.country.LS'
  },
  'shared.country.LT': {
    defaultMessage: 'Lithuania',
    id: 'shared.country.LT'
  },
  'shared.country.LU': {
    defaultMessage: 'Luxembourg',
    id: 'shared.country.LU'
  },
  'shared.country.LV': {
    defaultMessage: 'Latvia',
    id: 'shared.country.LV'
  },
  'shared.country.LY': {
    defaultMessage: 'Libya',
    id: 'shared.country.LY'
  },
  'shared.country.MA': {
    defaultMessage: 'Morocco',
    id: 'shared.country.MA'
  },
  'shared.country.MC': {
    defaultMessage: 'Monaco',
    id: 'shared.country.MC'
  },
  'shared.country.MD': {
    defaultMessage: 'Moldova, Republic of',
    id: 'shared.country.MD'
  },
  'shared.country.ME': {
    defaultMessage: 'Montenegro',
    id: 'shared.country.ME'
  },
  'shared.country.MF': {
    defaultMessage: 'Saint Martin (French part)',
    id: 'shared.country.MF'
  },
  'shared.country.MG': {
    defaultMessage: 'Madagascar',
    id: 'shared.country.MG'
  },
  'shared.country.MH': {
    defaultMessage: 'Marshall Islands',
    id: 'shared.country.MH'
  },
  'shared.country.MK': {
    defaultMessage: 'Macedonia',
    id: 'shared.country.MK'
  },
  'shared.country.ML': {
    defaultMessage: 'Mali',
    id: 'shared.country.ML'
  },
  'shared.country.MM': {
    defaultMessage: 'Myanmar',
    id: 'shared.country.MM'
  },
  'shared.country.MN': {
    defaultMessage: 'Mongolia',
    id: 'shared.country.MN'
  },
  'shared.country.MO': {
    defaultMessage: 'Macau',
    id: 'shared.country.MO'
  },
  'shared.country.MP': {
    defaultMessage: 'Northern Mariana Islands',
    id: 'shared.country.MP'
  },
  'shared.country.MQ': {
    defaultMessage: 'Martinique',
    id: 'shared.country.MQ'
  },
  'shared.country.MR': {
    defaultMessage: 'Mauritania',
    id: 'shared.country.MR'
  },
  'shared.country.MS': {
    defaultMessage: 'Montserrat',
    id: 'shared.country.MS'
  },
  'shared.country.MT': {
    defaultMessage: 'Malta',
    id: 'shared.country.MT'
  },
  'shared.country.MU': {
    defaultMessage: 'Mauritius',
    id: 'shared.country.MU'
  },
  'shared.country.MV': {
    defaultMessage: 'Maldives',
    id: 'shared.country.MV'
  },
  'shared.country.MW': {
    defaultMessage: 'Malawi',
    id: 'shared.country.MW'
  },
  'shared.country.MX': {
    defaultMessage: 'Mexico',
    id: 'shared.country.MX'
  },
  'shared.country.MY': {
    defaultMessage: 'Malaysia',
    id: 'shared.country.MY'
  },
  'shared.country.MZ': {
    defaultMessage: 'Mozambique',
    id: 'shared.country.MZ'
  },
  'shared.country.NA': {
    defaultMessage: 'Namibia',
    id: 'shared.country.NA'
  },
  'shared.country.NC': {
    defaultMessage: 'New Caledonia',
    id: 'shared.country.NC'
  },
  'shared.country.NE': {
    defaultMessage: 'Niger',
    id: 'shared.country.NE'
  },
  'shared.country.NF': {
    defaultMessage: 'Norfolk Island',
    id: 'shared.country.NF'
  },
  'shared.country.NG': {
    defaultMessage: 'Nigeria',
    id: 'shared.country.NG'
  },
  'shared.country.NI': {
    defaultMessage: 'Nicaragua',
    id: 'shared.country.NI'
  },
  'shared.country.NL': {
    defaultMessage: 'Netherlands',
    id: 'shared.country.NL'
  },
  'shared.country.NO': {
    defaultMessage: 'Norway',
    id: 'shared.country.NO'
  },
  'shared.country.NP': {
    defaultMessage: 'Nepal',
    id: 'shared.country.NP'
  },
  'shared.country.NR': {
    defaultMessage: 'Nauru',
    id: 'shared.country.NR'
  },
  'shared.country.NU': {
    defaultMessage: 'Niue',
    id: 'shared.country.NU'
  },
  'shared.country.NZ': {
    defaultMessage: 'New Zealand',
    id: 'shared.country.NZ'
  },
  'shared.country.OM': {
    defaultMessage: 'Oman',
    id: 'shared.country.OM'
  },
  'shared.country.PA': {
    defaultMessage: 'Panama',
    id: 'shared.country.PA'
  },
  'shared.country.PE': {
    defaultMessage: 'Peru',
    id: 'shared.country.PE'
  },
  'shared.country.PF': {
    defaultMessage: 'French Polynesia',
    id: 'shared.country.PF'
  },
  'shared.country.PG': {
    defaultMessage: 'Papua New Guinea',
    id: 'shared.country.PG'
  },
  'shared.country.PH': {
    defaultMessage: 'Philippines',
    id: 'shared.country.PH'
  },
  'shared.country.PK': {
    defaultMessage: 'Pakistan',
    id: 'shared.country.PK'
  },
  'shared.country.PL': {
    defaultMessage: 'Poland',
    id: 'shared.country.PL'
  },
  'shared.country.PM': {
    defaultMessage: 'Saint Pierre and Miquelon',
    id: 'shared.country.PM'
  },
  'shared.country.PN': {
    defaultMessage: 'Pitcairn',
    id: 'shared.country.PN'
  },
  'shared.country.PR': {
    defaultMessage: 'Puerto Rico (US)',
    id: 'shared.country.PR'
  },
  'shared.country.PS': {
    defaultMessage: 'State of Palestine',
    id: 'shared.country.PS'
  },
  'shared.country.PT': {
    defaultMessage: 'Portugal',
    id: 'shared.country.PT'
  },
  'shared.country.PW': {
    defaultMessage: 'Palau',
    id: 'shared.country.PW'
  },
  'shared.country.PY': {
    defaultMessage: 'Paraguay',
    id: 'shared.country.PY'
  },
  'shared.country.QA': {
    defaultMessage: 'Qatar',
    id: 'shared.country.QA'
  },
  'shared.country.RE': {
    defaultMessage: 'Reunion',
    id: 'shared.country.RE'
  },
  'shared.country.RO': {
    defaultMessage: 'Romania',
    id: 'shared.country.RO'
  },
  'shared.country.RS': {
    defaultMessage: 'Serbia',
    id: 'shared.country.RS'
  },
  'shared.country.RU': {
    defaultMessage: 'Russian Federation',
    id: 'shared.country.RU'
  },
  'shared.country.RW': {
    defaultMessage: 'Rwanda',
    id: 'shared.country.RW'
  },
  'shared.country.SA': {
    defaultMessage: 'Saudi Arabia',
    id: 'shared.country.SA'
  },
  'shared.country.SB': {
    defaultMessage: 'Solomon Islands',
    id: 'shared.country.SB'
  },
  'shared.country.SC': {
    defaultMessage: 'Seychelles',
    id: 'shared.country.SC'
  },
  'shared.country.SD': {
    defaultMessage: 'Sudan',
    id: 'shared.country.SD'
  },
  'shared.country.SE': {
    defaultMessage: 'Sweden',
    id: 'shared.country.SE'
  },
  'shared.country.SG': {
    defaultMessage: 'Singapore',
    id: 'shared.country.SG'
  },
  'shared.country.SH': {
    defaultMessage: 'St. Helena',
    id: 'shared.country.SH'
  },
  'shared.country.SI': {
    defaultMessage: 'Slovenia',
    id: 'shared.country.SI'
  },
  'shared.country.SJ': {
    defaultMessage: 'Svalbard and Jan Mayen',
    id: 'shared.country.SJ'
  },
  'shared.country.SK': {
    defaultMessage: 'Slovakia',
    id: 'shared.country.SK'
  },
  'shared.country.SL': {
    defaultMessage: 'Sierra Leone',
    id: 'shared.country.SL'
  },
  'shared.country.SM': {
    defaultMessage: 'San Marino',
    id: 'shared.country.SM'
  },
  'shared.country.SN': {
    defaultMessage: 'Senegal',
    id: 'shared.country.SN'
  },
  'shared.country.SO': {
    defaultMessage: 'Somalia',
    id: 'shared.country.SO'
  },
  'shared.country.SR': {
    defaultMessage: 'Suriname',
    id: 'shared.country.SR'
  },
  'shared.country.SS': {
    defaultMessage: 'South Sudan',
    id: 'shared.country.SS'
  },
  'shared.country.ST': {
    defaultMessage: 'Sao Tome and Principe',
    id: 'shared.country.ST'
  },
  'shared.country.SV': {
    defaultMessage: 'El Salvador',
    id: 'shared.country.SV'
  },
  'shared.country.SX': {
    defaultMessage: 'Sint Maarten (Dutch Part)',
    id: 'shared.country.SX'
  },
  'shared.country.SY': {
    defaultMessage: 'Syrian Arab Republic',
    id: 'shared.country.SY'
  },
  'shared.country.SZ': {
    defaultMessage: 'Swaziland',
    id: 'shared.country.SZ'
  },
  'shared.country.TC': {
    defaultMessage: 'Turks and Caicos Islands',
    id: 'shared.country.TC'
  },
  'shared.country.TD': {
    defaultMessage: 'Chad',
    id: 'shared.country.TD'
  },
  'shared.country.TF': {
    defaultMessage: 'French Southern Territories',
    id: 'shared.country.TF'
  },
  'shared.country.TG': {
    defaultMessage: 'Togo',
    id: 'shared.country.TG'
  },
  'shared.country.TH': {
    defaultMessage: 'Thailand',
    id: 'shared.country.TH'
  },
  'shared.country.TJ': {
    defaultMessage: 'Tajikistan',
    id: 'shared.country.TJ'
  },
  'shared.country.TK': {
    defaultMessage: 'Tokelau',
    id: 'shared.country.TK'
  },
  'shared.country.TL': {
    defaultMessage: 'East Timor',
    id: 'shared.country.TL'
  },
  'shared.country.TM': {
    defaultMessage: 'Turkmenistan',
    id: 'shared.country.TM'
  },
  'shared.country.TN': {
    defaultMessage: 'Tunisia',
    id: 'shared.country.TN'
  },
  'shared.country.TO': {
    defaultMessage: 'Tonga',
    id: 'shared.country.TO'
  },
  'shared.country.TR': {
    defaultMessage: 'Turkey',
    id: 'shared.country.TR'
  },
  'shared.country.TT': {
    defaultMessage: 'Trinidad and Tobago',
    id: 'shared.country.TT'
  },
  'shared.country.TV': {
    defaultMessage: 'Tuvalu',
    id: 'shared.country.TV'
  },
  'shared.country.TW': {
    defaultMessage: 'Taiwan',
    id: 'shared.country.TW'
  },
  'shared.country.TZ': {
    defaultMessage: 'Tanzania',
    id: 'shared.country.TZ'
  },
  'shared.country.UA': {
    defaultMessage: 'Ukraine',
    id: 'shared.country.UA'
  },
  'shared.country.UG': {
    defaultMessage: 'Uganda',
    id: 'shared.country.UG'
  },
  'shared.country.UM': {
    defaultMessage: 'United States Minor Outlying Islands',
    id: 'shared.country.UM'
  },
  'shared.country.US': {
    defaultMessage: 'United States',
    id: 'shared.country.US'
  },
  'shared.country.US.state.AK': {
    defaultMessage: 'Alaska',
    id: 'shared.country.US.state.AK'
  },
  // US states
  'shared.country.US.state.AL': {
    defaultMessage: 'Alabama',
    id: 'shared.country.US.state.AL'
  },
  'shared.country.US.state.AR': {
    defaultMessage: 'Arkansas',
    id: 'shared.country.US.state.AR'
  },
  'shared.country.US.state.AS': {
    defaultMessage: 'American Samoa',
    id: 'shared.country.US.state.AS'
  },
  'shared.country.US.state.AZ': {
    defaultMessage: 'Arizona',
    id: 'shared.country.US.state.AZ'
  },
  'shared.country.US.state.CA': {
    defaultMessage: 'California',
    id: 'shared.country.US.state.CA'
  },
  'shared.country.US.state.CO': {
    defaultMessage: 'Colorado',
    id: 'shared.country.US.state.CO'
  },
  'shared.country.US.state.CT': {
    defaultMessage: 'Connecticut',
    id: 'shared.country.US.state.CT'
  },
  'shared.country.US.state.DC': {
    defaultMessage: 'District of Columbia',
    id: 'shared.country.US.state.DC'
  },
  'shared.country.US.state.DE': {
    defaultMessage: 'Delaware',
    id: 'shared.country.US.state.DE'
  },
  'shared.country.US.state.FL': {
    defaultMessage: 'Florida',
    id: 'shared.country.US.state.FL'
  },
  'shared.country.US.state.FM': {
    defaultMessage: 'Federated States of Micronesia',
    id: 'shared.country.US.state.FM'
  },
  'shared.country.US.state.GA': {
    defaultMessage: 'Georgia',
    id: 'shared.country.US.state.GA'
  },
  'shared.country.US.state.GU': {
    defaultMessage: 'Guam',
    id: 'shared.country.US.state.GU'
  },
  'shared.country.US.state.HI': {
    defaultMessage: 'Hawaii',
    id: 'shared.country.US.state.HI'
  },
  'shared.country.US.state.IA': {
    defaultMessage: 'Iowa',
    id: 'shared.country.US.state.IA'
  },
  'shared.country.US.state.ID': {
    defaultMessage: 'Idaho',
    id: 'shared.country.US.state.ID'
  },
  'shared.country.US.state.IL': {
    defaultMessage: 'Illinois',
    id: 'shared.country.US.state.IL'
  },
  'shared.country.US.state.IN': {
    defaultMessage: 'Indiana',
    id: 'shared.country.US.state.IN'
  },
  'shared.country.US.state.KS': {
    defaultMessage: 'Kansas',
    id: 'shared.country.US.state.KS'
  },
  'shared.country.US.state.KY': {
    defaultMessage: 'Kentucky',
    id: 'shared.country.US.state.KY'
  },
  'shared.country.US.state.LA': {
    defaultMessage: 'Louisiana',
    id: 'shared.country.US.state.LA'
  },
  'shared.country.US.state.MA': {
    defaultMessage: 'Massachusetts',
    id: 'shared.country.US.state.MA'
  },
  'shared.country.US.state.MD': {
    defaultMessage: 'Maryland',
    id: 'shared.country.US.state.MD'
  },
  'shared.country.US.state.ME': {
    defaultMessage: 'Maine',
    id: 'shared.country.US.state.ME'
  },
  'shared.country.US.state.MH': {
    defaultMessage: 'Marshall Islands',
    id: 'shared.country.US.state.MH'
  },
  'shared.country.US.state.MI': {
    defaultMessage: 'Michigan',
    id: 'shared.country.US.state.MI'
  },
  'shared.country.US.state.MN': {
    defaultMessage: 'Minnesota',
    id: 'shared.country.US.state.MN'
  },
  'shared.country.US.state.MO': {
    defaultMessage: 'Missouri',
    id: 'shared.country.US.state.MO'
  },
  'shared.country.US.state.MP': {
    defaultMessage: 'Northern Mariana Islands',
    id: 'shared.country.US.state.MP'
  },
  'shared.country.US.state.MS': {
    defaultMessage: 'Mississippi',
    id: 'shared.country.US.state.MS'
  },
  'shared.country.US.state.MT': {
    defaultMessage: 'Montana',
    id: 'shared.country.US.state.MT'
  },
  'shared.country.US.state.NC': {
    defaultMessage: 'North Carolina',
    id: 'shared.country.US.state.NC'
  },
  'shared.country.US.state.ND': {
    defaultMessage: 'North Dakota',
    id: 'shared.country.US.state.ND'
  },
  'shared.country.US.state.NE': {
    defaultMessage: 'Nebraska',
    id: 'shared.country.US.state.NE'
  },
  'shared.country.US.state.NH': {
    defaultMessage: 'New Hampshire',
    id: 'shared.country.US.state.NH'
  },
  'shared.country.US.state.NJ': {
    defaultMessage: 'New Jersey',
    id: 'shared.country.US.state.NJ'
  },
  'shared.country.US.state.NM': {
    defaultMessage: 'New Mexico',
    id: 'shared.country.US.state.NM'
  },
  'shared.country.US.state.NV': {
    defaultMessage: 'Nevada',
    id: 'shared.country.US.state.NV'
  },
  'shared.country.US.state.NY': {
    defaultMessage: 'New York',
    id: 'shared.country.US.state.NY'
  },
  'shared.country.US.state.OH': {
    defaultMessage: 'Ohio',
    id: 'shared.country.US.state.OH'
  },
  'shared.country.US.state.OK': {
    defaultMessage: 'Oklahoma',
    id: 'shared.country.US.state.OK'
  },
  'shared.country.US.state.OR': {
    defaultMessage: 'Oregon',
    id: 'shared.country.US.state.OR'
  },
  'shared.country.US.state.PA': {
    defaultMessage: 'Pennsylvania',
    id: 'shared.country.US.state.PA'
  },
  'shared.country.US.state.PR': {
    defaultMessage: 'Puerto Rico',
    id: 'shared.country.US.state.PR'
  },
  'shared.country.US.state.PW': {
    defaultMessage: 'Palau',
    id: 'shared.country.US.state.PW'
  },
  'shared.country.US.state.RI': {
    defaultMessage: 'Rhode Island',
    id: 'shared.country.US.state.RI'
  },
  'shared.country.US.state.SC': {
    defaultMessage: 'South Carolina',
    id: 'shared.country.US.state.SC'
  },
  'shared.country.US.state.SD': {
    defaultMessage: 'South Dakota',
    id: 'shared.country.US.state.SD'
  },
  'shared.country.US.state.TN': {
    defaultMessage: 'Tennessee',
    id: 'shared.country.US.state.TN'
  },
  'shared.country.US.state.TX': {
    defaultMessage: 'Texas',
    id: 'shared.country.US.state.TX'
  },
  'shared.country.US.state.UT': {
    defaultMessage: 'Utah',
    id: 'shared.country.US.state.UT'
  },
  'shared.country.US.state.VA': {
    defaultMessage: 'Virginia',
    id: 'shared.country.US.state.VA'
  },
  'shared.country.US.state.VI': {
    defaultMessage: 'Virgin Islands',
    id: 'shared.country.US.state.VI'
  },
  'shared.country.US.state.VT': {
    defaultMessage: 'Vermont',
    id: 'shared.country.US.state.VT'
  },
  'shared.country.US.state.WA': {
    defaultMessage: 'Washington',
    id: 'shared.country.US.state.WA'
  },
  'shared.country.US.state.WI': {
    defaultMessage: 'Wisconsin',
    id: 'shared.country.US.state.WI'
  },
  'shared.country.US.state.WV': {
    defaultMessage: 'West Virginia',
    id: 'shared.country.US.state.WV'
  },
  'shared.country.US.state.WY': {
    defaultMessage: 'Wyoming',
    id: 'shared.country.US.state.WY'
  },
  'shared.country.UY': {
    defaultMessage: 'Uruguay',
    id: 'shared.country.UY'
  },
  'shared.country.UZ': {
    defaultMessage: 'Uzbekistan',
    id: 'shared.country.UZ'
  },
  'shared.country.VA': {
    defaultMessage: 'Vatican City',
    id: 'shared.country.VA'
  },
  'shared.country.VC': {
    defaultMessage: 'Saint Vincent and the Grenadines',
    id: 'shared.country.VC'
  },
  'shared.country.VE': {
    defaultMessage: 'Venezuela',
    id: 'shared.country.VE'
  },
  'shared.country.VG': {
    defaultMessage: 'British Virgin Islands',
    id: 'shared.country.VG'
  },
  'shared.country.VI': {
    defaultMessage: 'Virgin Islands (US)',
    id: 'shared.country.VI'
  },
  'shared.country.VN': {
    defaultMessage: 'Viet Nam',
    id: 'shared.country.VN'
  },
  'shared.country.VU': {
    defaultMessage: 'Vanuatu',
    id: 'shared.country.VU'
  },
  'shared.country.WF': {
    defaultMessage: 'Wallis and Futuna',
    id: 'shared.country.WF'
  },
  'shared.country.WS': {
    defaultMessage: 'Samoa (Independent)',
    id: 'shared.country.WS'
  },
  'shared.country.YE': {
    defaultMessage: 'Yemen',
    id: 'shared.country.YE'
  },
  'shared.country.YT': {
    defaultMessage: 'Mayotte',
    id: 'shared.country.YT'
  },
  'shared.country.ZA': {
    defaultMessage: 'South Africa',
    id: 'shared.country.ZA'
  },
  'shared.country.ZM': {
    defaultMessage: 'Zambia',
    id: 'shared.country.ZM'
  },
  'shared.country.ZW': {
    defaultMessage: 'Zimbabwe',
    id: 'shared.country.ZW'
  },
  'shared.countryList': {
    defaultMessage: 'See Country List',
    id: 'shared.countryList'
  },
  'shared.credit': {
    defaultMessage: '(credit)',
    id: 'shared.credit'
  },
  'shared.daily': {
    defaultMessage: 'Daily',
    id: 'shared.daily'
  },
  'shared.dailyplan': {
    defaultMessage: 'Daily Plan',
    id: 'shared.dailyplan'
  },
  'shared.daysremaining': {
    defaultMessage: '{number, plural, one {day} other {days}} remaining',
    id: 'shared.daysremaining'
  },
  'shared.discount': {
    defaultMessage: 'Discount applied',
    id: 'shared.discount'
  },
  'shared.discounted.price': {
    defaultMessage: 'Discounted price',
    id: 'shared.discounted.price'
  },
  'shared.edit': {
    defaultMessage: 'edit',
    id: 'shared.edit'
  },
  'shared.edit.billingaddress': {
    defaultMessage: 'EDIT BILLING ADDRESS',
    id: 'shared.edit.billingaddress'
  },
  'shared.error': {
    defaultMessage: 'Error',
    id: 'shared.error'
  },
  'shared.excludingtaxes': {
    defaultMessage: 'excluding taxes',
    id: 'shared.excludingtaxes'
  },
  'shared.expiringtrial': {
    defaultMessage: 'Your trial ends today',
    id: 'shared.expiringtrial'
  },
  'shared.from': {
    defaultMessage: 'From',
    id: 'shared.from'
  },
  'shared.g2mcancellation.features': {
    defaultMessage: 'If you cancel, you will lose access to GoToMeeting features like webcams, dial-in numbers, and phone support',
    id: 'shared.g2mcancellation.features'
  },
  'shared.getfreenow': {
    defaultMessage: 'Get free now',
    id: 'shared.getfreenow'
  },
  'shared.help.community': {
    defaultMessage: 'Community',
    id: 'shared.help.community'
  },
  'shared.help.contact': {
    defaultMessage: 'Contact',
    id: 'shared.help.contact'
  },
  'shared.help.faq': {
    defaultMessage: 'FAQ',
    id: 'shared.help.faq'
  },
  'shared.help.needhelp': {
    defaultMessage: 'NEED HELP?',
    id: 'shared.help.needhelp'
  },
  'shared.help.support': {
    defaultMessage: 'Support',
    id: 'shared.help.support'
  },
  'shared.help.training': {
    defaultMessage: 'Training',
    id: 'shared.help.training'
  },
  'shared.help.userguides': {
    defaultMessage: 'User guides',
    id: 'shared.help.userguides'
  },
  'shared.input.addressline1': {
    defaultMessage: 'Address Line 1',
    id: 'shared.input.addressline1'
  },
  'shared.input.addressline2': {
    defaultMessage: 'Address Line 2 (optional)',
    id: 'shared.input.addressline2'
  },
  'shared.input.billinginformation': {
    defaultMessage: 'Billing Information',
    id: 'shared.input.billinginformation'
  },
  'shared.input.city.AU': {
    defaultMessage: 'Locality/Placename',
    id: 'shared.input.city.AU'
  },
  'shared.input.city.CA': {
    defaultMessage: 'Municipality',
    id: 'shared.input.city.CA'
  },
  'shared.input.city.DE': {
    defaultMessage: 'Locality/Placename',
    id: 'shared.input.city.DE'
  },
  'shared.input.city.FR': {
    defaultMessage: 'Town/City',
    id: 'shared.input.city.FR'
  },
  'shared.input.city.GB': {
    defaultMessage: 'Town/City',
    id: 'shared.input.city.GB'
  },
  'shared.input.city.US': {
    defaultMessage: 'Town/City',
    id: 'shared.input.city.US'
  },
  'shared.input.country': {
    defaultMessage: 'Country',
    id: 'shared.input.country'
  },
  'shared.input.firstname': {
    defaultMessage: 'First Name',
    id: 'shared.input.firstname'
  },
  'shared.input.lastname': {
    defaultMessage: 'Last Name',
    id: 'shared.input.lastname'
  },
  'shared.input.optional': {
    defaultMessage: '(optional)',
    id: 'shared.input.optional'
  },
  'shared.input.paymentdetails': {
    defaultMessage: 'Payment Details',
    id: 'shared.input.paymentdetails'
  },
  'shared.input.paymentdetails.cardnumber': {
    defaultMessage: 'Card Number',
    id: 'shared.input.paymentdetails.cardnumber'
  },
  'shared.input.phonenumber': {
    defaultMessage: 'Phone Number',
    id: 'shared.input.phonenumber'
  },
  'shared.input.postalcode.AU': {
    defaultMessage: 'Postcode',
    id: 'shared.input.postalcode.AU'
  },
  'shared.input.postalcode.CA': {
    defaultMessage: 'Zip/Postal Code',
    id: 'shared.input.postalcode.CA'
  },
  'shared.input.postalcode.DE': {
    defaultMessage: 'Postcode',
    id: 'shared.input.postalcode.DE'
  },
  'shared.input.postalcode.FR': {
    defaultMessage: 'Postcode',
    id: 'shared.input.postalcode.FR'
  },
  'shared.input.postalcode.GB': {
    defaultMessage: 'Postcode',
    id: 'shared.input.postalcode.GB'
  },
  'shared.input.postalcode.US': {
    defaultMessage: 'Zip/Postal Code',
    id: 'shared.input.postalcode.US'
  },
  'shared.input.state.AU': {
    defaultMessage: 'State or Territory',
    id: 'shared.input.state.AU'
  },
  'shared.input.state.CA': {
    defaultMessage: 'Province',
    id: 'shared.input.state.CA'
  },
  'shared.input.state.US': {
    defaultMessage: 'State/Province',
    id: 'shared.input.state.US'
  },
  'shared.keepselectedplan': {
    defaultMessage: 'Keep selected plan',
    id: 'shared.keepselectedplan'
  },
  'shared.maxunits': {
    defaultMessage: 'Up to {max} {unit}',
    id: 'shared.maxunits'
  },
  'shared.modal.cancellationcompleted.cancellationsurveymessage': {
    defaultMessage: '<strong>Before we go ahead and finalize your cancellation</strong>, please take a short moment to answer the following three questions. Your feedback is important to us.',
    id: 'shared.modal.cancellationcompleted.cancellationsurveymessage'
  },
  'shared.month': {
    defaultMessage: 'Month',
    id: 'shared.month'
  },
  'shared.month.lowercase': {
    defaultMessage: 'month',
    id: 'shared.month.lowercase'
  },
  'shared.monthly': {
    defaultMessage: 'Monthly',
    id: 'shared.monthly'
  },
  'shared.monthly.lowercase': {
    defaultMessage: 'monthly',
    id: 'shared.monthly.lowercase'
  },
  'shared.monthlyplan': {
    defaultMessage: 'Monthly Plan',
    id: 'shared.monthlyplan'
  },
  'shared.months': {
    defaultMessage: '{number, plural, one {# month} other {# months}}',
    id: 'shared.months'
  },
  'shared.nav.invoicehistory': {
    defaultMessage: 'Invoice History',
    id: 'shared.nav.invoicehistory'
  },
  'shared.nav.paymentmethod': {
    defaultMessage: 'Payment Method',
    id: 'shared.nav.paymentmethod'
  },
  'shared.notapplicable': {
    defaultMessage: 'N/A',
    id: 'shared.notapplicable'
  },
  'shared.ordersummary': {
    defaultMessage: 'Order Summary',
    id: 'shared.ordersummary'
  },
  'shared.original.price': {
    defaultMessage: 'Original price',
    id: 'shared.original.price'
  },
  'shared.originalpaymentrefund': {
    defaultMessage: 'Your original payment method will be refunded',
    id: 'shared.originalpaymentrefund'
  },
  'shared.paidannually': {
    defaultMessage: 'Paid annually',
    id: 'shared.paidannually'
  },
  'shared.paymentmethod': {
    defaultMessage: 'PAYMENT METHOD',
    id: 'shared.paymentmethod'
  },
  'shared.perminute': {
    defaultMessage: 'per minute',
    id: 'shared.perminute'
  },
  'shared.permonth': {
    defaultMessage: 'Per month',
    id: 'shared.permonth'
  },
  'shared.perunit': {
    defaultMessage: 'Per {unit}',
    id: 'shared.perunit'
  },
  'shared.peryear': {
    defaultMessage: 'Per year',
    id: 'shared.peryear'
  },
  'shared.price.billing.annually': {
    defaultMessage: 'Billed annually',
    id: 'shared.price.billing.annually'
  },
  'shared.price.billing.monthly': {
    defaultMessage: 'Billed monthly',
    id: 'shared.price.billing.monthly'
  },
  'shared.price.estimate.subscription.quantity': {
    defaultMessage: '{quantity} {unit}',
    id: 'shared.price.estimate.subscription.quantity'
  },
  'shared.pricebeforetaxes': {
    defaultMessage: 'Price before taxes',
    id: 'shared.pricebeforetaxes'
  },
  'shared.priceperminute': {
    defaultMessage: '{price}/min',
    id: 'shared.priceperminute'
  },
  'shared.pricepermonth': {
    defaultMessage: '{price}/mo',
    id: 'shared.pricepermonth'
  },
  'shared.pricepermonthcaveat': {
    defaultMessage: '{price}/mo*',
    id: 'shared.pricepermonthcaveat'
  },
  'shared.priceperunit': {
    defaultMessage: '{price}/unit',
    id: 'shared.priceperunit'
  },
  'shared.pricetotal': {
    defaultMessage: 'Total: {price}',
    id: 'shared.pricetotal'
  },
  'shared.proceedtocheckout': {
    defaultMessage: 'Proceed to checkout',
    id: 'shared.proceedtocheckout'
  },
  'shared.quantity': {
    defaultMessage: 'Quantity',
    id: 'shared.quantity'
  },
  'shared.quantity.unit': {
    defaultMessage: '{quantity, plural, one {# unit} other {# units}}',
    id: 'shared.quantity.unit'
  },
  'shared.renewaldate': {
    defaultMessage: 'renewal date',
    id: 'shared.renewaldate'
  },
  'shared.savings': {
    defaultMessage: 'Savings: {savings}/yr',
    id: 'shared.savings'
  },
  'shared.select': {
    defaultMessage: 'Select',
    id: 'shared.select'
  },
  'shared.starttrial': {
    defaultMessage: 'Start trial',
    id: 'shared.starttrial'
  },
  'shared.subscription.edit': {
    defaultMessage: 'Edit Subscription',
    id: 'shared.subscription.edit'
  },
  'shared.subscriptions': {
    defaultMessage: 'Subscriptions',
    id: 'shared.subscriptions'
  },
  'shared.tax-number.apply': {
    defaultMessage: 'Apply',
    id: 'shared.tax-number.apply'
  },
  'shared.tax-number.gst': {
    defaultMessage: 'GST Number',
    id: 'shared.tax-number.gst'
  },
  'shared.tax-number.gst-india': {
    defaultMessage: 'GST India',
    id: 'shared.tax-number.gst-india'
  },
  'shared.tax-number.rfc': {
    defaultMessage: 'Tax ID (RFC)',
    id: 'shared.tax-number.rfc'
  },
  'shared.tax-number.vat': {
    defaultMessage: 'VAT Number',
    id: 'shared.tax-number.vat'
  },
  'shared.unitprice': {
    defaultMessage: 'Unit price',
    id: 'shared.unitprice'
  },
  'shared.unlimited': {
    defaultMessage: 'Unlimited',
    id: 'shared.unlimited'
  },
  'shared.updatebillingaddress': {
    defaultMessage: 'UPDATE BILLING ADDRESS',
    id: 'shared.updatebillingaddress'
  },
  'shared.updatecart.caps': {
    defaultMessage: 'UPDATE CART',
    id: 'shared.updatecart.caps'
  },
  'shared.updatepaymentmethod': {
    defaultMessage: 'UPDATE PAYMENT METHOD',
    id: 'shared.updatepaymentmethod'
  },
  'shared.user.agent': {
    defaultMessage: '{units, plural, one {agent} other {agents}}',
    id: 'shared.user.agent'
  },
  'shared.user.agent.cap': {
    defaultMessage: '{units, plural, one {Agent} other {Agents}}',
    id: 'shared.user.agent.cap'
  },
  'shared.user.attendee': {
    defaultMessage: '{units, plural, one {attendee} other {attendees}}',
    id: 'shared.user.attendee'
  },
  'shared.user.attendee.cap': {
    defaultMessage: '{units, plural, one {Attendee} other {Attendees}}',
    id: 'shared.user.attendee.cap'
  },
  'shared.user.default': {
    defaultMessage: '{units, plural, one {user} other {users}}',
    id: 'shared.user.default'
  },
  'shared.user.default.cap': {
    defaultMessage: '{units, plural, one {User} other {Users}}',
    id: 'shared.user.default.cap'
  },
  'shared.user.employee': {
    defaultMessage: '{units, plural, one {employee} other {employees}}',
    id: 'shared.user.employee'
  },
  'shared.user.employee.cap': {
    defaultMessage: '{units, plural, one {Employee} other {Employees}}',
    id: 'shared.user.employee.cap'
  },
  'shared.user.expert': {
    defaultMessage: '{units, plural, one {expert} other {experts}}',
    id: 'shared.user.expert'
  },
  'shared.user.expert.cap': {
    defaultMessage: '{units, plural, one {Expert} other {Experts}}',
    id: 'shared.user.expert.cap'
  },
  'shared.user.mobileaddon': {
    defaultMessage: '{units, plural, one {mobile add-on} other {mobile add-ons}}',
    id: 'shared.user.mobileaddon'
  },
  'shared.user.mobileaddon.cap': {
    defaultMessage: '{units, plural, one {Mobile Add-on} other {Mobile Add-ons}}',
    id: 'shared.user.mobileaddon.cap'
  },
  'shared.user.organizer': {
    defaultMessage: '{units, plural, one {organizer} other {organizers}}',
    id: 'shared.user.organizer'
  },
  'shared.user.organizer.cap': {
    defaultMessage: '{units, plural, one {Organizer} other {Organizers}}',
    id: 'shared.user.organizer.cap'
  },
  'shared.user.participant': {
    defaultMessage: '{units, plural, one {participant} other {participants}}',
    id: 'shared.user.participant'
  },
  'shared.user.participant.cap': {
    defaultMessage: '{units, plural, one {Participant} other {Participants}}',
    id: 'shared.user.participant.cap'
  },
  'shared.user.team': {
    defaultMessage: '{units, plural, one {team} other {teams}}',
    id: 'shared.user.team'
  },
  'shared.user.team.cap': {
    defaultMessage: '{units, plural, one {Team} other {Teams}}',
    id: 'shared.user.team.cap'
  },
  'shared.user.technician': {
    defaultMessage: '{units, plural, one {technician} other {technicians}}',
    id: 'shared.user.technician'
  },
  'shared.user.technician.cap': {
    defaultMessage: '{units, plural, one {Technician} other {Technicians}}',
    id: 'shared.user.technician.cap'
  },
  'shared.validation.iban': {
    defaultMessage: 'IBAN must be between 15 and 34 characters in length',
    id: 'shared.validation.iban'
  },
  'shared.validation.postalcode': {
    defaultMessage: 'Only letters, numbers, and hyphens are allowed',
    id: 'shared.validation.postalcode'
  },
  'shared.validation.required': {
    defaultMessage: 'Required',
    id: 'shared.validation.required'
  },
  'shared.viewrates': {
    defaultMessage: 'View Rates',
    id: 'shared.viewrates'
  },
  'shared.viewperminuterates': {
    defaultMessage: 'View per minute Rates',
    id: 'shared.viewperminuterates'
  },
  'shared.viewperminuterates.cap': {
    defaultMessage: 'VIEW PER MINUTE RATES',
    id: 'shared.viewperminuterates.cap'
  },
  'shared.year.lowercase': {
    defaultMessage: 'year',
    id: 'shared.year.lowercase'
  },
  'shared.years': {
    defaultMessage: '{number, plural, one {# year} other {# years}}',
    id: 'shared.years'
  },
  'subscription.addon.callme.description': {
    defaultMessage: `
      Never miss a meeting with Call Me. You and your attendees can request a callback to quickly join your meeting by phone.
      {isNonNorthAmericanCountryCodesList, select,
        true {Toll Free for your attendees: Offer the best experience possible with integrated toll-free audio for the US, Canada, UK, Europe, Australia, and over 25 additional countries. {viewRatesLink}.}
        false {Toll Free for your attendees: Offer the best experience possible with integrated toll-free audio for the US, Canada and over 50 additional countries. {viewRatesLink}.}
      }
      Call Me and Toll Free audio are provided by OpenVoice at {price}/min per attendee.
    `,
    id: 'subscription.addon.callme.description'
  },
  'subscription.addon.callme.modal.header': {
    defaultMessage: 'Call Me and Toll-free numbers',
    id: 'subscription.addon.callme.modal.header'
  },
  // CallMe
  'subscription.addon.callme.title': {
    defaultMessage: 'Be A Great Host: Add Premium Audio (Recommended)',
    id: 'subscription.addon.callme.title'
  },
  'subscription.addon.details': {
    defaultMessage: 'Plan details',
    id: 'subscription.addon.details'
  },
  'subscription.addon.eaflatrate.modal.body': {
    defaultMessage: 'The price shown is a flat rate including US and Canada per organizer. If participants choose to dial Toll-Free Numbers or use Call Me outside of the plan, you will be charged on a pay-as-you-go basis. {viewRatesLink} (PDF).',
    id: 'subscription.addon.eaflatrate.modal.body'
  },
  // Enhanced_Audio_Flat_Rate
  'subscription.addon.eaflatrate.title': {
    defaultMessage: 'Add Toll-Free Numbers and Call Me.',
    id: 'subscription.addon.eaflatrate.title'
  },
  'subscription.addon.eapaygo.description': {
    defaultMessage: `
      Never miss a meeting with Call Me. You and your attendees can request a callback to quickly join your meeting by phone.
      Toll-Free Numbers for your attendees: Offer the best experience possible with integrated toll-free audio for the US, Canada and over 50 additional countries. {viewRatesLink}.
      Toll-Free Numbers and Call Me are available for {price}/min per attendee.
    `,
    id: 'subscription.addon.eapaygo.description'
  },
  // Enhanced_Audio_Flat_Rate_global_callme
  'subscription.addon.eaflatrateglobalcallme.title': {
    defaultMessage: 'Enhanced Audio - Global Call Me',
    id: 'subscription.addon.eaflatrateglobalcallme.title'
  },
  'subscription.addon.shared.eaflatrateglobalcallme.description': {
    defaultMessage: `
      No need to enter access codes and pins, simply have the meeting call you and your attendees!
      Offered in over 60 countries, {countryListLink}
    `,
    id: 'subscription.addon.shared.eaflatrateglobalcallme.description'
  },
  'subscription.addon.eaflatrateglobalcallme.modal.body': {
    defaultMessage: `
      The price shown is a flat rate for unlimited Call Me minutes per organizer for all available countries.
      If participants choose to dial Toll-Free Numbers, you will be charged on a pay-as-you-go basis. {viewRatesLink} (PDF).
    `,
    id: 'subscription.addon.eaflatrateglobalcallme.modal.body'
  },
  // Enhanced_Audio_PayGo
  'subscription.addon.eapaygo.title': {
    defaultMessage: 'Be A Great Host: Add Enhanced Audio (Recommended)',
    id: 'subscription.addon.eapaygo.title'
  },
  'subscription.addon.ovi.description': {
    defaultMessage: `
      {isNonNorthAmericanCountryCodesList, select,
        true {Offer the best experience possible with integrated toll-free audio for the US, Canada, UK, Europe, Australia, and over 25 additional countries. {viewRatesLink}. GoToMeeting will include Call Me.}
        false {Offer the best experience possible with integrated toll-free audio for the US, Canada and over 50 additional countries. {viewRatesLink}. GoToMeeting will include Call Me.}
      }
      Toll Free audio and Call Me are provided by OpenVoice at {price}/min per attendee.
    `,
    id: 'subscription.addon.ovi.description'
  },
  'subscription.addon.ovi.modal.body.featurelist': {
    defaultMessage: `
      Integrated toll-free scheduling with GoToMeeting.
      {price}/min per attendee.
      Increase your reach with numbers for over 50 countries. {viewRatesLink} (PDF).
      Attendees choose to join with audio via toll-free or the built-in VoIP or long-distance options.
      All of your organizers can immediately begin using toll-free numbers.
    `,
    id: 'subscription.addon.ovi.modal.body.featurelist'
  },
  'subscription.addon.ovi.modal.header': {
    defaultMessage: 'Toll-free Numbers {br} Provided by OpenVoice',
    id: 'subscription.addon.ovi.modal.header'
  },
  // OVI
  'subscription.addon.ovi.title': {
    defaultMessage: 'Be A Great Host: Add Toll Free for Your Attendees',
    id: 'subscription.addon.ovi.title'
  },
  'subscription.addon.ovicommitinterim.description': {
    defaultMessage: `
      Make joining a meeting as simple as answering an automated phone call.
      Let your participants skip long-distance charges with Toll-Free Numbers for up to 50+ countries.
    `,
    id: 'subscription.addon.ovicommitinterim.description'
  },
  'subscription.addon.ovicommitinterim.modal.body': {
    defaultMessage: 'The price shown is the minimum monthly commit based on usage across all Organizers. When participants join through Toll-Free Numbers or Call Me, minutes used will detract from this total, starting at {price}/minute per participant. If usage exceeds {minCommitPrice}, you will be charged on a pay-as-you-go basis afterwards. {viewRatesLink}.',
    id: 'subscription.addon.ovicommitinterim.modal.body'
  },
  // OVI Commit
  'subscription.addon.ovicommitinterim.title': {
    defaultMessage: 'Add Toll-Free Numbers & Call Me (provided by OpenVoice) for {minCommitPrice}/month.',
    id: 'subscription.addon.ovicommitinterim.title'
  },
  'subscription.addon.oviflatrateinterim.modal.body': {
    defaultMessage: 'The price shown is a flat rate for unlimited minutes in US and Canada per organizer. If international participants choose to join through Toll-Free Numbers or Call Me, you will be charged extra on a pay-as-you-go basis. {viewRatesLink}.',
    id: 'subscription.addon.oviflatrateinterim.modal.body'
  },
  // OVI flat rate
  'subscription.addon.oviflatrateinterim.title': {
    defaultMessage: 'Add Toll-Free Numbers & Call Me (provided by OpenVoice).',
    id: 'subscription.addon.oviflatrateinterim.title'
  },
  'subscription.addon.ra-mobile.description': {
    defaultMessage: `
      The GoToAssist Remote Support Mobile Add-on offers remote control of Android devices*, remote view of iOS devices and Seeit Camera Share functionality.

      Start supporting your mobile users with:
      Mobile Chat, Remote View, Remote Control*, Device Info, Seeit Camera Share

      *Remote control supported for Samsung, LG, Motorola, Huawei and HTC Android devices.
    `,
    id: 'subscription.addon.ra-mobile.description'
  },
  // RA Mobile
  'subscription.addon.ra-mobile.title': {
    defaultMessage: 'Include Mobile Add-on',
    id: 'subscription.addon.ra-mobile.title'
  },
  'subscription.addon.shared.eapaygocallme.modal.body.featurelist': {
    defaultMessage: `
      Toll-free numbers are available for over 50 countries. {viewRatesLink} (PDF).
      Organizers can start using Call Me & toll-free numbers right away.
      Attendees may join with built-in computer audio or by phone using Call Me, toll-free or long-distance numbers.
      Call Me and toll-free rates start at {price}/min per attendee.
    `,
    id: 'subscription.addon.shared.eapaygocallme.modal.body.featurelist'
  },
  'subscription.addon.shared.eapaygoflatrate.modal.header': {
    defaultMessage: 'Toll-Free Numbers and Call Me',
    id: 'subscription.addon.shared.eapaygoflatrate.modal.header'
  },
  'subscription.addon.shared.ovicommitflatrateinterim.modal.body.featurelist': {
    defaultMessage: `
      Toll-Free Numbers are available for over 50 countries. {viewRatesLink}.
      Call Me is available in the US and 40+ countries. {viewRatesLink}.
      Organizers can start using Toll-Free Numbers & Call Me right away.
      Participants can still join through built-in computer audio or toll-based numbers.
    `,
    id: 'subscription.addon.shared.ovicommitflatrateinterim.modal.body.featurelist'
  },
  'subscription.addon.shared.ovicommitflatrateinterim.modal.header': {
    defaultMessage: 'Toll-Free Numbers & Call Me (provided by OpenVoice)',
    id: 'subscription.addon.shared.ovicommitflatrateinterim.modal.header'
  },
  'subscription.addon.shared.oviflatrateinterimeaflatrate.description': {
    defaultMessage: `
      Make joining a meeting as simple as answering an automated phone call.
      Let your participants skip long-distance charges with Toll-Free Numbers for the US & Canada.
    `,
    id: 'subscription.addon.shared.oviflatrateinterimeaflatrate.description'
  },
  'subscription.billingperiod.bestvalue': {
    defaultMessage: 'BEST VALUE',
    id: 'subscription.billingperiod.bestvalue'
  },
  'subscription.billingperiod.chooseplan': {
    defaultMessage: 'Choose a billing plan',
    id: 'subscription.billingperiod.chooseplan'
  },
  'subscription.billingperiod.keepselectedperiod': {
    defaultMessage: 'Keep selected billing frequency',
    id: 'subscription.billingperiod.keepselectedperiod'
  },
  'subscription.billingperiod.priceperyear': {
    defaultMessage: '{currency}/year',
    id: 'subscription.billingperiod.priceperyear'
  },
  'subscription.card.revert': {
    defaultMessage: 'Never mind, revert changes',
    id: 'subscription.card.revert'
  },
  'subscription.card.switchtoannual': {
    defaultMessage: 'Switch to Annual',
    id: 'subscription.card.switchtoannual'
  },
  'subscription.card.switchtoannual.pendingmessage': {
    defaultMessage: 'Switching to Annual...',
    id: 'subscription.card.switchtoannual.pendingmessage'
  },
  'subscription.card.switchtoannual.save': {
    defaultMessage: 'Save {amount}/yr',
    id: 'subscription.card.switchtoannual.save'
  },
  'subscription.card.upgradelink': {
    defaultMessage: 'Upgrade',
    id: 'subscription.card.upgradelink'
  },
  'subscription.date.canceled': {
    defaultMessage: 'Subscription Ends',
    id: 'subscription.date.canceled'
  },
  'subscription.date.expired': {
    defaultMessage: 'Expired',
    id: 'subscription.date.expired'
  },
  'subscription.date.expires': {
    defaultMessage: 'Expires',
    id: 'subscription.date.expires'
  },
  'subscription.date.renewal': {
    defaultMessage: 'Next Renewal',
    id: 'subscription.date.renewal'
  },
  'subscription.price.onlyannualavailable': {
    defaultMessage: 'Only annual billing is available for this plan',
    id: 'subscription.price.onlyannualavailable'
  },
  'subscription.price.onlymonthlyavailable': {
    defaultMessage: 'Only monthly billing is available for this plan',
    id: 'subscription.price.onlymonthlyavailable'
  },
  'subscription.productdetails.openvoice': {
    defaultMessage: 'Toll and Toll-Free Audio Conferencing',
    id: 'subscription.productdetails.openvoice'
  },
  'subscription.productdetails.openvoiceintegrated': {
    defaultMessage: 'Toll-Free Service for GoTo Products',
    id: 'subscription.productdetails.openvoiceintegrated'
  },
  'subscription.productdetails.validfor.day': {
    defaultMessage: 'valid for {duration, plural, one {# day} other {# days}}',
    id: 'subscription.productdetails.validfor.day'
  },
  'subscription.productdetails.validfor.month': {
    defaultMessage: 'valid for {duration, plural, one {# month} other {# months}}',
    id: 'subscription.productdetails.validfor.month'
  },
  'subscription.productdetails.validfor.year': {
    defaultMessage: 'valid for {duration, plural, one {# year} other {# years}}',
    id: 'subscription.productdetails.validfor.year'
  },
  'subscription.productfeatures.comparisonnotavailable': {
    defaultMessage: 'Feature comparison for your product is not available.',
    id: 'subscription.productfeatures.comparisonnotavailable'
  },
  'subscription.products.compareplans': {
    defaultMessage: 'COMPARE PLANS',
    id: 'subscription.products.compareplans'
  },
  'subscription.products.showless': {
    defaultMessage: 'SHOW LESS',
    id: 'subscription.products.showless'
  },
  'subscription.productselection.buy': {
    defaultMessage: 'BUY',
    id: 'subscription.productselection.buy'
  },
  'subscription.productselection.chooseplan': {
    defaultMessage: 'Choose a plan that’s right for you',
    id: 'subscription.productselection.chooseplan'
  },
  'subscription.productselection.try': {
    defaultMessage: 'TRY',
    id: 'subscription.productselection.try'
  },
  'subscription.quantity.choosequantity': {
    defaultMessage: 'Choose number of {unit}',
    id: 'subscription.quantity.choosequantity'
  },
  'subscription.quantity.current.message': {
    defaultMessage: 'You currently have {quantity} {unit}. Choose the total number of {unitsPlural} you would like to have on your subscription.',
    id: 'subscription.quantity.current.message'
  },
  'subscription.quantity.description': {
    defaultMessage: 'How many {unitsPlural} would you like to have? Each {unit} can schedule and launch their own sessions.',
    id: 'subscription.quantity.description'
  },
  'subscription.quantity.description.g2wteam': {
    defaultMessage: 'How many teams would you like to have? Each organizer in a team can schedule and launch their own webinar.',
    id: 'subscription.quantity.description.g2wteam'
  },
  'subscription.quantity.next': {
    defaultMessage: 'Next',
    id: 'subscription.quantity.next'
  },
  'subscriptioncard.addaddon': {
    defaultMessage: 'View Options',
    id: 'subscriptioncard.addaddon'
  },
  'subscriptionoverview.button.addunits': {
    defaultMessage: 'Add {units}',
    id: 'subscriptionoverview.button.addunits'
  },
  'subscriptions.header': {
    defaultMessage: 'SUBSCRIPTIONS',
    id: 'subscriptions.header'
  },
  'subscriptions.manageusers': {
    defaultMessage: 'MANAGE USERS',
    id: 'subscriptions.manageusers'
  },
  'subscriptions.subscription.actions.cancel': {
    defaultMessage: 'Cancel Subscription',
    id: 'subscriptions.subscription.actions.cancel'
  },
  'subscriptions.subscription.actions.remove.cancel': {
    defaultMessage: 'REMOVE PENDING CANCELLATION',
    id: 'subscriptions.subscription.actions.remove.cancel'
  },
  'syndeton.comma': {
    defaultMessage: ',',
    id: 'syndeton.comma'
  },
  'syndeton.conjunction.and': {
    defaultMessage: 'and',
    id: 'syndeton.conjunction.and'
  },
  'syndeton.conjunction.or': {
    defaultMessage: 'or',
    id: 'syndeton.conjunction.or'
  },
  'syndeton.oxfordcomma.and': {
    defaultMessage: ', and',
    id: 'syndeton.oxfordcomma.and'
  },
  'syndeton.oxfordcomma.or': {
    defaultMessage: ', or',
    id: 'syndeton.oxfordcomma.or'
  },
  'tax-number.patch.error.general': {
    defaultMessage: 'We apologize, but we experienced a problem saving your tax number details. Please try again.',
    id: 'tax-number.patch.error.general'
  },
  'tax-number.patch.error.invalid': {
    defaultMessage: 'Your tax number is an invalid format.',
    id: 'tax-number.patch.error.invalid'
  },
  'tax-number.patch.error.xss': {
    defaultMessage: 'Your tax number contains illegal characters.',
    id: 'tax-number.patch.error.xss'
  },
  'tnps.survey.feedback': {
    defaultMessage: 'Feedback',
    id: 'tnps.survey.feedback'
  },
  'tnps.survey.good': {
    defaultMessage: 'Good',
    id: 'tnps.survey.good'
  },
  'tnps.survey.ok': {
    defaultMessage: 'Ok',
    id: 'tnps.survey.ok'
  },
  'tnps.survey.poor': {
    defaultMessage: 'Poor',
    id: 'tnps.survey.poor'
  },
  'tnps.survey.title': {
    defaultMessage: 'Rate this page',
    id: 'tnps.survey.title'
  },
  'tos.agreement': {
    defaultMessage: 'By proceeding, you are agreeing to our <tos-link>Terms of Service</tos-link>, <privacy-policy-link>Privacy Policy</privacy-policy-link>, and <anti-spam-link>Anti-Spam Policy</anti-spam-link>',
    id: 'tos.agreement'
  }
});

export default t;
