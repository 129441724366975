import axios from 'axios';
import auth from 'lib/commerce-auth';
import environment from 'lib/environment';
import Session from 'lib/session';
import appconfig from 'config/appconfig';
import appRoute from 'lib/resolve-route';
import history from 'lib/history';

// ------------------------------------
// Environment api base urls
// ------------------------------------

const baseUrls = environment.get('api');

// ------------------------------------
// Interceptors
// ------------------------------------

/**
 * @callback ErrorResponseInterceptor
 *
 * An axios response interceptor error handler callback
 *
 * @param {object}  errorResponse: The error.response object from an HTTP response
 * @returns {Promise.<*>} The promise should resolve with the passed in errorResponse if the handler
 *                        handled this failure; the promise should reject with the passed in errorResponse if
 *                        the handler did not handle this failure. In special cases the promise may remain pending
 *                        (when the app will be hard-reloaded, for instance).
 */

/**
 * @typedef {object} InterceptorOptions
 *
 * Specifies interceptors to add to an axios instance
 *
 * @property {function[]} [requestInterceptors]
 * @property {function[]} [responseInterceptors]
 */

/**
 * Augments all HTTP requests with the Authorization header
 *
 * @param {object} authentication: An implementation of an authentication/authorization library which has a "token" property that can be used
 *                                 in an Authorization header for API calls. The "token" property itself is an object that should
 *                                 expose a "token_type" property (e.g. "Bearer") and a "access_token" property which is the actual
 *                                 access token.
 * @returns {function}
 */
export const requestInterceptorFactory = (authentication) => (config) => {
  const authToken = authentication.token;
  if (authToken && Object.prototype.hasOwnProperty.call(authToken, 'access_token')) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `${authToken.token_type} ${authToken.access_token}`
      }
    };
  }
  return config;
};

/**
 * This should always be the first of the chained interceptors. We trim the error object to just error.response for
 * consumption by the rest of the app, and store the error response into session storage.
 *
 * @param {object} error: The error.response object from an HTTP response
 * @returns {Promise.<*>}
 */
export const responseInterceptorPreErrorHandler = (error) => {
  let err = error.response;

  // In the case of a timeout, error.response is undefined, so we need to manually build an error object.
  if (error.code === 'ECONNABORTED') {
    err = {
      status: 'API Request Timeout',
      data: {
        id: error.code,
        errors: [
          {
            code: 'api.timeout'
          }
        ]
      }
    };
  }

  if (err == null) {
    err = {}; // Ensures that the error page renders.
  }
  Session.setItem(appconfig.storage.last_error, JSON.stringify(err));
  return Promise.reject(error.response);
};

/**
 * Checks for 401 status. The assumption with 401 is that we only receive it because of an invalid oauth token,
 * so clear out token from session storage and reload the page. This ideally should live early in the chain of interceptors,
 * so we can begin the reload phase as early as possible.
 *
 * @callback {ErrorResponseInterceptor}
 * @param {object} errorResponse: The error.response object from an HTTP response
 * @param [testOverride] Only used for the test suite because of challenges with overriding process.env.NODE_ENV
 * @returns {Promise.<*>}
 */
export const responseInterceptorAuthErrorHandler = (errorResponse, testOverride = false) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'test' || testOverride) {
    if (errorResponse.status === 401) {
      auth.clear();
      window.location.reload();
      return new Promise(() => {}); // pause other chained logic from happening since we are reloading the app
    }
  }
  return Promise.reject(errorResponse);
};

/**
 * Generic fallback error handling behavior: redirect the user to /error. This will handle any kind of
 * error, so this should be at the end of any chain of interceptors, if used at all.
 *
 * @callback {ErrorResponseInterceptor}
 */
export const responseInterceptorFallbackErrorHandler = (errorResponse) => {
  const errorRoute = appRoute.resolve('error');

  if (window.location.pathname !== errorRoute) {
    history.push(errorRoute);
  }

  return Promise.resolve(errorResponse);
};


// ------------------------------------
// Provider API
// ------------------------------------

/**
 * Create a baseline axios instance for use with the Billing Center. A baseline axios instance:
 *   - adds appropriate JSON-related headers to all requests
 *   - turns on CORS
 *   - sets a base URL for all api calls to use
 *   - if "interceptors" is not specified, then sets these default interceptors:
 *     - adds the authorization header for logged in user to all requests
 *     - adds an error response interceptor that filters the error object just to the response field
 *     - adds an error response interceptor that checks for and handles any 401 response
 *
 *   @param {string} baseURL: the base URL to use for this axios instance
 *   @param {InterceptorOptions} [interceptors]: if specified, use these interceptors instead of the defaults
 *   @param {Object} customHeaders: any custom headers needed to be sent in request header.
 *   @returns {axios.AxiosInstance}
 */
export const createBaselineInstance = (baseURL, interceptors, customHeaders = {}) => {
  const axiosInstance = axios.create({
    headers: {
      ...customHeaders,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: 66000, // One second longer than the maximum COG timeout, see @CPS-556
    baseURL
  });

  if (interceptors) {
    if (interceptors.requestInterceptors) {
      interceptors.requestInterceptors.forEach((interceptor) => {
        axiosInstance.interceptors.request.use(interceptor, (errorMsg) => Promise.reject(errorMsg));
      });
    }
    if (interceptors.responseInterceptors) {
      interceptors.responseInterceptors.forEach((interceptor) => {
        axiosInstance.interceptors.response.use((response) => response, interceptor);
      });
    }
  } else {
    axiosInstance.interceptors.request.use(requestInterceptorFactory(auth), (errorMsg) => Promise.reject(errorMsg));
    axiosInstance.interceptors.response.use((response) => response, responseInterceptorPreErrorHandler);
    axiosInstance.interceptors.response.use((response) => response, responseInterceptorAuthErrorHandler);
  }

  return axiosInstance;
};

/**
 * Add the specified handler(s) to the specified axios instance as response interceptor error handlers. Note the success handler will
 * just pass the response through. The error handlers will be extended to forward on an indication in their success path
 * that the error has been handled (see finalizeInterceptorChain).
 *
 * @param {axios.AxiosInstance} axiosInstance
 * @param {...ErrorResponseInterceptor} handlers
 */
export const addResponseInterceptorErrorHandlers = (axiosInstance, ...handlers) => {
  handlers.forEach((handler) => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (errorResponse) => handler(errorResponse).then(
        (errResp) => Promise.resolve({...errResp, lmiErrorHandled: true})
      )
    );
  });
};

/**
 * Final response interceptor in the chain. This should always be called with an axios instance after it has had all the desired interceptors added.
 *
 * If the promise resolved with a value that is an object with the property "lmiErrorHandled" on it, then
 * it forwards on a rejected promise with that error response so the rest of the app can also react to the failure. If there is no "lmiErrorHandler" (or the resolved value
 * is not an object), then we assume there were no errors and the resolved value is forwarded on as a success (resolved promise). If the promise is rejected, then no
 * interceptor handled this failure, and we also forward on that rejected promise to the rest of the app.
 */
export const finalizeInterceptorChain = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      if (typeof response === 'object' && response.lmiErrorHandled) {
        const errorResponse = {...response};
        delete errorResponse.lmiErrorHandled;
        return Promise.reject(errorResponse);
      }
      return Promise.resolve(response);
    },
    (errorResponse) => Promise.reject(errorResponse)
  );
};

/**
 * The default fallback axios instance that is exported by this module: it is a baseline instance which will later (after app bootstrapping) be augmented with error
 * handlers for 403 and a fallback error handler for all other errors. This is used by DAO modules that have not yet been updated to use a newer custom axios instance.
 * This is to help during the transition while some DAOs continue maintaining the current app-wide behavior where any error ends up on robot page (except for 401 and 403),
 * since they will use this instance by default.
 *
 * @type {axios.AxiosInstance}
 */
export const fallbackClient = createBaselineInstance(baseUrls.cog.url, '', {'x-cog-tenant': 'BPOR'});
export default fallbackClient;

/**
 * A second axios instance which, after app bootstrapping, does NOT include the fallback /error (robot) page handler that the fallbackClient has.
 *
 * @type {axios.AxiosInstance}
 */
export const customClient = createBaselineInstance(baseUrls.cog.url, '', {'x-cog-tenant': 'BPOR'});

/**
 * An axios instance which handles the scim network calls.
 *
 * @type {axios.AxiosInstance}
 */
export const scimClient = createBaselineInstance(baseUrls.scim.url);

/**
 * An axios instance which handles the extAdmin network calls.
 *
 * @type {axios.AxiosInstance}
 */
export const extAdminClient = createBaselineInstance(baseUrls.extAdmin.url);

/**
 * An axios instance which handles product settings calls.
 *
 * @type {axios.AxiosInstance}
 */
export const productSettingsClient = (product) => createBaselineInstance(baseUrls.productSetting[product].url, {
  requestInterceptors: [requestInterceptorFactory(auth)],
  responseInterceptors: [responseInterceptorPreErrorHandler]
});

/**
 * An axios instance which handles the Engagement Engine API calls.
 *
 * @type {axios.AxiosInstance}
 */
export const eeClient = createBaselineInstance(baseUrls.ee.url, {
  requestInterceptors: [requestInterceptorFactory(auth)],
  responseInterceptors: [responseInterceptorPreErrorHandler]
});
