import React from 'react';
import PropTypes from 'prop-types';
import classes from './badge.scss';

const BadgeView = (props) => (
  <span className={`${classes['badge-circle']} ${props.classNames}`}>
    <span>{props.children}</span>
  </span>
);

BadgeView.propTypes = {
  classNames: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default BadgeView;
