/**
 * Creates a stylesheet and injects it into the DOM Head.
 * You will need to invoke this in the reverse order of what cascade order you need
 * @param url
 * @param {number} [maxWait=3000] - How long to wait for the promise to resolve (in milliseconds) before rejecting.
 * @returns {Promise}
 */
const createSheet = (url, maxWait = 3000) => new Promise((resolve, reject) => {
  const sheet = document.createElement('link');
  sheet.type = 'text/css';
  sheet.rel = 'stylesheet';
  sheet.onload = resolve;
  sheet.onerror = reject;
  sheet.href = url;
  document.head.insertBefore(sheet, document.head.getElementsByTagName('link')[0].nextSibling);
  window.setTimeout(reject, maxWait);
});

/**
 * Creates a script and injects it into the DOM body.
 * @param url
 * @param {number} [maxWait=3000] - How long to wait for the promise to resolve (in milliseconds) before rejecting.
 * @returns {Promise}
 */
const createScript = (url, maxWait = 3000) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = url;
  script.onerror = reject;
  script.onload = resolve;
  document.body.appendChild(script);
  window.setTimeout(reject, maxWait);
});

export default {
  createScript,
  createSheet
};
