// ------------------------------------
// Constants
// ------------------------------------

// Event names
// Cancellation events
export const CancellationStart = 'CancellationStart';
export const CancellationConfirm = 'CancellationConfirm';
export const CancellationComplete = 'CancellationComplete';
export const FlowStart = 'FlowStart';
export const FlowExit = 'FlowExit';
export const RemoveCancelStart = 'RemoveCancelStart';
export const RemoveCancelConfirm = 'RemoveCancelConfirm';
export const RemoveCancelComplete = 'RemoveCancelComplete';
export const RemoveCancelExit = 'RemoveCancelExit';
export const RetentionStart = 'RetentionStart';
export const RetentionComplete = 'RetentionComplete';

// View and action events
export const AddToCart = 'AddToCart';
export const BillingInfo = 'BillingInfo';
export const Cancel = 'Cancel';
export const CancelCancel = 'CancelCancel';
export const Cart = 'Cart';
export const CartRemove = 'CartRemove';
export const CartUpdate = 'CartUpdate';
export const Checkout = 'Checkout';
export const DefaultCancellationConfirm = 'DefaultCancellationConfirm';
export const Err = 'Err';
export const ExternalLink = 'ExternalLink';
export const Invoices = 'Invoices';
export const Order = 'Order';
export const OrderConfirmation = 'OrderConfirmation';
export const PaymentMethodAdd = 'PaymentMethodAdd';
export const PaymentMethodOverviewView = 'PaymentMethodOverviewView';
export const PaymentMethodUpdate = 'PaymentMethodUpdate';
export const ProceedToCheckout = 'ProceedToCheckout';
export const ProductFamilies = 'ProductFamilies';
export const Revenue = 'Revenue';
export const StartTrial = 'StartTrial';
export const SubsConfig = 'SubsConfig';
export const SubscriptionGroups = 'SubscriptionGroups';
export const TrialExpirationReminder = 'TrialExpirationReminder';

// Checkout Optimization events
export const PromoLanding = 'PromoLanding';

// Google Tag Manger events
export const SessionInformation = 'session_information';
export const UserInformation = 'user_information';
export const ViewContent = 'view_content';
export const ViewItem = 'view_item';
export const ProductDetails = 'productDetails';
export const ClickCTA = 'click_cta';
export const AddToCartGTM = 'add_to_cart';
export const AddToCartGA4 = 'addToCart';
export const RemoveFromCartGTM = 'remove_from_cart';
export const RemoveFromCartGA4 = 'removeFromCart';
export const BeginCheckout = 'begin_checkout';
export const Purchase = 'purchase';
export const PurchaseError = 'error_purchase';
export const StartTrialFlow = 'start_trial_flow';
export const CompleteTrialFlow = 'complete_trial_flow';
export const StartBuyFlow = 'start_buy_flow';
export const CompleteBuyFlow = 'complete_buy_flow';
export const LoadForm = 'load_form';
export const AbandonForm = 'abandon_form';
export const SubmitForm = 'submit_form';
export const ErrorForm = 'error_form';

export const SubscriptionGroupsViewContent = 'SubscriptionGroupsViewContent';
export const InvoiceViewContent = 'InvoiceViewContent';
export const BillingInfoViewContent = 'BillingInfoViewContent';
export const CartViewContent = 'CartViewContent';
export const CheckoutViewContent = 'CheckoutViewContent';
export const ProductFamiliesViewContent = 'ProductFamiliesViewContent';
export const SubscriptionConfigViewContent = 'SubscriptionConfigViewContent';
export const OrderConfirmationViewContent = 'OrderConfirmationViewContent';
export const PaymentOverviewContent = 'PaymentOverviewContent';
export const ErrorViewContent = 'ErrorViewContent';

export const SubscriptionGroupsViewItem = 'SubscriptionGroupsViewItem';
export const ProductViewItem = 'ProductViewItem';


export const AddToCartCTA = 'AddToCartCTA';
export const CartRemoveCTA = 'CartRemoveCTA';
export const CartUpdateCTA = 'CartUpdateCTA';
export const ProceedToCheckoutCTA = 'CheckoutCTA';
export const SubscriptionCardAddToCartCTA = 'SubscriptionCardAddToCartCTA';
export const OrderCTA = 'OrderCTA';

export const AddressForm = 'AddressForm';
export const PaymentForm = 'PaymentForm';

export const CheckoutProcessStep2and3 = 'CheckoutProcessStep2and3';
export const CartUpdateGTM = 'CartUpdateGTM';

export const DisplaySwitchToGTA = 'DisplaySwitchToGTA';
export const LearnMoreGTA = 'LearnMoreGTA';
export const SwitchToGTA = 'SwitchToGTA';
export const PreviewGTA = 'PreviewGTA';
