const isNullOrUndefined = (input) => input == null || typeof input === 'undefined';
const isObject = (input) => typeof input === 'object';
const isStandardAction = (input) => !isNullOrUndefined(input) && isObject(input);

/**
 * A tracking middleware that will invoke handleTrackingEvent for every event name that is attached to an action
 *
 * @param {function} handleTrackingEvent
 * @returns {function}
 */
const trackingMiddleware = (handleTrackingEvent) => ({ getState }) => (next) => (action) => {
  if (!isStandardAction(action)) {
    return next(action);
  }

  const { meta: { tracking } = {} } = action;

  if (tracking) {
    Object.keys(tracking).forEach((eventName) => {
      const event = tracking[eventName];
      const extendedEvent = {
        payload: event,
        state: getState()
      };
      handleTrackingEvent(eventName, extendedEvent);
    });
  }
  return next(action);
};

export default trackingMiddleware;
