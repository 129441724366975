import {
  customClient as apiV2,
  default as api
} from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const quoteEndpoint = appconfig.cog_endpoints.quote;

export const daoQuoteInvoiceGet = (billingAccountKey, quoteKey) => api.get(`${quoteEndpoint(billingAccountKey, quoteKey)}/invoice`);
export const daoQuoteOrderPost = (billingAccountKey, quoteKey, payload) => apiV2.post(`${quoteEndpoint(billingAccountKey, quoteKey)}/orders`, payload);
export const daoQuoteImportantDatesGet = (billingAccountKey, quoteKey) => api.get(`${quoteEndpoint(billingAccountKey, quoteKey)}/importantdates?loadProduct=true`);

export default {
  daoQuoteInvoiceGet,
  daoQuoteOrderPost,
  daoQuoteImportantDatesGet
};
