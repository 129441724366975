import resolveRoute from 'lib/resolve-route';
import { isAuthorizedRole } from 'lib/security';
import Session from 'lib/session';
import {
  cybersourceRegionCountryCodes,
  PAYMENT_STATUS_GRACE_RENEWAL
} from './billing-account-constants';

// ------------------------------------
// Selectors
// ------------------------------------
export const billingAccountTree = (state) => state.billingAccount || {};
export const billingAccountIsLoading = (state) => billingAccountTree(state).isLoading;
export const billingAccountActive = (state) => {
  const billingAccounts = billingAccountTree(state).billingAccounts;
  if (!Array.isArray(billingAccounts)) {
    return {};
  }
  return billingAccounts.find((account) => account.key === billingAccountTree(state).activeBillingAccount) || {};
};
export const billingAccountActiveKey = (state) => billingAccountActive(state).key;
export const billingAccountRoles = (state) => billingAccountActive(state).roles || [];
export const billingAccountActiveAccountKey = (state) => billingAccountActive(state).accountKey || '';
export const billingAccountCurrency = (state) => billingAccountActive(state).currencyCode;
export const billingAccountCountryCode = (state) => billingAccountActive(state).tempCountry;
export const billingAccountCountryIsUS = (state) => billingAccountCountryCode(state) === 'US';
export const billingAccountCountryIsDE = (state) => billingAccountCountryCode(state) === 'DE';
export const billingAccountCountryIsGB = (state) => billingAccountCountryCode(state) === 'GB';
export const billingAccountCountryIsCA = (state) => billingAccountCountryCode(state) === 'CA';
export const billingAccountCurrencyIsUSD = (state) => billingAccountCurrency(state) === 'USD';
export const billingAccountCurrencyIsEUR = (state) => billingAccountCurrency(state) === 'EUR';
export const billingAccountCurrencyIsGBP = (state) => billingAccountCurrency(state) === 'GBP';
export const billingAccountCurrencyIsAUD = (state) => billingAccountCurrency(state) === 'AUD';
export const billingAccountCurrencyIsCAD = (state) => billingAccountCurrency(state) === 'CAD';
export const billingAccountCountryIsAU = (state) => billingAccountCountryCode(state) === 'AU';
export const billingAccountTaxNumberType = (state) => billingAccountActive(state).taxNumberType || '';
export const billingAccountTaxNumber = (state) => billingAccountActive(state).taxNumber || '';
export const billingAccountAllowedPaymentMethods = (state) => billingAccountActive(state).allowedPaymentMethodTypes || [];
export const billingAccountRestrictChangeOrder = (state) => billingAccountActive(state).restrictChangeOrder || false;
export const billingAccountRestrictNewOrder = (state) => billingAccountActive(state).restrictNewOrder || false;
export const billingAccountRestrictPaymentMethod = (state) => billingAccountActive(state).restrictPaymentMethod || false;
export const billingAccountRestrictLogin = (state) => billingAccountActive(state).restrictLogin || false;
export const billingAccountPaymentStatus = (state) => billingAccountActive(state).paymentStatus || '';
export const billingAccountPaymentStatusIsGraceRenewal = (state) => billingAccountPaymentStatus(state) === PAYMENT_STATUS_GRACE_RENEWAL;
export const billingAccountUserAuthorizedByRoles = (roles) => (state, ownProps) => {
  const unauthorizedRoute = resolveRoute.resolve('unauthorized');
  if ((ownProps.location && ownProps.location.pathname === unauthorizedRoute) || Session.getOriginalTarget() === unauthorizedRoute) {
    return false;
  }
  return !!isAuthorizedRole(roles, billingAccountRoles(state));
};

/**
 * Get the cybersource region given the billing account's region
 * @param {object} state
 * @returns {string} region key to lookup in environment under cybersource, e.g. environment.get('cybersource.regions.CA')
 */
export const billingAccountCybersourceRegion = (state) => {
  const countryCode = billingAccountCountryCode(state);
  if (cybersourceRegionCountryCodes.includes(countryCode)) {
    return countryCode;
  }
  return 'EU';
};
