import {
  meKey,
  meFirstName,
  meLastName,
  meEmail,
  meLocale
} from 'modules/me-module';
import {
  productByKeyIsTrial,
  productByKeyFamily
} from 'modules/product-module';
import {
  TRACKING_TXN_TYPE_TRIAL,
  TRACKING_TXN_TYPE_CONVERT,
  TRACKING_TXN_TYPE_NEW_BUY,
  TRACKING_TXN_TYPE_LAPSED_BUY,
  TRACKING_TXN_TYPE_CHANGE,
  TRACKING_TXN_TYPE_RETRIAL,
  default as channelTracker
} from 'lib/channel-tracker';
import {
  Cancel,
  CancelCancel,
  Order,
  SubsConfig
} from './tracking-constants';

/**
 * @typedef {object} TrackingChannelUserInfo
 *
 * @property {string} [key='']: user key from ME module
 * @property {string} [firstName='']: firstName from ME module
 * @property {string} [lastName='']: lastName from ME module
 * @property {string} [email='']: email from ME module
 * @property {string} [locale='']: locale value from ME module
 */

/**
 * @typedef {object} TrackingChannelOrderItem
 *
 * Tracking information that is derived from a quote item.
 *
 * @property {string} productFamilyKey: the NG comm canonical product family key for the product in this order item
 * @property {string} txnType: one of the tracking transaction type constants (TRACKING_TXN_TYPE_...)
 */

/**
 * @typedef {object} TrackingChannelOrderInfo
 *
 * Gather various information from other parts of state that relate to an order and to the user performing the order,
 * and conform to the standards of the channel tracking library.
 *
 * @property {TrackingChannelUserInfo} userInfo: information about the person performing the order
 * @property {TrackingChannelOrderItem[]} orderItems: information about each item in the order
 */

/**
 * This method has knowledge of the redux selectors that correspond to user data that may be of interest for tracking
 *
 * @param state
 * @returns {TrackingChannelUserInfo}
 */
export const trackingChannelUserInfo = (state) => ({
  key: meKey(state),
  firstName: meFirstName(state),
  lastName: meLastName(state),
  email: meEmail(state),
  locale: meLocale(state)
});

/**
 * This method has knowledge of the redux selectors that correspond to user data and the most recent quote that was generated
 * and then saved into the state tree. The assumption is that the quote corresponds to the order that needs tracking.
 *
 * @param state - redux state tree
 * @param {PayloadForOrderEvent} payload
 * @returns {TrackingChannelOrderInfo}
 */
export const trackingChannelOrderInfo = (state, payload) => ({
  userInfo: trackingChannelUserInfo(state),
  orderItems: payload.quoteItems.map((quoteItem) => {
    let txnType;
    // @TODO BPOR-821
    if (quoteItem.typeIsNew) {
      // new trial or new purchase
      if (productByKeyIsTrial(state, quoteItem.productKey)) {
        // change type is NEW and new subscription is trial, so this is a new trial
        txnType = TRACKING_TXN_TYPE_TRIAL;
      } else {
        // change type is NEW and new subscription is paid, so this is a new buy
        txnType = TRACKING_TXN_TYPE_NEW_BUY;
      }
    } else if (quoteItem.typeIsConvertTrial) {
      // change type is CONVERT_TRIAL so this is a CONVERT_TRIAL
      txnType = TRACKING_TXN_TYPE_CONVERT;
    } else if (quoteItem.typeIsReactivate) {
      // reactivate a trial or a paid subscription
      if (productByKeyIsTrial(state, quoteItem.productKey)) {
        // change type is REACTIVATE and new subscription is trial, so this is re-trial
        txnType = TRACKING_TXN_TYPE_RETRIAL;
      } else {
        // change type is REACTIVATE and new subscription is paid, so this is lapsed buy
        txnType = TRACKING_TXN_TYPE_LAPSED_BUY;
      }
    } else if (quoteItem.typeIsChange) {
      // change type is CHANGE, so this is a change plan
      txnType = TRACKING_TXN_TYPE_CHANGE;
    } else {
      // if none of the above, then this is adding an addon
      // @NOTE currently we don't have a channel tracking value to send for quoteItemChangeType ADD_ON.
      // @TODO BPOR-1330 to figure out whether addons should have quoteItemChangeType as ADD_ON or not
      txnType = '';
    }

    return {
      productFamilyKey: productByKeyFamily(state, quoteItem.productKey),
      txnType
    };
  })
});

export const channelTrackerTrackCancel = (eventName, {state, payload: {productFamilyKey}}) => (
  channelTracker.trackCancel(productFamilyKey, trackingChannelUserInfo(state))
);

export const channelTrackerTrackCancelCancel = (eventName, {state, payload: {productFamilyKey}}) => (
  channelTracker.trackCancelCancel(productFamilyKey, trackingChannelUserInfo(state))
);

export const channelTrackerTrackOrder = (eventName, {state, payload}) => (
  channelTracker.trackOrder(trackingChannelOrderInfo(state, payload))
);

export const channelTrackerAppendPromo = (eventName, {payload: {productFamilyKey, promoCodesQueryParam}}) => (
  // NOTE: we need to ensure channelTracker.visit is called before channelTracker.appendPromo
  channelTracker
    .visit(productFamilyKey)
    .then(() => {
      if (promoCodesQueryParam) {
        channelTracker.appendPromo(productFamilyKey, promoCodesQueryParam);
      }
    })
);

/**
 * The event details which will consist of the state and payload properties
 *
 * @typedef {object} EventDetails
 *
 * @property {object} state
 * @property {object/array} payload
 */

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, event) => {
  switch (eventName) {
    case Cancel: {
      channelTrackerTrackCancel(eventName, event);
      break;
    }
    case CancelCancel: {
      channelTrackerTrackCancelCancel(eventName, event);
      break;
    }
    case Order: {
      channelTrackerTrackOrder(eventName, event);
      break;
    }
    case SubsConfig: {
      channelTrackerAppendPromo(eventName, event);
      break;
    }
    default: {
      break;
    }
  }
};
