import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AppLink from 'components/app-link';
import Avatar from './avatar';
import ExternalAnchor from 'components/external-anchor';
import MainNavCartButton from './main-nav-cart-button';
import {
  NavLayout,
  NavBrandImage,
  NavContent,
  NavLinks,
  NavRight,
  NavDropdown
} from 'shared-components/nav';
import st from 'components/shared-translations';

export const MainNavView = (props) => {
  const intl = useIntl();
  const excludedIcons = ['G2M', 'G2W', 'G2T'];
  return (
    <NavLayout>

      <NavBrandImage label={intl.formatMessage(st['nav.hamburgertoggle'])}>
        {
          props.isAuthorized && <React.Fragment>
            <AppLink to="root" className="navbar-brand-lite" id="nav-root">
              {/*

              An accessibility label follows. This pattern is NOT recommended, but many of our nav styles are tightly coupled
              to the navbar-brand-lite class, so we can't do the more recommended option.
              For reference, the ideal would be: <span className="navbar-brand-lite" role="img" aria-label="LogMeIn" />,
              removing the navbar-brand-lite class from AppLink.
            */}
              <span role="img" aria-label="GoTo" />

            </AppLink>
            <div className="text-uppercase brand-name">{intl.formatMessage(st['shared.app.title'])}</div>
          </React.Fragment>
        }
        {
          !props.isAuthorized && <React.Fragment>
            <span className="navbar-brand-lite" />
            <div className="text-uppercase brand-name">{intl.formatMessage(st['shared.app.title'])}</div>
          </React.Fragment>
        }
      </NavBrandImage>
      <NavContent>
        {
          props.isAuthorized &&
            <NavLinks>
              <li>
                <AppLink to="subscriptions" activeClassName="active" id="nav-subscriptions">
                  {intl.formatMessage(st['shared.subscriptions'])}
                </AppLink>
              </li>
              <li>
                <AppLink to="invoices" activeClassName="active" id="nav-invoices">
                  {intl.formatMessage(st['shared.nav.invoicehistory'])}
                </AppLink>
              </li>
              <li>
                <AppLink to="paymentmethods" activeClassName="active" id="nav-paymentmethods">
                  {intl.formatMessage(st['shared.nav.paymentmethod'])}
                </AppLink>
              </li>
            </NavLinks>
        }
        <NavRight>
          {props.isAuthorized && <li><MainNavCartButton /></li>}
          <NavDropdown
            id="drop-down-help-menu"
            icon={<i className="togo-icon togo-icon-question-circle" />}
            label={intl.formatMessage(st['nav.help'])}
            ariaLabel={intl.formatMessage(st['nav.help'])}
          >
            <li className="faq-url">
              <ExternalAnchor href={props.faqUrl} role="menuitem" id="nav-faq">
                <i className="togo-icon togo-icon-list togo-dropdown-link-icon" />
                <span className="faq-text">{intl.formatMessage(st['shared.help.faq'])}</span>
              </ExternalAnchor>
            </li>
            <li className="contact-support">
              <ExternalAnchor href={props.supportUrl} role="menuitem" id="nav-support">
                <i className="togo-icon togo-icon-phone togo-dropdown-link-icon" />
                <span className="contact-support-text">{intl.formatMessage(st['nav.contactsupport'])}</span>
              </ExternalAnchor>
            </li>
          </NavDropdown>
          <NavDropdown
            id="navPersonalMenu"
            icon={<Avatar className="dropdown-avatar" id="navAvatar" />}
            ariaLabel={`${props.fullName} ${props.email}`}
            label={(
              <div className="dropdown-avatar-user-info">
                <div className="user-name" id="avatarUserName">{props.fullName}</div>
                <div className="user-email" id="avatarUserEmail">{props.email}</div>
              </div>
            )}
          >
            <li className="dropdown-menu-user-info">
              <Avatar id="navMenuAvatar" />
              <div>
                <div className="user-name" id="navMenuUserName">{props.fullName}</div>
                <div className="user-email" id="navMenuUserEmail">{props.email}</div>
              </div>
            </li>
            <li className="divider dropdown-user-info" />
            <li className="dropdown-first-menu-item">
              <ExternalAnchor href={props.myAccountUrl} role="menuitem" id="nav-myaccount">
                <i className="togo-icon togo-icon-person togo-dropdown-link-icon" />
                <span className="my-account-text">{intl.formatMessage(st['nav.dropdown.myaccount'])}</span>
              </ExternalAnchor>
            </li>
            <li className="personal-info">
              <ExternalAnchor href={props.personalInfoUrl} role="menuitem" id="nav-personal-info">
                <i className="togo-icon togo-icon-id-card togo-dropdown-link-icon" />
                <span className="personal-info-text">{intl.formatMessage(st['nav.dropdown.personalinfo'])}</span>
              </ExternalAnchor>
            </li>
            <li className="signin-security-url">
              <ExternalAnchor href={props.signinSecurityUrl} role="menuitem" id="nav-signin-security">
                <i className="togo-icon togo-icon-lock-closed togo-dropdown-link-icon" />
                <span className="signin-security-text">{intl.formatMessage(st['nav.dropdown.signin.security'])}</span>
              </ExternalAnchor>
            </li>
            <li className="admin-center-url">
              <ExternalAnchor href={props.adminCenterUrl} role="menuitem" id="nav-admin-centre">
                <i className="togo-icon togo-icon-people togo-dropdown-link-icon" />
                <span className="admin-center-text">{intl.formatMessage(st['nav.dropdown.admincenter'])}</span>
              </ExternalAnchor>
            </li>
            {
              props.isAuthorized &&
                <li className="billing-url">
                  <AppLink to="root" activeClassName="active" className="selected" role="menuitem" id="nav-billing">
                    <i className="togo-icon togo-icon-money togo-dropdown-link-icon" />
                    <span className="billing-text">{intl.formatMessage(st['nav.dropdown.billing'])}</span>
                  </AppLink>
                </li>
            }
            {props.products.length > 0 && <li className="divider" />}
            {
              props.products.map((product) => (
                <li key={product.name} className={excludedIcons.includes(product.iconClasses) ? product.iconClasses : ''}>
                  <ExternalAnchor
                    href={product.href}
                    role="menuitem"
                    id={`nav-${product.name}`}>
                    <i className={excludedIcons.includes(product.iconClasses) ? '' : `togo-icon ${product.iconClasses}`} />
                    {product.name}
                  </ExternalAnchor>
                </li>
              ))
            }
            <li className="divider" />
            <li className="logout-button">
              <AppLink to="logout" role="menuitem" id="nav-logout">
                <i className="togo-icon togo-icon-leave-meeting togo-dropdown-link-icon" />
                {intl.formatMessage(st['nav.dropdown.signout'])}
              </AppLink>
            </li>
          </NavDropdown>
        </NavRight>
      </NavContent>
    </NavLayout>
  );
};

MainNavView.propTypes = {
  adminCenterUrl: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  faqUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  myAccountUrl: PropTypes.string.isRequired,
  personalInfoUrl: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  signinSecurityUrl: PropTypes.string.isRequired,
  supportUrl: PropTypes.string.isRequired
};

export default MainNavView;
