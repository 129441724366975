import { daoMeGet } from 'dao/me-dao';
import { daoScimMeGet } from 'dao/scim-dao';
import { scimFamilyToCommerceFamily } from 'lib/product-catalog';

// ------------------------------------
// Constants
// ------------------------------------
export const ME_RECEIVE_GET_SUCCESS = 'ME_RECEIVE_GET_SUCCESS';
export const SCIM_ME_RECEIVE_GET_SUCCESS = 'SCIM_ME_RECEIVE_GET_SUCCESS';
export const ACCOUNT_ADMIN = 'acctadmin';
// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  key: '0',
  timezone: 'UTC',
  locale: 'en_US'
};

// ------------------------------------
// Selectors
// ------------------------------------

export const meTree = (state) => state.me || {};
export const meKey = (state) => meTree(state).key || '';
export const meFirstName = (state) => meTree(state).firstName || '';
export const meLastName = (state) => meTree(state).lastName || '';
export const meFullName = (state) => {
  const first = meFirstName(state);
  const last = meLastName(state);
  return ((first || last) && `${first} ${last}`) || '';
};
export const meEmail = (state) => meTree(state).email || '';
export const meLocale = (state) => meTree(state).locale;
export const meTimezone = (state) => meTree(state).timezone;
export const meLanguage = (state) => (meTree(state).locale) ? meTree(state).locale.split('_')[0] : 'en';
export const meEntitlements = (state) => meTree(state).entitlements || [];
export const meIsAccountAdmin = (state) => meTree(state).isAccountAdmin || false;
export const meIsLanguageEnglish = (state) => meLanguage(state) === 'en';
export const meIsLanguageDeutsch = (state) => meLanguage(state) === 'de';

// ------------------------------------
// Actions
// ------------------------------------
export const meReceiveGetSuccess = (payload = {}) => ({
  type: ME_RECEIVE_GET_SUCCESS,
  payload
});

export const scimMeReceiveGetSuccess = (payload = {}) => ({
  type: SCIM_ME_RECEIVE_GET_SUCCESS,
  payload
});

export const scimMeReceiveGetFailure = (payload = {}) => () => Promise.reject(payload);

export const meRequestGet = () => (dispatch) => daoMeGet()
  .then(
    (response) => dispatch(meReceiveGetSuccess(response.data))
  );

export const scimMeRequestGet = () => (dispatch) => daoScimMeGet()
  .then(
    (response) => dispatch(scimMeReceiveGetSuccess(response.data)),
    (err) => dispatch(scimMeReceiveGetFailure(err))
  );


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [ME_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (action.payload instanceof Object) {
      const {email, firstName, key, lastName, locale, timezone} = action.payload;
      return {
        ...state,
        email,
        firstName,
        key,
        lastName,
        locale: locale || initialState.locale,
        timezone: timezone || initialState.timezone
      };
    }
    return {...state};
  },
  [SCIM_ME_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (action.payload instanceof Object && Array.isArray(action.payload.entitlements)) {
      return {
        ...state,
        entitlements: action.payload.entitlements.reduce((userEntitlementList, scimProductFamilyKey) => {
          if (scimFamilyToCommerceFamily[scimProductFamilyKey]) {
            userEntitlementList.push(scimFamilyToCommerceFamily[scimProductFamilyKey]);
          }
          return userEntitlementList;
        }, []),
        isAccountAdmin: action.payload.entitlements.includes(ACCOUNT_ADMIN)
      };
    }
    return {...state};
  }
});

// ------------------------------------
// Reducer
// ------------------------------------
const meReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default meReducer;
