import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  productFamilyNameByKey
} from 'lib/product-catalog';
import {
  alertUnmatchedProductHide,
  alertUnmatchedProductFamilyHide
} from 'modules/alert-module';
import { productByKey } from 'modules/product-module';
import Selectors from 'store/selectors';
import AlertProductUnmatchedView from './alert-product-unmatched-view';

export const mapStateToProps = (state, ownProps) => ({
  experimentByUnmatchedKeyIsMultiproduct: Selectors.experimentByUnmatchedKeyIsMultiproduct(state, ownProps),
  getProductByKey: (productKey) => productByKey(state, productKey)
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  handleAlertDismiss: () => ownProps.unmatchedKey.productKey ? dispatch(alertUnmatchedProductHide()) : dispatch(alertUnmatchedProductFamilyHide())
});

export class AlertProductUnmatchedContainer extends Component {
  getProductFamilyNameByKey = (productFamilyKey) => productFamilyNameByKey(productFamilyKey);

  render = () => {
    const {
      unmatchedKey: { productKey = '', productFamilyKey = ''} = {},
      getProductByKey
    } = this.props;
    let productName;

    if (Object.prototype.hasOwnProperty.call(this.props.unmatchedKey, 'productKey')) {
      const { productDescription = ''} = getProductByKey(productKey);
      productName = productDescription;
    } else if (Object.prototype.hasOwnProperty.call(this.props.unmatchedKey, 'productFamilyKey')) {
      productName = this.getProductFamilyNameByKey(productFamilyKey);
    }

    if (!productName) {
      // If there is no valid product name, don't render the component
      return null;
    }

    return (
      <AlertProductUnmatchedView
        productName={productName}
        handleAlertDismiss={this.props.handleAlertDismiss}
        experimentIsMultiproduct={this.props.experimentByUnmatchedKeyIsMultiproduct}
      />
    );
  }
}

AlertProductUnmatchedContainer.propTypes = {
  experimentByUnmatchedKeyIsMultiproduct: PropTypes.bool.isRequired,
  getProductByKey: PropTypes.func.isRequired,
  handleAlertDismiss: PropTypes.func.isRequired,
  unmatchedKey: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertProductUnmatchedContainer);
