import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import st from 'components/shared-translations';
import Button from 'components/button';
import classes from './alert-try-switch-goto-admin.scss';
import { Modal } from 'reactstrap';
import ExternalAnchor from 'components/external-anchor';
import FeatureDetail from './feature-detail';
import { LearnMoreGTA, PreviewGTA, SwitchToGTA } from 'modules/tracking-module';

const AlertTrySwitchGotoAdminView = (props) => (
  <React.Fragment>
    <Modal
      isOpen={props.isModal}
      toggle={props.toggleModal}
      className={classes['alert-try-switch-modal']}
    >
      <div className={classes['alert-try-switch-modal-body']}>
        <i
          className={`togo-icon togo-icon-closes ${classes['alert-try-switch-modal-close']}`}
          onClick={props.toggleModal}
          role="button"
          tabIndex={0}
        />
        <h3 className={classes['alert-try-switch-modal-title']}>
          <FormattedMessage {...st['alert.try.switch.modal.title']} />
        </h3>
        <p className={classes['alert-try-switch-modal-body-message']}>
          <FormattedMessage {...st['alert.try.switch.modal.sub.title']} />
        </p>
        <hr className={classes['alert-try-switch-modal-divider']} />
        <FeatureDetail />
        <hr className={classes['alert-try-switch-modal-divider']} />
        <p className={classes['alert-try-switch-modal-body-message']}>
          <FormattedMessage {...st['alert.try.switch.modal.body']} />
        </p>
        <Button
          theme="tertiary"
          id="try-switch-gta"
          onClick={() => {
            props.trackGTA(SwitchToGTA);
            props.handleSwitchToGTA();
          }}
          className={classes['alert-try-switch-gta']}
          disabled={props.isPutAccountLoading}
          isLoading={props.isPutAccountLoading}
          wide
        >
          <FormattedMessage {...st['alert.try.switch.modal.primary.button']} />
        </Button>
        <ExternalAnchor
          href={`${props.gotoAdminURL}/${props.accountKey}/billing/subscriptions`}
          id="preview-gta"
          className={classes['alert-try-switch-preview-link']}
        >
          <Button
            theme="tertiary"
            id="try-switch-preview"
            onClick={() => {
              props.trackGTA(PreviewGTA);
              props.toggleModal();
            }}
            className={classes['alert-try-switch-preview']}
            wide
          >
            <FormattedMessage
              {...st['alert.try.switch.modal.secondary.button']}
            />
          </Button>
        </ExternalAnchor>
      </div>
    </Modal>
    <Alert
      type="danger"
      title={
        <div className={classes['alert-try-switch-body']}>
          <i
            className={`togo-icon togo-icon-info ${classes['alert-try-switch-info']}`}
          />
          <p className={classes['alert-try-switch-message']}>
            <FormattedMessage {...st['alert.try.switch.message']} />
          </p>
          <Button
            theme="tertiary"
            id="try-switch-learn-more"
            onClick={() => {
              props.trackGTA(LearnMoreGTA);
              props.toggleModal();
            }}
            className={classes['alert-try-switch-learn-more']}
          >
            <FormattedMessage {...st['alert.try.switch.learn.more']} />
          </Button>
        </div>
      }
    />
  </React.Fragment>
);

AlertTrySwitchGotoAdminView.propTypes = {
  isModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSwitchToGTA: PropTypes.func.isRequired,
  isPutAccountLoading: PropTypes.bool.isRequired,
  accountKey: PropTypes.string.isRequired,
  gotoAdminURL: PropTypes.string.isRequired,
  trackGTA: PropTypes.func.isRequired
};

export default AlertTrySwitchGotoAdminView;
