import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const invoicesEndpoint = appconfig.cog_endpoints.invoices;

export const daoInvoicesGet = (billingAccountKey) => api.get(invoicesEndpoint(billingAccountKey));
export const daoInvoiceGet = (billingAccountKey, invoiceKey) => api.get(`${invoicesEndpoint(billingAccountKey)}/${invoiceKey}`);

export default {
  daoInvoicesGet,
  daoInvoiceGet
};
