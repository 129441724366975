import {
  customClient as apiV2,
  default as api
} from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const subscriptionGroupsEndpoint = appconfig.cog_endpoints.subscriptionGroups;
const subscriptionEndpoint = appconfig.cog_endpoints.subscription;
const subscriptionActionEndpoint = appconfig.cog_endpoints.subscriptionAction;

export const daoSubscriptionGroupsGet = (billingAccountId) => apiV2.get(subscriptionGroupsEndpoint(billingAccountId));
export const daoSubscriptionGet = (billingAccountId, subscriptionKey) => api.get(subscriptionEndpoint(billingAccountId, subscriptionKey));
export const daoSubscriptionActionDelete = (billingAccountId, subscriptionKey) => api.delete(subscriptionActionEndpoint(billingAccountId, subscriptionKey));
export const daoSubscriptionActionPost = (billingAccountId, subscriptionKey, payload) => api.post(subscriptionActionEndpoint(billingAccountId, subscriptionKey), payload);

export default {
  daoSubscriptionGroupsGet,
  daoSubscriptionGet,
  daoSubscriptionActionDelete,
  daoSubscriptionActionPost
};
