import ProductCatalog from 'lib/product-catalog';
import {
  RELATIONSHIP_REQUIRE_ANY_PRODUCTS,
  RELATIONSHIP_UPGRADE
} from 'modules/product-module';
import SubscriptionModel from './subscription-model';

// ------------------------------------
// Comparators
// ------------------------------------
/**
 * Creates a comparator function that is useful for iterators of subscription models
 *
 * @param {string} subscriptionKey key used to test against input subscriptions
 */
const subscriptionByKeyComparator = (subscriptionKey) => (subscription) => subscription.key === subscriptionKey;

/**
 * Creates a comparator function that is useful for iterators of subscription models
 *
 * @param {string} productKey key used to test against input subscriptions
 */
const subscriptionByProductKeyComparator = (productKey) => (subscription) => subscription.productKey === productKey;

/**
 * Creates a comparator function that is useful for iterators of subscription models
 *
 * @param {string} productKey key used to test against input subscriptions
 */
const subscriptionByProductFamilyKeyComparator = (productFamilyKey) => (subscription) => subscription.productFamilyKey === productFamilyKey;

const subscriptionRelationshipProductKeys = (relationshipType) => (subscription) => {
  const { productKeys = [] } = subscription
    .relationships
    .find(({ type }) => type === relationshipType)
    || {};
  return productKeys;
};

const subscriptionSortByDaysRemaining = (a, b) => a.daysRemainingInTerm - b.daysRemainingInTerm;

// ------------------------------------
// Selectors
// ------------------------------------
const subscriptionTree = (state) => state.subscription || {};

/**
 * Returns a list of all subscription models
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionModels = (state) => subscriptionTree(state).subscriptionsV2 || [];

const subscriptionCards = (state) => subscriptionTree(state).cards || {};

export const subscriptionsAreLoading = (state) => subscriptionTree(state).isLoading;

/**
 * Returns a list of subscription models that have an active-like status. This
 * means that some subscriptions may be in a pending cancellation state
 *
 * @param {ApplicationShape} state current (redux) state
 */
const subscriptionsArray = (state) => {
  const subscriptions = subscriptionModels(state);
  const comparator = (subscription) => subscription.status === SubscriptionModel.SUBSCRIPTION_STATUS_ACTIVE;
  return subscriptions.filter(comparator);
};

/**
 * Returns an array with all the addons product keys that can be supported by
 * the currently active subscriptions
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAddonProductKeys = (state) => [...new Set((subscriptionsArray(state).map((sub) => sub.addonProductKeys) || []).flat())];

/**
 * Returns a subscription model by the ownProps.subscriptionKey value, or an empty
 * object literal if one is not found.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {object} ownProps input props, can have a subscriptionKey
 */
export const subscriptionModelByKey = (state, ownProps) => {
  const { subscriptionKey } = ownProps;
  return subscriptionModels(state)
    .find(subscriptionByKeyComparator(subscriptionKey))
    || {};
};

/**
 * Determines whether a subscription exists for the provided key.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} subscriptionKey key to test
 */
export const subscriptionByKeyExists = (state, subscriptionKey) => subscriptionModels(state)
  .some(subscriptionByKeyComparator(subscriptionKey));

export const subscriptionByKeyProductFamily = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).productFamilyKey || '';

export const subscriptionByKeyUpgradeProductKeys = (state, { subscriptionKey }) => {
  const subscription = subscriptionModelByKey(state, { subscriptionKey });
  const { productKeys } = subscription
    .relationships
    .find((relationship) => relationship.type === RELATIONSHIP_UPGRADE)
    || {};

  return productKeys || [];
};

/**
 * Used in components from the subscription card that need to send a payload to Tealium
 * when a change is made and added to the shopping cart.
 *
 * @param {object} Subscription key
 * @returns {object} Tealium payload
 */
export const subscriptionByKeySubscriptionCardTealiumPayload = (state, { subscriptionKey }) => {
  const subscription = subscriptionModelByKey(state, { subscriptionKey });
  return {
    productKey: subscription.productKey,
    productFamilyKey: subscription.productFamilyKey,
    productName: subscription.productDisplayName,
    promoCodes: subscription.promotionCodes
  };
};

export const subscriptionByKeyProductKey = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).productKey || '';

export const subscriptionByKeyProductName = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).productDescription || '';

export const subscriptionByKeyQuantity = (state, ownProps) => subscriptionModelByKey(state, ownProps).quantity || 1;

export const subscriptionByKeySubscriptionPricePerUnit = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).subscriptionPrice || '';
export const subscriptionByKeyBillingDuration = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).billingDuration || 1;
export const subscriptionByKeyBillingPeriod = (state, subscriptionKey) => subscriptionModelByKey(state, { subscriptionKey }).billingPeriod || '';
export const subscriptionByKeyBillingFrequency = (state, subscriptionKey) => ({
  billingPeriod: subscriptionByKeyBillingPeriod(state, subscriptionKey),
  billingDuration: subscriptionByKeyBillingDuration(state, subscriptionKey)
});

export const subscriptionByKeyIsActive = (state, subscriptionKey) => !!subscriptionModelByKey(state, { subscriptionKey }).isActive;

/**
 * Returns a list of product keys for the user's subscriptions. Filters for
 * subscriptions that are active (or pending cancellation).
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAllProductKeys = (state) => subscriptionModels(state)
  .map((subscription) => subscription.productKey);

// tealium selectors
/**
 * Returns a list of product families that the user currently has any status of
 * subscription for.
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAllProductFamilies = (state) => subscriptionModels(state)
  .map((subscription) => subscription.productFamilyKey);

/**
 * Returns a list of billing periods for subscriptions in any status.
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAllProductBillingPeriods = (state) => subscriptionModels(state)
  .map((subscription) => subscription.billingPeriod);

/**
 * Returns a list of billing durations for subscriptions in any status.
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAllProductBillingDurations = (state) => subscriptionModels(state)
  .map((subscription) => subscription.billingDuration);

/**
 * Returns a list of long product names (soon to be productDescription) for
 * subscriptions in any status.
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsAllProductLongNames = (state) => subscriptionModels(state)
  .map(({ productDescription }) => productDescription || '');

// tealium & telemetry selectors
export const subscriptionsAccountKey = (state) => subscriptionTree(state).accountKey || '';

export const subscriptionsActiveHavePaidProducts = (state) => (
  subscriptionsArray(state).some((subscription) => ProductCatalog.productTypeIsPaid(subscription.productType))
);

// Product family
// @NOTE: 'disabled' in this case refers to how they appear in the product family dropdown
export const subscriptionsByProductFamilyKeyPaid = (state, productFamilyKey) => subscriptionsArray(state)
  .filter(subscriptionByProductFamilyKeyComparator(productFamilyKey))
  .filter((subscription) => !subscription.isTrial);

/**
 * Returns a the first trial subscription that expires in the next 30 days,
 * sorted by daysRemainingInTerm.
 *
 * @param {ApplicationShape} state current (redux) state
 */
export const subscriptionsActiveTrialExpiringSoonestSubscriptionKey = (state) => {
  const activeTrialExpiringSoonest = subscriptionsArray(state)
    .filter((subscription) => subscription.isTrial)
    .filter((subscription) => subscription.daysRemainingInTerm < 30)
    .sort(subscriptionSortByDaysRemaining)[0]
    || {};

  if (activeTrialExpiringSoonest.key) {
    return activeTrialExpiringSoonest.key;
  }
  return '';
};

/**
 * Determines whether any of the provided loadingKeys are still loading or not based on the subscriptionKey
 *
 * @param {object} state
 * @param {object} - an object that has all the needed data other than the state.
 * @returns {boolean}
 */
export const subscriptionChangesLoadingKeyIsLoading = (state, {subscriptionKey, loadingKeys}) => {
  const subscriptionChangesLoadingKeys = (subscriptionTree(state).loading || {})[subscriptionKey] || [];
  return (loadingKeys || []).some((loadingKey) => subscriptionChangesLoadingKeys.includes(loadingKey));
};

export const subscriptionIsEditing = (state, subscriptionKey) => !!subscriptionCards(state)[subscriptionKey];
export const subscriptionHasChanged = (state, subscriptionKey) => {
  const subscriptionCard = subscriptionCards(state)[subscriptionKey];
  if (subscriptionCard && Object.keys(subscriptionCard).length) {
    return true;
  }
  return false;
};
export const subscriptionChanges = (state, subscriptionKey) => subscriptionCards(state)[subscriptionKey] || {};

// Multiproduct-ready selectors

/**
 * Returns a list of active (and _not_ pending cancel) subscriptions with the
 * specified productKey
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} productKey productKey to find
 */
export const subscriptionsActiveByProductKey = (state, productKey) => subscriptionsArray(state)
  .filter(subscriptionByProductKeyComparator(productKey))
  .filter((subscription) => !subscription.pendingActionIsCancel);

/**
 * Returns a list of all subscriptions with the input productKey.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} productKey productKey to find
 */
export const subscriptionsByProductKey = (state, productKey) => subscriptionModels(state)
  .filter(subscriptionByProductKeyComparator(productKey));

/**
 * Returns a list of all subscriptions with the input productFamilyKey.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} productKey productKey to find
 */
export const subscriptionsAllByProductFamilyKey = (state, productFamilyKey) => subscriptionModels(state)
  .filter(subscriptionByProductFamilyKeyComparator(productFamilyKey));

export const subscriptionByProductKeyExists = (state, productKey) => subscriptionsByProductKey(state, productKey).length > 0;

/**
 * Returns a list of subscriptions that are active addons that are required by the
 * parent product.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} productKey product to find active addons for
 */
export const subscriptionByProductKeyActiveAddonsRequiringProduct = (state, productKey) => {
  // Get all active addons
  const activeAddons = subscriptionModels(state)
    .filter((subscription) => subscriptionRelationshipProductKeys(RELATIONSHIP_REQUIRE_ANY_PRODUCTS)(subscription).length)
    .filter((subscription) => !subscription.pendingActionIsCancel);

  // Inspect the product keys they have in REQUIRE_ANY_PRODUCTS
  // Ensure that the current product key is among the list of REQUIRE_ANY_PRODUCTS
  return activeAddons.filter((subscription) => (
    subscriptionRelationshipProductKeys(RELATIONSHIP_REQUIRE_ANY_PRODUCTS)(subscription).includes(productKey)
  ));
};

/**
 * {Return} an [array] of the required product keys for any addons that require the product we are attempting to cancel if
 * the subscription with that product is the last one on the account that would fulfill the addons’ required products relationships.
 *
 * Otherwise {return} empty [array].
 *
 * We use this to decide when we need to show the user a modal during cancel flow warning them that if they cancel this
 * product (subscription), they will need to adjust  their addons which would now no longer work once the subscription expires.
 *
 * @param {object} state
 * @param {string} productKey - the productKey that is currently being cancelled
 * @returns {array}
 */

export const requiredProductKeysForAddonsByLastRequiredProductKey = (state, productKey) => {
  const flatten = (arr2d) => [].concat.apply(...arr2d);

  // Inspect the product keys they have in REQUIRE_ANY_PRODUCTS
  // Ensure that the currently cancelling product key is among the list of REQUIRE_ANY_PRODUCTS
  const activeAddonsRequiringCancellingProduct = subscriptionByProductKeyActiveAddonsRequiringProduct(state, productKey);

  if (activeAddonsRequiringCancellingProduct.length) {
    return flatten(activeAddonsRequiringCancellingProduct
      .map((subscription) => {
        const requiredProductKeys = subscriptionRelationshipProductKeys(RELATIONSHIP_REQUIRE_ANY_PRODUCTS)(subscription);
        const requiredSubsMatrix = requiredProductKeys
          .map((reqProductKey) => subscriptionsByProductKey(state, reqProductKey))
          .filter((subs) => subs.length);
        const activeRequiredProducts = flatten(requiredSubsMatrix)
          .filter((s) => !s.pendingActionIsCancel);
        // If only one subscription exists, then show the modal message.
        if (activeRequiredProducts.length === 1) {
          return requiredProductKeys;
        }
        return [];
      }));
  }
  return [];
};

/**
 * Returns a list of trial subscriptions by product family key sorted by
 * daysRemainingInTerm.
 *
 * @param {ApplicationShape} state current (redux) state
 * @param {string} productFamilyKey product family key to search for
 */
export const subscriptionByProductFamilyKeyTrialExpiringSoonest = (state, productFamilyKey) => (
  subscriptionModels(state)
    .filter(subscriptionByProductFamilyKeyComparator(productFamilyKey))
    .filter((subscription) => subscription.isTrial)
    .sort(subscriptionSortByDaysRemaining)[0] || {});
