import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import st from 'components/shared-translations';
import { FormattedMessage } from 'react-intl';

export const AlertPreconfiguration = (props) => (
  <Alert
    type="danger"
    title={<FormattedMessage {...st['alert.preconfiguration.title']} />}
    body={<FormattedMessage {...st['alert.preconfiguration.body']} />}
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertPreconfiguration.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertPreconfiguration;
