import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import DocumentTitle from 'react-document-title';
import { useIntl } from 'react-intl';
import st from 'components/shared-translations';

const titleKeys = [
  'shared.error',
  'shared.nav.invoicehistory',
  'importantdates.item.notfound',
  'shared.ordersummary',
  'shared.nav.paymentmethod',
  'shared.subscriptions'
] as const; // as const tells TSC this is a tuple
type TitleKeys = typeof titleKeys[number]; // using [number] tells TSC to index this by number

const documentTitlePropTypes = {
  titleKey: PropTypes.oneOf<TitleKeys>(titleKeys).isRequired
};

export type DocumentTitleProps = InferProps<typeof documentTitlePropTypes>

const DocumentTitleView: React.FC<DocumentTitleProps> = (
  ({titleKey, ...remainingProps}) => {
    const intl = useIntl();
    const title = [
      intl.formatMessage(st[titleKey]),
      intl.formatMessage(st['shared.app.title']),
      'GoTo'
    ].join(' — ');
    return (
      <DocumentTitle title={title} {...remainingProps} />
    );
  }
);

DocumentTitleView.propTypes = documentTitlePropTypes;

export default DocumentTitleView;
