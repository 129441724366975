import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { alertSepaSetupSuccessHide } from 'modules/alert-module';
import {
  paymentMethodsSepaMandateReference,
  paymentMethodsDefaultType,
  PAYMENT_METHODS_TYPE_SEPA
} from 'modules/payment-methods-module';
import AlertSepaSetupSuccessView from './alert-sepa-setup-success-view';

export const mapStateToProps = (state) => ({
  mandateReference: paymentMethodsSepaMandateReference(state),
  paymentMethodsDefaultType: paymentMethodsDefaultType(state)
});

export const mapDispatchToProps = {
  handleAlertDismiss: alertSepaSetupSuccessHide
};

export class AlertSepaSetupSuccessContainer extends Component {
  componentDidUpdate = (prevProps) => {
    // If the current payment method is not SEPA, then dismiss the banner
    if (
      prevProps.paymentMethodsDefaultType === PAYMENT_METHODS_TYPE_SEPA &&
      this.props.paymentMethodsDefaultType !== PAYMENT_METHODS_TYPE_SEPA
    ) {
      this.props.handleAlertDismiss();
    }
  };

  render = () => (
    <AlertSepaSetupSuccessView
      mandateReference={this.props.mandateReference}
      handleAlertDismiss={this.props.handleAlertDismiss}
    />
  );
}

AlertSepaSetupSuccessContainer.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  mandateReference: PropTypes.string.isRequired,
  paymentMethodsDefaultType: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertSepaSetupSuccessContainer);
