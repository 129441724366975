// ------------------------------------
// Selectors
// ------------------------------------
export const invoiceTree = (state) => state.invoice || {};
export const invoiceIsLoading = (state) => invoiceTree(state).isLoading;
export const invoices = (state) => invoiceTree(state).invoices || [];
export const invoiceItems = (state) => invoiceTree(state).invoice.items || [];
export const invoiceSubTotal = (state) => invoiceTree(state).invoice.subTotal || '0.0';
export const invoiceGrandTotal = (state) => invoiceTree(state).invoice.grandTotal || '0.0';
export const invoiceIsRefund = (state) => invoiceGrandTotal(state) < 0;
export const invoiceCurrencyCode = (state) => invoiceTree(state).invoice.currencyCode || '';
export const invoiceTotalTaxes = (state) => invoiceTree(state).invoice.totalTaxes || [];
export const invoiceTotalTaxesHasBreakdown = (state) => invoiceTotalTaxes(state).length > 1;
