import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import App from './app-view';
import history from 'lib/history';

export const AppContainer = (props) => (
  <Provider store={props.store}>
    <IntlProvider
      locale={props.languageCode}
      messages={props.reactIntlMessages}
      // We need to set the textComponent to span in order to keep the old behavior where the various react-intl APIs method would wrap the output with a span element
      textComponent="span"
    >
      <App history={history} />
    </IntlProvider>
  </Provider>
);

AppContainer.propTypes = {
  languageCode: PropTypes.string.isRequired,
  reactIntlMessages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default AppContainer;
