import {
  meKey,
  meLanguage,
  meLocale
} from 'modules/me-module';
import { billingAccountCountryCode } from 'modules/billing-account';
import {
  SessionInformation,
  UserInformation,
  ViewContent,
  SubscriptionGroupsViewContent,
  InvoiceViewContent,
  BillingInfoViewContent,
  CartViewContent,
  ClickCTA,
  AddToCartCTA,
  SubscriptionCardAddToCartCTA,
  CheckoutViewContent,
  ProceedToCheckoutCTA,
  OrderCTA,
  OrderConfirmationViewContent,
  ProductFamiliesViewContent,
  SubscriptionConfigViewContent,
  PaymentOverviewContent,
  ErrorViewContent,
  SubscriptionGroupsViewItem,
  ViewItem,
  ProductDetails,
  ProductViewItem,
  AddToCartGTM,
  AddToCartGA4,
  RemoveFromCartGTM,
  CartRemoveCTA,
  BeginCheckout,
  Purchase,
  PurchaseError,
  StartTrialFlow,
  CompleteTrialFlow,
  StartBuyFlow,
  CompleteBuyFlow,
  AddressForm,
  PaymentForm,
  CheckoutProcessStep2and3,
  CartUpdateGTM,
  RemoveFromCartGA4
} from './tracking-constants';
import Selectors from 'store/selectors';
import TrackGTMEvents from 'lib/gtm-tracker';
import {
  quoteModels,
  quoteAllProductBillingPeriods,
  quoteItemNewSubscriptionEffectivePrice,
  quoteItemNewSubscriptionProductType,
  quoteItemNewSubscriptionProductFamilyKey
} from '../quote';
import environment from 'lib/environment';

import {
  paymentMethodsDefaultProvider,
  paymentMethodsDefaultType
} from 'modules/payment-methods-module';

// send session information
export const trackSessionInfo = () => {
  const trackObject = {
    event: SessionInformation,
    session_last: undefined,
    session_number: undefined,
    session_type: undefined,
    is_logged_in: true
  };
  TrackGTMEvents(trackObject);
};

// send user information
export const trackUserInfo = (state) => {
  const trackObject = {
    event: UserInformation,
    account_id: meKey(state),
    user_id: meKey(state),
    plan: undefined
  };
  TrackGTMEvents(trackObject);
};

// basic details to send all view_content events
export const baseViewContent = (state) => ({
  event: ViewContent,
  platform_environment: environment.get('env') === 'default' ? 'prod' : 'dev',
  platform_product: 'ngc',
  platform_type: 'billing-portal',
  platform_locale: meLocale(state)?.toLowerCase(),
  platform_region: undefined,
  platform_country: billingAccountCountryCode(state)?.toLowerCase(),
  platform_language: meLanguage(state),
  section: 'billing-center',
  account_id: meKey(state),
  is_logged_in: true
});

// subscription page view content
export const trackSubscriptionGroupsViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'subscriptions',
    template: 'home'
  };
  TrackGTMEvents(trackObject);
};

// Invoice page view content
export const trackInvoicesViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'invoice-history',
    template: 'home'
  };
  TrackGTMEvents(trackObject);
};

// Payment Overview page view content
export const trackPaymentOverviewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'payment',
    template: 'home'
  };
  TrackGTMEvents(trackObject);
};

// Billing Info page view content
export const trackBillingInfoViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'payment',
    template: 'billing-information',
    form_name: 'Billing Information'
  };
  TrackGTMEvents(trackObject);
};

// Cart page view content
export const trackCartViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'cart',
    template: 'cart-overview'
  };
  TrackGTMEvents(trackObject);
};

// track checkout for new GA-4 event
const trackCheckoutGA4Event = (items, step) => {
  const newGAItems = items.map((item) => ({
    brand: item['item_brand'],
    category: `${item['item_category']}/${item['item_category2']}/${item['item_category3']}/${item['item_category4']}`,
    id: item['item_id'],
    quantity: item.quantity,
    name: item['item_name'],
    price: item.price,
    variant: item['item_variant']
  }));

  const trackObject = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step,
          action: 'checkout',
          option: undefined
        },
        products: newGAItems
      }
    }
  };
  TrackGTMEvents(trackObject);
};


// Checkout page view content
export const trackCheckoutViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'cart',
    template: 'checkout'
  };
  TrackGTMEvents(trackObject);

  // begin_checkout event step 4
  const items = Selectors.getCheckoutItemFromQuote(state);
  if (items.length) {
    const trackBeginCheckoutObject = {
      event: BeginCheckout,
      actionField: {step: 4},
      ecommerce: {
        items
      }
    };
    TrackGTMEvents(trackBeginCheckoutObject);

    // track checkout event
    trackCheckoutGA4Event(items, 4);
  }
};

// Send purchase event for GA4 event
const trackGA4PurchaseEvent = (payload) => {
  const { actionField, ecommerce } = payload;
  const ga4EventObject = {
    event: 'Purchase',
    ecommerce: {
      purchase: {
        actionField: {
          action: Purchase,
          coupon: undefined,
          id: actionField.transaction_id,
          revenue: actionField.revenue,
          shipping: actionField.shipping,
          tax: actionField.tax
        },
        products: ecommerce.items.map((item) => ({
          brand: item['item_brand'],
          category: `${item['item_category']}/${item['item_category2']}/${item['item_category3']}/${item['item_category4']}`,
          id: item['item_id'],
          quantity: item.quantity,
          name: item['item_name'],
          price: item.price,
          variant: item['item_variant'],
          coupon: item.coupon
        }))
      }
    }
  };
  TrackGTMEvents(ga4EventObject);
};

// Order confirmation page view content
export const trackOrderConfirmationViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'cart',
    template: 'order-confirmation'
  };
  TrackGTMEvents(trackObject);

  const paidProducts = quoteModels(state).filter((item) => quoteItemNewSubscriptionProductType(state, item.cartItemKey) !== 'TRIAL');
  // if paid products are present, send purchase and complete_buy_flow event
  if (paidProducts.length) {
    const allBillingPeriod = quoteAllProductBillingPeriods(state);
    const planList = [];
    // send monthly or annual plan in events only once.
    allBillingPeriod.forEach((billingPeriod) => {
      if (billingPeriod === 'MONTH' && !planList.includes('monthly')) {
        planList.push('monthly');
      } else if (billingPeriod === 'YEAR' && !planList.includes('annual')) {
        planList.push('annual');
      }
    });

    const payload = Selectors.getPurchaseData(state);
    const eventObject = {
      event: Purchase,
      ...payload,
      plan: planList.join(',')
    };
    TrackGTMEvents(eventObject);

    // track purchase event
    trackGA4PurchaseEvent(payload);

    const trackCompleteBuyFlowObject = {
      event: CompleteBuyFlow,
      platform_product: 'ngc',
      account_id: meKey(state),
      user_id: meKey(state),
      conversion_type: 'purchase',
      buy_type: 'add-on',
      plan: planList.join(','),
      payment_type: paymentMethodsDefaultProvider(state) || paymentMethodsDefaultType(state)
    };
    TrackGTMEvents(trackCompleteBuyFlowObject);
  }

  const trialProducts = quoteModels(state).filter((item) => quoteItemNewSubscriptionProductType(state, item.cartItemKey) === 'TRIAL');
  // For trial products send new event.
  if (trialProducts.length) {
    trialProducts.forEach((product) => {
      const eventObject = {
        event: CompleteTrialFlow,
        page_type: 'product_detail',
        account_id: meKey(state),
        user_id: meKey(state),
        conversion_type: 'trial',
        plan: 'daily',
        platform_product: quoteItemNewSubscriptionProductFamilyKey(state, product.cartItemKey)
      };
      TrackGTMEvents(eventObject);
    });
  }
};

// track product families view content
export const trackProductFamilesViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'subscription',
    template: 'products'
  };
  TrackGTMEvents(trackObject);

  // start_buy_flow event
  const trackStartBuyFlowObject = {
    event: StartBuyFlow,
    page_type: 'products',
    platform_product: 'ngc',
    account_id: meKey(state)
  };
  TrackGTMEvents(trackStartBuyFlowObject);
};

// track subscription configuration view content
export const trackSubscriptionConfigViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'subscription',
    template: 'configuration'
  };
  TrackGTMEvents(trackObject);
};

// track error view content
export const trackErrorViewContent = (state) => {
  const trackObject = {
    ...baseViewContent(state),
    sub_section: 'error',
    template: 'error page'
  };
  TrackGTMEvents(trackObject);
};

// track product details for new GA events
const trackProductDetailsGA4Event = (items) => {
  const newGAItems = items.map((item) => ({
    brand: item['item_brand'],
    category: `${item['item_category']}/${item['item_category2']}/${item['item_category3']}/${item['item_category4']}`,
    id: item['item_id'],
    name: item['item_name'],
    price: item.price,
    variant: item['item_variant']
  }));

  const trackObject = {
    event: ProductDetails,
    ecommerce: {
      detail: {
        actionField: {list: undefined, action: 'detail'},
        products: newGAItems
      }
    }
  };
  TrackGTMEvents(trackObject);
};

// track subscription group view items
export const trackSubscriptionGroupsViewItem = (state) => {
  const items = Selectors.subscriptionDataForGTMEvents(state);
  if (items.length) {
    const trackObject = {
      event: ViewItem,
      ecommerce: { items }
    };
    TrackGTMEvents(trackObject);

    // track product details in this page
    trackProductDetailsGA4Event(items);
  }
};

// track products in subscription configuration page
export const trackProductViewItem = (payload, state) => {
  const items = Selectors.productDataForGTMevents(payload, state);
  if (items.length) {
    const trackObject = {
      event: ViewItem,
      ecommerce: { items }
    };
    TrackGTMEvents(trackObject);

    // track product details in this page
    trackProductDetailsGA4Event(items);
  }
};

// track addToCart for new GA-4 event
const trackAddToCartGA4Event = (items) => {
  const newGAItems = items.map((item) => ({
    brand: item['item_brand'],
    category: `${item['item_category']}/${item['item_category2']}/${item['item_category3']}/${item['item_category4']}`,
    id: item['item_id'],
    list: undefined,
    quantity: item.quantity,
    name: item['item_name'],
    price: item.price,
    variant: item['item_variant']
  }));

  const trackObject = {
    event: AddToCartGA4,
    ecommerce: {
      add: {
        products: newGAItems
      },
      currencyCode: items[0].currency
    }
  };
  TrackGTMEvents(trackObject);
};

// track add to cart events and step 1 of the checkout process
const addToCartAndBeginCheckoutEvent = (state) => {
  const cartItems = Selectors.cartAddPayload(state);
  // add_to_cart event
  const trackAddToCartObject = {
    event: AddToCartGTM,
    ecommerce: {
      items: cartItems
    }
  };
  TrackGTMEvents(trackAddToCartObject);

  // track addToCart event
  trackAddToCartGA4Event(cartItems);

  const checkoutItems = cartItems.reduce((acm, item) => {
    const itemObj = { ...item };
    delete itemObj.currency;
    acm.push(itemObj);
    return acm;
  }, []);
  // Checkout step 1
  const trackBeginCheckoutObject = {
    event: BeginCheckout,
    actionField: {step: 1},
    ecommerce: {
      // Use the above cart addition payload but remove the currency
      items: checkoutItems
    }
  };
  TrackGTMEvents(trackBeginCheckoutObject);

  // track checkout event
  trackCheckoutGA4Event(checkoutItems, 1);
};

// Add to cart CTA
export const trackAddToCartCTA = (state) => {
  const trackCTAObject = {
    event: ClickCTA,
    cta_name: 'Add To Cart',
    cta_url: '/order'
  };
  TrackGTMEvents(trackCTAObject);

  // add to cart event
  addToCartAndBeginCheckoutEvent(state);
};

// proceed to checkout CTA.
export const trackCheckoutCTA = (state) => {
  const trackCTAObject = {
    event: ClickCTA,
    cta_name: 'Proceed To Checkout',
    cta_url: 'order/preview'
  };
  TrackGTMEvents(trackCTAObject);

  // add to cart event
  addToCartAndBeginCheckoutEvent(state);
};

// add to cart from subscription card
export const trackSubscriptionCardAddToCart = (state, cartItemKey) => {
  const trackCTAObject = {
    event: ClickCTA,
    cta_name: 'Add To Cart',
    cta_url: 'order/preview'
  };
  TrackGTMEvents(trackCTAObject);

  const cartItem = Selectors.cartAddOrRemovePayload(state, cartItemKey);
  const trackAddToCartObject = {
    event: AddToCartGTM,
    ecommerce: {
      items: [cartItem]
    }
  };
  TrackGTMEvents(trackAddToCartObject);

  // track addToCart event
  trackAddToCartGA4Event([cartItem]);

  // Checkout step 1
  const checkoutPayload = {...cartItem};
  delete checkoutPayload.currency;
  const trackBeginCheckoutObject = {
    event: BeginCheckout,
    actionField: {step: 1},
    ecommerce: {
      items: [checkoutPayload]
    }
  };
  TrackGTMEvents(trackBeginCheckoutObject);

  // track checkout event
  trackCheckoutGA4Event([checkoutPayload], 1);
};

// track step 2 & 3 of checkout
export const trackCheckoutProcessStep2and3 = (state) => {
  const items = Selectors.getCheckoutItemFromQuote(state);
  if (items.length) {
    let trackBeginCheckoutObject = {
      event: BeginCheckout,
      actionField: {step: 2},
      ecommerce: {
        items
      }
    };
    TrackGTMEvents(trackBeginCheckoutObject);

    // track checkout event
    trackCheckoutGA4Event(items, 2);

    trackBeginCheckoutObject = {
      ...trackBeginCheckoutObject,
      actionField: {step: 3}
    };
    TrackGTMEvents(trackBeginCheckoutObject);

    // track checkout event
    trackCheckoutGA4Event(items, 3);
  }
};

// track addToCart for new GA-4 event
const trackRemoveFromCartGA4Event = (items) => {
  const newGAItems = items.map((item) => ({
    brand: item['item_brand'],
    category: `${item['item_category']}/${item['item_category2']}/${item['item_category3']}/${item['item_category4']}`,
    id: item['item_id'],
    quantity: item.quantity,
    name: item['item_name'],
    price: item.price,
    variant: item['item_variant']
  }));

  const trackObject = {
    event: RemoveFromCartGA4,
    ecommerce: {
      remove: {
        products: newGAItems
      }
    }
  };
  TrackGTMEvents(trackObject);
};


// remove from cart event
export const trackRemoveFromCartCTA = (state, cartItemKey) => {
  const items = [Selectors.cartAddOrRemovePayload(state, cartItemKey)];
  if (items.length) {
    const trackCTAObject = {
      event: ClickCTA,
      cta_name: 'Delete',
      cta_url: '/order'
    };
    TrackGTMEvents(trackCTAObject);

    const trackRemoveFromCartObject = {
      event: RemoveFromCartGTM,
      ecommerce: {
        items
      }
    };
    TrackGTMEvents(trackRemoveFromCartObject);

    // track removeFromCart event
    trackRemoveFromCartGA4Event(items);
  }
};

/**
 * Iterate over cartItemsToPatch, get the initial values from the cartkey.
 * Then check for changes if only quantity has changed, check whether it has been increased or decreased.
 * Based on that we will send either add cart or remove cart event with difference between the payload.
 * If Billing Period is changed then first send remove cart event then send add cart event.
 * @param {object} state
 * @param {object} payload
 */
export const trackCartUpdate = (state, payload) => {
  const { cartItemsToPatch, initialPayload } = payload;
  const addToCartItem = [];
  const removeFromCartItem = [];

  cartItemsToPatch.forEach((cartItem) => {
    const item = initialPayload[cartItem.cartItemKey];
    // If only quantity change.
    if (item && cartItem.quantity && !cartItem.billingPeriod) {
      if (cartItem.quantity > item.quantity) {
        addToCartItem.push({ ...item, quantity: cartItem.quantity - item.quantity});
      } else {
        removeFromCartItem.push({ ...item, quantity: item.quantity - cartItem.quantity});
      }
    }
    // if only billing period or billing period and quantity change
    if (item && cartItem.billingPeriod) {
      removeFromCartItem.push({...item});
      addToCartItem.push({
        ...item,
        item_category3: cartItem.billingPeriod,
        quantity: cartItem.quantity || item.quantity,
        price: parseFloat(quoteItemNewSubscriptionEffectivePrice(state, cartItem.cartItemKey))
      });
    }
  });

  // first send remove cart event
  if (removeFromCartItem.length) {
    const trackRemoveFromCartObject = {
      event: RemoveFromCartGTM,
      ecommerce: {
        items: removeFromCartItem
      }
    };
    TrackGTMEvents(trackRemoveFromCartObject);

    // track removeFromCart event
    trackRemoveFromCartGA4Event(removeFromCartItem);
  }

  // secondly send add cart event
  if (addToCartItem.length) {
    const trackAddToCartObject = {
      event: AddToCartGTM,
      ecommerce: {
        items: addToCartItem
      }
    };
    TrackGTMEvents(trackAddToCartObject);

    // track addToCart event
    trackAddToCartGA4Event(addToCartItem);
  }
};

// place order clicked
export const trackOrderCTA = () => {
  const trackCTAObject = {
    event: ClickCTA,
    cta_name: 'Place Order',
    cta_url: 'order/confirmation'
  };
  TrackGTMEvents(trackCTAObject);
};

// Purchase error track
export const trackPurchaseError = (state, payload) => {
  const allBillingPeriod = quoteAllProductBillingPeriods(state);
  const planList = [];
  allBillingPeriod.forEach((billingPeriod) => {
    if (billingPeriod === 'MONTH' && !planList.includes('monthly')) {
      planList.push('monthly');
    } else if (billingPeriod === 'YEAR' && !planList.includes('annual')) {
      planList.push('annual');
    }
  });

  const trackCTAObject = {
    event: PurchaseError,
    conversion_type: 'purchase',
    buy_type: 'add-on',
    plan: planList.join(','),
    error_purchase_message: payload,
    payment_type: paymentMethodsDefaultProvider(state)
  };
  TrackGTMEvents(trackCTAObject);
};

export const trackTrialFlow = (state, payload) => {
  const trackCTAObject = {
    event: payload,
    page_type: 'product_detail',
    product_name: Selectors.uiSubsConfigSelectedProductName(state)
  };
  TrackGTMEvents(trackCTAObject);
};

export const trackForm = (state, payload) => {
  const trackCTAObject = { ...payload };
  TrackGTMEvents(trackCTAObject);
};

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, {payload, state}) => {
  switch (eventName) {
    case SessionInformation:
      trackSessionInfo();
      break;

    case UserInformation:
      trackUserInfo(state);
      break;

    case SubscriptionGroupsViewContent:
      trackSubscriptionGroupsViewContent(state);
      break;

    case InvoiceViewContent:
      trackInvoicesViewContent(state);
      break;

    case PaymentOverviewContent:
      trackPaymentOverviewContent(state);
      break;

    case BillingInfoViewContent:
      trackBillingInfoViewContent(state);
      break;

    case CartViewContent:
      trackCartViewContent(state);
      break;

    case CheckoutViewContent:
      trackCheckoutViewContent(state);
      break;

    case ProductFamiliesViewContent:
      trackProductFamilesViewContent(state);
      break;

    case SubscriptionConfigViewContent:
      trackSubscriptionConfigViewContent(state);
      break;

    case ErrorViewContent:
      trackErrorViewContent(state);
      break;

    case SubscriptionGroupsViewItem:
      trackSubscriptionGroupsViewItem(state);
      break;

    case ProductViewItem:
      trackProductViewItem(payload, state);
      break;

    case AddToCartCTA:
      trackAddToCartCTA(state);
      break;

    case SubscriptionCardAddToCartCTA:
      trackSubscriptionCardAddToCart(state, payload);
      break;

    case CartRemoveCTA:
      trackRemoveFromCartCTA(state, payload);
      break;

    case CartUpdateGTM:
      trackCartUpdate(state, payload);
      break;

    case OrderConfirmationViewContent:
      trackOrderConfirmationViewContent(state);
      break;

    case ProceedToCheckoutCTA:
      trackCheckoutCTA(state);
      break;

    case CheckoutProcessStep2and3:
      trackCheckoutProcessStep2and3(state);
      break;

    case OrderCTA:
      trackOrderCTA();
      break;

    case PurchaseError:
      trackPurchaseError(state, payload);
      break;

    case StartTrialFlow:
      trackTrialFlow(state, StartTrialFlow);
      break;

    case AddressForm:
      trackForm(state, payload);
      break;

    case PaymentForm:
      trackForm(state, payload);
      break;

    default:
      break;
  }
};
