import intersection from 'lodash/intersection';

/**
 * Intersects an array of required roles and an array of user roles
 * @param {array} requiredRoles
 * @param {array} userRoles
 * @returns {boolean}
 */
export const isAuthorizedRole = (requiredRoles, userRoles) => (
  Array.isArray(userRoles)
  && userRoles.length > 0
  && Array.isArray(requiredRoles)
  && requiredRoles.length > 0
  && intersection(requiredRoles, userRoles).length > 0
);

export default {
  isAuthorizedRole
};
