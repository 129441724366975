import { billingAccountTaxNumber } from 'modules/billing-account';

export const TAX_FORM_SHOW = 'TAX_FORM_SHOW';
export const TAX_FORM_HIDE = 'TAX_FORM_HIDE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  shown: false
};

// ------------------------------------
// Selectors
// ------------------------------------
export const taxFormTree = (state) => state.taxForm || {};
export const taxFormVisible = (state) => !billingAccountTaxNumber(state) ? true : taxFormTree(state).shown;

// ------------------------------------
// Actions
// ------------------------------------
export const taxFormShow = () => ({
  type: TAX_FORM_SHOW
});

export const taxFormHide = () => ({
  type: TAX_FORM_HIDE
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [TAX_FORM_SHOW]: (state) => ({
    ...state,
    shown: true
  }),
  [TAX_FORM_HIDE]: (state) => ({
    ...state,
    shown: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const taxFormReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default taxFormReducer;
