import {
  customClient as apiV2,
  default as api
} from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const billingAccountEndpoint = appconfig.cog_endpoints.billingAccount;

export const daoBillingAccountGet = (billingAccountKey) => api.get(billingAccountEndpoint(billingAccountKey));
export const daoBillingAccountPatch = (billingAccountKey, payload) => apiV2.patch(billingAccountEndpoint(billingAccountKey), payload);

export default {
  daoBillingAccountGet,
  daoBillingAccountPatch
};
