// See http://www.ietf.org/rfc/bcp/bcp47.txt for format that navigator.language || navigator.browserLanguage could return
export const getBrowserLocale = (currentWindow = window) => (currentWindow.navigator.language || currentWindow.navigator.browserLanguage || 'en').split('-').slice(0, 2).join('_');

export const updateLangAttribute = (userLanguageCode, currentWindow = window) => {
  const htmlElement = currentWindow.document.querySelector('html');
  htmlElement.setAttribute('lang', userLanguageCode);
};

export default {
  getBrowserLocale,
  updateLangAttribute
};
