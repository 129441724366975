import {
  IMPORTANT_DATES_RECEIVE_GET_SUCCESS,
  IMPORTANT_DATES_RECEIVE_GET_FAILURE,
  IMPORTANT_DATES_REQUEST_GET
} from './important-dates-action-types';
import { daoQuoteImportantDatesGet } from 'dao/quote-dao';

// ------------------------------------
// Actions
// ------------------------------------
export const importantDatesReceiveGetSuccess = (payload = {}) => ({
  type: IMPORTANT_DATES_RECEIVE_GET_SUCCESS,
  payload
});

export const importantDatesReceiveGetFailure = (payload = {}) => ({
  type: IMPORTANT_DATES_RECEIVE_GET_FAILURE,
  payload
});

export const importantDatesRequestGet = (billingAccountKey, quoteKey) => (dispatch) => {
  dispatch({
    type: IMPORTANT_DATES_REQUEST_GET
  });

  return daoQuoteImportantDatesGet(billingAccountKey, quoteKey)
    .then(
      (response) => dispatch(importantDatesReceiveGetSuccess(response.data)),
      (ex) => dispatch(importantDatesReceiveGetFailure(ex))
    );
};
