import {
  daoPaymentMethodsGet,
  daoPaymentMethodsPatch
} from 'dao/payment-methods-dao';
import { billingAccountAllowedPaymentMethods } from 'modules/billing-account';
import moment from 'moment-timezone';

// ------------------------------------
// Constants
// ------------------------------------
export const PAYMENT_METHODS_REQUEST_GET = 'PAYMENT_METHODS_REQUEST_GET';
export const PAYMENT_METHODS_RECEIVE_GET_SUCCESS = 'PAYMENT_METHODS_RECEIVE_GET_SUCCESS';
export const PAYMENT_METHODS_RECEIVE_GET_FAILURE = 'PAYMENT_METHODS_RECEIVE_GET_FAILURE';
export const PAYMENT_METHODS_REQUEST_POST = 'PAYMENT_METHODS_REQUEST_POST';
export const PAYMENT_METHODS_RECEIVE_POST_SUCCESS = 'PAYMENT_METHODS_RECEIVE_POST_SUCCESS';
export const PAYMENT_METHODS_RECEIVE_POST_FAILURE = 'PAYMENT_METHODS_RECEIVE_POST_FAILURE';
export const PAYMENT_METHODS_REQUEST_PATCH = 'PAYMENT_METHODS_REQUEST_PATCH';
export const PAYMENT_METHODS_RECEIVE_PATCH_SUCCESS = 'PAYMENT_METHODS_RECEIVE_PATCH_SUCCESS';
export const PAYMENT_METHODS_RECEIVE_PATCH_FAILURE = 'PAYMENT_METHODS_RECEIVE_PATCH_FAILURE';
export const PAYMENT_METHODS_TYPE_CREDIT_CARD = 'CREDIT_CARD';
export const PAYMENT_METHODS_TYPE_SEPA = 'SEPA';

// Credit card types
export const PAYMENT_METHODS_TYPE_CREDIT_CARD_VISA = 'VISA';
export const PAYMENT_METHODS_TYPE_CREDIT_CARD_MASTER_CARD = 'MASTER_CARD';
export const PAYMENT_METHODS_TYPE_CREDIT_CARD_DISCOVER = 'DISCOVER';
export const PAYMENT_METHODS_TYPE_CREDIT_CARD_AMEX = 'AMEX';

// LMI's supported credit card types
// See: https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=COM&title=COG+-+INFO+-+Supported+CC+Types
// @NOTE This object contains all of LMI's supported cards regardless of the region/country support
export const SUPPORTED_CREDIT_CARD_TYPES = {
  '001': PAYMENT_METHODS_TYPE_CREDIT_CARD_VISA,
  '002': PAYMENT_METHODS_TYPE_CREDIT_CARD_MASTER_CARD,
  '003': PAYMENT_METHODS_TYPE_CREDIT_CARD_AMEX,
  '004': PAYMENT_METHODS_TYPE_CREDIT_CARD_DISCOVER
};

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  paymentData: [],
  loading: {
    paymentMethodPost: false,
    paymentMethodGet: false,
    paymentMethodPatch: false
  },
  payerAuthEnrollmentData: {},
  paymentAccountKey: '',
  paymentMethodKey: '',
  isLoading: false
};

// ------------------------------------
// Selectors
// ------------------------------------
export const paymentMethodsTree = (state) => state.paymentMethods || {};
export const paymentMethodsLoading = (state) => paymentMethodsTree(state).loading || {};
export const paymentMethodsGetIsLoading = (state) => paymentMethodsLoading(state).paymentMethodGet;
export const paymentMethodsPostIsLoading = (state) => paymentMethodsLoading(state).paymentMethodPost;
export const paymentMethodsPatchIsLoading = (state) => paymentMethodsLoading(state).paymentMethodPatch;
export const paymentMethodsIsLoading = (state) => paymentMethodsTree(state).isLoading;
export const paymentMethodsData = (state) => paymentMethodsTree(state).paymentData || [];
export const paymentMethodsExist = (state) => paymentMethodsData(state) && paymentMethodsData(state).length > 0;
export const paymentMethodsDefault = (state) => paymentMethodsData(state)[0] || {};
export const paymentMethodsDefaultAddress = (state) => paymentMethodsDefault(state).address || {};
// @NOTE COG only validates US billing addresses which means validAddress and validAddressReason fields will only exist on US billing addresses
export const paymentMethodsDefaultAddressInvalid = (state) => paymentMethodsDefaultAddress(state).validAddress === false;
export const paymentMethodsCreditCardData = (state) => paymentMethodsDefault(state).creditCard || {};
export const paymentMethodsCreditCardLast4Digits = (state) => paymentMethodsExist(state) ? paymentMethodsCreditCardData(state).last4Digits : '';
export const paymentMethodsCreditCardIsExisting9sAffected = (state) => paymentMethodsExist(state) ? paymentMethodsCreditCardData(state).isExisting9sAffected : false;
export const paymentMethodsDefaultType = (state) => paymentMethodsExist(state) ? paymentMethodsDefault(state).type : '';
export const paymentMethodsCurrentBillingCountry = (state) => paymentMethodsDefaultAddress(state).country || '';
export const paymentMethodsBillingAddressFormInitialValues = (state) => ({
  firstName: paymentMethodsDefaultAddress(state).firstName || '',
  lastName: paymentMethodsDefaultAddress(state).lastName || '',
  addressLine1: paymentMethodsDefaultAddress(state).addressLine1 || '',
  addressLine2: paymentMethodsDefaultAddress(state).addressLine2 || '',
  state: paymentMethodsDefaultAddress(state).state || '',
  city: paymentMethodsDefaultAddress(state).city || '',
  country: paymentMethodsCurrentBillingCountry(state) || '',
  postalCode: paymentMethodsDefaultAddress(state).postalCode || '',
  phoneNumber: paymentMethodsDefaultAddress(state).phoneNumber || ''
});
export const paymentMethodsDefaultProvider = (state) => {
  if (!paymentMethodsExist(state)) {
    return '';
  }
  let provider = '';
  if (paymentMethodsDefault(state).type === PAYMENT_METHODS_TYPE_CREDIT_CARD) {
    provider = paymentMethodsCreditCardData(state).type;
  }
  return provider;
};

/**
 * Returns a payment method object or an empty object literal.
 *
 * @param {ApplicationShape} state current (redux) state of the application
 * @param {string} methodKey payment method key
 */
export const paymentMethodByKey = (state, methodKey) => paymentMethodsData(state)
  .find((paymentMethod) => paymentMethod.key === methodKey) || {};

export const paymentMethodsChoices = (state) => {
  const choices = [PAYMENT_METHODS_TYPE_CREDIT_CARD]; // available to everyone by default
  if (billingAccountAllowedPaymentMethods(state).includes(PAYMENT_METHODS_TYPE_SEPA)) {
    choices.push(PAYMENT_METHODS_TYPE_SEPA);
  }
  return choices;
};
export const paymentMethodsCreditCard = (state) => {
  const creditCardMatch = paymentMethodsData(state).find((method) => method.type === PAYMENT_METHODS_TYPE_CREDIT_CARD);
  return creditCardMatch || {address: {}, creditCard: {}};
};
export const paymentMethodsCreditCardAddress = (state) => paymentMethodsCreditCard(state).address || {};
export const paymentMethodsSepa = (state) => {
  const sepaMatch = paymentMethodsData(state).find((method) => method.type === PAYMENT_METHODS_TYPE_SEPA);
  return sepaMatch || {address: {}, sepa: {}};
};
export const paymentMethodsSepaAddress = (state) => paymentMethodsSepa(state).address || {};
export const paymentMethodsSepaDetails = (state) => paymentMethodsSepa(state).sepa || {};
export const paymentMethodsSepaMandateReference = (state) => paymentMethodsSepaDetails(state).mandateReference || '';
export const paymentMethodsSepaMandateReceivedDate = (state) => paymentMethodsSepaDetails(state).mandateReceivedDate || '';
export const paymentMethodsSepaIBANLast4Digits = (state) => {
  if (!paymentMethodsExist(state) || !paymentMethodsSepaDetails(state).iban) {
    return '';
  }
  const iban = paymentMethodsSepaDetails(state).iban;
  return iban.slice(iban.length - 4);
};
export const payerAuthEnrollmentData = (state) => paymentMethodsTree(state).payerAuthEnrollmentData || {};
export const cardinalAcsUrl = (state) => payerAuthEnrollmentData(state).acsUrl || '';
export const paymentAccountKey = (state) => paymentMethodsTree(state).paymentAccountKey || '';
export const paymentMethodsKey = (state) => paymentMethodsTree(state).paymentMethodKey || paymentMethodsDefault(state).key || '';
export const paymentMethodDefaultIsSepa = (state) => paymentMethodsDefault(state).type === PAYMENT_METHODS_TYPE_SEPA;
export const paymentMethodDefaultIsCreditCard = (state) => paymentMethodsDefault(state).type === PAYMENT_METHODS_TYPE_CREDIT_CARD;
export const paymentMethodDefaultIsExpired = (state) => paymentMethodsDefault(state).expired === true;
export const paymentMethodByKeyIsCreditCard = (state, paymentMethodKey) => paymentMethodByKey(state, paymentMethodKey).type === PAYMENT_METHODS_TYPE_CREDIT_CARD;
export const paymentMethodByKeyIsSepa = (state, paymentMethodKey) => paymentMethodByKey(state, paymentMethodKey).type === PAYMENT_METHODS_TYPE_SEPA;
export const paymentMethodByKeyIsExpired = (state, paymentMethodKey) => paymentMethodByKey(state, paymentMethodKey).expired === true;

// @NOTE we still need to have a default card type since we're still accepting unsupported card types, such as maestro.
// However, we can safely remove the default value once BPOR-1667 is completed
export const creditCardBrandByCardTypeNumber = (cardTypeNumber) => SUPPORTED_CREDIT_CARD_TYPES[cardTypeNumber] || SUPPORTED_CREDIT_CARD_TYPES['001'];
export const supportedCreditCardBrandNames = () => Object.values(SUPPORTED_CREDIT_CARD_TYPES);

// ------------------------------------
// Actions
// ------------------------------------
export const paymentMethodsReceiveGetSuccess = (payload = {}) => ({
  type: PAYMENT_METHODS_RECEIVE_GET_SUCCESS,
  payload
});

export const paymentMethodsReceiveGetFailure = (payload = {}) => ({
  type: PAYMENT_METHODS_RECEIVE_GET_FAILURE,
  payload
});

export const paymentMethodsRequestGet = (billingAccountKey) => (dispatch) => {
  dispatch({
    type: PAYMENT_METHODS_REQUEST_GET
  });

  return daoPaymentMethodsGet(billingAccountKey)
    .then(
      (response) => dispatch(paymentMethodsReceiveGetSuccess(response.data)),
      (ex) => dispatch(paymentMethodsReceiveGetFailure(ex))
    );
};

export const paymentMethodsReceivePostSuccess = (payload = {}) => ({
  type: PAYMENT_METHODS_RECEIVE_POST_SUCCESS,
  payload
});

export const paymentMethodsReceivePostFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PAYMENT_METHODS_RECEIVE_POST_FAILURE});

  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const paymentMethodsReceivePatchSuccess = (payload = {}) => ({
  type: PAYMENT_METHODS_RECEIVE_PATCH_SUCCESS,
  payload
});

export const paymentMethodsReceivePatchFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PAYMENT_METHODS_RECEIVE_PATCH_FAILURE});

  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const paymentMethodsRequestPatch = (billingAccountKey, paymentMethodKey, payload = {}) => (dispatch) => {
  dispatch({
    type: PAYMENT_METHODS_REQUEST_PATCH
  });

  return daoPaymentMethodsPatch(billingAccountKey, paymentMethodKey, payload)
    .then((response) => {
      const patchSuccessData = dispatch(paymentMethodsReceivePatchSuccess(response.data));
      dispatch(paymentMethodsRequestGet(billingAccountKey));
      // @NOTE we need to always return the return value of paymentMethodsReceivePatchSuccess since consumers are expecting its response shape
      return patchSuccessData;
    })
    .catch((ex) => dispatch(paymentMethodsReceivePatchFailure(ex)));
};

// ------------------------------------
// Action Handlers Helpers
// ------------------------------------
const isPaymentMethodExpired = (paymentMethod) => {
  if (paymentMethod.type === PAYMENT_METHODS_TYPE_CREDIT_CARD) {
    const {creditCard: {expirationMonth, expirationYear}} = paymentMethod;
    const creditCardExpirationDate = moment(`${expirationMonth}/01/${expirationYear}`, 'MM-DD-YYYY').add(1, 'month');
    const currentDate = moment();

    if (currentDate.isAfter(creditCardExpirationDate)) {
      return true;
    }
  }
  return false;
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [PAYMENT_METHODS_REQUEST_GET]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodGet: true
    },
    isLoading: true
  }),
  [PAYMENT_METHODS_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        paymentData: action.payload.map((paymentMethod) => ({
          key: paymentMethod.key,
          billingAccountKey: paymentMethod.billingAccountKey,
          type: paymentMethod.type,
          address: paymentMethod.address,
          creditCard: paymentMethod.creditCard,
          sepa: paymentMethod.sepa,
          expired: isPaymentMethodExpired(paymentMethod)
        })),
        loading: {
          ...state.loading,
          paymentMethodGet: false
        },
        isLoading: false
      };
    }
    return {
      ...state,
      loading: {
        ...state.loading,
        paymentMethodGet: false
      },
      isLoading: false
    };
  },
  [PAYMENT_METHODS_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodGet: false
    },
    isLoading: false
  }),
  [PAYMENT_METHODS_REQUEST_POST]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodPost: true
    },
    isLoading: true
  }),
  [PAYMENT_METHODS_RECEIVE_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        payerAuthEnrollmentData: action.payload?.flexResponse?.payerAuthEnrollmentResult,
        paymentAccountKey: action.payload?.paymentAccountKey,
        paymentMethodKey: action.payload?.flexResponse?.paymentMethodKey,
        loading: {
          ...state.loading,
          paymentMethodPost: false
        },
        isLoading: false
      };
    }
    return {
      ...state,
      payerAuthEnrollmentData: {},
      paymentAccountKey: '',
      paymentMethodKey: '',
      loading: {
        ...state.loading,
        paymentMethodPost: false
      },
      isLoading: false
    };
  },
  [PAYMENT_METHODS_RECEIVE_POST_FAILURE]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodPost: false
    },
    isLoading: false
  }),
  [PAYMENT_METHODS_REQUEST_PATCH]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodPatch: true
    },
    isLoading: true
  }),
  [PAYMENT_METHODS_RECEIVE_PATCH_SUCCESS]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodPatch: false
    },
    isLoading: false
  }),
  [PAYMENT_METHODS_RECEIVE_PATCH_FAILURE]: (state) => ({
    ...state,
    loading: {
      ...state.loading,
      paymentMethodPatch: false
    },
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const paymentMethodsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default paymentMethodsReducer;
