import * as LDClient from 'ldclient-js';
import { v4 as uuidv4 } from 'uuid';
import Amplitude from 'lib/amplitude';

export class LaunchDarkly {
  constructor(library) {
    this.library = library;
    this.handleChange = () => {};
    this.ready = false;
  }

  /**
   * Update the flags using the provided handler.
   * Gets the new value from the client or uses the passed in value
   * @param {object} flags
   */
  updateFlags = (flags) => {
    const updatedFlags = {};
    for (const flag in flags) {
      if (Object.prototype.hasOwnProperty.call(flags, flag)) {
        updatedFlags[flag] = (this.ready) ? this.client.variation(flag) : flags[flag];
      }
    }
    this.handleChange(updatedFlags);
  };

  /**
   * Sets the function that will fired off when the client instance receives change events for flags.
   * @param {function} handler
   */
  setChangeHandler = (handler) => {
    if (typeof handler === 'function') {
      this.handleChange = (flags) => {
        // Send the experiment flags to Amplitude on first visit and any time a dynamic change is made to a flag in LD.
        Amplitude.identify({ experiments: flags });
        handler(flags);
      };
    }
  }

  /**
   * Sets up event listeners on the provided flags.
   * When the event is emitted the change handler will fire off with the new values
   * @param {object} flags
   */
  subscribeToChanges = (flags) => {
    for (const flag in flags) {
      if (Object.prototype.hasOwnProperty.call(flags, flag)) {
        this.client.on(`change:${flag}`, this.subscriberForKey(flag));
      }
    }
  }

  /**
   * Creates a callback function that will be registered with the event listener from the client library.
   * @param {object} flag
   */
  subscriberForKey = (flag) => (current) => {
    const newFlag = {};
    newFlag[flag] = current;
    this.handleChange(newFlag);
  }

  /**
   * Creates a callback for when the client library emits the ready event
   * @param {object} flags
   * @param {function} callback
   */
  onClientReady = (flags, callback = () => {}) => () => {
    this.ready = true;
    this.updateFlags(flags);
    this.subscribeToChanges(flags);
    callback();
  }

  /**
   * Attaches a given callback to the flag change event
   * @param {string} flag
   * @param {function} callback
   */
  onFlagChange = (flag, callback) => {
    this.client.on(`change:${flag}`, (value, previousValue) => {
      if (value !== previousValue) {
        callback(value, previousValue);
      }
    });
  }

  /**
   * Call LaunchDarkly SDK .track() method, to track custom events/goals
   * @param {string} goal - the custom goal being tracked
   */
  track = (goal) => {
    if (!this.client) {
      return;
    }
    this.client.track(goal);
  }

  /**
   * Initializes the client with the provided settings
   * @param {string} clientId
   * @param {object} user
   * @param {object} flags
   * @param {function} onChange
   * @param {function} onReady
   * @param {object} options
   */
  initialize = ({ clientId, user, flags, onChange, onReady, options }) => {
    // Setup the onChange handler
    if (onChange) {
      this.setChangeHandler(onChange);
    }

    // Set the user object to the default with the required `key` field
    let userData = {
      key: uuidv4()
    };
    // If the passed in user object has the required `key` field we will use that instead
    if (user && Object.prototype.hasOwnProperty.call(user, 'key')) {
      userData = user;
    }

    // Initialize the LD client instance with our data
    this.client = this.library.initialize(
      clientId,
      userData,
      options
    );

    // Setup event listener on client
    this.client.on('ready', this.onClientReady(flags, onReady));
  }
}

export default new LaunchDarkly(LDClient);
