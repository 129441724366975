import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  injectIntl
} from 'react-intl';
import TextField from 'components/text-field';
import FormField from 'components/form-field';
import st from 'components/shared-translations';
import SelectField from 'components/select-field';
import classes from './billing-information.scss';
import { billingInformationFieldsNamesProps } from 'modules/form';
import { intlProps } from 'modules/intl';

const BillingInformationView = (props) => {
  const postalCodeLabel = `${props.intl.formatMessage(st[`shared.input.postalcode.${props.selectedCountryValue}`] || st['shared.input.postalcode.US'])}${props.isPostalCodeOptional ? ` ${props.intl.formatMessage(st['shared.input.optional'])}` : ''}`;
  const stateLabel = props.intl.formatMessage(st[`shared.input.state.${props.selectedCountryValue}`] || st['shared.input.state.US']);
  const cityLabel = props.intl.formatMessage(st[`shared.input.city.${props.selectedCountryValue}`] || st['shared.input.city.US']);

  return (
    <div className="col-md col-12 pr-4">
      <h5 className="font-weight-bold mb-2"><FormattedMessage {...st['shared.input.billinginformation']} /></h5>
      <div className="row">
        <div className="col">
          <FormField
            formName={props.formId}
            id="first-name"
            label={props.intl.formatMessage(st['shared.input.firstname'])}
            name={props.billingInformationFieldsNames.FORM_FIELD_FIRST_NAME}
            component={TextField}
            maxLength="60"
          />
        </div>
        <div className="col">
          <FormField
            formName={props.formId}
            id="last-name"
            label={props.intl.formatMessage(st['shared.input.lastname'])}
            name={props.billingInformationFieldsNames.FORM_FIELD_LAST_NAME}
            component={TextField}
            maxLength="60"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormField
            formName={props.formId}
            id="address-line-1"
            label={props.intl.formatMessage(st['shared.input.addressline1'])}
            name={props.billingInformationFieldsNames.FORM_FIELD_ADDRESS_LINE_1}
            component={TextField}
            className={classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_ADDRESS_LINE_1]]}
            maxLength="60"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormField
            formName={props.formId}
            id="address-line-2"
            label={props.intl.formatMessage(st['shared.input.addressline2'])}
            name={props.billingInformationFieldsNames.FORM_FIELD_ADDRESS_LINE_2}
            className={classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_ADDRESS_LINE_2]]}
            component={TextField}
            maxLength="60"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormField
            formName={props.formId}
            id={props.billingInformationFieldsNames.FORM_FIELD_CITY}
            label={cityLabel}
            name={props.billingInformationFieldsNames.FORM_FIELD_CITY}
            component={TextField}
            className={classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_CITY]]}
            maxLength="50"
          />
        </div>
        <div className="col">
          <FormField
            label={props.intl.formatMessage(st['shared.input.country'])}
            formName={props.formId}
            id={props.billingInformationFieldsNames.FORM_FIELD_COUNTRY}
            name={props.billingInformationFieldsNames.FORM_FIELD_COUNTRY}
            component={SelectField}
            className={`custom-select ${classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_COUNTRY]]}`}
            disabled={props.countryDropdownDisabled}
          >
            <option value="" disabled>
              {props.intl.formatMessage(st['shared.input.country'])}
            </option>
            {!props.paymentInfoSelectedTypeIsSepa && props.countryKeys.map((countryKey) => (
              <option key={countryKey} value={countryKey}>{props.intl.formatMessage(st[`shared.country.${countryKey}`])}</option>
            ))}
            {props.paymentInfoSelectedTypeIsSepa && <option value="DE">{props.intl.formatMessage(st['shared.country.DE'])}</option>}
          </FormField>
        </div>
      </div>
      <div className="row">
        {
          props.showStateField &&
            <div className="col-6">
              <FormField
                formName={props.formId}
                id={props.billingInformationFieldsNames.FORM_FIELD_STATE}
                name={props.billingInformationFieldsNames.FORM_FIELD_STATE}
                label={stateLabel}
                className={`custom-select ${classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_STATE]]}`}
                component={SelectField}
              >
                <option value="" disabled>
                  {stateLabel}
                </option>
                {props.countryStateCodes.map((stateCode) => (
                  <option key={stateCode} value={stateCode}>
                    {props.intl.formatMessage(st[`shared.country.${props.selectedCountryValue}.state.${stateCode}`])}
                  </option>
                ))}
              </FormField>
            </div>
        }
        <div className="col-6">
          <FormField
            formName={props.formId}
            id="postal-code"
            label={postalCodeLabel}
            name={props.billingInformationFieldsNames.FORM_FIELD_POSTAL_CODE}
            component={TextField}
            className={classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_POSTAL_CODE]]}
            maxLength="10"
          />
        </div>
        <div className="col-6">
          <FormField
            formName={props.formId}
            id="phone-number"
            label={props.intl.formatMessage(st['shared.input.phonenumber'])}
            name={props.billingInformationFieldsNames.FORM_FIELD_PHONE_NUMBER}
            component={TextField}
            className={classes[props.addressFieldClassNames[props.billingInformationFieldsNames.FORM_FIELD_PHONE_NUMBER]]}
            type="tel"
            maxLength="20"
          />
        </div>
      </div>
    </div>
  );
};

BillingInformationView.propTypes = {
  addressFieldClassNames: PropTypes.object.isRequired,
  billingInformationFieldsNames: billingInformationFieldsNamesProps.isRequired,
  countryDropdownDisabled: PropTypes.bool.isRequired,
  countryKeys: PropTypes.array.isRequired,
  countryStateCodes: PropTypes.array.isRequired,
  formId: PropTypes.string.isRequired,
  showStateField: PropTypes.bool.isRequired,
  paymentInfoSelectedTypeIsSepa: PropTypes.bool.isRequired,
  intl: intlProps.isRequired,
  selectedCountryValue: PropTypes.string.isRequired,
  isPostalCodeOptional: PropTypes.bool.isRequired
};

export default injectIntl(BillingInformationView);
