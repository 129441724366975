import {
  customClient as api,
  productSettingsClient
} from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const productSettingEndpoint = appconfig.product_setting_endpoints.settings;

export const daoProductsByProductKeysGet = (productKeys, countryCode, promoCodes, locale) => {
  const keyOrKeys = (Array.isArray(productKeys)) ? productKeys.join(',') : productKeys;
  return api.get(appconfig.cog_endpoints.productsByProductKeys(keyOrKeys, countryCode, Array.isArray(productKeys), promoCodes, locale));
};
export const daoProductsBySubscriptionKeyGet = (subscriptionKey, billingAccountKey, promoCodes, locale) => (
  api.get(appconfig.cog_endpoints.productsBySubscriptionKey(subscriptionKey, billingAccountKey, promoCodes, locale))
);
export const daoProductsByProductFamilyKeyGet = (productFamilyKey, billingAccountKey, promoCodes, locale) => (
  api.get(appconfig.cog_endpoints.productsByProductFamilyKey(productFamilyKey, billingAccountKey, promoCodes, locale))
);
export const daoProductFamiliesGet = (countryCode, billingAccountKey) => (
  api.get(appconfig.cog_endpoints.productFamilies(countryCode, billingAccountKey))
);

// Needs to be removed once FlatRate is migrated to Global Call me.
export const daoProductRelationsGet = (productKey) => api.get(appconfig.cog_endpoints.productRelationship(productKey));

export const daoProductSettingGet = (productFamilyKey) => productSettingsClient(productFamilyKey).get(productSettingEndpoint);

export default {
  daoProductsByProductKeysGet,
  daoProductsBySubscriptionKeyGet,
  daoProductsByProductFamilyKeyGet,
  daoProductFamiliesGet,
  daoProductRelationsGet,
  daoProductSettingGet
};
