import environment from 'lib/environment';

let libLoadedPromise;

/**
 * Load the Tealium JS into global scope (that's how it currently works).
 *
 * @param {HTMLDocument} doc: the document to write the <script> tag to
 * @param {Environment} env: the environment from which to get configuration
 * @returns {Promise} Promise that resolves when all Tealium tracking libraries have been successfully loaded; rejects otherwise
 */
export const loadTealiumTrackerLib = (doc, env) => {
  const url = env.get('tealium.url');
  // only load the library once
  if (libLoadedPromise) {
    return libLoadedPromise;
  }

  libLoadedPromise = new Promise((resolve, reject) => {
    const apiScript = doc.createElement('script');
    apiScript.type = 'text/javascript';
    apiScript.async = true;
    apiScript.onload = resolve;
    apiScript.onerror = () => {
      // @TODO BPOR-599 send a JS error event once we have JS error tracking figured out
      reject(`tealium tracking javascript resource failed to load: ${url}`);
    };
    apiScript.src = url;
    doc.head.appendChild(apiScript);
  });

  return libLoadedPromise;
};

/**
 * Convenience interface for working with the Tealium analytics platform.
 *
 * After the external library has loaded, there will be a utag object available on the global window object.
 */
export class TealiumTrackerWrapper {
  /**
   * @param {HTMLDocument} [doc]: the document to write the <script> tag to
   * @param {Environment} [env]: the environment from which to get configuration
   * @param {Window} [win]: the global window scope where Tealium inserts itself
   * @param {Promise} [libLoadProm]: promise to use for the loading of the library (for testing / dependency injection)
   */
  constructor(doc = document, env = environment, win = window, libLoadProm) {
    this.env = env;
    this.win = win;
    this.win.utag_data = {};
    this.win.utag_cfg_ovrd = {noview: true}; // tells Tealium to override the base config and disallow autotracking on script load
    this.libLoad = libLoadProm || loadTealiumTrackerLib(doc, env);
  }

  /**
   * Fire a "view" Tealium event
   * View events are used for when the user views a page. Since pages aren't relevant in SPAs this
   * will typically be dispatched on a componentDidMount in whichever component best represents a logical page.
   *
   * @param {object} object: the event object that will be sent to tealium.
   * @returns {Promise} Returns Promise that waits for tealium tracking libraries to be loaded, and if that is successful, fires off a view event
   *                    Returns resolved promise if environment configuration has tealium tracking disabled.
   */
  view(object) {
    // create a copy of the object because Tealium will inject its own properties
    return this.env.get('tealium.enabled') ? this.libLoad.then(() => this.win.utag.view({...object})) : Promise.resolve();
  }

  /**
   * Fire a "link" Tealium event
   * Link events are used for actions/operations (remove from cart, submit order, etc.)
   *
   * @param {object} object: the event object that will be sent to tealium.
   * @returns {Promise} Returns Promise that waits for tealium tracking libraries to be loaded, and if that is successful, fires off a link event
   *                    Returns resolved promise if environment configuration has tealium tracking disabled.
   */
  link(object) {
    // create a copy of the object because Tealium will inject its own properties
    return this.env.get('tealium.enabled') ? this.libLoad.then(() => this.win.utag.link({...object})) : Promise.resolve();
  }
}

export default __TEST__ ? {} : new TealiumTrackerWrapper();
