import axios from 'axios';
import Session from 'lib/session';

/**
 * @typedef {object} Environment
 *
 * Provides methods for setting and getting data from a session, and fetching an environment configuration JSON.
 *
 * @property {function} fetch: Fetches an environment configuration JSON file, then hydrates the session with this data
 * @property {function} get: returns the full configuration object retrieved by fetch()
 * @property {function} set: sets a specified configuration object in the session (using key 'environment')
 */


export const set = (config) => Session.setItem('environment', JSON.stringify(config));

export const fetch = () => (
  axios.get('/config/config.json')
    .then((response) => {
      set(response.data);
      return response.data;
    })
);

export const get = (property) => {
  const requestedVal = Session.getItem('environment', property);
  if (!requestedVal) {
    return undefined;
  }
  return JSON.parse(requestedVal);
};

/**
 * @type {Environment}
 */
export default {
  set,
  get,
  fetch
};
