import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import AlertAuthExpired from 'components/alert-auth-expired';
import AlertAuthWarning from 'components/alert-auth-warning';
import AlertProductUnmatched from 'components/alert-product-unmatched';
import AlertErrors from 'components/alert-errors';
import AlertExpiredPaymentMethod from 'components/alert-expired-payment-method';
import AlertFraud from 'components/alert-fraud/alert-fraud-container';
import AlertPreconfiguration from 'components/alert-preconfiguration';
import AlertWarningPaymentMethod from 'components/alert-warning-payment-method';
import AlertWarningAddressInvalid from 'components/alert-warning-address-invalid';
import AlertSepaSetupSuccess from 'components/alert-sepa-setup-success';
import Loader from 'components/loader';
import Footer from 'components/footer';
import MainNav from 'components/main-nav';
import Crown from 'components/crown';
import '../../styles/core.scss';
import classes from './core.scss';
import DocumentTitleRoute from 'components/document-title-route';
import SubscriptionsOverview from 'routes/subscriptions/routes/overview';
import SubscriptionConfiguration from 'routes/subscriptions/routes/configuration';
import InvoicesOverview from 'routes/invoices/routes/history';
import CartOverview from 'routes/order/routes/cart';
import OrderPreview from 'routes/order/routes/preview';
import OrderConfirmation from 'routes/order/routes/confirmation';
import PaymentMethodsOverview from 'routes/payment-methods/routes/payment-methods-overview';
import PaymentInfoOverview from 'routes/payment-methods/routes/payment-info';
import BillingAddressOverview from 'routes/payment-methods/routes/billing-address-info';
import ErrorView from 'routes/error';
import Logout from 'routes/logout';
import Unauthorized from 'routes/unauthorized';
import NotFound from 'routes/not-found';
import CartConfigure from 'routes/order/routes/cart-configure';
import PreviewConfigure from 'routes/order/routes/preview-configure';
import AlertPaymentExisting9sContainer from 'components/alert-payment-existing-9s';
import AlertTrySwitchGotoAdmin from '../alert-try-switch-goto-admin';

const Core = (props) => (
  <Crown userLanguage={props.userLanguage}>
    <div className="app-container">
      <MainNav isAuthorized={props.isAuthorized} />
      <div className="container-fluid">
        <div className="app-content">
          {props.paymentMethodsCreditCardIsExisting9sAffected &&
            <AlertPaymentExisting9sContainer />}
          {
            props.alertAuthWarningIsVisible &&
              <AlertAuthWarning />
          }
          {
            props.alertProductUnmatchedVisible &&
              <AlertProductUnmatched unmatchedKey={props.alertProductUnmatchedKey} />
          }
          {
            props.alertWarningAddressInvalidVisible &&
              <AlertWarningAddressInvalid />
          }
          {
            props.alertAuthExpiredIsVisible &&
              <AlertAuthExpired />
          }
          {
            props.alertExpiredPaymentMethodMessageVisible &&
              <AlertExpiredPaymentMethod />
          }
          {
            props.alertWarningPaymentMethodIsVisible &&
              <AlertWarningPaymentMethod />
          }
          {
            props.alertFraudIsVisible &&
              <AlertFraud />
          }
          {
            props.alertPreconfigurationIsVisible &&
              <AlertPreconfiguration />
          }
          {
            props.alertErrorsAreVisible &&
              <AlertErrors />
          }
          {
            props.alertSepaSetupSuccessIsVisible &&
              <AlertSepaSetupSuccess />
          }
          {
            props.alertTrySwitchIsVisible &&
              <AlertTrySwitchGotoAdmin />
          }
          <div className={classes.mainContainer}>
            <Suspense fallback={<Loader />}>
              <Switch>
                <DocumentTitleRoute
                  exact
                  titleKey="shared.subscriptions"
                  path="/account/:accountKey/subscriptions"
                  component={SubscriptionsOverview}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.subscriptions"
                  path="/account/:accountKey/subscriptions/configure/:key?"
                  component={SubscriptionConfiguration}
                />
                <DocumentTitleRoute
                  titleKey="shared.nav.invoicehistory"
                  path="/account/:accountKey/invoices"
                  component={InvoicesOverview}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.ordersummary"
                  path="/account/:accountKey/order"
                  component={CartOverview}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.ordersummary"
                  path="/account/:accountKey/order/:quoteKey/preview"
                  component={OrderPreview}
                />
                <DocumentTitleRoute
                  exact
                  path="/account/:accountKey/order/cartconfigure"
                  component={CartConfigure}
                />
                <DocumentTitleRoute
                  exact
                  path="/account/:accountKey/order/previewConfigure"
                  component={PreviewConfigure}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.ordersummary"
                  path="/account/:accountKey/order/confirmation"
                  component={OrderConfirmation}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.nav.paymentmethod"
                  path="/account/:accountKey/paymentmethods"
                  component={PaymentMethodsOverview}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.nav.paymentmethod"
                  path="/account/:accountKey/paymentmethods/manage"
                  component={PaymentInfoOverview}
                />
                <DocumentTitleRoute
                  exact
                  titleKey="shared.nav.paymentmethod"
                  path="/account/:accountKey/paymentmethods/:paymentMethodKey/billingaddress"
                  component={BillingAddressOverview}
                />
                <DocumentTitleRoute
                  titleKey="shared.error"
                  path="/error"
                  component={ErrorView}
                />
                <DocumentTitleRoute
                  titleKey="shared.error"
                  path="/unauthorized"
                  component={Unauthorized}
                />
                <DocumentTitleRoute
                  titleKey="shared.error"
                  path="/logout"
                  exact
                  component={Logout}
                />
                {/* @FIXME BPOR-2010 This NotFound component is rendering every time user comes into the base app route (/), not ideal? */}
                <DocumentTitleRoute
                  titleKey="importantdates.item.notfound"
                  component={NotFound}
                />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </Crown>
);

Core.propTypes = {
  alertAuthExpiredIsVisible: PropTypes.bool,
  alertAuthWarningIsVisible: PropTypes.bool,
  alertErrorsAreVisible: PropTypes.bool,
  alertExpiredPaymentMethodMessageVisible: PropTypes.bool,
  alertFraudIsVisible: PropTypes.bool,
  alertPreconfigurationIsVisible: PropTypes.bool,
  alertProductUnmatchedKey: PropTypes.object.isRequired,
  alertProductUnmatchedVisible: PropTypes.bool.isRequired,
  alertWarningAddressInvalidVisible: PropTypes.bool,
  alertWarningPaymentMethodIsVisible: PropTypes.bool,
  alertSepaSetupSuccessIsVisible: PropTypes.bool,
  alertTrySwitchIsVisible: PropTypes.bool,
  paymentMethodsCreditCardIsExisting9sAffected: PropTypes.bool,
  userLanguage: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired
};

export default Core;
