import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';

export const AlertSepaSetupSuccessView = (props) => (
  <Alert
    type="success"
    title={<FormattedMessage {...st['alert.sepa.setup.success.title']} />}
    body={<FormattedMessage
      {...st['alert.sepa.setup.success.body']}
      values={{
        mandateReference: props.mandateReference,
        strong: (msg) => <strong>{msg}</strong>
      }}
    />}
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertSepaSetupSuccessView.propTypes = {
  mandateReference: PropTypes.string.isRequired,
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertSepaSetupSuccessView;
