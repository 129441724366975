import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import DocumentTitle, { DocumentTitleProps } from '../document-title/document-title-view';

export type DocumentTitleRouteProps = RouteProps & DocumentTitleProps;

const DocumentTitleRoute: FC<DocumentTitleRouteProps> = (props) => {
  const {
    titleKey,
    ...remaining
  } = props;
  return (
    <React.Fragment>
      {titleKey && <DocumentTitle titleKey={titleKey} />}
      <Route {...remaining} />
    </React.Fragment>
  );
};

export default DocumentTitleRoute;
