// Subscription (Configuration)
export const UI_SUBS_CONFIG_RESET_STATE = 'UI_SUBS_CONFIG_RESET_STATE';
export const UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION = 'UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION';
export const UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION = 'UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION';
export const UI_SUBS_CONFIG_START_TRIAL_CONVERSION = 'UI_SUBS_CONFIG_START_TRIAL_CONVERSION';
export const UI_SUBS_CONFIG_SELECT_PRODUCT = 'UI_SUBS_CONFIG_SELECT_PRODUCT';
export const UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY = 'UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY';
export const UI_SUBS_CONFIG_TOGGLE_BUY_TRY = 'UI_SUBS_CONFIG_TOGGLE_BUY_TRY';
export const UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW = 'UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW';
export const UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION = 'UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION';
export const UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY = 'UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY';
export const UI_SUBS_CONFIG_ENABLE_SECTION_FREQUENCY = 'UI_SUBS_CONFIG_ENABLE_SECTION_FREQUENCY';
export const UI_SUBS_CONFIG_UPDATE_QUANTITY = 'UI_SUBS_CONFIG_UPDATE_QUANTITY';
export const UI_SUBS_CONFIG_TOGGLE_DETAILS_FEATURES_VIEW = 'UI_SUBS_CONFIG_TOGGLE_DETAILS_FEATURES_VIEW';
export const UI_SUBS_CONFIG_TOGGLE_DETAILS_FEATURES_VIEW_ALL = 'UI_SUBS_CONFIG_TOGGLE_DETAILS_FEATURES_VIEW_ALL';
export const UI_SUBS_CONFIG_CONFIGURE_START = 'UI_SUBS_CONFIG_CONFIGURE_START';
export const UI_SUBS_CONFIG_CONFIGURE_FINISH = 'UI_SUBS_CONFIG_CONFIGURE_FINISH';
export const UI_SUBS_CONFIG_TOGGLE_ADDON = 'UI_SUBS_CONFIG_TOGGLE_ADDON';

// Subscriptions (Overview)
export const UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW = 'UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW';
export const UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE = 'UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE';
