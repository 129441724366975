import channelTracker from './channel-tracker-module';
import tealium from './tealium-tracker-module';
import telemetry from './telemetry-module';
// @NOTE: The LaunchDarkly Module is commented out since it does not currently have a default export.
// import launchDarkly from './launch-darkly-module';
import amplitude from './amplitude-module';
import GTM from './gtm-tracker-module';

/**
 * This module contains logic for various tracking needs:
 *  - Amplitude
 *  - LaunchDarkly
 *  - Channel Tracking (Marketing Service): https://confluence.ops.expertcity.com/display/SOA/Marketing+Service+JS+API
 *  - Tealium
 *  - Telemetry Service: https://confluence.ops.expertcity.com/display/SOA/Telemetry
 */
export * from './tracking-constants';

/**
 * The event details which will consist of the state and payload properties
 *
 * @typedef {object} EventDetails
 *
 * @property {object} state
 * @property {object/array} payload
 */

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, event) => {
  amplitude(eventName, event);
  channelTracker(eventName, event);
  // @NOTE: The LaunchDarkly Module is commented out since it does not currently have a default export.
  // launchDarkly(eventName, event);
  tealium(eventName, event);
  telemetry(eventName, event);
  GTM(eventName, event);
};
