import {factory as telemetryFactory} from '@getgo/telemetry-client';
import transportFactory from '@getgo/telemetry-client/lib/fetch-buffered-queue';
import environment from 'lib/environment';
import { v4 as uuidv4 } from 'uuid';

const telemetryUrl = environment.get('telemetry.url');

const machineId = uuidv4();

const telemetryOptions = {
  ev_source: 'logmein.commerce.frontend.web',
  ev_time: new Date().toISOString(),
  id_run: uuidv4(),
  id_dev: machineId,
  attributes: {
    ev_namespace: 'logmein.commerce.frontend'
  }
};

const transporter = transportFactory.createTransport(
  `${telemetryUrl}/reportEvent`,
  telemetryOptions
);

const telemetryClient = telemetryFactory.createClient(
  transporter.add.bind(transporter),
  telemetryOptions
);

export class Telemetry {
  constructor(telemetry, transport) {
    this.event = telemetry.event;
    this.flush = transport.flush;
    this.setGlobalAttributes = telemetry.setGlobalAttributes;
    this.ticker = setInterval(() => this.flush(), 5000);
  }

  emit = (eventName, eventData) => this.event(eventName, eventData);

  destroy() {
    clearInterval(this.ticker);
  }
}

const instance = new Telemetry(telemetryClient, transporter);

export default instance;
