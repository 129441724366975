import { ME_RECEIVE_GET_SUCCESS } from 'modules/me-module';
import {
  BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE,
  BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS,
  BILLING_ACCOUNT_FRAUD_REQUEST_GET,
  BILLING_ACCOUNT_RECEIVE_GET_FAILURE,
  BILLING_ACCOUNT_RECEIVE_GET_SUCCESS,
  BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE,
  BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS,
  BILLING_ACCOUNT_REQUEST_GET,
  BILLING_ACCOUNT_REQUEST_PATCH
} from './billing-account-action-types';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  activeBillingAccount: 0,
  billingAccounts: [],
  isLoading: false
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [BILLING_ACCOUNT_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [BILLING_ACCOUNT_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      const { roles, name, key, currencyCode, tempCountry, taxNumber, taxNumberType, allowedPaymentMethodTypes } = action.payload;
      let matchedAccount = false;
      // Update the relevant billing account
      const updatedAccounts = state.billingAccounts.map((account) => {
        if (action.payload.key === account.key) {
          matchedAccount = true;

          return {
            ...account,
            key,
            name,
            currencyCode,
            tempCountry,
            taxNumberType,
            taxNumber,
            allowedPaymentMethodTypes
          };
        }
        return account;
      });
      // Didn't find a record so add to the list of billingAccounts
      if (!matchedAccount) {
        updatedAccounts.push({
          roles,
          key,
          name,
          currencyCode,
          tempCountry,
          taxNumberType,
          taxNumber,
          allowedPaymentMethodTypes
        });
      }

      return {
        ...state,
        billingAccounts: updatedAccounts,
        isLoading: false
      };
    }
    return {...state, isLoading: false};
  },
  [BILLING_ACCOUNT_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [BILLING_ACCOUNT_REQUEST_PATCH]: (state) => ({...state, isLoading: true}),
  [BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS]: (state) => ({...state, isLoading: false}),
  [BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE]: (state) => ({...state, isLoading: false}),
  /**
   * Takes the billingBillingAccounts array from the /me API response
   * and populates the billingAccounts object with its data
   *
   * @param state
   * @param action
   */
  [ME_RECEIVE_GET_SUCCESS]: (state, action) => ({
    ...state,
    billingAccounts: action.payload.billingAccounts,
    activeBillingAccount: action.payload.billingAccounts[0] ? action.payload.billingAccounts[0].key : null
  }),
  [BILLING_ACCOUNT_FRAUD_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        billingAccounts: state.billingAccounts.map((billingAccount) => {
          if (billingAccount.key === action.billingAccountKey) {
            return {
              ...billingAccount,
              restrictChangeOrder: action.payload.restrictChangeOrder,
              restrictNewOrder: action.payload.restrictNewOrder,
              restrictPaymentMethod: action.payload.restrictPaymentMethod,
              restrictLogin: action.payload.restrictLogin,
              paymentStatus: action.payload.paymentStatus
            };
          }
          return billingAccount;
        }),
        isLoading: false
      };
    }
    return {...state, isLoading: false};
  },
  [BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const billingAccountReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};
