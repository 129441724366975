import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

export const AppDateView = (props) => (
  <span>
    {
      !props.isDateInvalid &&
        <FormattedDate
          value={props.date}
          {...props.format}
        />
    }
  </span>
);

AppDateView.propTypes = {
  isDateInvalid: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  format: PropTypes.object.isRequired
};

export default AppDateView;
