import React from 'react';
import FeatureDetailView from './feature-detail-view';

const FeatureDetailContainer = () => {
  const featureDetailList = [
    {
      icon: 'paint-brush',
      message: 'alert.try.switch.modal.look.message'
    },
    {
      icon: 'phone',
      message: 'alert.try.switch.modal.tools.message'
    },
    {
      icon: 'star',
      message: 'alert.try.switch.modal.features.message'
    }
  ];
  return <FeatureDetailView featureDetailList={featureDetailList} />;
};

export default FeatureDetailContainer;
