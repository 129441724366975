/* eslint-disable react/prop-types */
import React from 'react';
import classes from './feature-detail.scss';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';

const FeatureDetailView = (props) => (
  <div className={classes['feature-detail']}>
    {props.featureDetailList.map((feature, index) => (
      <div
        key={`${feature.message}-${index}`}
        className={classes['feature-detail-content']}
      >
        <i
          className={`togo-icon togo-icon-${feature.icon} ${classes['feature-detail-icon']}`}
        />
        <FormattedMessage
          {...st[feature.message]}
          values={{
            title: (msg) => <h6>{msg}</h6>,
            body: (msg) => (
              <p className={classes['feature-detail-content-body']}>
                {msg}
              </p>
            )
          }}
        />
      </div>
    ))}
  </div>
);

export default FeatureDetailView;
