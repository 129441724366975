import React, {Component} from 'react';
import environment from 'lib/environment';
import UnauthorizedView from './unauthorized-view';
import commerceAuth from 'lib/commerce-auth';

class UnauthorizedContainer extends Component {
  accountUrl: string;

  constructor() {
    super({});

    this.accountUrl = environment.get('unauthorizedRedirect.url');
  }

  componentDidMount = () => {
    // Since the user is unauthorized and will be moved away from the app, we want to clear out any auth-related information.
    // Doing so here will properly clear the information no matter how the user navigates away.
    commerceAuth.clear();
  };

  render = () => (
    <UnauthorizedView
      accountUrl={this.accountUrl}
    />
  )
}

export default UnauthorizedContainer;
