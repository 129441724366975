import React from 'react';
import Loader from 'components/loader';
import classes from './button.scss';

type ButtonPropsTheme = 'primary' | 'secondary' | 'tertiary' | 'link';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  isLoading?: boolean;
  size?: 'sm' | 'lg';
  theme?: ButtonPropsTheme;
  wide?: boolean;
}

export const ButtonView: React.FC<ButtonProps> = (props) => {
  const { children, theme, size, wide, className, isLoading, ...otherProps } = props;
  const btnSize = size ? `btn-${size}` : '';
  return (
    <button
      type="button"
      {...otherProps}
      className={[
        'btn',
        `btn-${theme}`,
        `${btnSize}`,
        `${wide ? 'btn-block' : ''}`,
        `${classes['relative']}`,
        `${className}`
      ].reduce((carriedName, currentName) => `${carriedName} ${currentName}`.trim())}
    >
      <span className={isLoading ? classes['a11y-invisible'] : classes['a11y-visible']}>
        {children}
      </span>
      {props.isLoading && <Loader size="xs" light />}
    </button>
  );
};

ButtonView.defaultProps = {
  className: '',
  disabled: false,
  theme: 'primary',
  wide: false
};

export default ButtonView;
