import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExternalLink } from 'modules/tracking-module';
import { track } from 'lib/tracking';
import ExternalAnchorView from './external-anchor-view';

export const mapDispatchToProps = {
  track
};

export class ExternalAnchorContainer extends Component {
  relValue = () => {
    if (!this.props.target || this.props.target === '_blank') {
      if (!this.props.rel) {
        return 'noopener noreferrer';
      }
    }
    return this.props.rel;
  };

  handleClick = (e) => {
    const { href } = this.props;
    this.props.track({[ExternalLink]: {href}});
    if (this.props.onClick) {
      e.preventDefault();
      this.props.onClick(e);
    }
  }

  render = () => (
    <ExternalAnchorView
      href={this.props.href}
      target={this.props.target || '_blank'}
      className={this.props.className}
      rel={this.relValue()}
      type={this.props.type}
      download={this.props.download}
      onClick={this.handleClick}
      id={this.props.id}
      children={this.props.children}
      role={this.props.role}
    />
  );
}

ExternalAnchorContainer.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  download: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.node,
  track: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default connect(null, mapDispatchToProps)(ExternalAnchorContainer);
