import React from 'react';
import PropTypes from 'prop-types';
import classes from './loader.scss';

export const LoaderView = (props) => (
  <React.Fragment>
    <span className={`chameleon-loader ${props.absolute ? classes['absolute'] : ''} ${props.classNames || ''}`}>
      <span
        className={`loader size-${props.size} chameleon-loader-dots-${props.light ? 'white' : 'blue'}`}
        tabIndex="-1"
        aria-label={props.label}
      />

    </span>
    {
      props.isLowerText && props.children
    }
  </React.Fragment>
);

LoaderView.defaultProps = {
  absolute: true,
  light: false,
  label: 'Loading',
  size: 'md'
};

LoaderView.propTypes = {
  classNames: PropTypes.string,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  isLowerText: PropTypes.bool,
  children: PropTypes.node
};

export default LoaderView;
