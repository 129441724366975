import {
  daoCartGet,
  daoCartItemDelete
} from 'dao/cart-dao';
import { QUOTE_ORDER_RECEIVE_POST_SUCCESS } from 'modules/quote';
import { billingAccountActiveKey } from 'modules/billing-account';
import { CartRemove, CartRemoveCTA } from 'modules/tracking-module';
import { track } from 'lib/tracking';

// ------------------------------------
// Constants
// ------------------------------------
export const CART_REQUEST_GET = 'CART_REQUEST_GET';
export const CART_RECEIVE_GET_SUCCESS = 'CART_RECEIVE_GET_SUCCESS';
export const CART_RECEIVE_GET_FAILURE = 'CART_RECEIVE_GET_FAILURE';
export const CART_REQUEST_PATCH = 'CART_REQUEST_GET';
export const CART_RECEIVE_PATCH_SUCCESS = 'CART_RECEIVE_PATCH_SUCCESS';
export const CART_RECEIVE_PATCH_FAILURE = 'CART_RECEIVE_PATCH_FAILURE';
export const CART_ITEM_REQUEST_POST = 'CART_ITEM_REQUEST_POST';
export const CART_ITEM_RECEIVE_POST_SUCCESS = 'CART_ITEM_RECEIVE_POST_SUCCESS';
export const CART_ITEM_RECEIVE_POST_FAILURE = 'CART_ITEM_RECEIVE_POST_FAILURE';
export const CART_ITEM_REQUEST_PATCH = 'CART_ITEM_REQUEST_PATCH';
export const CART_ITEM_RECEIVE_PATCH_SUCCESS = 'CART_ITEM_RECEIVE_PATCH_SUCCESS';
export const CART_ITEM_RECEIVE_PATCH_FAILURE = 'CART_ITEM_RECEIVE_PATCH_FAILURE';
export const CART_ITEM_REQUEST_DELETE = 'CART_ITEM_REQUEST_DELETE';
export const CART_ITEM_RECEIVE_DELETE_SUCCESS = 'CART_ITEM_RECEIVE_DELETE_SUCCESS';
export const CART_ITEM_RECEIVE_DELETE_FAILURE = 'CART_ITEM_RECEIVE_DELETE_FAILURE';
export const CART_PATCH_ITEMS_AND_QUOTE = 'CART_PATCH_ITEMS_AND_QUOTE';
export const CART_PATCH_ITEMS_AND_QUOTE_FINISH = 'CART_PATCH_ITEMS_AND_QUOTE_FINISH';

// Flows
export const ADD_TO_CART_AND_COMPLETE_ORDER = 'ADD_TO_CART_AND_COMPLETE_ORDER';
export const ADD_TO_CART_THEN_ROUTE_TO_INVOICE = 'ADD_TO_CART_THEN_ROUTE_TO_INVOICE';
export const ADD_TO_CART_THEN_ROUTE_TO_CART = 'ADD_TO_CART_THEN_ROUTE_TO_CART';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  items: [],
  isLoading: false,
  orderPaymentKey: ''
};

// ------------------------------------
// Selectors
// ------------------------------------
export const cartTree = (state) => state.cart || {};
export const cartIsLoading = (state) => cartTree(state).isLoading;
export const cartItems = (state) => cartTree(state).items || [];
export const cartItemCount = (state) => cartItems(state).length || 0;
export const cartItemsByProductKey = (state, productKey) => cartItems(state).filter((item) => item.productKey === productKey) || {};
export const cartItemsByProductKeyQuantity = (state, productKey) => cartItemsByProductKey(state, productKey).reduce((acc, {quantity}) => acc + quantity, 0);
export const cartItemBySubscriptionKey = (state, subscriptionKey) => cartItems(state).find((item) => item.subscriptionKey === subscriptionKey) || {};
export const cartItemBySubscriptionKeyQuantity = (state, subscriptionKey) => cartItemBySubscriptionKey(state, subscriptionKey).quantity || 0;
export const cartItemHasSubscriptionKey = (state, subscriptionKey) => cartItems(state).some((item) => item.subscriptionKey === subscriptionKey);
export const cartItemKeyBySubscriptionKey = (state, subscriptionKey) => cartItemBySubscriptionKey(state, subscriptionKey).key;
export const cartOrderPaymentKey = (state) => cartTree(state).orderPaymentKey;

// ------------------------------------
// Actions
// ------------------------------------
export const cartReceiveGetSuccess = (payload = {}) => ({
  type: CART_RECEIVE_GET_SUCCESS,
  payload
});

export const cartReceiveGetFailure = (payload = {}) => ({
  type: CART_RECEIVE_GET_FAILURE,
  payload
});

export const cartRequestGet = () => (dispatch, getState) => {
  dispatch({
    type: CART_REQUEST_GET
  });

  return daoCartGet(billingAccountActiveKey(getState()))
    .then(
      (response) => dispatch(cartReceiveGetSuccess(response.data)),
      (ex) => dispatch(cartReceiveGetFailure(ex))
    );
};

export const cartReceivePatchSuccess = (payload = {}) => ({
  type: CART_RECEIVE_PATCH_SUCCESS,
  payload
});

export const cartItemReceivePostSuccess = (payload = {}) => ({
  type: CART_ITEM_RECEIVE_POST_SUCCESS,
  payload
});

export const cartItemReceivePatchSuccess = (payload = {}, partOfBatch = false) => ({
  type: CART_ITEM_RECEIVE_PATCH_SUCCESS,
  payload,
  partOfBatch
});

export const cartItemReceiveDeleteSuccess = (payload = {}) => (dispatch) => {
  const cartItemReceiveDeleteSuccessRequest = {
    type: CART_ITEM_RECEIVE_DELETE_SUCCESS,
    payload
  };

  dispatch(cartItemReceiveDeleteSuccessRequest);

  payload.cartItemsKeys.forEach((cartItemKey) => {
    const tracking = {
      [CartRemove]: cartItemKey,
      [CartRemoveCTA]: cartItemKey
    };

    dispatch(track(tracking));
  });
};

export const cartItemReceiveDeleteFailure = (payload = {}) => ({
  type: CART_ITEM_RECEIVE_DELETE_FAILURE,
  payload
});

export const cartItemRequestDelete = (cartItemsKeys) => (dispatch, getState) => {
  const billingAccountKey = billingAccountActiveKey(getState());

  dispatch({
    type: CART_ITEM_REQUEST_DELETE
  });

  const deleteItems = cartItemsKeys.map((cartItemKey) => daoCartItemDelete(billingAccountKey, cartItemKey));
  return Promise.all(deleteItems)
    .then(
      () => dispatch(cartItemReceiveDeleteSuccess({cartItemsKeys})),
      (ex) => dispatch(cartItemReceiveDeleteFailure(ex))
    );
};

export const cartPatchItemsAndQuoteFinish = (payload = {}) => ({
  type: CART_PATCH_ITEMS_AND_QUOTE_FINISH,
  payload
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [CART_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [CART_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        items: action.payload.items.map((item) => ({
          key: item.key,
          productKey: item.productKey,
          quantity: item.quantity,
          subscriptionKey: item.subscriptionKey,
          billingPeriod: item.billingPeriod
        })),
        isLoading: false
      };
    }
    return {
      ...state,
      isLoading: false
    };
  },
  [CART_RECEIVE_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [CART_REQUEST_PATCH]: (state) => ({...state, isLoading: true}),
  [CART_RECEIVE_PATCH_SUCCESS]: (state) => ({...state, isLoading: false}),
  [CART_RECEIVE_PATCH_FAILURE]: (state) => ({...state, isLoading: false}),
  [CART_ITEM_REQUEST_POST]: (state) => ({...state, isLoading: true}),
  [CART_ITEM_RECEIVE_POST_SUCCESS]: (state) => ({
    ...state,
    isLoading: false
  }),
  [CART_ITEM_RECEIVE_POST_FAILURE]: (state) => ({...state, isLoading: false}),
  [CART_ITEM_REQUEST_PATCH]: (state) => ({...state, isLoading: true}),
  [CART_ITEM_RECEIVE_PATCH_SUCCESS]: (state, action) => ({
    ...state,
    isLoading: (action.partOfBatch) ? state.isLoading : false
  }),
  [CART_ITEM_RECEIVE_PATCH_FAILURE]: (state) => ({...state, isLoading: false}),
  [CART_ITEM_REQUEST_DELETE]: (state) => ({...state, isLoading: true}),
  [CART_ITEM_RECEIVE_DELETE_SUCCESS]: (state, action) => ({
    ...state,
    items: state.items
      .filter((cartItem) => !action.payload.cartItemsKeys
        .includes(cartItem.key)
      ),
    isLoading: false
  }),
  [CART_ITEM_RECEIVE_DELETE_FAILURE]: (state) => ({...state, isLoading: false}),
  [QUOTE_ORDER_RECEIVE_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        items: [],
        orderPaymentKey: action.payload.paymentKey
      };
    }
    return {
      ...state,
      items: []
    };
  },
  [CART_PATCH_ITEMS_AND_QUOTE]: (state) => ({...state, isLoading: true}),
  [CART_PATCH_ITEMS_AND_QUOTE_FINISH]: (state) => ({...state, isLoading: false})
});

// ------------------------------------
// Reducer
// ------------------------------------
const cartReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default cartReducer;
