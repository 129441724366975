import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classes from './text-field.scss';
import st from 'components/shared-translations';

const TextFieldView = (props) => {
  const fieldShouldShowMessage = props.meta.touched || props.meta.submitFailed;
  const fieldHasError = props.meta.error && st[props.meta.error];
  const fieldHasWarning = props.meta.warning;

  return (
    <div>
      <input
        id={props.id}
        {...props.input}
        type={props.type}
        className={`${props.type === 'radio' ? classes['input-radio-size'] : ''} ${props.fieldClasses}`}
        maxLength={props.maxLength}
        disabled={props.disabled}
      />
      {fieldShouldShowMessage && (
        (fieldHasError &&
          <span className="form-text-error"><FormattedMessage {...st[props.meta.error]} /></span>) ||
        (fieldHasWarning &&
          <span>{props.meta.warning}</span>
        )
      )}
    </div>
  );
};

TextFieldView.propTypes = {
  fieldClasses: PropTypes.string,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }),
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool
};

export default TextFieldView;
