import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  paymentMethodsDefaultType,
  paymentMethodsGetIsLoading,
  PAYMENT_METHODS_TYPE_SEPA
} from 'modules/payment-methods-module';
import {
  paymentInfoSetTypeCredit,
  paymentInfoSetTypeSepa
} from 'modules/payment-info-module';
import {
  billingAccountIsLoading
} from 'modules/billing-account';
import { subscriptionsAreLoading } from 'modules/subscription';
import Thunks from 'store/thunks';
import Selectors from 'store/selectors';
import BillingAddressInfoView from './billing-address-info-view';
import PropTypes from 'prop-types';
import environment from 'lib/environment';
import queryString from 'query-string';
import { track } from 'lib/tracking';
import {
  AddressForm,
  LoadForm,
  SubmitForm,
  AbandonForm
} from 'modules/tracking-module';

export const mapStateToProps = (state) => ({
  billingAccountIsLoading: billingAccountIsLoading(state),
  paymentMethodsDefaultType: paymentMethodsDefaultType(state),
  paymentMethodsGetIsLoading: paymentMethodsGetIsLoading(state),
  subscriptionsAreLoading: subscriptionsAreLoading(state),
  showCustomerCareLinkOnPaymentMethod: Selectors.showCustomerCareLinkOnPaymentMethod(state)
});

export const mapDispatchToProps = {
  handleRedirectOnPaymentMethodsForm: Thunks.handleRedirectOnPaymentMethodsForm,
  paymentInfoSetTypeCredit,
  paymentInfoSetTypeSepa,
  loadBillingAddressFormData: Thunks.loadBillingAddressFormData,
  track
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  track: dispatchProps.track,
  redirectOverride: {
    route: queryString.parse(ownProps.location.search).redirect,
    params: [queryString.parse(ownProps.location.search).param]
  }
});

export class BillingAddressInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.careLink = environment.get('support.url');
    this.isAbandoned = true;
  }

  setPaymentMethodType = (type) => {
    if (type === PAYMENT_METHODS_TYPE_SEPA) {
      return this.props.paymentInfoSetTypeSepa();
    }

    return this.props.paymentInfoSetTypeCredit();
  };

  componentDidMount = async () => {
    await this.props.loadBillingAddressFormData();
    this.setPaymentMethodType(this.props.paymentMethodsDefaultType);
    this.props.track({
      [AddressForm]: {
        event: LoadForm,
        form_name: 'Address-Form'
      }
    });
  }

  onSuccess = async (response = {}) => {
    this.isAbandoned = false;
    this.props.track({
      [AddressForm]: {
        event: SubmitForm,
        form_name: 'Address-Form'
      }
    });
    return this.props.handleRedirectOnPaymentMethodsForm(response, this.props.redirectOverride);
  }

  componentWillUnmount = () => {
    if (this.isAbandoned) {
      this.props.track({
        [AddressForm]: {
          event: AbandonForm,
          form_name: 'Address-Form'
        }
      });
    }
  }

  isLoading = () => this.props.subscriptionsAreLoading || this.props.paymentMethodsGetIsLoading || this.props.billingAccountIsLoading

  render = () => {
    const { props } = this;

    return (
      <BillingAddressInfoView
        {...props}
        onSuccess={this.onSuccess}
        isLoading={this.isLoading()}
        shouldShowCustomerCareLink={this.props.showCustomerCareLinkOnPaymentMethod}
        careLink={this.careLink}
      />
    );
  };
}

BillingAddressInfoContainer.propTypes = {
  billingAccountIsLoading: PropTypes.bool.isRequired,
  handleRedirectOnPaymentMethodsForm: PropTypes.func.isRequired,
  loadBillingAddressFormData: PropTypes.func.isRequired,
  paymentInfoSetTypeCredit: PropTypes.func.isRequired,
  paymentInfoSetTypeSepa: PropTypes.func.isRequired,
  paymentMethodsDefaultType: PropTypes.string.isRequired,
  paymentMethodsGetIsLoading: PropTypes.bool.isRequired,
  redirectOverride: PropTypes.object,
  showCustomerCareLinkOnPaymentMethod: PropTypes.bool.isRequired,
  subscriptionsAreLoading: PropTypes.bool.isRequired,
  track: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BillingAddressInfoContainer);
