import React, { Component } from 'react';
import { connect } from 'react-redux';
import resolveRoute from 'lib/resolve-route';
import history from 'lib/history';
import PropTypes from 'prop-types';
import {
  alertAddressInvalidHide,
  alertAddressInvalidShow
} from 'modules/alert-module';
import { reduxFormHOC } from 'modules/form';
import {
  billingAccountActiveKey,
  billingAccountIsLoading
} from 'modules/billing-account';
import {
  errorsDismissAlertsByPrefix,
  errorsShowAlert,
  getErrorStatus
} from 'modules/errors-module';
import {
  paymentMethodsBillingAddressFormInitialValues,
  paymentMethodsDefaultAddressInvalid,
  paymentMethodsKey,
  paymentMethodsPatchIsLoading,
  paymentMethodsRequestPatch
} from 'modules/payment-methods-module';
import {
  subscriptionsAreLoading
} from 'modules/subscription';
import Selectors from 'store/selectors';
import st from 'components/shared-translations';
import BillingAddressFormView from './billing-address-form-view';
import { paymentInfoSelectedTypeIsSepa } from 'modules/payment-info-module';
import { track } from 'lib/tracking';
import { AddressForm, ErrorForm } from 'modules/tracking-module';

export const FORM_ID = 'billing-information-form';

export const mapStateToProps = (state) => {
  const paymentMethodKey = paymentMethodsKey(state);
  return ({
    paymentMethodKey,
    billingAccountActiveKey: billingAccountActiveKey(state),
    billingAccountIsLoading: billingAccountIsLoading(state),
    initialValues: paymentMethodsBillingAddressFormInitialValues(state),
    paymentMethodsDefaultAddressInvalid: paymentMethodsDefaultAddressInvalid(state),
    paymentMethodsPatchIsLoading: paymentMethodsPatchIsLoading(state),
    subscriptionsAreLoading: subscriptionsAreLoading(state),
    editBillingAddressIsRestricted: Selectors.editBillingAddressIsRestricted(state, {paymentMethodKey}),
    paymentInfoSelectedTypeIsSepa: paymentInfoSelectedTypeIsSepa(state)
  });
};

export const mapDispatchToProps = {
  alertAddressInvalidHide,
  alertAddressInvalidShow,
  errorsShowAlert,
  paymentMethodsRequestPatch,
  errorsDismissBillingInformation: () => errorsDismissAlertsByPrefix('shared.alert.error.paymentmethod.invalid.billinginformation'),
  showPaymentFormErrorBanner: (msgKey, values) => errorsShowAlert({...(st[msgKey]), values}),
  track
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  paymentMethodsRequestPatch: (payload = {}) => dispatchProps.paymentMethodsRequestPatch(stateProps.billingAccountActiveKey, stateProps.paymentMethodKey, payload),
  track: dispatchProps.track
});

export class BillingAddressFormContainer extends Component {
  componentDidMount = () => {
    if (this.props.editBillingAddressIsRestricted) {
      history.replace(
        resolveRoute.resolve('paymentmethods', {billingAccountKey: this.props.billingAccountActiveKey})
      );
    }
  }

  componentWillUnmount = () => {
    this.props.errorsDismissBillingInformation();
  }

  /**
   * Handles the various errors that are related to PATCH calls
   */
  handleBillingAddressFormError = (errorResponse) => {
    const status = getErrorStatus(errorResponse);
    this.props.track({
      [AddressForm]: {
        event: ErrorForm,
        form_name: 'Address-Form',
        form_error_id: status
      }
    });
    if (status === 400) {
      this.props.showPaymentFormErrorBanner('shared.alert.error.paymentmethod.invalid.billinginformation.general');
    } else if (status !== 403) {
      // Ignore 403 errors as they are already handled globally
      this.props.showPaymentFormErrorBanner('shared.alert.error.general.refreshtryagain');
    }
  };

  /**
   * This is called by redux-form's handleSubmit method and is passed the values of the form inputs.
   * @param {Object} values The key/value pairs from the form
   */
  onSubmit = (values) => {
    this.props.errorsDismissBillingInformation();
    const payload = {
      address: values
    };
    return (
      this.props.paymentMethodsRequestPatch(payload)
        .then(this.props.onSuccess)
        .catch(this.handleBillingAddressFormError)
    );
  }

  render = () => {
    const { props } = this;

    return (
      <BillingAddressFormView
        {...props}
        disableFormSubmissionButton={this.props.paymentMethodsPatchIsLoading || this.props.pristine}
        formId={FORM_ID}
        handleSubmit={this.props.handleSubmit(this.onSubmit)}
        patchIsLoading={this.props.paymentMethodsPatchIsLoading}
      />
    );
  };
}

BillingAddressFormContainer.propTypes = {
  billingAccountActiveKey: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  paymentMethodsPatchIsLoading: PropTypes.bool.isRequired,
  paymentMethodsRequestPatch: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  editBillingAddressIsRestricted: PropTypes.bool.isRequired,
  showPaymentFormErrorBanner: PropTypes.func.isRequired,
  errorsDismissBillingInformation: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  reduxFormHOC({form: FORM_ID})(BillingAddressFormContainer)
);
