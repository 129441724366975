import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const meEndpoint = appconfig.cog_endpoints.me;

export const daoMeGet = () => api.get(meEndpoint);

export default {
  daoMeGet
};
