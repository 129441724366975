import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { meTimezone } from 'modules/me-module';
import moment from 'moment-timezone';
import AppDateView from './app-date-view';

const INVALID_DATE = 'Invalid date';

export const mapStateToProps = (state) => ({
  timeZone: meTimezone(state)
});

export class AppDateContainer extends Component {
  formatDateAddSeconds = (date, seconds) => moment(date).add(seconds, 'seconds');

  // We need to use moment.tz() for cross-browser support (some browsers don't handle the timeZone option for Intl.DateTimeFormat yet).
  // We need to also continue using moment.format('LL') so that we get a string that reflects the timezone that is then further interpreted
  // by Intl.DateTimeFormat (via <FormattedDate> in the view).
  formatDate = () => this.formatDateAddSeconds(this.props.value, this.props.addSeconds)
    .tz(this.props.timeZone).format('LL');

  isDateInvalid = () => this.formatDate() === INVALID_DATE;

  render = () => (
    <AppDateView
      isDateInvalid={this.isDateInvalid()}
      date={this.formatDate()}
      format={this.props.format}
    />
  );
}

AppDateContainer.defaultProps = {
  format: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  addSeconds: 0
};

AppDateContainer.propTypes = {
  addSeconds: PropTypes.number,
  format: PropTypes.object.isRequired, // see https://github.com/yahoo/react-intl/wiki/Components#date-formatting-components
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired // String from COG or instance object of momentjs
};

export default connect(mapStateToProps)(AppDateContainer);
