/**
 * This module contains global configuration values that are not different per environment.
 * It is not meant to be used for environment-specific values like Authentication, APIs, client secrets, etc. as
 * values here will be included in all production bundles unchanged.
 *
 * Environment-specific values are configured in src/static/config/config.json for local sandbox development,
 * and in the billing-portal-config project for other environments. See README.md for more details.
 */

export default {
  routes: {
    root: () => '/subscriptions',
    subscriptions: () => '/subscriptions',
    'subscriptions.overview': () => '/subscriptions',
    'subscriptions.configuration': (subscriptionKey) => `/subscriptions/configure/${subscriptionKey}`,
    invoices: () => '/invoices',
    'invoices.history': () => '/invoices',
    order: () => '/order',
    'order.cart': () => '/order',
    'order.preview': (quoteKey) => `/order/${quoteKey}/preview`,
    'order.confirmation': () => '/order/confirmation',
    paymentmethods: () => '/paymentmethods',
    'paymentmethods.manage': () => '/paymentmethods/manage',
    'paymentmethods.editbillingaddress': (paymentMethodKey) => `/paymentmethods/${paymentMethodKey}/billingaddress`,
    noKey: {
      logout: () => '/logout',
      unauthorized: () => '/unauthorized',
      error: () => '/error',
      components: () => '/components'
    }
  },
  cog_endpoints: {
    me: 'v1/me',
    billingAccount: (billingAccountKey) => `v1/billingaccounts/${billingAccountKey}`,
    subscriptionGroups: (billingAccountKey) => `v1/billingaccounts/${billingAccountKey}/subscriptiongroups`,
    subscription: (billingAccountKey, subscriptionKey) => `v1/billingaccounts/${billingAccountKey}/subscriptions/${subscriptionKey}`,
    subscriptionAction: (billingAccountKey, subscriptionKey) => `v1/billingaccounts/${billingAccountKey}/subscriptions/${subscriptionKey}/pendingactions`,
    cart: (billingAccountKey) => `v1/billingaccounts/${billingAccountKey}/cart`,
    quote: (billingAccountKey, quoteKey) => `v1/billingaccounts/${billingAccountKey}/quotes/${quoteKey}`,
    fraud: (billingAccountKey) => `v1/billingaccounts/${billingAccountKey}/accounteligibility`,
    productsByProductKeys: (productKeys, country, batch, promoCodes, locale) => `v1/products/${productKeys}?countryCode=${country}&batch=${batch}${promoCodes ? `&promotionCodes=${promoCodes}` : ''}&locale=${locale}&loadDisplayFeatures=true`,
    productsBySubscriptionKey: (subscriptionKey, billingAccountKey, promoCodes, locale) => `v1/products?subscriptionKey=${subscriptionKey}&billingAccountKey=${billingAccountKey}${promoCodes ? `&promotionCodes=${promoCodes}` : ''}&locale=${locale}&loadDisplayFeatures=true`,
    productsByProductFamilyKey: (productFamilyKey, billingAccountKey, promoCodes, locale) => `v1/products?productFamily=${productFamilyKey}&billingAccountKey=${billingAccountKey}${promoCodes ? `&promotionCodes=${promoCodes}` : ''}&locale=${locale}&loadDisplayFeatures=true`,
    productFamilies: (country, billingAccountKey) => `v1/productfamilies?countryCode=${country}&billingAccountKey=${billingAccountKey}`,
    paymentMethods: (billingAccountKey, version) => `${version}/billingaccounts/${billingAccountKey}/paymentmethods`,
    paymentForm: (country, version, hostName) => `${version}/paymentforms?country=${country}&hostName=${hostName}&microFormV11=true`,
    payerAuth: (paymentAccountKey, paymentMethodKey, billingAccountKey) => `v1/paymentAccountKey/${paymentAccountKey}/paymentMethodKey/${paymentMethodKey}/payerAuth/${billingAccountKey}?microFormV11=true`,
    invoices: (billingAccountKey) => `v1/billingaccounts/${billingAccountKey}/invoices`,
    productRelationship: (productKey) => `v1/productrelations/${productKey}`, // Needs to be removed once FlatRate is migrated to Global Call me.
    scaToken: () => 'v1/sca/token'
  },
  scim_endpoints: {
    me: 'Users/me'
  },
  extAdmin_endpoints: {
    account: (accountKey) => `accounts/${accountKey}`
  },
  product_setting_endpoints: {
    settings: 'rest/2/settings'
  },
  ee_endpoints: {
    cancel: (entitlementAccountId, productFamilyKey, subscriptionKey, locale) => `account/${entitlementAccountId}?product=${productFamilyKey}&subscriptionKey=${subscriptionKey}&locale=${locale}`
  },
  storage: {
    oauth: 'auth_token',
    last_error: 'last_error'
  }
};
