import { ImportantDateActionTypes } from 'lib/cog-enums';
import ProductCatalog from 'lib/product-catalog';

/**
 * Returns the important dates app model object
 *
 * @returns {object}
 */
const buildAppModel = (dto: ImportantDate): ImportantDateModel => {
  const items: ImportantDateItemModel[] = dto.items.map((item) => ({
    ...item,
    isFreeOrTrial: ProductCatalog.productTypeIsFreeOrTrial(item.productType),
    isPaid: ProductCatalog.productTypeIsPaid(item.productType),
    amountAsInt: parseInt(item.amount || '0', 10)
  }));

  const appModel: ImportantDateModel = {
    ...dto,
    items
  };

  appModel.hasFutureDatedCharges = (
    [ImportantDateActionTypes.RENEWAL, ImportantDateActionTypes.PENDING_ACTION_EFFECTIVE].includes(appModel.action)
  ) && items.some((item) => item.isPaid);

  return appModel;
};

export default {
  buildAppModel
};
