import Amplitude from 'lib/amplitude';
import {
  AddToCart,
  BillingInfo,
  CancellationComplete,
  CancellationConfirm,
  CancellationStart,
  Cart,
  CartRemove,
  CartUpdate,
  Checkout,
  Err,
  ExternalLink,
  FlowExit,
  FlowStart,
  Invoices,
  Order,
  OrderConfirmation,
  PaymentMethodAdd,
  PaymentMethodOverviewView,
  PaymentMethodUpdate,
  ProceedToCheckout,
  ProductFamilies,
  RemoveCancelComplete,
  RemoveCancelConfirm,
  RemoveCancelExit,
  RemoveCancelStart,
  RetentionComplete,
  RetentionStart,
  Revenue,
  StartTrial,
  SubsConfig,
  SubscriptionGroups,
  TrialExpirationReminder,
  PromoLanding,
  DisplaySwitchToGTA,
  LearnMoreGTA,
  SwitchToGTA,
  PreviewGTA
} from './tracking-constants';
import ProductCatalog from 'lib/product-catalog';

/**
 * @typedef {object} TrackingRevenueAmplitudeInfo
 *
 * @property {string} currencyCode - the currency code that is used for the purchase
 * @property {number} orderItemAmount - the amount of a single invoice item
 * @property {string} orderType - the type of order (e.g. refund, purchase, free)
 * @property {string} productId - an identifier for the order item
 */

/**
 * Returns an object that contains all the data is need to track revenue
 *
 * @param {object} orderItem
 * @returns {TrackingRevenueAmplitudeInfo}
 */
export const trackingRevenueAmplitudeInfo = (orderItem) => {
  const productId = `${ProductCatalog.getProductFamily(orderItem.productKey)}.${orderItem.productKey}`;
  const orderItemAmount = orderItem.amount;
  let orderType = '';

  if (orderItemAmount > 0) {
    orderType = 'purchase';
  } else if (orderItemAmount < 0) {
    orderType = 'refund';
  } else {
    orderType = 'free';
  }

  return {
    currencyCode: orderItem.currencyCode,
    orderItemAmount: parseFloat(orderItemAmount),
    orderType,
    productId
  };
};


export const trackPageView = (eventName, {payload = {}}) => {
  let amplitudeEventName;
  let amplitudePayload = payload;

  // The existing app events have not followed a structured naming convention. We want to start following a structure for event names
  // in Amplitude (and for new app events overall) moving forward. However we will not change the existing app event names, we will just
  // map to the preferred Amplitude names. Events are grouped into logical areas of the app. Format of an event name is "[GroupName] - [EventName]".
  //   - GroupName should be a noun when possible
  //   - EventName should be formatted as "SubjectAction"
  // See https://confluence.ops.expertcity.com/pages/viewpage.action?pageId=120621719 Amplitude section for more details
  switch (eventName) {
    case AddToCart:
      amplitudeEventName = 'Cart - ItemAdd';
      break;

    case BillingInfo:
      amplitudeEventName = 'PaymentMethod - PaymentFormView';
      break;

    case CancellationComplete:
      amplitudeEventName = 'CancelFlow - CancellationComplete';
      break;

    case CancellationConfirm:
      amplitudeEventName = 'CancelFlow - CancellationConfirm';
      break;

    case CancellationStart:
      amplitudeEventName = 'CancelFlow - CancellationStart';
      break;

    case Cart:
      amplitudeEventName = 'Cart - View';
      break;

    case CartRemove:
      amplitudeEventName = 'Cart - ItemRemove';
      amplitudePayload = {}; // we do not want to send over cartItemKey
      break;

    case CartUpdate:
      amplitudeEventName = 'Cart - UpdateFromView';
      break;

    case Checkout:
      amplitudeEventName = 'Checkout - CheckoutView';
      break;

    case Err:
      amplitudeEventName = 'Error - ErrorView';
      amplitudePayload = {...payload.errorState};
      break;

    case ExternalLink:
      amplitudeEventName = 'Application - ExternalLinkClick';
      break;

    case FlowExit:
      amplitudeEventName = 'CancelFlow - FlowExit';
      break;

    case FlowStart:
      amplitudeEventName = 'CancelFlow - FlowStart';
      break;

    case Invoices:
      amplitudeEventName = 'Invoice - InvoicesView';
      break;

    case Order:
      amplitudeEventName = 'Checkout - OrderProcessed';
      break;

    case OrderConfirmation:
      amplitudeEventName = 'Checkout - ConfirmationView';
      break;

    case PaymentMethodAdd:
      amplitudeEventName = 'PaymentMethod - PaymentMethodAdd';
      break;

    case PaymentMethodOverviewView:
      amplitudeEventName = 'PaymentMethod - OverviewView';
      break;

    case PaymentMethodUpdate:
      amplitudeEventName = 'PaymentMethod - PaymentMethodUpdate';
      break;

    case ProceedToCheckout:
      amplitudeEventName = 'Configure - ProceedToCheckoutClick';
      break;

    case ProductFamilies:
      amplitudeEventName = 'Overview - ProductFamiliesView';
      break;

    case RemoveCancelComplete:
      amplitudeEventName = 'RemoveCancelFlow - RemoveCancelComplete';
      break;

    case RemoveCancelConfirm:
      amplitudeEventName = 'RemoveCancelFlow - RemoveCancelConfirm';
      break;

    case RemoveCancelExit:
      amplitudeEventName = 'RemoveCancelFlow - RemoveCancelExit';
      break;

    case RemoveCancelStart:
      amplitudeEventName = 'RemoveCancelFlow - RemoveCancelStart';
      break;

    case RetentionComplete:
      amplitudeEventName = 'CancelFlow - RetentionComplete';
      break;

    case RetentionStart:
      amplitudeEventName = 'CancelFlow - RetentionStart';
      break;

    case StartTrial:
      amplitudeEventName = 'Configure - StartTrialClick';
      break;

    case SubsConfig:
      amplitudeEventName = 'Configure - ConfigureView';
      break;

    case SubscriptionGroups:
      amplitudeEventName = 'Overview - SubscriptionsView';
      break;

    case TrialExpirationReminder:
      amplitudeEventName = 'Overview - ExpiringTrialView';
      break;

    case PromoLanding:
      amplitudeEventName = 'Checkout Optimization - PromoLanding';
      break;

    case DisplaySwitchToGTA:
      amplitudeEventName = 'GTA - Display switch';
      break;

    case LearnMoreGTA:
      amplitudeEventName = 'GTA - Learn More';
      break;

    case SwitchToGTA:
      amplitudeEventName = 'GTA - Switch';
      break;

    case PreviewGTA:
      amplitudeEventName = 'GTA - Preview';
      break;

    default:
      amplitudeEventName = eventName;
      amplitudePayload = payload;
  }

  return Amplitude.track(amplitudeEventName, amplitudePayload);
};

export const trackRevenueAmplitude = (eventName, {payload}) => {
  if (Array.isArray(payload)) {
    payload.forEach((orderItem) => {
      const revenuePayload = trackingRevenueAmplitudeInfo(orderItem);
      return Amplitude.trackRevenue(revenuePayload);
    });
  }
};

/**
 * The event details which will consist of the state and payload properties
 *
 * @typedef {object} EventDetails
 *
 * @property {object} state
 * @property {object/array} payload
 */

/**
 * @param {string} eventName
 * @param {EventDetails} event
 */
export default (eventName, event) => {
  switch (eventName) {
    case AddToCart:
    case BillingInfo:
    case CancellationComplete:
    case CancellationConfirm:
    case CancellationStart:
    case Cart:
    case CartRemove:
    case CartUpdate:
    case Checkout:
    case Err:
    case ExternalLink:
    case FlowExit:
    case FlowStart:
    case Invoices:
    case Order:
    case OrderConfirmation:
    case PaymentMethodAdd:
    case PaymentMethodOverviewView:
    case PaymentMethodUpdate:
    case ProceedToCheckout:
    case ProductFamilies:
    case RemoveCancelComplete:
    case RemoveCancelConfirm:
    case RemoveCancelExit:
    case RemoveCancelStart:
    case RetentionComplete:
    case RetentionStart:
    case StartTrial:
    case SubsConfig:
    case SubscriptionGroups:
    case PromoLanding:
    case DisplaySwitchToGTA:
    case LearnMoreGTA:
    case SwitchToGTA:
    case PreviewGTA:
    case TrialExpirationReminder: {
      trackPageView(eventName, event);
      break;
    }
    case Revenue: {
      trackRevenueAmplitude(eventName, event);
      break;
    }
    default: {
      break;
    }
  }
};
