import st from 'components/shared-translations';
import {
  alertAddressInvalidShow,
  alertAddressInvalidHide,
  alertWarningPaymentMethodHide
} from 'modules/alert-module';
import {
  daoProductsBySubscriptionKeyGet,
  daoProductsByProductFamilyKeyGet,
  daoProductsByProductKeysGet
} from 'dao/product-dao';
import {
  daoCartQuoteGet,
  daoCartPatch,
  daoCartItemPatch,
  daoCartItemPost
} from 'dao/cart-dao';
import { daoPaymentMethodsPost } from 'dao/payment-methods-dao';
import { daoQuoteOrderPost } from 'dao/quote-dao';
import { daoSubscriptionGroupsGet } from 'dao/subscription-dao';
import {
  billingAccountActive,
  billingAccountActiveKey,
  billingAccountCountryCode,
  billingAccountRequestGet
} from 'modules/billing-account';
import {
  experimentCancelOnOrderSuccess,
  experimentCancelRequestGet
} from 'modules/experiments';
import {
  errorsDismissAlertsByPrefix,
  errorsShowAlert,
  getErrorStatus,
  getErrorList,
  errorsRecordQuoteOrderPost
} from 'modules/errors-module';
import {
  modalOpen,
  modalExpiringTrialModalShouldShow
} from 'modules/modal-module';
import {
  PAYMENT_METHODS_REQUEST_POST,
  paymentMethodsDefaultAddressInvalid,
  paymentMethodsReceivePostFailure,
  paymentMethodsReceivePostSuccess,
  paymentMethodsRequestGet,
  cardinalAcsUrl
} from 'modules/payment-methods-module';
import {
  productsRequestBySubscriptionKeyGetSuccess,
  productsRequestBySubscriptionKeyGetFailure,
  productsRequestByProductFamilyKeyGetSuccess,
  productsRequestByProductFamilyKeyGetFailure,
  productsRequestByProductKeysGetSuccess,
  productsRequestByProductKeysGetFailure,
  PRODUCTS_REQUEST_BY_SUBSCRIPTION_KEY_GET,
  PRODUCTS_REQUEST_BY_PRODUCT_FAMILY_KEY_GET,
  PRODUCTS_REQUEST_BY_PRODUCT_KEYS_GET,
  productByKey,
  productsByFamilyTrial,
  productRelationshipRequestGet,
  ENHANCED_AUDIO_FLAT_RATE
} from 'modules/product-module';
import {
  QUOTE_ORDER_RECEIVE_POST_FAILURE,
  QUOTE_ORDER_RECEIVE_POST_SUCCESS,
  QUOTE_ORDER_REQUEST_POST,
  quoteItemNewSubscriptionBillingDurationByPeriod,
  QUOTE_REQUEST_GET,
  quoteAllSubscriptionKeys,
  quoteReceiveGetFailure,
  quoteReceiveGetSuccess
} from '../modules/quote';
import {
  SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE,
  SUBSCRIPTION_GROUPS_REQUEST_GET,
  subscriptionsAllProductKeys,
  subscriptionsActiveTrialExpiringSoonestSubscriptionKey,
  subscriptionGroupsReceiveGetSuccess,
  subscriptionModels
} from 'modules/subscription';
import {
  CartUpdate,
  FlowExit,
  ProductFamilies,
  RemoveCancelExit,
  SubscriptionGroups,
  SubscriptionGroupsViewContent,
  Order,
  Revenue,
  ProductFamiliesViewContent,
  SubscriptionGroupsViewItem,
  PurchaseError,
  CartUpdateGTM
} from 'modules/tracking-module';
import {
  CART_RECEIVE_PATCH_FAILURE,
  CART_REQUEST_PATCH,
  CART_ITEM_RECEIVE_PATCH_FAILURE,
  CART_ITEM_REQUEST_PATCH,
  CART_PATCH_ITEMS_AND_QUOTE,
  CART_ITEM_RECEIVE_POST_FAILURE,
  CART_ITEM_REQUEST_POST,
  cartReceivePatchSuccess,
  cartItemReceivePatchSuccess,
  cartRequestGet,
  cartPatchItemsAndQuoteFinish,
  cartItemCount,
  cartItemReceivePostSuccess,
  cartItemRequestDelete
} from 'modules/cart-module';
import {
  UI_SUBS_CONFIG_TOGGLE_BUY_TRY,
  uiSubsConfigPreviousSelectedProductKey,
  uiSubsConfigProductFamilyKey,
  uiSubsConfigSelectedProductKey,
  uiSubsConfigSelectProduct,
  uiSubsConfigTrialFlowSelected,
  uiSubsOverviewProductFamiliesShow
} from 'modules/ui';
import { meLocale } from 'modules/me-module';
import { track } from 'lib/tracking';
import history from 'lib/history';
import Selectors from './selectors';
import appRoute from 'lib/resolve-route';
import CybersourceFingerprint from 'lib/cybersource-fingerprint';
import { daoPayerAuthPost } from 'dao/payer-auth-dao';
import {
  PAYER_AUTH_REQUEST_POST,
  payerAuthRequestPostSuccess,
  payerAuthRequestPostFailure
} from 'modules/payment/payer-auth';


// ------------------------------------
// Action Creators
// ------------------------------------
const uiSubsConfigToggleBuyTry = (payload = {}) => (dispatch, getState) => {
  const state = getState();
  let newProductKey;
  let previousProductKey;
  const trialProducts = productsByFamilyTrial(state, uiSubsConfigProductFamilyKey(state));

  if (uiSubsConfigTrialFlowSelected(state)) {
    newProductKey = uiSubsConfigPreviousSelectedProductKey(state);
  } else {
    previousProductKey = uiSubsConfigSelectedProductKey(state);

    // If the productKey is provided, then preselect it;
    // otherwise, preselect the first item of the trial products list since we don't know which product to preselect when there are more than one trial per family
    newProductKey = payload.productKey || (trialProducts[0] || {}).key;
  }

  const selectProductPayload = {
    previousProductKey,
    key: newProductKey
  };

  dispatch(uiSubsConfigSelectProduct(selectProductPayload));
  return dispatch({type: UI_SUBS_CONFIG_TOGGLE_BUY_TRY});
};

const showBanner = (msgKey, values) => (dispatch) => {
  dispatch(errorsShowAlert({ ...st[msgKey], values }));
};

const paymentMethodsRequestPost = (billingAccountKey, payload = {}) => (dispatch, getState) => {
  const payloadWithFingerprint = {
    ...payload,
    fingerPrintSessionId: CybersourceFingerprint.id
  };

  dispatch({
    type: PAYMENT_METHODS_REQUEST_POST
  });

  return daoPaymentMethodsPost(billingAccountKey, payloadWithFingerprint)
    .then((response) => dispatch(paymentMethodsReceivePostSuccess(response.data)))
    .catch((ex) => dispatch(paymentMethodsReceivePostFailure(ex)))
    .finally(() => {
      const state = getState();
      const acsUrl = cardinalAcsUrl(state);
      if (!acsUrl) {
        dispatch(paymentMethodsRequestGet(billingAccountKey));
      }
    });
};

const payerAuthRequestPost = (paymentKeys, billingAccountKey, payload) => (dispatch) => {
  dispatch({
    type: PAYER_AUTH_REQUEST_POST
  });

  const { paymentAccountKey, paymentMethodsKey } = paymentKeys;
  return daoPayerAuthPost(paymentAccountKey, paymentMethodsKey, billingAccountKey, payload)
    .then((response) => dispatch(payerAuthRequestPostSuccess(response.data)))
    .catch((ex) => dispatch(payerAuthRequestPostFailure(ex)))
    .finally(() => dispatch(paymentMethodsRequestGet(billingAccountKey)));
};

const cartItemReceivePostFailure = (errorResponse = {}) => (dispatch) => {
  dispatch({
    type: CART_ITEM_RECEIVE_POST_FAILURE,
    errorResponse
  });
  let error;
  const status = getErrorStatus(errorResponse);
  if (status === 400) {
    error = getErrorList(errorResponse).find((err) => {
      switch (err.code) {
        case 'invalid':
        case 'invalid.type':
          switch (err.field) {
            case 'DUPLICATE_CART_ITEM':
              dispatch(showBanner('shared.alert.error.cartitem.duplicate'));
              return true;
            case 'quantity':
            case 'CART_ITEM_QUANTITY_INVALID':
              dispatch(showBanner('shared.alert.error.cartitem.invalid.quantity'));
              return true;
            case 'EXISTING_CART_ITEM_SUBSCRIPTION':
              dispatch(showBanner('shared.alert.error.cartitem.invalid.existing.subs'));
              return true;
            case 'billingPeriod':
            case 'billingDuration':
            case 'subscriptionKey':
            case 'productKey':
              dispatch(showBanner('shared.alert.error.cartitem.invalid.field'));
              return true;
            default:
              return false;
          }
        case 'invalid.format':
          switch (err.field) {
            case 'billingPeriod':
            case 'billingDuration':
              dispatch(showBanner('shared.alert.error.cartitem.general'));
              return true;
            default:
              return false;
          }
        case 'repeat.trial':
          dispatch(showBanner('shared.alert.error.cartitem.invalid.trial'));
          return true;
        default:
          return false;
      }
    });
  }
  if (status !== 403 && !error) {
    dispatch(showBanner('shared.alert.error.cartitem.general'));
  }
  return Promise.reject(errorResponse);
};

const cartItemRequestPost = ({productKey, quantity, billingPeriod, billingDuration, subscriptionKey}) => (dispatch, getState) => {
  dispatch({
    type: CART_ITEM_REQUEST_POST
  });

  const state = getState();
  const billingAccountKey = billingAccountActiveKey(state);
  const cartItem = {
    productKey,
    subscriptionKey,
    quantity,
    billingPeriod,
    billingDuration
  };

  return daoCartItemPost(billingAccountKey, cartItem)
    .then(
      (response) => dispatch(cartItemReceivePostSuccess(response.data)),
      (ex) => dispatch(cartItemReceivePostFailure(ex))
    );
};

const cartItemReceivePatchFailure = (errorResponse = {}) => (dispatch) => {
  dispatch({
    type: CART_ITEM_RECEIVE_PATCH_FAILURE,
    errorResponse
  });
  let error;
  const status = getErrorStatus(errorResponse);
  if (status === 400) {
    error = getErrorList(errorResponse).find((err) => {
      switch (err.code) {
        case 'invalid':
        case 'invalid.type':
          switch (err.field) {
            case 'quantity':
            case 'CART_ITEM_QUANTITY_INVALID':
              dispatch(showBanner('shared.alert.error.cartitem.invalid.quantity'));
              return true;
            case 'billingPeriod':
            case 'billingDuration':
            case 'subscriptionKey':
            case 'productKey':
            case 'removeUserKeys':
              dispatch(showBanner('shared.alert.error.cartitem.invalid.field'));
              return true;
            default:
              return false;
          }
        case 'repeat.trial':
          dispatch(showBanner('shared.alert.error.cartitem.invalid.trial'));
          return true;
        default:
          return false;
      }
    });
  }
  if (status !== 403 && !error) {
    dispatch(showBanner('shared.alert.error.cartitem.general'));
  }
};

const cartItemRequestPatch = (cartItemKey, payload = {}, partOfBatch = false) => (dispatch, getState) => {
  const billingAccountKey = billingAccountActiveKey(getState());
  let billingDuration;

  dispatch({
    type: CART_ITEM_REQUEST_PATCH
  });

  // if patching billingPeriod, we need to also grab the corresponding billingDuration and send that
  if (payload.billingPeriod) {
    billingDuration = quoteItemNewSubscriptionBillingDurationByPeriod(getState(), cartItemKey, payload.billingPeriod);
  }

  return daoCartItemPatch(billingAccountKey, cartItemKey, {...payload, billingDuration})
    .then(
      (response) => dispatch(cartItemReceivePatchSuccess(response.data, partOfBatch)),
      (ex) => dispatch(cartItemReceivePatchFailure(ex))
    );
};

const cartReceivePatchFailure = (errorResponse = {}) => (dispatch) => {
  dispatch({
    type: CART_RECEIVE_PATCH_FAILURE,
    errorResponse
  });
  if (getErrorStatus(errorResponse) !== 403) {
    dispatch(showBanner('shared.alert.error.cart.patch.general'));
  }
  return Promise.reject(errorResponse);
};

const cartRequestPatch = (billingAccountKey, payload = {}) => (dispatch) => {
  dispatch({
    type: CART_REQUEST_PATCH
  });

  return daoCartPatch(billingAccountKey, payload)
    .then(
      (response) => dispatch(cartReceivePatchSuccess(response.data)),
      (ex) => dispatch(cartReceivePatchFailure(ex))
    );
};

const subscriptionGroupsReceiveGetFailure = (payload = {}) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_GROUPS_RECEIVE_GET_FAILURE,
    payload
  });
  if (getErrorStatus(payload) !== 403) {
    dispatch(showBanner('shared.alert.error.general.refreshtryagain'));
  }
};

const subscriptionGroupsRequestGet = (billingAccountId) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_GROUPS_REQUEST_GET,
    billingAccountId
  });

  return daoSubscriptionGroupsGet(billingAccountId)
    .then((response) => {
      const { data } = response;
      /**
       * Remove G2W Flex Usage product from the subscription data.
       * As these products are not supported by Billing Portal right now.
      */
      const modifiedData = data.map((subscriptionObj) => {
        const subscriptions = subscriptionObj.subscriptions.filter((subscription) => subscription.productKey !== 'G2W_Plus_Flex_Usage');
        const modifiedSubscriptionObj = { ...subscriptionObj };
        modifiedSubscriptionObj.subscriptions = subscriptions;
        return modifiedSubscriptionObj;
      });
      return dispatch(subscriptionGroupsReceiveGetSuccess(modifiedData));
    })
    // TODO: Needs to be removed once FlatRate is migrated to Global Call me.
    .then((response) => {
      const { payload } = response;
      const isFlatRate = payload[0].subscriptions.some((subscription) => subscription.productKey === ENHANCED_AUDIO_FLAT_RATE);
      if (isFlatRate) {
        dispatch(productRelationshipRequestGet(ENHANCED_AUDIO_FLAT_RATE));
      }
    })
    .catch((ex) => dispatch(subscriptionGroupsReceiveGetFailure(ex)));
};

const productsRequestBySubscriptionKeyGet = (subscriptionKey, promoCodes) => (dispatch, getState) => {
  const currentState = getState();
  const billingAccountKey = billingAccountActiveKey(currentState);
  const locale = meLocale(currentState);

  dispatch({
    type: PRODUCTS_REQUEST_BY_SUBSCRIPTION_KEY_GET,
    subscriptionKey,
    promoCodes
  });
  return daoProductsBySubscriptionKeyGet(subscriptionKey, billingAccountKey, promoCodes, locale)
    .then(
      (response) => dispatch(productsRequestBySubscriptionKeyGetSuccess(subscriptionKey, response.data)),
      (ex) => dispatch(productsRequestBySubscriptionKeyGetFailure(ex))
    );
};

const productsRequestByProductFamilyKeyGet = (productFamilyKey, promoCodes) => (dispatch, getState) => {
  const currentState = getState();
  const billingAccountKey = billingAccountActiveKey(currentState);
  const locale = meLocale(currentState);
  dispatch({
    type: PRODUCTS_REQUEST_BY_PRODUCT_FAMILY_KEY_GET,
    productFamilyKey,
    promoCodes
  });

  return daoProductsByProductFamilyKeyGet(productFamilyKey, billingAccountKey, promoCodes, locale)
    .then(
      (response) => dispatch(productsRequestByProductFamilyKeyGetSuccess(response.data)),
      (ex) => dispatch(productsRequestByProductFamilyKeyGetFailure(ex))
    );
};

const productsRequestByProductKeysGet = (productKeys, promoCodes) => (dispatch, getState) => {
  const currentState = getState();
  const currencyCode = billingAccountCountryCode(currentState);
  const locale = meLocale(currentState);
  if (productKeys.length < 1) {
    // exit early if we don't have productKeys to retrieve data for
    return Promise.resolve();
  }

  dispatch({
    type: PRODUCTS_REQUEST_BY_PRODUCT_KEYS_GET
  });

  return daoProductsByProductKeysGet(productKeys, currencyCode, promoCodes, locale)
    .then(
      (response) => dispatch(productsRequestByProductKeysGetSuccess(response.data)),
      (ex) => dispatch(productsRequestByProductKeysGetFailure(ex))
    );
};

const quoteOrderReceivePostSuccess = (billingAccountKey, payload = {}, orderInfo) => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: QUOTE_ORDER_RECEIVE_POST_SUCCESS,
    payload,
    meta: {
      tracking: {
        [Order]: Selectors.payloadForOrderEvent(state),
        [Revenue]: orderInfo.orderItems
      }
    }
  });

  dispatch(experimentCancelOnOrderSuccess(orderInfo.quoteSubscriptionKeys));
  if (!payload.acsUrl) {
    history.push(appRoute.resolve('order.confirmation', {billingAccountKey}));
  }
  return Promise.resolve(payload);
};

const quoteOrderReceivePostFailure = (payload = {}) => (dispatch) => {
  dispatch({
    type: QUOTE_ORDER_RECEIVE_POST_FAILURE,
    payload
  });
  dispatch(track({
    [PurchaseError]: payload.status
  }));
  dispatch(errorsRecordQuoteOrderPost(payload));

  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

const quoteOrderRequestPost = (billingAccountKey, currentQuoteKey, orderItems, psd2Palyload = {}) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: QUOTE_ORDER_REQUEST_POST
  });

  const payload = {
    // NOTE: sending fingerprint id is not necessary anymore but the COG currently can't handle not sending any value so we send the quote key.
    // @TODO BPOR-1768 - Remove fingerPrintKey from POST /orders payload
    fingerPrintKey: currentQuoteKey,
    termsOfServiceAccepted: true,
    ...psd2Palyload
  };

  const orderInfo = {
    quoteSubscriptionKeys: quoteAllSubscriptionKeys(state),
    orderItems
  };

  return daoQuoteOrderPost(billingAccountKey, currentQuoteKey, payload)
    .then(
      (response) => dispatch(quoteOrderReceivePostSuccess(billingAccountKey, response.data, orderInfo)),
      (ex) => dispatch(quoteOrderReceivePostFailure(ex))
    );
};

const clearPaymentMethodsErrorBanners = () => (dispatch) => {
  dispatch(errorsDismissAlertsByPrefix('(shared.)?alert.error.paymentmethod'));
};

const clearProductErrorBanners = () => (dispatch) => {
  dispatch(errorsDismissAlertsByPrefix('(shared.)?alert.error.products'));
};

const clearCartItemErrorBanners = () => (dispatch) => {
  dispatch(errorsDismissAlertsByPrefix('(shared.)?alert.error.cartitem'));
};

const clearCartPatchErrorBanners = () => (dispatch) => {
  dispatch(errorsDismissAlertsByPrefix('(shared.)?alert.error.cart.patch'));
};

const setProductFamiliesMenuState = (isOpen = '') => (dispatch) => {
  const shouldBeOpen = isOpen.toLowerCase() === 'true';
  if (shouldBeOpen) {
    dispatch(uiSubsOverviewProductFamiliesShow());
    dispatch(track({
      [ProductFamilies]: {},
      [ProductFamiliesViewContent]: {}
    }));
  }
};

const handleRedirectOnPaymentMethodsForm = (response, redirectOverride) => async (dispatch, getState) => {
  const currentState = getState();
  const billingAccount = billingAccountActive(currentState);
  const validAddress = (response.payload || {}).validAddress;
  if (validAddress === false) {
    dispatch(alertAddressInvalidShow());
  } else {
    dispatch(alertAddressInvalidHide());
  }
  if (redirectOverride.route) {
    history.replace(
      appRoute.resolve(redirectOverride.route, {billingAccountKey: billingAccount.key, routeParams: [redirectOverride.params]})
    );
  } else {
    history.replace(
      appRoute.resolve('paymentmethods', {billingAccountKey: billingAccount.key})
    );
  }

  await dispatch(billingAccountRequestGet(billingAccount.key));
  // If the user does not dismiss the payment method warning banner, we will hide banner when the user updates the payment method successfully
  dispatch(alertWarningPaymentMethodHide());
};

const loadBillingAddressFormData = () => async (dispatch, getState) => {
  let currentState = getState();
  const billingAccount = billingAccountActive(currentState);

  await dispatch(paymentMethodsRequestGet(billingAccount.key));
  // Update the state after retrieving the payment data
  currentState = getState();

  if (paymentMethodsDefaultAddressInvalid(currentState)) {
    dispatch(alertAddressInvalidShow());
  }

  if (!subscriptionModels(currentState).length) {
    // @NOTE We need this call in order to determine whether to show the care link or not
    await dispatch(subscriptionGroupsRequestGet(billingAccount.key));
  }
};

const fetchEEData = () => (dispatch, getState) => {
  const currentState = getState();
  const eeCalls = subscriptionModels(currentState)
    .map((subscriptionModel) => dispatch(experimentCancelRequestGet(subscriptionModel)));

  return Promise.all(eeCalls);
};

const loadSubscriptionsOverview = (ownProps) => (dispatch, getState) => {
  let currentState = getState();
  const billingAccount = billingAccountActive(currentState);
  return dispatch(subscriptionGroupsRequestGet(billingAccount.key))
    .then(() => {
      dispatch(fetchEEData());
      // this get products call is needed in order to know about whether products are usage, and also
      // drives some selectors needed by the add quantity button which uses minQuantity and maxQuantity
      currentState = getState();
      const subscriptionKeys = subscriptionsAllProductKeys(currentState);

      if (subscriptionKeys.length > 0) {
        return dispatch(productsRequestByProductKeysGet(subscriptionKeys))
          .catch((errorResponse) => {
            if (getErrorStatus(errorResponse) !== 403) {
              dispatch(showBanner('shared.alert.error.products.general'));
            }
          });
      }
      return false;
    })
    .then(() => {
      dispatch(track({
        [SubscriptionGroups]: {},
        [SubscriptionGroupsViewContent]: {},
        [SubscriptionGroupsViewItem]: {}
      }));
      currentState = getState();
      const menuIsOpen = Selectors.queryStringProp('expandProductDropdown')(currentState, ownProps);
      dispatch(setProductFamiliesMenuState(menuIsOpen));
    });
};

const loadModalRegistry = () => (dispatch, getState) => {
  const currentState = getState();
  const subscriptionKeyActiveTrialExpiring = subscriptionsActiveTrialExpiringSoonestSubscriptionKey(currentState);
  if (modalExpiringTrialModalShouldShow(currentState) && !!subscriptionKeyActiveTrialExpiring) {
    dispatch(modalOpen('expiring-trial-subscribe-modal', {subscriptionKey: subscriptionKeyActiveTrialExpiring}));
  }
};

const onFlowExit = (subscription) => (dispatch) => {
  const { productFamilyKey, key } = subscription;
  dispatch(track({ [FlowExit]: { subscriptionKey: key, productFamilyKey } }));
};

const onRemoveCancelExit = (subscription) => (dispatch) => {
  const { productFamilyKey, key } = subscription;
  dispatch(track({ [RemoveCancelExit]: { productFamilyKey, subscriptionKey: key } }));
};

const quoteRequestGet = () => (dispatch, getState) => {
  if (cartItemCount(getState()) === 0) {
    // resolve early; we don't need to get a quote for an empty cart
    return Promise.resolve();
  }

  dispatch({
    type: QUOTE_REQUEST_GET
  });

  return daoCartQuoteGet(billingAccountActiveKey(getState()))
    .then((response) => {
      const { data } = response;
      const items = data.items.filter((item) => item.newSubscription.product.visible);
      const result = {
        ...data,
        items
      };

      let productKeys = [];

      items.forEach((quoteItem) => {
        const newSubscriptionProductKey = (quoteItem.newSubscription || {}).productKey;
        const currentSubscriptionProductKey = (quoteItem.currentSubscription) ? quoteItem.currentSubscription.productKey : undefined;

        if (newSubscriptionProductKey) productKeys.push(newSubscriptionProductKey);
        if (currentSubscriptionProductKey && newSubscriptionProductKey !== currentSubscriptionProductKey) productKeys.push(currentSubscriptionProductKey);
      });

      // Ensures we don't have duplicate productKeys
      productKeys = productKeys.filter((productKey, index, array) => array.indexOf(productKey) === index);

      // if we have product keys to fetch, make the quote request wait for the product results before returning
      if (productKeys.length) {
        // Clear out the product banner in case the user tries to checkout again before refreshing the page
        dispatch(errorsDismissAlertsByPrefix('(shared.)?alert.error.products'));
        // This logic seems to tightly couple quote and product, but that was an intentional design decision that was made,
        // due to the fact that we *always* need product data to support the quote data. In particular, addon and price information
        // are not included in the quote call, yet they are needed to support all of the various quote-related routes.
        return dispatch(productsRequestByProductKeysGet(productKeys))
          .then(() => {
            /**
             * In order to make sure our quote items have the right product model
             * attached to them, start by making a record of products that were
             * previously requested
             */
            const currentState = getState();
            const products = productKeys.reduce((acc, productKey) => {
              acc[productKey] = productByKey(currentState, productKey);
              return acc;
            }, {});
            /**
             * Now iterate the quotes and attach the product to all newSubscription
             * and currentSubscription objects.
             */
            result.items.forEach((quoteItem) => {
              const { currentSubscription, newSubscription } = quoteItem;
              // each quote item _must_ have a newSubscription, and _can_ have
              // a currentSubscription
              if (currentSubscription) {
                currentSubscription.product = products[currentSubscription.productKey];
              }
              newSubscription.product = products[newSubscription.productKey];
            });
            return dispatch(quoteReceiveGetSuccess(result));
          })
          .catch((errorResponse) => {
            if (getErrorStatus(errorResponse) !== 403) {
              dispatch(errorsShowAlert({ ...st['shared.alert.error.products.encounterederror.tryagain']}));
            }
            dispatch(quoteReceiveGetFailure());
          });
      }

      return dispatch(quoteReceiveGetSuccess(result));
    },
    (ex) => dispatch(quoteReceiveGetFailure(ex))
    );
};

/**
 * Takes an ownProps object and dispatches a request to delete with all the cart item keys that should be deleted.
 * Once the cart items have been deleted it fetches the updated quote.
 */
const cartItemsDelete = (ownProps) => async (dispatch) => {
  const { baseProductToRemove = {}, addOnsToRemove = [], cartItemKey } = ownProps;
  const addOnsToRemoveCartItemKeys = addOnsToRemove.map((addOn) => addOn.cartItemKey);
  const allProductsToRemoveKeys = [...addOnsToRemoveCartItemKeys, baseProductToRemove.cartItemKey, cartItemKey].filter(Boolean);
  await dispatch(cartItemRequestDelete(allProductsToRemoveKeys));
  return dispatch(quoteRequestGet());
};

/**
 * Takes an array of cartItems and dispatches a patch for each item.
 * Once all cartItems have been patched it fetches the updated quote.
 * Note: Passes `true` as third argument to cartItemRequestPatch to indicate that we are batch updating cartItems
 * so we will not set isLoading to false on the success of any of the patches.
 */
const cartPatchItemsAndQuote = (cartItemsToPatch) => (dispatch, getState) => {
  dispatch({
    type: CART_PATCH_ITEMS_AND_QUOTE
  });

  const cartItemPromises = [];

  const state = getState();
  const initialPayload = {};
  cartItemsToPatch.forEach((cartItem) => {
    initialPayload[cartItem.cartItemKey] = Selectors.cartAddOrRemovePayload(state, cartItem.cartItemKey);
  });

  cartItemsToPatch.forEach((cartItem) => {
    cartItemPromises.push(dispatch(cartItemRequestPatch(cartItem.cartItemKey, cartItem, true)));
  });

  return Promise.all(cartItemPromises)
    .then(() => Promise.all([
      dispatch(quoteRequestGet()),
      dispatch(cartRequestGet())
    ]))
    .then(() => dispatch(cartPatchItemsAndQuoteFinish()))
    .then(() => dispatch(track({
      [CartUpdate]: {},
      [CartUpdateGTM]: {
        initialPayload,
        cartItemsToPatch
      }
    })));
};

export default {
  uiSubsConfigToggleBuyTry,
  handleRedirectOnPaymentMethodsForm,
  loadBillingAddressFormData,
  cartPatchItemsAndQuote,
  clearProductErrorBanners,
  loadModalRegistry,
  loadSubscriptionsOverview,
  onFlowExit,
  onRemoveCancelExit,
  setProductFamiliesMenuState,
  showBanner,
  quoteRequestGet,
  quoteOrderReceivePostSuccess,
  quoteOrderReceivePostFailure,
  quoteOrderRequestPost,
  subscriptionGroupsRequestGet,
  cartRequestPatch,
  paymentMethodsRequestPost,
  productsRequestBySubscriptionKeyGet,
  productsRequestByProductFamilyKeyGet,
  productsRequestByProductKeysGet,
  cartItemRequestPost,
  clearCartItemErrorBanners,
  cartItemRequestPatch,
  clearCartPatchErrorBanners,
  clearPaymentMethodsErrorBanners,
  cartItemsDelete,
  payerAuthRequestPost
};
