import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BillingInformation from '../../../billing-information';
import Button from 'components/button';
import st from 'components/shared-translations';

export const BillingAddressFormView = (props) => (
  <div id="edit-billing-address-form" className="text-left">
    <form id={props.formId} onSubmit={props.handleSubmit}>
      <BillingInformation formId={props.formId} />
      <Button
        id="submit-credit-card-form"
        type="submit"
        className="float-right mr-4"
        disabled={props.disableFormSubmissionButton}
        isLoading={props.patchIsLoading}
      >
        <FormattedMessage {...st['shared.updatebillingaddress']} />
      </Button>
    </form>
  </div>
);

BillingAddressFormView.propTypes = {
  disableFormSubmissionButton: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  patchIsLoading: PropTypes.bool.isRequired
};

export default BillingAddressFormView;
