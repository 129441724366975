import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  paymentInfoSelectedType,
  paymentInfoSelectedTypeIsSepa
} from 'modules/payment-info-module';
import {
  BILLING_INFORMATION_FORM_FIELD_RELATIONSHIP_TOUCH,
  billingInformationFieldsNames,
  fieldsMeta,
  formFieldsRequiredCountries,
  formValues,
  paymentFormStateFieldIsRequired,
  reduxFormActions
} from 'modules/form';
import {
  paymentMethodsDefaultAddress,
  paymentMethodsDefaultAddressInvalid,
  paymentMethodsDefaultType,
  paymentMethodsExist
} from 'modules/payment-methods-module';
import { subscriptionsActiveHavePaidProducts } from 'modules/subscription';
import BillingInformationView from './billing-information-view';
import CountriesData from 'lib/countries-data';

export const mapStateToProps = (state, { formId }) => ({
  formValues: formValues(state, formId),
  paymentInfoSelectedType: paymentInfoSelectedType(state),
  paymentInfoSelectedTypeIsSepa: paymentInfoSelectedTypeIsSepa(state),
  paymentMethodsDefaultAddress: paymentMethodsDefaultAddress(state),
  paymentMethodsDefaultAddressInvalid: paymentMethodsDefaultAddressInvalid(state),
  paymentMethodsDefaultType: paymentMethodsDefaultType(state),
  paymentMethodsExist: paymentMethodsExist(state),
  subscriptionsActiveHavePaidProducts: subscriptionsActiveHavePaidProducts(state),
  fieldsMeta: fieldsMeta(state, formId)
});

export const mapDispatchToProps = (dispatch, { formId }) => ({
  changeFormFieldValue: (fieldName, newValue) => dispatch(reduxFormActions.change(formId, fieldName, newValue)),
  touchFormFields: (fields) => dispatch(reduxFormActions.touch(formId, ...fields))
});

export class BillingInformationContainer extends Component {
  /**
   * Returns the country field value
   *
   * @returns {string}
   */
  formFieldCountryValue = () => this.props.formValues[billingInformationFieldsNames.FORM_FIELD_COUNTRY] || '';

  /**
   * Returns the country keys
   *
   * @returns {array}
   */
  countryKeys = () => CountriesData.getCountryKeys() || [];

  /**
   * Returns the country state codes by the country key
   *
   * @returns {array}
   */
  countryStateCodes = (countryKey) => CountriesData.getStateCodesByCountryKey(countryKey) || [];

  /**
   * Country dropdown should be disabled if:
   *   1. The account already has a payment method AND
   *   2. The account has current (active) paid subscriptions
   */
  countryDropdownDisabled = () => this.props.subscriptionsActiveHavePaidProducts && this.props.paymentMethodsExist;

  /**
   * @returns {array}
   */
  getTouchFields = (fieldName) => BILLING_INFORMATION_FORM_FIELD_RELATIONSHIP_TOUCH[fieldName] || [];

  /**
   * Postal Code should be optional if:
   *  1. The payment method type selected is not SEPA AND
   *  2. The country selected does not have Postal Code as mandatory
   *
   * @returns {boolean}
   */
  postalCodeOptional = () => (
    !this.props.paymentInfoSelectedTypeIsSepa &&
    !formFieldsRequiredCountries[billingInformationFieldsNames.FORM_FIELD_POSTAL_CODE].includes(this.formFieldCountryValue())
  )

  componentDidUpdate = (prevProps) => {
    // If the country field's value changes, then we will reset the state field's value
    if (prevProps.formValues[billingInformationFieldsNames.FORM_FIELD_COUNTRY] !== this.props.formValues[billingInformationFieldsNames.FORM_FIELD_COUNTRY]) {
      this.props.changeFormFieldValue(billingInformationFieldsNames.FORM_FIELD_STATE, '');
    }

    Object.keys(this.props.formValues).forEach((fieldName) => {
      // Filter out the fields which are already touched
      const formFieldsNonTouch = this.getTouchFields(fieldName).filter((fieldNameTouch) => !(this.props.fieldsMeta[fieldNameTouch] || {}).touched);
      // If a field value changes and that field affects the state of other fields, then the affected fields should be touched if they're not already touched
      if (!!formFieldsNonTouch.length && prevProps.formValues[fieldName] !== this.props.formValues[fieldName]) {
        this.props.touchFormFields(formFieldsNonTouch);
      }
    });
  };

  /**
   * An address field is considered invalid if:
   *   1. the address as a whole is invalid AND
   *   2. it contains some data AND
   *   3. The address field belongs to the address that's being edited
   *
   * @returns {boolean}
   */
  addressFieldInvalid = (addressField) => (
    !!this.props.paymentMethodsDefaultAddress[addressField]
    && this.props.paymentMethodsDefaultAddressInvalid
    && this.props.paymentMethodsDefaultType === this.props.paymentInfoSelectedType
  );

  /**
   * Returns an object that contains the address fields as keys and the warning className as their values if they're invalid
   *
   * @returns {object}
   */
  addWarningClassNameToAddressFields = () => (
    Object.keys(this.props.paymentMethodsDefaultAddress).reduce((acc, addressField) => {
      if (this.addressFieldInvalid(addressField)) {
        acc[addressField] = 'addressInvalid';
      } else {
        acc[addressField] = '';
      }
      return acc;
    }, {})
  );

  /**
   * Show the state field only if it's required and the selected country has states
   *
   * @returns {boolean}
   */
  showStateField = () => (
    paymentFormStateFieldIsRequired(this.props.formValues) &&
    !!this.countryStateCodes(this.formFieldCountryValue()).length
  );

  render = () => (
    <BillingInformationView
      addressFieldClassNames={this.addWarningClassNameToAddressFields()}
      billingInformationFieldsNames={billingInformationFieldsNames}
      countryDropdownDisabled={this.countryDropdownDisabled()}
      countryKeys={this.countryKeys()}
      countryStateCodes={this.countryStateCodes(this.formFieldCountryValue())}
      formId={this.props.formId}
      paymentInfoSelectedTypeIsSepa={this.props.paymentInfoSelectedTypeIsSepa}
      showStateField={this.showStateField()}
      selectedCountryValue={this.formFieldCountryValue()}
      isPostalCodeOptional={this.postalCodeOptional()}
      labelMessage={this.getLabelMessage}
    />
  );
}

BillingInformationContainer.propTypes = {
  changeFormFieldValue: PropTypes.func.isRequired,
  fieldsMeta: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  paymentInfoSelectedType: PropTypes.string.isRequired,
  paymentInfoSelectedTypeIsSepa: PropTypes.bool.isRequired,
  paymentMethodsDefaultAddress: PropTypes.object.isRequired,
  paymentMethodsDefaultAddressInvalid: PropTypes.bool.isRequired,
  paymentMethodsDefaultType: PropTypes.string.isRequired,
  paymentMethodsExist: PropTypes.bool.isRequired,
  subscriptionsActiveHavePaidProducts: PropTypes.bool.isRequired,
  touchFormFields: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInformationContainer);
