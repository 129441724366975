// ------------------------------------
// Action Types
// ------------------------------------
export const BILLING_ACCOUNT_REQUEST_GET = 'BILLING_ACCOUNT_REQUEST_GET';
export const BILLING_ACCOUNT_RECEIVE_GET_SUCCESS = 'BILLING_ACCOUNT_RECEIVE_GET_SUCCESS';
export const BILLING_ACCOUNT_RECEIVE_GET_FAILURE = 'BILLING_ACCOUNT_RECEIVE_GET_FAILURE';
export const BILLING_ACCOUNT_REQUEST_PATCH = 'BILLING_ACCOUNT_REQUEST_PATCH';
export const BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS = 'BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS';
export const BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE = 'BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE';
export const BILLING_ACCOUNT_FRAUD_REQUEST_GET = 'BILLING_ACCOUNT_FRAUD_REQUEST_GET';
export const BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS = 'BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS';
export const BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE = 'BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE';
