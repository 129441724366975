import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { errorsDismissAlert } from 'modules/errors-module';
import AlertErrorView from './alert-error-view';

export const mapDispatchToProps = (dispatch, ownProps) => ({
  handleAlertDismiss: () => dispatch(errorsDismissAlert({key: ownProps.msg.key}))
});

export const AlertErrorContainer = (props) => (
  <AlertErrorView msg={props.msg} handleAlertDismiss={props.handleAlertDismiss} />
);

AlertErrorContainer.propTypes = {
  msg: PropTypes.shape({
    key: PropTypes.string.isRequired,
    msgObj: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  }).isRequired,
  handleAlertDismiss: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(AlertErrorContainer);
