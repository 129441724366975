import environment from 'lib/environment';
import {
  INVOICES_RECEIVE_GET_FAILURE,
  INVOICES_RECEIVE_GET_SUCCESS,
  INVOICES_REQUEST_GET,
  INVOICE_PREVIEW_RECEIVE_GET_FAILURE,
  INVOICE_PREVIEW_RECEIVE_GET_SUCCESS,
  INVOICE_PREVIEW_REQUEST_GET,
  INVOICE_RECEIVE_GET_FAILURE,
  INVOICE_RECEIVE_GET_SUCCESS,
  INVOICE_REQUEST_GET
} from './invoice-action-types';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  invoice: {
    subTotal: '0.0',
    totalTaxes: [],
    grandTotal: '0.0',
    items: []
  },
  invoices: [],
  isLoading: false
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const invoiceGetSuccessHandler = (state, action) => {
  const { currencyCode, subTotal, grandTotal, items, totalTaxes } = action.payload;
  return ({
    ...state,
    invoice: {
      subTotal,
      totalTaxes: totalTaxes.map((tax) => ({
        name: tax.name,
        description: tax.description,
        amount: tax.amount,
        percent: tax.percent
      })),
      grandTotal,
      currencyCode,
      items
    },
    isLoading: false
  });
};

const ACTION_HANDLERS = () => ({
  [INVOICES_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [INVOICES_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (Array.isArray(action.payload)) {
      return {
        ...state,
        invoices: action.payload.map((invoice) => ({
          key: invoice.key,
          createDate: invoice.createDate,
          grandTotal: invoice.grandTotal,
          currencyCode: invoice.currencyCode,
          url: `${environment.get('invoice.url')}${invoice.key}`
        })),
        isLoading: false
      };
    }
    return {...state, isLoading: false};
  },
  [INVOICES_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [INVOICE_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [INVOICE_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return invoiceGetSuccessHandler(state, action);
    }
    return {...state, isLoading: false};
  },
  [INVOICE_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [INVOICE_PREVIEW_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [INVOICE_PREVIEW_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return invoiceGetSuccessHandler(state, action);
    }

    // If there is no invoice preview to show, COG returns 204 - no content, with undefined payload.
    // In this case clear the state back to initial state.
    return {...initialState};
  },
  [INVOICE_PREVIEW_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const invoiceReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default invoiceReducer;
