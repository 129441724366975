// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_CREATE = 'MODAL_CREATE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_GO_TO_STEP = 'MODAL_GO_TO_STEP';
export const MODAL_GO_TO_NEXT_STEP = 'MODAL_GO_TO_NEXT_STEP';
export const MODAL_EXPIRING_TRIAL_HIDE = 'MODAL_EXPIRING_TRIAL_HIDE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  registry: [],
  // @NOTE expiringTrialModal flag should be set to true here
  expiringTrialModal: true
};

// ------------------------------------
// Selectors
// ------------------------------------
export const modalTree = (state) => state.modal || {};
export const modalRegistryTree = (state) => modalTree(state).registry || [];
export const modalVisible = (state, modalId) => modalRegistryTree(state).some((modal) => modal.id === modalId && modal.visible);
export const modalCurrentStep = (state, modalId) => {
  const foundModal = modalRegistryTree(state).find((modal) => modal.id === modalId);
  return (foundModal) ? foundModal.step : '';
};
export const modalData = (state, modalId) => {
  const foundModal = modalRegistryTree(state).find((modal) => modal.id === modalId);
  return (foundModal) ? foundModal.data : {};
};
export const modalExpiringTrialModalShouldShow = (state) => modalTree(state).expiringTrialModal || false;
// ------------------------------------
// Actions
// ------------------------------------
export const modalExpiringTrialHide = () => ({
  type: MODAL_EXPIRING_TRIAL_HIDE
});

export const modalCreate = (modalId = '', startStep = '', data = {}) => ({
  type: MODAL_CREATE,
  payload: {
    modalId,
    startStep,
    data
  }
});

export const modalOpen = (modalId = '', data = {}) => ({
  type: MODAL_OPEN,
  payload: {
    modalId,
    data
  }
});

export const modalClose = (modalId, startStep) => ({
  type: MODAL_CLOSE,
  payload: {modalId, startStep}
});

export const modalGoToStep = (modalId, step, data) => ({
  type: MODAL_GO_TO_STEP,
  payload: {modalId, step, data}
});

export const modalGoToNextStep = (modalId, steps = []) => ({
  type: MODAL_GO_TO_NEXT_STEP,
  payload: {modalId, steps}
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [MODAL_CREATE]: (state, { payload }) => {
    const match = state.registry.findIndex((modal) => modal.id === payload.modalId);
    if (match !== -1) {
      // replace existing modal
      return {
        ...state,
        registry: state.registry.map((modal) => {
          if (modal.id === payload.modalId) {
            return {
              id: payload.modalId,
              step: payload.startStep,
              visible: false,
              data: {...payload.data}
            };
          }
          return modal;
        })
      };
    }
    // create new modal
    return {
      ...state,
      registry: [
        ...state.registry,
        {
          id: payload.modalId,
          step: payload.startStep,
          visible: false,
          data: {...payload.data}
        }
      ]
    };
  },
  [MODAL_OPEN]: (state, { payload }) => ({
    ...state,
    registry: state.registry.map((modal) => {
      if (modal.id === payload.modalId) {
        return {
          ...modal,
          visible: true,
          data: {
            ...modal.data,
            ...payload.data
          }
        };
      }
      return modal;
    })
  }),
  [MODAL_CLOSE]: (state, { payload }) => ({
    ...state,
    registry: state.registry.map((modal) => {
      if (modal.id === payload.modalId) {
        return {
          ...modal,
          step: payload.startStep,
          visible: false
        };
      }
      return modal;
    })
  }),
  [MODAL_GO_TO_STEP]: (state, { payload }) => ({
    ...state,
    registry: state.registry.map((modal) => {
      if (modal.id === payload.modalId) {
        return {
          ...modal,
          step: payload.step,
          data: {
            ...modal.data,
            ...payload.data
          }
        };
      }
      return modal;
    })
  }),
  [MODAL_GO_TO_NEXT_STEP]: (state, { payload }) => ({
    ...state,
    registry: state.registry.map((modal) => {
      if (modal.id === payload.modalId) {
        let nextStep;
        const currentStepIndex = payload.steps.findIndex((step) => step.id === modal.step);
        if (currentStepIndex !== -1) {
          nextStep = (payload.steps[currentStepIndex + 1] || {}).id || '';
        } else {
          nextStep = modal.step;
        }

        return {
          ...modal,
          step: nextStep
        };
      }
      return modal;
    })
  }),
  [MODAL_EXPIRING_TRIAL_HIDE]: (state) => ({
    ...state,
    expiringTrialModal: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
const modalReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default modalReducer;
