import actions from 'redux-form/lib/actions';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import form from 'redux-form/lib/reducer';

// ------------------------------------
// Actions
// ------------------------------------

export const reduxFormActions = { ...actions };

// ------------------------------------
// Other Redux form APIs
// ------------------------------------
export const reduxFormHOC = reduxForm;
export const ReduxFormSubmissionError = SubmissionError;
export const ReduxFormField = Field;
export const reduxFormReducer = form;
