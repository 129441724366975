import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CoreView from './core-view';
import {
  BILLING_ACCOUNT_ROLES,
  billingAccountActiveKey,
  billingAccountFraudRequestGet,
  billingAccountPaymentStatusIsGraceRenewal,
  billingAccountRestrictPaymentMethod,
  billingAccountUserAuthorizedByRoles
} from 'modules/billing-account';
import {
  alertAuthenticationExpiredVisible,
  alertAuthenticationWarningVisible,
  alertExpiredPaymentMethodMessageVisible,
  alertExpiredPaymentMethodToggle,
  alertFraudVisible,
  alertPreconfigurationVisible,
  alertProductUnmatchedKey,
  alertProductUnmatchedVisible,
  alertWarningAddressInvalidVisible,
  alertWarningPaymentMethodShow,
  alertWarningPaymentMethodVisible,
  alertSepaSetupSuccessVisible
} from 'modules/alert-module';
import Session from 'lib/session';
import resolveRoute from 'lib/resolve-route';
import appconfig from 'config/appconfig';
import { errorsAlertsAreVisible } from 'modules/errors-module';
import {
  paymentMethodDefaultIsExpired,
  paymentMethodsCreditCardIsExisting9sAffected,
  paymentMethodsRequestGet
} from 'modules/payment-methods-module';
import { meLanguage } from 'modules/me-module';
import { accountToggleUnifiedAdmin } from 'modules/account-module';

export const mapStateToProps = (state, ownProps) => ({
  alertAuthExpiredIsVisible: alertAuthenticationExpiredVisible(state),
  alertAuthWarningIsVisible: alertAuthenticationWarningVisible(state),
  alertErrorsAreVisible: errorsAlertsAreVisible(state),
  alertExpiredPaymentMethodMessageVisible: alertExpiredPaymentMethodMessageVisible(state),
  alertFraudIsVisible: alertFraudVisible(state),
  alertPreconfigurationIsVisible: alertPreconfigurationVisible(state),
  alertProductUnmatchedKey: alertProductUnmatchedKey(state),
  alertProductUnmatchedVisible: alertProductUnmatchedVisible(state),
  alertWarningAddressInvalidVisible: alertWarningAddressInvalidVisible(state),
  alertWarningPaymentMethodIsVisible: alertWarningPaymentMethodVisible(state),
  alertSepaSetupSuccessIsVisible: alertSepaSetupSuccessVisible(state),
  paymentMethodsCreditCardIsExisting9sAffected: paymentMethodsCreditCardIsExisting9sAffected(state),
  billingAccountActiveKey: billingAccountActiveKey(state),
  userLanguage: meLanguage(state),
  paymentMethodDefaultIsExpired: paymentMethodDefaultIsExpired(state),
  paymentStatusIsGraceRenewal: billingAccountPaymentStatusIsGraceRenewal(state),
  restrictPaymentMethod: billingAccountRestrictPaymentMethod(state),
  userAuthorized: billingAccountUserAuthorizedByRoles([BILLING_ACCOUNT_ROLES.READ_BILLING_ACCOUNT, BILLING_ACCOUNT_ROLES.BILLING_ADMIN])(state, ownProps),
  identityToggleUnifiedAdmin: accountToggleUnifiedAdmin(state)
});

// eslint-disable-next-line react-redux/mapDispatchToProps-prefer-shorthand
export const mapDispatchToProps = (dispatch) => ({
  alertExpiredPaymentMethodToggle: () => dispatch(alertExpiredPaymentMethodToggle()),
  alertWarningPaymentMethodShow: () => dispatch(alertWarningPaymentMethodShow()),
  checkFraud: (billingAccountKey) => dispatch(billingAccountFraudRequestGet(billingAccountKey)),
  paymentMethodsRequestGet: (billingAccountKey) => dispatch(paymentMethodsRequestGet(billingAccountKey))
});

const accountURIPattern = /\/account\/(\d+)(.+)?/i;

export class CoreContainer extends Component {
  routeToDefault = () => resolveRoute.resolve('root', { billingAccountKey: this.props.billingAccountActiveKey });

  destinationIsKeyless = (destination) => {
    const keylessRoutes = appconfig.routes.noKey;
    let routeIsKeyless = false;
    for (const route in keylessRoutes) {
      if (Object.prototype.hasOwnProperty.call(keylessRoutes, route) && keylessRoutes[route]() === destination) {
        routeIsKeyless = true;
        break;
      }
    }
    return routeIsKeyless;
  };

  routeToError = () => {
    const err = {
      status: 401,
      data: {
        errors: [
          {
            code: 'invalid.billingaccountkey'
          }
        ]
      }
    };
    Session.setItem(appconfig.storage.last_error, JSON.stringify(err));
    return resolveRoute.resolve('error');
  };

  determineUserDestination = () => {
    // First, set route to the users originally intended destination
    const originalTarget = Session.getOriginalTarget();
    let userDestination = this.props.location.pathname;
    let queryParams = '';

    if (originalTarget) {
      // If there is a destination in storage then clear it out and plan on routing to it.
      // Does not use resolve-route because we are just handling user intent here
      Session.removeOriginalTarget();
      userDestination = originalTarget;
      const queryParamsStart = originalTarget.indexOf('?');
      if (queryParamsStart >= 0) {
        // if the original target includes query parameters we need to pull those off and add them back on at the very end.
        userDestination = originalTarget.slice(0, queryParamsStart);
        queryParams = originalTarget.slice(queryParamsStart);
      }
    }

    const accountInURI = userDestination.match(accountURIPattern);

    if (userDestination === '/' || (accountInURI && !accountInURI[2]) || (accountInURI && accountInURI[2] === '/')) {
      // The user was navigating to the domain without any additional path info
      // OR The user was navigating to /account/[billingAccountKey]
      // OR The user was navigating to /account/[billingAccountKey]/
      userDestination = this.routeToDefault();
    } else if (!accountInURI && !this.destinationIsKeyless(userDestination)) {
      // The path does not contain the required `account/{key}`
      // AND The path does not exist as a keyless route.
      // We will inject the `account/{key}` into their original destination.
      userDestination = `/account/${this.props.billingAccountActiveKey}${userDestination}`;
    } else if (accountInURI && accountInURI[1] !== this.props.billingAccountActiveKey) {
      // The URI contains the required `account/{key}` but the key does not match their billing account key.
      // We will route them to the error page.
      userDestination = this.routeToError();
    }
    return `${userDestination}${queryParams}`;
  };

  componentDidMount = () => {
    // Core container is shared by the normal app, /unauthorized, and /error. Have appropriate checks in place before adding
    // new behavior here
    if (this.props.userAuthorized) {
      const targetDestination = this.determineUserDestination();
      const billingAccountKey = this.props.billingAccountActiveKey;

      this.props.history.replace(targetDestination);

      return this.props.checkFraud(billingAccountKey).then(() => {
        if (this.props.paymentStatusIsGraceRenewal && !this.props.restrictPaymentMethod) {
          this.props.alertWarningPaymentMethodShow();
        }

        if (!this.props.paymentStatusIsGraceRenewal && !this.props.restrictPaymentMethod) {
          return this.props.paymentMethodsRequestGet(billingAccountKey)
            .then(() => this.props.paymentMethodDefaultIsExpired
              ? this.props.alertExpiredPaymentMethodToggle()
              : Promise.resolve()
            );
        }
        return Promise.resolve();
      });
    }
    this.props.history.push(resolveRoute.resolve('unauthorized'));
    return Promise.resolve();
  };

  render = () => (
    <CoreView
      alertAuthExpiredIsVisible={this.props.alertAuthExpiredIsVisible}
      alertAuthWarningIsVisible={this.props.alertAuthWarningIsVisible}
      alertErrorsAreVisible={this.props.alertErrorsAreVisible}
      alertExpiredPaymentMethodMessageVisible={this.props.alertExpiredPaymentMethodMessageVisible}
      alertFraudIsVisible={this.props.alertFraudIsVisible}
      alertPreconfigurationIsVisible={this.props.alertPreconfigurationIsVisible}
      alertProductUnmatchedKey={this.props.alertProductUnmatchedKey}
      alertProductUnmatchedVisible={this.props.alertProductUnmatchedVisible}
      alertWarningAddressInvalidVisible={this.props.alertWarningAddressInvalidVisible}
      alertWarningPaymentMethodIsVisible={this.props.alertWarningPaymentMethodIsVisible}
      alertSepaSetupSuccessIsVisible={this.props.alertSepaSetupSuccessIsVisible}
      paymentMethodsCreditCardIsExisting9sAffected={this.props.paymentMethodsCreditCardIsExisting9sAffected}
      userLanguage={this.props.userLanguage}
      isAuthorized={this.props.userAuthorized}
      alertTrySwitchIsVisible={this.props.identityToggleUnifiedAdmin}
    />
  );
}

CoreContainer.defaultProps = {
  userAuthorized: false
};

CoreContainer.propTypes = {
  alertAuthExpiredIsVisible: PropTypes.bool,
  alertAuthWarningIsVisible: PropTypes.bool,
  alertErrorsAreVisible: PropTypes.bool,
  alertExpiredPaymentMethodMessageVisible: PropTypes.bool.isRequired,
  alertExpiredPaymentMethodToggle: PropTypes.func.isRequired,
  alertFraudIsVisible: PropTypes.bool,
  alertPreconfigurationIsVisible: PropTypes.bool,
  alertProductUnmatchedKey: PropTypes.object.isRequired,
  alertProductUnmatchedVisible: PropTypes.bool.isRequired,
  alertWarningAddressInvalidVisible: PropTypes.bool.isRequired,
  alertWarningPaymentMethodIsVisible: PropTypes.bool.isRequired,
  alertSepaSetupSuccessIsVisible: PropTypes.bool.isRequired,
  alertWarningPaymentMethodShow: PropTypes.func.isRequired,
  billingAccountActiveKey: PropTypes.string,
  checkFraud: PropTypes.func.isRequired,
  userLanguage: PropTypes.string.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  paymentMethodDefaultIsExpired: PropTypes.bool.isRequired,
  paymentMethodsRequestGet: PropTypes.func.isRequired,
  paymentStatusIsGraceRenewal: PropTypes.bool.isRequired,
  paymentMethodsCreditCardIsExisting9sAffected: PropTypes.bool.isRequired,
  restrictPaymentMethod: PropTypes.bool.isRequired,
  userAuthorized: PropTypes.bool,
  identityToggleUnifiedAdmin: PropTypes.bool.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CoreContainer)
);
