import { customClient as api } from 'dao/providers/axios';
import config from 'config/appconfig';
import environment from 'lib/environment';

// @NOTE The hostname should be the same as the page domain
const windowOrigin = (window.location.origin === environment.get('newBPORUrl.url') ? environment.get('newBPORUrl.url') : environment.get('oauth.redirect_url')).replace(/\/$/, '');

const paymentFormEndpoint = config.cog_endpoints.paymentForm;

export const daoPaymentFormGet = (country) => api.get(paymentFormEndpoint(country, 'v2', windowOrigin));

export default {
  daoPaymentFormGet
};
