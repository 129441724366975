import PropTypes from 'prop-types';

export const billingInformationFieldsNamesProps = PropTypes.shape({
  FORM_FIELD_ADDRESS_LINE_1: PropTypes.string.isRequired,
  FORM_FIELD_ADDRESS_LINE_2: PropTypes.string.isRequired,
  FORM_FIELD_CITY: PropTypes.string.isRequired,
  FORM_FIELD_COUNTRY: PropTypes.string.isRequired,
  FORM_FIELD_FIRST_NAME: PropTypes.string.isRequired,
  FORM_FIELD_LAST_NAME: PropTypes.string.isRequired,
  FORM_FIELD_POSTAL_CODE: PropTypes.string.isRequired,
  FORM_FIELD_PHONE_NUMBER: PropTypes.string.isRequired,
  FORM_FIELD_STATE: PropTypes.string.isRequired
});

export const creditCardFieldsNamesProps = PropTypes.shape({
  FORM_FIELD_CARD_NUMBER: PropTypes.string.isRequired,
  FORM_FIELD_CVN: PropTypes.string.isRequired,
  FORM_FIELD_EXPIRATION_MONTH: PropTypes.string.isRequired,
  FORM_FIELD_EXPIRATION_YEAR: PropTypes.string.isRequired
});

export const sepaFieldsNamesProps = PropTypes.shape({
  FORM_FIELD_IBAN: PropTypes.string.isRequired
});
