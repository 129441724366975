import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import AppLink from 'components/app-link';
import Button from 'components/button';
import st from 'components/shared-translations';

export const AlertExpiredPaymentMethodView = (props) => (
  <Alert
    title={
      <FormattedMessage {...st['alert.expired.payment.method.title']} />
    }
    body={
      <FormattedMessage {...st['alert.expired.payment.method.cc.message']} />
    }
    buttons={
      <AppLink to="paymentmethods.manage" id="alert-expired-paymentmethods">
        <Button theme="link" id="expired-payment-method" onClick={props.handleAlertDismiss}>
          <FormattedMessage {...st['alert.expired.payment.method.button']} />
        </Button>
      </AppLink>
    }
    type="danger"
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertExpiredPaymentMethodView.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertExpiredPaymentMethodView;
