import React from 'react';
import PropTypes from 'prop-types';
import AlertError from './alert-error';

export const AlertErrorsView = (props) => (
  <div id="alert-errors">
    {props.alertMessages.map((alertMessage) => (
      <AlertError msg={alertMessage} key={alertMessage.key} />
    ))}
  </div>
);

AlertErrorsView.propTypes = {
  alertMessages: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    msgObj: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired
    })
  })).isRequired
};

export default AlertErrorsView;
