import {
  QUOTE_RECEIVE_GET_SUCCESS,
  QUOTE_RECEIVE_GET_FAILURE
} from './quote-action-types';

// ------------------------------------
// Actions
// ------------------------------------
export const quoteReceiveGetSuccess = (payload = {}) => ({
  type: QUOTE_RECEIVE_GET_SUCCESS,
  payload
});

export const quoteReceiveGetFailure = (payload = {}) => ({
  type: QUOTE_RECEIVE_GET_FAILURE,
  payload
});
