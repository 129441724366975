import { daoAccountGet, daoAccountPut } from 'dao/account-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const ACCOUNT_RECEIVE_GET_SUCCESS = 'ACCOUNT_RECEIVE_GET_SUCCESS';
export const ACCOUNT_REQUEST_PUT = 'ACCOUNT_REQUEST_PUT';
export const ACCOUNT_RECEIVE_PUT_SUCCESS = 'ACCOUNT_RECEIVE_PUT_SUCCESS';
export const ACCOUNT_RECEIVE_PUT_FAILURE = 'ACCOUNT_RECEIVE_PUT_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  unifiedAdmin: false,
  toggleUnifiedAdmin: false,
  isPutLoading: false
};

// ------------------------------------
// Selectors
// ------------------------------------

export const accountTree = (state) => state.account || {};
export const accountUnifiedAdmin = (state) => accountTree(state).unifiedAdmin || false;
export const accountToggleUnifiedAdmin = (state) => accountTree(state).toggleUnifiedAdmin || false;
export const accountIsLoading = (state) => accountTree(state).isPutLoading;

// ------------------------------------
// Actions
// ------------------------------------
export const accountReceiveGetSuccess = (payload = {}) => ({
  type: ACCOUNT_RECEIVE_GET_SUCCESS,
  payload
});

export const accountReceiveGetFailure =
  (payload = {}) => () => Promise.reject(payload);

export const accountRequestGet = (accountKey) => (dispatch) => daoAccountGet(accountKey).then(
  (response) => dispatch(accountReceiveGetSuccess(response.data)),
  (err) => dispatch(accountReceiveGetFailure(err))
);

export const accountReceivePutSuccess = (payload = {}) => ({
  type: ACCOUNT_RECEIVE_PUT_SUCCESS,
  payload
});

export const accountReceivePutFailure =
  (payload = {}) => (dispatch) => {
    dispatch({
      type: ACCOUNT_RECEIVE_PUT_FAILURE,
      payload
    });
    return Promise.reject(payload);
  };

export const accountRequestPut = (accountKey, payload) => (dispatch) => {
  dispatch({
    type: ACCOUNT_REQUEST_PUT
  });

  return daoAccountPut(accountKey, payload).then(
    (response) => dispatch(accountReceivePutSuccess(response.data)),
    (err) => dispatch(accountReceivePutFailure(err))
  );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [ACCOUNT_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (action.payload instanceof Object) {
      const { unifiedAdmin, toggleUnifiedAdmin } = action.payload;
      return {
        ...state,
        unifiedAdmin,
        toggleUnifiedAdmin
      };
    }
    return { ...state };
  },
  [ACCOUNT_REQUEST_PUT]: (state) => ({ ...state, isPutLoading: true }),
  [ACCOUNT_RECEIVE_PUT_SUCCESS]: (state) => ({ ...state, isPutLoading: false }),
  [ACCOUNT_RECEIVE_PUT_FAILURE]: (state) => ({ ...state, isPutLoading: false })
});

// ------------------------------------
// Reducer
// ------------------------------------
const accountReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default accountReducer;
