import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  formValues,
  paymentFormFieldsNormalize,
  paymentFormFieldsValidation
} from 'modules/form';
import FormFieldView from './form-field-view';
import classes from './form-field.scss';

export const mapStateToProps = (state, ownProps) => ({
  formValues: formValues(state, ownProps.formName)
});

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  isEmpty: !((stateProps.formValues || {})[ownProps.name])
});

export class FormFieldContainer extends Component {
  labelClasses = () => {
    if (this.props.type === 'radio') {
      return `${classes['radio-label']}`;
    }
    return `label ${this.props.isEmpty ? 'label-hide' : ''} ${this.props.disabled ? 'label-disabled' : ''} mt-1 px-1`;
  };

  render = () => (
    <FormFieldView
      labelClasses={this.labelClasses()}
      id={this.props.id}
      label={this.props.label}
      name={this.props.name}
      component={this.props.component}
      validate={paymentFormFieldsValidation}
      normalize={paymentFormFieldsNormalize(this.props.name)}
      maxLength={this.props.maxLength}
      disabled={this.props.disabled}
      type={this.props.type}
      children={this.props.children}
      fieldClasses={this.props.className}
      value={this.props.value}
    />
  );
}

FormFieldContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string
};


export default connect(mapStateToProps, null, mergeProps)(FormFieldContainer);
