import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
}

export const NavRight: React.FC<Props> = (props) => (
  <React.Fragment>
    <hr className="nav-mobile-separator-line" />
    <ul className="navbar-nav navbar-right">
      {props.children}
    </ul>
  </React.Fragment>
);

(NavRight as any).propTypes = {
  children: PropTypes.node.isRequired
};

export default NavRight;
