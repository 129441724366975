import auth from 'lib/commerce-auth';
import { alertRestrictPaymentMethodShow } from 'modules/alert-module';
import {
  daoBillingAccountGet,
  daoBillingAccountPatch
} from 'dao/billing-account-dao';
import { daoFraudGet } from 'dao/fraud-dao';
import {
  BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE,
  BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS,
  BILLING_ACCOUNT_FRAUD_REQUEST_GET,
  BILLING_ACCOUNT_RECEIVE_GET_FAILURE,
  BILLING_ACCOUNT_RECEIVE_GET_SUCCESS,
  BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE,
  BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS,
  BILLING_ACCOUNT_REQUEST_GET,
  BILLING_ACCOUNT_REQUEST_PATCH
} from './billing-account-action-types';
import { billingAccountActiveKey } from './billing-account-selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const billingAccountReceiveGetSuccess = (payload = {}) => ({
  type: BILLING_ACCOUNT_RECEIVE_GET_SUCCESS,
  payload
});

export const billingAccountReceiveGetFailure = () => ({type: BILLING_ACCOUNT_RECEIVE_GET_FAILURE});

export const billingAccountRequestGet = (billingAccountKey) => (dispatch) => {
  dispatch({type: BILLING_ACCOUNT_REQUEST_GET});

  return daoBillingAccountGet(billingAccountKey)
    .then(
      (response) => dispatch(billingAccountReceiveGetSuccess(response.data)),
      (ex) => {
        dispatch(billingAccountReceiveGetFailure());

        // we need to continue the fail chain so outside code can react to failures as needed
        return Promise.reject(ex);
      });
};

/**
 * Bootstrap the billing account by checking if there is a billing account key
 * before loading the billing account.
 */
export const bootstrapBillingAccount = () => async (dispatch, getState) => {
  const currentState = getState();
  const billingAccountKey = billingAccountActiveKey(currentState);
  if (billingAccountKey) {
    await dispatch(billingAccountRequestGet(billingAccountKey));
  }
  return Promise.resolve(true);
};

export const billingAccountReceivePatchSuccess = (payload = {}) => ({
  type: BILLING_ACCOUNT_RECEIVE_PATCH_SUCCESS,
  payload
});

export const billingAccountReceivePatchFailure = (payload = {}) => (dispatch) => {
  dispatch({
    type: BILLING_ACCOUNT_RECEIVE_PATCH_FAILURE
  });

  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const billingAccountRequestPatch = (id, payload = {}) => (dispatch) => {
  dispatch({
    type: BILLING_ACCOUNT_REQUEST_PATCH
  });

  const cleanedPayload = {...payload};
  if (Object.prototype.hasOwnProperty.call(cleanedPayload, 'taxNumber') && cleanedPayload.taxNumber === '') {
    // In order to remove a VAT the value must be null as an empty string will fail validation.
    cleanedPayload.taxNumber = null;
  }

  return daoBillingAccountPatch(id, cleanedPayload)
    .then(
      (response) => dispatch(billingAccountReceivePatchSuccess(response.data)),
      (ex) => dispatch(billingAccountReceivePatchFailure(ex))
    );
};

export const billingAccountFraudReceiveGetSuccess = (billingAccountKey, payload = {}) => (dispatch) => {
  dispatch({
    type: BILLING_ACCOUNT_FRAUD_RECEIVE_GET_SUCCESS,
    billingAccountKey,
    payload
  });

  if (payload.restrictLogin) {
    /**
     * A user with fraudSuspend should not be allowed access. Log them out immediately.
     */
    auth.logout();
  }

  if (payload.restrictPaymentMethod) {
    dispatch(alertRestrictPaymentMethodShow());
  }

  return Promise.resolve();
};

export const billingAccountFraudReceiveGetFailure = (payload = {}) => ({
  type: BILLING_ACCOUNT_FRAUD_RECEIVE_GET_FAILURE,
  payload
});

export const billingAccountFraudRequestGet = (billingAccountKey) => (dispatch) => {
  dispatch({
    type: BILLING_ACCOUNT_FRAUD_REQUEST_GET
  });

  return daoFraudGet(billingAccountKey)
    .then(
      (response) => dispatch(billingAccountFraudReceiveGetSuccess(billingAccountKey, response.data)),
      (ex) => dispatch(billingAccountFraudReceiveGetFailure(ex))
    );
};
