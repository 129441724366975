import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BillingAddressForm from './components/billing-address-form';
import Loader from 'components/loader';
import ExternalAnchor from 'components/external-anchor';
import st from 'components/shared-translations';
import AppLink from 'components/app-link';

export const BillingAddressInfoView = (props) => (
  <div className="text-left">
    {props.isLoading && <Loader />}
    {
      !props.isLoading &&
        <div>
          <h3 className="mb-0">
            <FormattedMessage {...st['shared.edit.billingaddress']} />
          </h3>
          <div id="cancel-edit" className="mb-3">
            <AppLink to="paymentmethods" id="billing-info-payment-method">
              <FormattedMessage {...st['billing-address-info.button.canceledit']} />
            </AppLink>
          </div>
          {
            props.shouldShowCustomerCareLink &&
              <div className="row">
                <div className="col-12">
                  <div id="contact-care-billing-country" className="small pb-3 px-0">
                    <FormattedMessage
                      {...st['shared.billingcountry.customercare']}
                      values={{
                        'care-link': (msg) => <ExternalAnchor id="billing-address-care" href={props.careLink}>{msg}</ExternalAnchor>
                      }}
                    />
                  </div>
                </div>
              </div>
          }
          <BillingAddressForm onSuccess={props.onSuccess} />
        </div>
    }
  </div>
);

BillingAddressInfoView.propTypes = {
  careLink: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  shouldShowCustomerCareLink: PropTypes.bool.isRequired
};

export default BillingAddressInfoView;
