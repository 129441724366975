import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import st from 'components/shared-translations';

const AlertWarningAddressInvalidView = (props) => (
  <Alert
    type="warning"
    title={<FormattedMessage {...st['alert.warning.address.invalid.message']} />}
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertWarningAddressInvalidView.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertWarningAddressInvalidView;
