import {
  EE_CANCEL,
  EE_CANCEL_CAMPAIGN_FLATRATE,
  EXPERIMENT_LD_DEFAULT_FLAGS,
  IN_RETENTION_PRODUCT_MUTABLE_PROPERTIES_BY_USER
} from './experiments-constants';

// ------------------------------------
// Selectors
// ------------------------------------
export const experimentTree = (state) => state.experiments || {};
export const experimentById = (state, id) => experimentTree(state)[id] || false;

// LD flag selectors
export const experimentLDFlags = (state) => Object.keys(EXPERIMENT_LD_DEFAULT_FLAGS).reduce((acc, next) => {
  acc[next] = experimentById(state, next);
  return acc;
}, {});
export const experimentByFamilyKeyIsMultiproduct = (state, { productFamilyKey }) => {
  const experimentFlag = `${productFamilyKey.toLowerCase()}Multiproduct`;
  return experimentLDFlags(state)[experimentFlag] || false;
};

// EE Cancel selectors
export const experimentCancelByFamilyKey = (state, productFamilyKey) => experimentById(state, EE_CANCEL)[productFamilyKey] || false;
export const experimentCancelBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  (experimentCancelByFamilyKey(state, productFamilyKey) || {})[subscriptionKey] || false
);
export const experimentCancelCampaignBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (experimentCancelBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).campaign || '';
export const experimentCancelTestGroupBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (experimentCancelBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).mlTestingGroup || '';
export const experimentCancelIsControlGroupBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => {
  // if there is no test group field, then assume the default/none campaign, and assume everyone is "control"; otherwise A is control group, B is experiment group
  const testGroup = experimentCancelTestGroupBySubscriptionKey(state, productFamilyKey, subscriptionKey);
  return testGroup ? testGroup === 'A' : true;
};
export const experimentCancelDataBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  (experimentCancelBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).data || false
);
export const experimentCancelMeetingCountBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  (experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).meetings || 0
);
export const experimentCancelPromoCodeBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).promoCode || '';
export const experimentCancelDowngradeSkuBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).downgradeSku || '';
export const experimentCancelDiscountPercentBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  ((experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).discountPercent || 0) / 100
);
export const experimentCancelCurrentPriceBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (
  experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).currentPrice || '0';
export const experimentCancelEffectivePriceBySubscriptionKey = (state, productFamilyKey, subscriptionKey) => (experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey) || {}).effectivePrice || '0';
export const experimentCancelBySubscriptionKeyIsLoading = (state, productFamilyKey, subscriptionKey) => (
  (experimentById(state, EE_CANCEL).isLoading[productFamilyKey] || {})[subscriptionKey] || false
);

// EE Cancel "in retention flow" selectors
export const experimentCancelInRetentionFlow = (state) => experimentById(state, EE_CANCEL).inRetentionFlow || [];
export const experimentCancelInRetentionFlowProductKeys = (state) => experimentCancelInRetentionFlow(state).map((subs) => subs.productKey);
export const experimentCancelInRetentionFlowSubscriptionKeys = (state) => experimentCancelInRetentionFlow(state).map((subs) => subs.subscriptionKey);
export const experimentCancelInRetentionFlowBySubscriptionKey = (state, subscriptionKey) => (
  experimentCancelInRetentionFlow(state).find((item) => item.subscriptionKey === subscriptionKey)
) || {};
export const experimentCancelInRetentionFlowBySubscriptionKeyProductKey = (state, subscriptionKey) => experimentCancelInRetentionFlowBySubscriptionKey(state, subscriptionKey).productKey || '';
export const experimentCancelInRetentionFlowBySubscriptionKeyCampaign = (state, subscriptionKey) => experimentCancelInRetentionFlowBySubscriptionKey(state, subscriptionKey).campaign || '';
export const experimentCancelInRetentionFlowBySubscriptionKeyData = (state, subscriptionKey) => experimentCancelInRetentionFlowBySubscriptionKey(state, subscriptionKey).data || {};
export const experimentCancelInRetentionFlowBySubscriptionKeyPromoCode = (state, subscriptionKey) => (
  experimentCancelInRetentionFlowBySubscriptionKeyData(state, subscriptionKey).promoCode || ''
);
export const experimentCancelInRetentionFlowBySubscriptionKeyCurrentPrice = (state, subscriptionKey) => (
  experimentCancelInRetentionFlowBySubscriptionKeyData(state, subscriptionKey).currentPrice || '0'
);
export const experimentCancelInRetentionFlowBySubscriptionKeyEffectivePrice = (state, subscriptionKey) => (
  experimentCancelInRetentionFlowBySubscriptionKeyData(state, subscriptionKey).effectivePrice || '0'
);
export const experimentCancelInRetentionFlowBySubscriptionKeyDiscountPercent = (state, subscriptionKey) => (
  Math.abs(parseFloat(experimentCancelInRetentionFlowBySubscriptionKeyData(state, subscriptionKey).discountPercent || 0) / 100)
);
export const experimentCancelInRetentionFlowBySubscriptionKeyCampaignIsFlatRate = (state, subscriptionKey) => (
  !!EE_CANCEL_CAMPAIGN_FLATRATE[experimentCancelInRetentionFlowBySubscriptionKeyCampaign(state, subscriptionKey)]
);
export const experimentCancelInRetentionFlowTestGroupBySubscriptionKey = (state, subscriptionKey) => (
  experimentCancelInRetentionFlowBySubscriptionKey(state, subscriptionKey).mlTestingGroup || '');
export const experimentCancelQuoteItemsInRetentionFlow = (state, quoteItems) => {
  const inRetentionFlowSubscriptionKeys = experimentCancelInRetentionFlowSubscriptionKeys(state);
  return quoteItems.filter((quote) => inRetentionFlowSubscriptionKeys.includes(quote.subscriptionKey));
};
export const experimentCancelShouldShowImportantDateMessage = (state, quoteItemInRetentionFlow) => {
  const inRetentionFlowBySubscriptionKeyPromoCode = experimentCancelInRetentionFlowBySubscriptionKeyPromoCode(state, quoteItemInRetentionFlow.subscriptionKey);
  const quoteItemHasInRetentionPromoCode = (quoteItemInRetentionFlow.promotionCodes || []).some((promoCode) => (
    promoCode.promotionCode === inRetentionFlowBySubscriptionKeyPromoCode
  ));
  // Show the important date message for a campaign if:
  //  1. The quote item which represents the subscription in retention flow has the promoCode applied to it
  //  2. The subscription's billingPeriod, productKey and quantity values have not changed
  return quoteItemHasInRetentionPromoCode && IN_RETENTION_PRODUCT_MUTABLE_PROPERTIES_BY_USER.every((property) => (
    (quoteItemInRetentionFlow.newSubscription || {})[property] === (quoteItemInRetentionFlow.currentSubscription || {})[property])
  );
};
export const experimentCancelInRetentionFlowCampaignsImportantDatesData = (state, { quoteItems = [] }) => {
  const quoteItemsInRetentionFlow = experimentCancelQuoteItemsInRetentionFlow(state, quoteItems);

  return quoteItemsInRetentionFlow.map((quoteItemInRetentionFlow) => ({
    subscriptionKey: quoteItemInRetentionFlow.subscriptionKey,
    productKey: (quoteItemInRetentionFlow.newSubscription || {}).productKey,
    isCampaignFlatRate: experimentCancelInRetentionFlowBySubscriptionKeyCampaignIsFlatRate(state, quoteItemInRetentionFlow.subscriptionKey),
    shouldShowImportantDateMessage: experimentCancelShouldShowImportantDateMessage(state, quoteItemInRetentionFlow)
  }));
};
export const experimentCancelInRetentionFlowCampaignFlatRateProductKeys = (state, { quoteItems }) => (
  experimentCancelInRetentionFlowCampaignsImportantDatesData(state, { quoteItems }).reduce((acc, campaignImportantDatesData) => {
    if (campaignImportantDatesData.isCampaignFlatRate && campaignImportantDatesData.shouldShowImportantDateMessage) {
      acc.push(campaignImportantDatesData.productKey);
    }
    return acc;
  }, [])
);
export const experimentCancelInRetentionFlowMessageSubscriptionKeys = (state, { quoteItems }) => experimentCancelInRetentionFlowCampaignsImportantDatesData(state, { quoteItems })
  .filter((retentionMessage) => retentionMessage.shouldShowImportantDateMessage && retentionMessage.isCampaignFlatRate)
  .map((retentionMessage) => retentionMessage.subscriptionKey);
