import {
  ProductTypes,
  QuoteItemChangeTypes
} from 'lib/cog-enums';
import { subscriptionByKeyBillingFrequency } from 'modules/subscription';
import {
  pricesSortedByFrequencyDescending,
  pricesFilteredByTargetOrHigher
} from 'lib/prices';
import flatten from 'lodash/flatten';

// ------------------------------------
// Selectors
// ------------------------------------
export const quoteTree = (state) => state.quote || {};
export const quoteIsLoading = (state) => quoteTree(state).isLoading;
export const quoteKey = (state) => quoteTree(state).quoteKey;
export const quoteOrderKey = (state) => quoteTree(state).order.orderKey;

// Gets the quoteItems with corresponding product data
export const quoteModels = (state) => quoteTree(state).items;

export const quoteItemsForCart = (state) => quoteModels(state)
  .filter((fullItem) => fullItem.newSubscription.autoAdd === false)
  .sort((a, b) => {
    const productA = a.newSubscription.productKey.toUpperCase();
    const productB = b.newSubscription.productKey.toUpperCase();
    if (productA > productB) {
      return 1;
    }
    if (productA < productB) {
      return -1;
    }
    return 0;
  });

// individual quote item selectors
export const quoteItemFindByKey = (state, key) => quoteModels(state).find((item) => item.cartItemKey === key) || {};
export const quoteItemSubscriptionKey = (state, key) => quoteItemFindByKey(state, key).subscriptionKey || '';
export const quoteItemNewSubscriptionProductKey = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).productKey || '';
export const quoteItemNewSubscriptionQuantity = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).quantity || 0;
export const quoteItemNewSubscriptionAvailableBillingPeriods = (state, key) => {
  const newSubscription = quoteItemFindByKey(state, key).newSubscription || {};
  const product = newSubscription.product || {};
  const prices = product.prices || [];
  const sortedPrices = pricesSortedByFrequencyDescending(prices);
  const subscriptionKey = quoteItemSubscriptionKey(state, key);
  let availablePrices = sortedPrices;
  if (subscriptionKey) {
    availablePrices = pricesFilteredByTargetOrHigher(sortedPrices, subscriptionByKeyBillingFrequency(state, subscriptionKey));
  }
  return availablePrices.map((price) => price.billingPeriod);
};
export const quoteItemEffectiveTotalDifference = (state, key) => (
  ((quoteItemFindByKey(state, key).newSubscription || {}).effectiveTotal) -
  ((quoteItemFindByKey(state, key).currentSubscription || {}).effectiveTotal || 0)
);
const quoteItemNewSubscription = (state, key) => (quoteItemFindByKey(state, key)).newSubscription;
export const quoteItemNewSubscriptionBillingDuration = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).billingDuration || 0;
export const quoteItemNewSubscriptionBillingPeriod = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).billingPeriod || '';
export const quoteItemNewSubscriptionEffectivePrice = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).effectivePrice || '';
export const quoteItemNewSubscriptionPrice = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).subscriptionPrice || '';
export const quoteItemCurrentSubscriptionEffectivePrice = (state, key) => (quoteItemFindByKey(state, key).currentSubscription || {}).effectivePrice || '';
export const quoteItemCurrentSubscriptionPrice = (state, key) => (quoteItemFindByKey(state, key).currentSubscription || {}).subscriptionPrice || '';
export const quoteItemIsDiscounted = (state, key, isCurrentSubscription) => (
  isCurrentSubscription
    ? parseFloat(quoteItemCurrentSubscriptionEffectivePrice(state, key)) < parseFloat(quoteItemCurrentSubscriptionPrice(state, key))
    : parseFloat(quoteItemNewSubscriptionEffectivePrice(state, key)) < parseFloat(quoteItemNewSubscriptionPrice(state, key))
);
export const quoteItemTypeIsChange = (state, key) => quoteItemFindByKey(state, key).quoteItemChangeType === QuoteItemChangeTypes.CHANGE;
export const quoteItemTypeIsNew = (state, key) => quoteItemFindByKey(state, key).quoteItemChangeType === QuoteItemChangeTypes.NEW;
export const quoteItemTypeIsConvertTrial = (state, key) => quoteItemFindByKey(state, key).quoteItemChangeType === QuoteItemChangeTypes.CONVERT_TRIAL;
export const quoteItemTypeIsReactivate = (state, key) => quoteItemFindByKey(state, key).quoteItemChangeType === QuoteItemChangeTypes.REACTIVATE;
export const quoteItemPromoCodes = (state, key) => quoteItemFindByKey(state, key).promotionCodes || [];
export const quoteItemNewSubscriptionProductName = (state, key) => (quoteItemNewSubscription(state, key) || { product: { productDescription: '' } }).product.productDescription;
export const quoteItemNewSubscriptionProductType = (state, key) => (quoteItemNewSubscription(state, key) || { product: { productType: '' } }).product.productType;
export const quoteItemNewSubscriptionProductFamilyKey = (state, key) => (quoteItemNewSubscription(state, key) || { product: { productFamilyKey: '' } }).product.productFamilyKey;

export const quoteItemNewSubscriptionBillingDurationByPeriod = (state, key, billingPeriod) => {
  // @NOTE this assumes that there is only one price entry for each possible billingPeriod value, e.g. there is
  //       only one "MONTHLY" price. If we ever end up with several prices under a given period, e.g. we have both
  //       a MONTHLY/1 (billingPeriod MONTH and billingDuration 1) and a MONTHLY/5 (billingPeriod MONTH and billingDuration 5)
  //       then this will need to be refactored
  const quoteItem = quoteItemFindByKey(state, key).newSubscription.product || {};
  const price = (quoteItem.prices || []).find((p) => p.billingPeriod === billingPeriod);
  return price && price.billingDuration ? price.billingDuration : undefined;
};
export const quoteItemNewSubscriptionCurrencyCode = (state, key) => (quoteItemFindByKey(state, key).newSubscription || {}).currencyCode || '';
export const quoteItemCurrentSubscriptionByProductKey = (state, productKey) => quoteModels(state)
  .find((quoteItem) => (quoteItem.currentSubscription || {}).productKey === productKey) || {};

export const quoteItemsByProductKeyTypeChangeNewQuantity = (state, {productKey}) => (
  quoteModels(state)
    .filter(({cartItemKey}) => quoteItemTypeIsChange(state, cartItemKey) && quoteItemNewSubscriptionProductKey(state, cartItemKey) === productKey)
    .reduce((acc, {cartItemKey}) => quoteItemNewSubscriptionQuantity(state, cartItemKey) + acc, 0)
);

// selectors for grabbing all values in the quote across all quote items, both in currentSubscription and newSubscription
export const quoteAllProductKeys = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.productKey, item.newSubscription.productKey]
  : item.newSubscription.productKey)
);
export const quoteAllSubscriptionKeys = (state) => quoteModels(state).map(({ subscriptionKey }) => subscriptionKey).filter((subsKey) => subsKey);
export const quoteAllProductNames = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.product.productDescription, item.newSubscription.product.productDescription]
  : item.newSubscription.product.productDescription
));
export const quoteAllProductQuantities = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.quantity, item.newSubscription.quantity]
  : item.newSubscription.quantity
));
export const quoteAllProductBillingPeriods = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.billingPeriod, item.newSubscription.billingPeriod]
  : item.newSubscription.billingPeriod
));
export const quoteAllProductBillingDurations = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.billingDuration, item.newSubscription.billingDuration]
  : item.newSubscription.billingDuration
));
export const quoteAllProductFamilies = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.product.productFamilyKey, item.newSubscription.product.productFamilyKey]
  : item.newSubscription.product.productFamilyKey
));
export const quoteAllProductPricesPerUnit = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.effectivePrice, item.newSubscription.effectivePrice]
  : item.newSubscription.effectivePrice
));
export const quoteAllProductCurrencyCodes = (state) => flatten(quoteModels(state).map((item) => item.currentSubscription
  ? [item.currentSubscription.currencyCode, item.newSubscription.currencyCode]
  : item.newSubscription.currencyCode
));
export const quoteAllProductActions = (state) => flatten(quoteModels(state).map((item) => {
  // @NOTE The ADD, CONVERT, REMOVE and RETRIAL strings are being used inside the Tealium code in order to determine whether a given subscription is
  // ADD, CONVERT, REMOVE or RETRIAL. Therefore, if we change anything in here (updating strings values, updating the logic, etc...),
  // we should notify the person who is responsible for updating the Tealium code.
  let txnType;

  if (quoteItemTypeIsConvertTrial(state, item.cartItemKey)) {
    // quoteItemType is CONVERT_TRIAL, then txnType should be CONVERT
    txnType = 'CONVERT';
  } else if (quoteItemTypeIsChange(state, item.cartItemKey)) {
    // quoteItemType is CHANGE, then txnType should be ['REMOVE', 'ADD']
    // @NOTE REMOVE represents the currentSubscription that will be removed and ADD represents the new subscription
    txnType = ['REMOVE', 'ADD'];
  } else if (quoteItemTypeIsNew(state, item.cartItemKey)) {
    // quoteItemType is NEW, then txnType should be ADD
    txnType = 'ADD';
  } else if (quoteItemTypeIsReactivate(state, item.cartItemKey)) {
    // reactivate a trial or a paid subscription
    const itemProductData = (item.newSubscription || {}).product || {};
    if (itemProductData.productType === ProductTypes.TRIAL) {
      // quoteItemType is REACTIVATE and new subscription is trial, so txnType should be RETRIAL
      txnType = 'RETRIAL';
    } else {
      // quoteItemType is REACTIVATE, but new subscription is paid, then txnType should be ADD
      txnType = 'ADD';
    }
  } else {
    txnType = '';
  }

  return txnType;
}));
