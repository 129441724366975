import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
}

// For example usage, refer to the test file index.spec.js
export const NavLayout: React.FC<Props> = (props) => (
  <nav id="navbar" className="navbar navbar-expand-md navbar-lite" role="navigation">
    <div className="container-fluid">
      {props.children}
    </div>
  </nav>
);

(NavLayout as any).propTypes = {
  children: PropTypes.node.isRequired
};

export default NavLayout;
