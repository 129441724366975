import { ImportantDateActionTypes } from 'lib/cog-enums';

// ------------------------------------
// Selectors
// ------------------------------------
const importantDatesTree = (state: ApplicationShape) => state.importantDates || {};

export const importantDatesIsLoading = (state: ApplicationShape) => importantDatesTree(state).isLoading;

export const importantDatesItems = (state: ApplicationShape) => importantDatesTree(state).items || [];

/**
 * Maps the important dates models into a flat list of important date messages.
 *
 * @param state
 */
export const importantDateMessages = (state: ApplicationShape) => importantDatesItems(state)
  .reduce<ImportantDateMessageModel[]>((acc, {items, ...itemRemaining}, idx) => {
    const messages = items.map((currentItem, i) => ({
      ...itemRemaining,
      ...currentItem,
      key: `${itemRemaining.action}-${currentItem.productKey}-${idx}-${i}`
    }));
    acc.push(...messages);
    return acc;
  }, []);

export const importantDatesHasFutureDatedCharges = (state: ApplicationShape) => importantDatesItems(state).some((importantDatesItem) => importantDatesItem.hasFutureDatedCharges);

const importantDatesPendingItems = (state: ApplicationShape) => {
  const dates = importantDatesItems(state)
    .find((item) => item.action === ImportantDateActionTypes.PENDING_ACTION_EFFECTIVE);
  return dates ? dates.items : [];
};

const importantDatesPendingItemsByProductKey = (state: ApplicationShape, productKey: string) => importantDatesPendingItems(state)
  .find((item) => item.productKey === productKey);

export const importantDatesPendingByProductKeyAmount = (state: ApplicationShape, productKey: string) => {
  const dates = importantDatesPendingItemsByProductKey(state, productKey);
  return dates ? dates.amount : '';
};
