import {
  UI_SUBS_CONFIG_RESET_STATE,
  UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION,
  UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION,
  UI_SUBS_CONFIG_START_TRIAL_CONVERSION,
  UI_SUBS_CONFIG_SELECT_PRODUCT,
  UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW,
  UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY,
  UI_SUBS_CONFIG_TOGGLE_BUY_TRY,
  UI_SUBS_CONFIG_UPDATE_QUANTITY,
  UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION,
  UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY,
  UI_SUBS_CONFIG_CONFIGURE_START,
  UI_SUBS_CONFIG_CONFIGURE_FINISH,
  UI_SUBS_CONFIG_TOGGLE_ADDON,
  UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW,
  UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE
} from './ui-action-types';
import { SUBSCRIPTION_RECEIVE_GET_SUCCESS } from 'modules/subscription';
import { PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS } from 'modules/product-module';
import ProductCatalog from 'lib/product-catalog';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  subsOverview: {
    showProductFamilies: false
  },
  subsConfig: {
    subscriptionKey: '',
    productFamilyKey: '',

    unmatchedProductKey: '',
    unmatchedProductFamilyKey: '',

    // product selection section
    selectedProductKey: '',
    previousSelectedProductKey: '',
    subscriptionProductKey: '',
    compareFeaturesViewExpanded: false,
    trialFlowSelected: false,

    // quantity selection section
    selectedQuantity: 0,
    quantitySelectionViewEnabled: false,

    // billing period section
    userSelectedBillingPeriod: '',
    userSelectedBillingDuration: 0,

    // estimated price section
    estimatedPriceViewVisible: false,

    // overrides isLoading when configuring a product to avoid jarring animations
    configuring: false,

    // contains objects that describe whether the customer has indicated they want to add the product addon to their order
    addons: [],

    // tracks if we are adding new subscription (as opposed to editing existing paid or converting from trial)
    isAddingNewSubscription: false
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [UI_SUBS_CONFIG_RESET_STATE]: (state) => ({
    ...state,
    subsConfig: {
      ...initialState.subsConfig
    }
  }),
  [UI_SUBS_CONFIG_START_NEW_SUBSCRIPTION]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      quantitySelectionViewEnabled: false,
      isAddingNewSubscription: true
    }
  }),
  [UI_SUBS_CONFIG_START_EDIT_SUBSCRIPTION]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      quantitySelectionViewEnabled: true,
      isAddingNewSubscription: false
    }
  }),
  [UI_SUBS_CONFIG_START_TRIAL_CONVERSION]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      quantitySelectionViewEnabled: false,
      isAddingNewSubscription: false
    }
  }),
  [UI_SUBS_CONFIG_SELECT_PRODUCT]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        subsConfig: {
          ...state.subsConfig,
          previousSelectedProductKey: action.payload.previousProductKey || state.subsConfig.previousSelectedProductKey,
          selectedProductKey: action.payload.key || '',
          // If the selected product has changed, reset the addons array
          addons: action.payload.key === state.subsConfig.selectedProductKey ? state.subsConfig.addons : []
        }
      };
    }
    return state;
  },
  [UI_SUBS_CONFIG_TOGGLE_COMPARE_FEATURES_VIEW]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      compareFeaturesViewExpanded: !state.subsConfig.compareFeaturesViewExpanded
    }
  }),
  [UI_SUBS_CONFIG_UPDATE_PRODUCT_FAMILY_KEY]: (state, action) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      productFamilyKey: action.payload.productFamilyKey
    }
  }),
  [UI_SUBS_CONFIG_TOGGLE_BUY_TRY]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      trialFlowSelected: !state.subsConfig.trialFlowSelected
    }
  }),
  [UI_SUBS_CONFIG_UPDATE_QUANTITY]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        subsConfig: {
          ...state.subsConfig,
          selectedQuantity: action.payload.quantity
        }
      };
    }
    return state;
  },
  [UI_SUBS_CONFIG_SELECT_BILLING_PERIOD_AND_DURATION]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        subsConfig: {
          ...state.subsConfig,
          userSelectedBillingPeriod: action.payload.billingPeriod,
          userSelectedBillingDuration: action.payload.billingDuration
        }
      };
    }
    return state;
  },
  [UI_SUBS_CONFIG_ENABLE_SECTION_QUANTITY]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      quantitySelectionViewEnabled: true
    }
  }),
  [UI_SUBS_CONFIG_CONFIGURE_START]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      configuring: true
    }
  }),
  [UI_SUBS_CONFIG_CONFIGURE_FINISH]: (state) => ({
    ...state,
    subsConfig: {
      ...state.subsConfig,
      configuring: false
    }
  }),
  [UI_SUBS_CONFIG_TOGGLE_ADDON]: (state, action) => {
    const newAddons = [...state.subsConfig.addons];
    const productKeyIndex = newAddons.indexOf(action.payload.productKey);
    if (productKeyIndex === -1) {
      newAddons.push(action.payload.productKey);
    } else {
      newAddons.splice(productKeyIndex, 1);
    }
    return {
      ...state,
      subsConfig: {
        ...state.subsConfig,
        addons: newAddons
      }
    };
  },
  [UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_SHOW]: (state) => ({
    ...state,
    subsOverview: {
      ...state.subsOverview,
      showProductFamilies: true
    }
  }),
  [UI_SUBS_OVERVIEW_PRODUCT_FAMILIES_HIDE]: (state) => ({
    ...state,
    subsOverview: {
      ...state.subsOverview,
      showProductFamilies: false
    }
  }),
  [SUBSCRIPTION_RECEIVE_GET_SUCCESS]: (state, action) => {
    const { productKey, assignedSeats, quantity, key, billingPeriod, billingDuration, productFamilyKey } = action.payload;

    return {
      ...state,
      subsConfig: {
        ...state.subsConfig,
        subscriptionProductKey: productKey,
        subscriptionAssignedSeats: assignedSeats,
        subscriptionQuantity: quantity,
        subscriptionKey: key,
        subscriptionBillingPeriod: billingPeriod,
        subscriptionBillingDuration: billingDuration,
        productFamilyKey
      }
    };
  },
  [PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS]: (state, action) => {
    // Find the product object matching the subscription product key
    const subscriptionProduct = action.payload.find((product) => (product.key === state.subsConfig.subscriptionProductKey)) || {};

    // If the subscription product is a trial, it will not be a preselected product since it doesn't show up in the UI
    // and we will use the subscription's assigned seats for the selected quantity
    if (ProductCatalog.productTypeIsTrial(subscriptionProduct.productType)) {
      return {
        ...state,
        subsConfig: {
          ...state.subsConfig,
          selectedProductKey: '',
          selectedQuantity: state.subsConfig.subscriptionAssignedSeats
        }
      };
    }

    // We are editing an existing non-trial subscription which can be preselected for the user and we will use
    // the subscription's quantity for the selected quantity
    return {
      ...state,
      subsConfig: {
        ...state.subsConfig,
        selectedProductKey: state.subsConfig.subscriptionProductKey,
        selectedQuantity: state.subsConfig.subscriptionQuantity,
        userSelectedBillingPeriod: state.subsConfig.subscriptionBillingPeriod,
        userSelectedBillingDuration: state.subsConfig.subscriptionBillingDuration
      }
    };
  }
});

// ------------------------------------
// Reducer
// ------------------------------------
export const uiReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default uiReducer;
