import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import Button from 'components/button';
import st from 'components/shared-translations';
import { FormattedMessage } from 'react-intl';

export const AlertAuthExpired = (props) => (
  <Alert
    type="danger"
    title={<FormattedMessage {...st['alert.auth.expired.header']} />}
    body={<FormattedMessage {...st['alert.auth.expired.description']} />}
    buttons={
      <Button theme="link" id="auth-expired-login" onClick={props.handleLogin}>
        <FormattedMessage {...st['alert.auth.expired.buttonLogin']} />
      </Button>
    }
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertAuthExpired.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired
};

export default AlertAuthExpired;
