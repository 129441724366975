import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Alert from 'components/alert';
import AppLink from 'components/app-link';
import st from 'components/shared-translations';

const AlertWarningPaymentMethodView = (props) => (
  <Alert
    type="warning"
    title={
      <FormattedMessage
        {...st['alert.warning.payment.method.message']}
        values={{
          updatePaymentMethodLink: <AppLink to="paymentmethods.manage" id="alert-warn-paymentmethods">
            <span onClick={props.handleAlertDismiss} role="button" tabIndex="0">
              <FormattedMessage {...st['alert.warning.payment.method.link']} />
            </span>
          </AppLink>
        }}
      />
    }
    handleDismiss={props.handleAlertDismiss}
  />
);

AlertWarningPaymentMethodView.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired
};

export default AlertWarningPaymentMethodView;
