import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectFieldView from './select-field-view';

export class SelectFieldContainer extends Component {
  fieldClasses = () => (this.props.meta.touched && this.props.meta.error) ? `${this.props.className} border border-danger` : this.props.className;

  render = () => (
    <SelectFieldView
      fieldClasses={this.fieldClasses()}
      meta={this.props.meta}
      id={this.props.id}
      input={this.props.input}
      disabled={this.props.disabled}
      children={this.props.children}
    />
  );
}

SelectFieldContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.object
  }),
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default connect()(SelectFieldContainer);
