import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BootstrapFailView from './bootstrap-fail-view';
import environment from 'lib/environment';
import domHelpers from 'lib/dom-helpers';

const myAccountUrl = environment.get('myAccount.url');

export class BootstrapFailContainer extends Component {
  state = {
    ready: false
  }

  reload = () => window.location.reload();

  componentDidMount = () => domHelpers.createSheet('//weblibrary.cdn.getgo.com/togo/6.3.7/styles/togo.css')
    .then(() => this.setState({ ready: true }));


  render = () => this.state.ready && <BootstrapFailView
    store={this.props.store}
    languageCode={this.props.languageCode}
    reactIntlMessages={this.props.reactIntlMessages}
    myAccountUrl={myAccountUrl}
    reload={this.reload}
  />;
}

BootstrapFailContainer.propTypes = {
  languageCode: PropTypes.string.isRequired,
  reactIntlMessages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default connect()(BootstrapFailContainer);
