import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Session from 'lib/session';
import ErrorView from './error-view';
import appconfig from 'config/appconfig';
import { track } from 'lib/tracking';
import { Err } from 'modules/tracking-module';

interface DispatchProps {
  track: (event: Record<string, any>) => (void);
}

interface State {
  error: any;
  errorUrl: string;
}

export const mapDispatchToProps = {
  track
};

export class ErrorContainer extends Component<DispatchProps, State> {
  constructor(props: DispatchProps) {
    super(props);
    this.state = {
      error: {},
      errorUrl: appconfig.routes.subscriptions()
    };
  }

  componentDidMount = () => {
    const rawError = JSON.parse((Session as any).getItem(appconfig.storage.last_error)) || {};
    if (rawError.status && rawError.data) {
      this.setState({
        error: {status: rawError.status, data: rawError.data}
      }, () => {
        this.props.track({
          [Err]: {
            errorState: this.state.error
          }
        });
      });
    }
  };

  render = () => (
    <ErrorView
      errorUrl={this.state.errorUrl}
      error={this.state.error}
    />
  );
}

(ErrorContainer as any).propTypes = {
  track: PropTypes.func.isRequired
};

export default connect<{}, DispatchProps, {}, ApplicationShape>(null, mapDispatchToProps)(ErrorContainer);
