import React from 'react';
import { connect } from 'react-redux';
import environment from 'lib/environment';
import getVersion from 'lib/version';
import FooterView from './footer-view';

const aboutUsUrl = environment.get('aboutUs.url');
const privacyPolicyUrl = environment.get('privacyPolicy.url');
const supportUrl = environment.get('support.url');
const version = getVersion();

export const FooterContainer = () => {
  const currentDate = new Date();

  return (
    <FooterView
      aboutUsUrl={aboutUsUrl}
      currentDate={currentDate}
      privacyPolicyUrl={privacyPolicyUrl}
      supportUrl={supportUrl}
      version={version}
    />
  );
};

export default connect()(FooterContainer);
