import environment from 'lib/environment';
import ProductCatalog from 'lib/product-catalog';
import {
  ADDON_RELATIONSHIPS,
  RELATIONSHIP_REQUIRE_ANY_PRODUCTS
} from 'modules/product-module';

// ------------------------------------
// Constants
// ------------------------------------
// Subscription statuses
const SUBSCRIPTION_STATUS_ACTIVE = 'ACTIVE';
const SUBSCRIPTION_STATUS_EXPIRED = 'EXPIRED';
const SUBSCRIPTION_STATUS_PENDING_CANCEL = 'PENDING_CANCEL';

// ------------------------------------
// Expanded DTO construction
// ------------------------------------

/**
 * Return the current status string
 *
 * @param {object} expandedDto - see subscription-props.subscriptionDtoExpandedProps
 * @returns {string} a subscription status string we use for various UX decisions. One of:
 *          'active',
 *          'inactive',
 *          'cancellation'
 */
const currentStatus = (expandedDto) => {
  // Trial subscriptions
  if (expandedDto.isTrial) {
    if (expandedDto.isActive) {
      return 'active';
    }
    return 'inactive';
  }
  // Paid subscriptions
  if (expandedDto.isPaid) {
    if (expandedDto.isActive && !expandedDto.pendingActionIsCancel) {
      return 'active';
    }
    if (expandedDto.isActive && expandedDto.pendingActionIsCancel) {
      return 'cancellation';
    }
    if (expandedDto.isInactive) {
      return 'inactive';
    }
  }
  return 'inactive';
};

/**
 * Return the "date type" string
 *
 * @param {object} expandedDto - see subscription-props.subscriptionDtoExpandedProps
 * @returns {string} a value derived from the subscription status to indicate what kind of date to use in the UX. One of:
 *          'expired',
 *          'canceled',
 *          'expires',
 *          'renewal'
 */
const dateType = (expandedDto) => {
  if (expandedDto.isInactive) {
    return 'expired';
  }
  if (expandedDto.pendingActionIsCancel && !expandedDto.isTrial) {
    return 'canceled';
  }
  if (expandedDto.isActive && expandedDto.isTrial) {
    return 'expires';
  }
  return 'renewal';
};

/**
 * Determines whether the product has a rate sheet URL.
 *
 * @param {object} expandedDto - see subscription-props.subscriptionDtoExpandedProps
 * @returns {boolean} true if the product has a rate sheet URL
 */
const hasRateSheetUrl = (expandedDto) => {
  const {
    productFamilyKey,
    productKey
  } = expandedDto;

  // precedence:
  // 1) productKey has a rateSheet
  // 2) productFamilyKey.defaults has a rateSheet
  // 3) there is no rate sheet
  const productFamily = environment.get(`products.${productFamilyKey}`) || {};
  const product = productFamily[productKey] || {};

  return !!product.rateSheet || !!productFamily.defaults.rateSheet;
};

/**
 * Build a subscription "expanded DTO" from a "subscription" API response (DTO)
 *
 * @param {object} dto - the API response subscription object; see subscription-props.subscriptionDtoProps
 * @returns {object} a "expanded DTO" see subscription-props.subscriptionDtoExpandedProps
 */
const buildFromDto = (dto) => {
  // The expandedDto will start out with all the API fields, with some default values added. Add the default values here
  // rather than in selectors.
  const expandedDto = {
    ...dto,
    pendingAction: dto.pendingAction || {},
    productFamilyKey: dto.productFamilyKey || '',
    productType: dto.productType || '',
    relationships: dto.relationships || [],
    seats: dto.seats || 0,
    status: dto.status || ''
  };

  // ===> add "derived" properties (these can be derived solely from this DTO)

  // replaces subscription-module.[subscriptionByKeyCrossSellProductKeys, subscriptionByKeyRelationshipsCrossSell, and subscriptionByKeyRelationships]
  expandedDto.addonProductKeys = expandedDto.relationships
    .filter((relationship) => ADDON_RELATIONSHIPS.includes(relationship.type))
    .map((relationship) => relationship.productKeys || [])
    .flat()
    .reduce((acc, productKey) => {
      if (!acc.includes(productKey)) {
        acc.push(productKey);
      }
      return acc;
    }, []);

  // replaces subscription-module.[subscriptionByKeyHasPromotionCodes and subscriptionByKeyPromotionCodes]
  expandedDto.hasPromotionCodes = (expandedDto.promotionCodes || []).some((promotionCode) => !!Object.keys(promotionCode || {}).length);

  // replaces subscription-module.[subscriptionByKeyIsActive and subscriptionByKeyStatus]
  expandedDto.isActive = expandedDto.status === SUBSCRIPTION_STATUS_ACTIVE;

  expandedDto.isAnnual = ProductCatalog.productBillingPeriodIsYear(expandedDto.billingPeriod);

  // replaces subscription-module.[subscriptionByKeyIsInactive]
  expandedDto.isInactive = [SUBSCRIPTION_STATUS_EXPIRED, SUBSCRIPTION_STATUS_PENDING_CANCEL].includes(expandedDto.status);

  // replaces subscription-module.[✅subscriptionByKeyIsPaid and subscriptionByKeyProductType]
  expandedDto.isPaid = ProductCatalog.productTypeIsPaid(expandedDto.productType);

  // replaces subscription-module.[subscriptionByKeyIsTrial and subscriptionByKeyProductType]
  expandedDto.isTrial = ProductCatalog.productTypeIsTrial(expandedDto.productType);

  // replaces subscription-module.[subscriptionByKeyPendingActionIsCancel and subscriptionByKeyPendingAction]
  expandedDto.pendingActionIsCancel = ProductCatalog.isPendingActionCancel(expandedDto.pendingAction.action);

  // Converts the promotion codes array of objects into an array of strings. I.e [{ promotionCode: '10off' }, { promotionCode: '50off' }] => ['10off', '50off']
  expandedDto.promotionCodes = (expandedDto.promotionCodes || [])
    .map((promoCode) => promoCode.promotionCode)
    .filter((promotionCode) => promotionCode);

  // replaces subscription-module.[subscriptionByKeyRequireProductsProductKeys]
  expandedDto.requireProductsProductKeys = (expandedDto.relationships.find((relationship) => relationship.type === RELATIONSHIP_REQUIRE_ANY_PRODUCTS) || {}).productKeys || [];

  // @TODO Add more derived properties (and replace corresponding selectors) as we transition to everything using this subscription model.
  //       Mark fully replaced selectors with ✅ when nothing else in the codebase uses the old selector

  // ===> add "meta-derived" properties (these can be derived from other derived properties above)

  expandedDto.currentStatus = currentStatus(expandedDto);
  expandedDto.dateType = dateType(expandedDto);
  expandedDto.hasRateSheetUrl = hasRateSheetUrl(expandedDto);
  return expandedDto;
};

// ------------------------------------
// Full App Model construction
// ------------------------------------

// domain selectors
// @NOTE these work directly with the "subscription" slice of the full redux state tree, as opposed to "global" selectors which receive the full state tree
const subscriptionsAll = (state = {}) => state.subscriptionsV2 || [];
const subscriptionsActive = (state) => subscriptionsAll(state)
  .filter((subscription) => subscription.status === SUBSCRIPTION_STATUS_ACTIVE);
/*
 * @NOTE This selector does not return the subscriptions that are in pending cancellation #2.
 *
 * There are two types of pending cancellations that a subscription can have:
 *   1. The subscription's PENDING_CANCEL value from the status field which means
 *        "Subscription is about to be canceled later tonight. It's in the process of getting fully canceled. Show as expired UI."(COG)
 *   2. The subscription's CANCEL value from the pendingAction object which means
 *        "This subscription will be cancelled and will not renew and be charged anymore after the anniversary date"(COG)
 */
const subscriptionsInactive = (state) => subscriptionsAll(state)
  .filter((subscription) => subscription.status === SUBSCRIPTION_STATUS_EXPIRED || subscription.status === SUBSCRIPTION_STATUS_PENDING_CANCEL);

const canPerformActions = (state, model) => {
  // if requireProductsProductKeys array is empty, it means the subscription can perform actions. E.g it can be reactivated or converted to a paid subscription
  if (!model.requireProductsProductKeys.length) {
    return true;
  }

  // If requireProductsProductKeys array has productKeys, then the subscription requires one of the requireProductKeys to be on the account.
  const activeSubscriptionsProductKeys = subscriptionsActive(state).map((subscription) => subscription.productKey);
  return model.requireProductsProductKeys.some((productKey) => activeSubscriptionsProductKeys.includes(productKey));
};

const hasAddonAvailable = (state, model) => {
  const subscriptionsProductKeys = subscriptionsAll(state).map((subscription) => subscription.productKey);
  const subscriptionHasAddonAvailable = model.addonProductKeys.reduce((hasAddon, addonProductKey) => {
    if (hasAddon) {
      return true;
    }

    return !subscriptionsProductKeys.includes(addonProductKey);
  }, false);

  // If a subscription has some addon productKeys AND
  // If any of those productKeys is not already on the account AND
  // If base subscription is active
  // Then we should show the user a link/button to be able to add it.
  return !!model.addonProductKeys.length && model.isActive && subscriptionHasAddonAvailable;
};

const subscriptionsAllHavePaidProductsNonUsage = (state) => {
  const subscriptionIsPaid = (subscription) => (
    ProductCatalog.productTypeIsPaid(subscription.productType) && !ProductCatalog.productFamilyKeyIsOpenVoice(subscription.productFamilyKey)
  );
  return subscriptionsActive(state).some(subscriptionIsPaid) || subscriptionsInactive(state).some(subscriptionIsPaid);
};

const shouldRedirectToMarketing = (state, model) => {
  // If the subscription's product family is G2MGR and it's a trial, then this is an exemption and we should always redirect users to marketing
  if (ProductCatalog.productFamilyKeyIsG2MGR(model.productFamilyKey) && model.isTrial) {
    return true;
  }

  // normal redirect logic
  return environment.get('marketingPurchaseFlowEnabled')
    && !subscriptionsAllHavePaidProductsNonUsage(state)
    && model.isTrial
    && !!ProductCatalog.getProductMarketingUrl(model.productFamilyKey, model.productKey)
    && !ProductCatalog.productShouldSkipMarketingFlow(model.productKey);
};

/**
 * Return a subscription app model object (containers and views interact with instances of this, which combine state model props with compound props)
 *
 * @param {object} subscriptionTree - the "subscription" slice of the state tree, used with the domain selectors
 * @returns {function} which:
 *   takes param {object} expandedDtoOrModel - either:
 *     - an expanded DTO (see subscription-props.subscriptionDtoExpandedProps) which will be filled out with compound props OR
 *     - a full app subscription model (see subscription-props.subscriptionModelProps) whose compound properties will be recomputed
 *   returns {object} a full app subscription model (see subscription-props.subscriptionModelProps)
 */
const buildAppModel = (subscriptionTree) => (expandedDtoOrModel) => {
  // copy over the expandedDtoOrModel properties
  const appModel = {
    ...expandedDtoOrModel
  };

  // ===> add/update "compound" properties (these need state from outside of this specific expandedDtoOrModel):

  // replaces subscription-module.[subscriptionByKeyCanPerformActions,
  //                               subscriptionByKeyRequireProductsProductKeys,
  //                               subscriptionsActiveProductKeys,
  //                               subscriptionByKeyRelationshipsRequireProducts]
  appModel.canPerformActions = canPerformActions(subscriptionTree, expandedDtoOrModel);

  // replaces subscription-module.subscriptionByKeyHasAddonAvailable
  appModel.hasAddonAvailable = hasAddonAvailable(subscriptionTree, expandedDtoOrModel);

  // replaces selectors.subscriptionByKeyShouldRedirectToMarketing
  appModel.shouldRedirectToMarketing = shouldRedirectToMarketing(subscriptionTree, expandedDtoOrModel);

  return appModel;
};

export default {
  buildAppModel,
  buildFromDto,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_EXPIRED,
  SUBSCRIPTION_STATUS_PENDING_CANCEL
};
