import { connect } from 'react-redux';
import { alertAuthenticationExpiredToggle } from 'modules/alert-module';
import AlertAuthExpiredView from './alert-auth-expired-view';

export const mapDispatchToProps = {
  handleAlertDismiss: alertAuthenticationExpiredToggle
};

export const mergeProps = (stateProps, dispatchProps) => ({
  handleAlertDismiss: dispatchProps.handleAlertDismiss,
  handleLogin: () => window.location.reload()
});

export default connect(null, mapDispatchToProps, mergeProps)(AlertAuthExpiredView);
