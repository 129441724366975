import domHelpers from 'lib/dom-helpers';
import environment from 'lib/environment';
import { families } from 'lib/product-catalog';

let libLoadedPromise;

/* eslint-disable no-multi-spaces  */
export const TRACKING_TXN_TYPE_TRIAL = 'TRACKING_TXN_TYPE_TRIAL';           // new trial
export const TRACKING_TXN_TYPE_CONVERT = 'TRACKING_TXN_TYPE_CONVERT';       // convert a trial (@TODO whether lapsed or current?) to paid
export const TRACKING_TXN_TYPE_NEW_BUY = 'TRACKING_TXN_TYPE_NEW_BUY';       // new paid subscription
export const TRACKING_TXN_TYPE_LAPSED_BUY = 'TRACKING_TXN_TYPE_LAPSED_BUY'; // renew a lapsed (expired) paid subscription
export const TRACKING_TXN_TYPE_CHANGE = 'TRACKING_TXN_TYPE_CHANGE';         // change / edit an existing subscription
export const TRACKING_TXN_TYPE_RETRIAL = 'TRACKING_TXN_TYPE_RETRIAL';       // re-trial (renewing an expired trial)
/* eslint-enable no-multi-spaces */

/**
 * The marketing service has their own set of 'keys' which map to product families. Channel tracking is done only at the family
 * level currently, so we need the keys below to make the various calls.
 */
const titanToChannelKeymap = {
  [families['EA']]: 'Enhanced_Audio',
  [families['G2M']]: 'GoToMeeting',
  [families['G2MFREE']]: 'GoToMeeting',
  [families['G2W']]: 'GoToWebinar',
  [families['G2T']]: 'GoToTraining',
  [families['OPENVOICE']]: 'OpenVoice',
  [families['G2MGR']]: 'GoToManageRemoteSupport',
  [families['G2ASD']]: 'GoToAssistServiceDesk',
  [families['G2ASEEIT']]: 'GoToAssistSeeit',
  [families['G2AX']]: 'GoToAssistExpress',
  [families['PROMPT']]: families['PROMPT']
};

/**
 * Load the ChannelTracker JS API into global scope (that's how it currently works). @NOTE this library
 * depends on jQuery being available in global scope as well, which will already be loaded in the main document (see index.ejs)
 * by the time this module runs.
 *
 * @param {HTMLDocument} doc: the document to write the <script> tag to
 * @param {Environment} env: the environment from which to get configuration
 * @returns {Promise} Promise that resolves when all channel tracking libraries have been successfully loaded; rejects otherwise
 */
const loadChannelTrackerLib = (doc, env) => {
  // only load the library once...
  if (libLoadedPromise) {
    return libLoadedPromise;
  }

  const marketingApiUrl = env.get('marketingService.api.url');
  const marketingPluginsUrl = env.get('marketingService.plugins.url');

  const libLoadedPromises = [domHelpers.createScript(marketingApiUrl), domHelpers.createScript(marketingPluginsUrl)];
  libLoadedPromise = Promise.all(libLoadedPromises);

  return libLoadedPromise;
};

/**
 * Convenience interface for working with the Marketing Service javascript API (otherwise known as Channel Tracking).
 * See https://confluence.ops.expertcity.com/display/SOA/Marketing+Service+JS+API
 *
 * After the external library has loaded, there will be a global ChannelTracker class which this class wraps.
 */
export class ChannelTrackerWrapper {
  /**
   * @param {HTMLDocument} [doc]: the document to write the <script> tag to
   * @param {Environment} [env]: the environment from which to get configuration
   * @param {Promise} [libLoadProm]: promise to use for the loading of the library (for testing / dependency injection)
   */
  constructor(doc = document, env = environment, libLoadProm) {
    this.libLoad = libLoadProm || loadChannelTrackerLib(doc, env);
    this.channelTrackers = {};
    this.domain = doc.domain;
  }

  /**
   * Get the channel tracker for the specified product family key; either retrieves the already-existent tracker or creates, stores, and
   * returns a new one for the family key. The specified family key is the canonical family key as used on the Titan side; it is mapped
   * to the key that marketing service expects internally.
   *
   * @param {string} titanFamilyKey: the Titan canonical product family key (see families from product-catalog.js)
   * @returns {Promise}: a Promise resolving to an instance of the 3rd party ChannelTracker library after the libraries load, or rejected promise
   *                     if the libraries did not load
   */
  getChannelTracker(titanFamilyKey) {
    return this.libLoad.then(() => {
      const channelFamilyKey = titanToChannelKeymap[titanFamilyKey];
      const foundTracker = this.channelTrackers[channelFamilyKey];

      if (foundTracker) {
        return foundTracker;
      }

      this.channelTrackers[channelFamilyKey] = new ChannelTracker(channelFamilyKey, this.domain);
      return this.channelTrackers[channelFamilyKey];
    });
  }

  /**
   * Fire a "visit" channel tracking event (trackChannel() API call)
   *
   * @param {string} titanFamilyKey: the Titan canonical product family key (see families from product-catalog.js)
   * @returns {Promise} Promise that waits for channel tracking libraries to be loaded, and if that is successful, fires off a visit event for the
   *                    specified product family key; does nothing if the libraries have failed to load
   */
  visit(titanFamilyKey) {
    return this.getChannelTracker(titanFamilyKey).then(
      (tracker) => {
        tracker.trackChannel();
      },
      () => { /* noop for failure */ }
    );
  }

  /**
   * Append a promotion (specifically a promotion alias) to the channel. As far as the channel tracking library is concerned, any free form key/value pair
   * can be appended to a channel, but as far as commerce is concerned, we currently only ever append a promotion alias (which is same as the promotion code).
   * By convention the marketing service expects a promotion alias to have a key of 'pals' (short for "promotion alias").
   *
   * @param {string} titanFamilyKey: the Titan canonical product family key (see families from product-catalog.js)
   * @param {string} promoAlias: the promotion alias/code
   * @returns {Promise} Promise that waits for channel tracking libraries to be loaded, and if that is successful, appends the promotion alias (as attribute "pals") to the channel
   */
  appendPromo(titanFamilyKey, promoAlias) {
    return this.getChannelTracker(titanFamilyKey).then(
      (tracker) => {
        tracker.appendToChannel({pals: promoAlias});
      },
      () => { /* noop for failure */ }
    );
  }

  /**
   * Send channel tracking events for a successful order based on the specified order information.
   * For safety, first call trackChannel() to ensure trackChannelEvent() works as expected;
   * This comes from some of the assumptions that the channel tracking library makes about always having called trackChannel() before calling trackChannelEvent()
   *
   * @param {TrackingChannelOrderInfo} orderInfo
   * @returns {Promise} Promise that waits for channel tracking libraries to be loaded, and if that is successful, sends the appropriate channel tracking events for the order
   */
  trackOrder(orderInfo) {
    return Promise.all(orderInfo.orderItems.map((orderItem) => this.getChannelTracker(orderItem.productFamilyKey).then(
      (tracker) => {
        const registrant = {
          firstName: orderInfo.userInfo.firstName,
          lastName: orderInfo.userInfo.lastName,
          locale: orderInfo.userInfo.locale
        };

        // always track the channel first (note that duplicate calls to this should not hurt; subsequent calls to this function check the cookie values
        // to see if it should actually fire the event or not
        tracker.trackChannel();

        switch (orderItem.txnType) {
          case TRACKING_TXN_TYPE_TRIAL:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'NOCC_TRIAL', false, registrant);
            break;

          case TRACKING_TXN_TYPE_NEW_BUY:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'BUY', false, registrant);
            break;

          case TRACKING_TXN_TYPE_CONVERT:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'CONVERSION', false);
            break;

          case TRACKING_TXN_TYPE_LAPSED_BUY:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'LAPSED_BUY', true);
            break;

          case TRACKING_TXN_TYPE_CHANGE:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'CHANGE_PLAN', true);
            break;

          case TRACKING_TXN_TYPE_RETRIAL:
            tracker.trackChannelEvent(orderInfo.userInfo.key, orderInfo.userInfo.email, 'NOCC_RETRIAL', true);
            break;

          default: // no-op
        }
      },
      () => { /* noop for failure */ }
    )));
  }

  /**
   * Send channel tracking event for subscription cancellation (turn off auto-renew). For safety, first call trackChannel() to ensure trackChannelEvent() works as expected;
   * This comes from some of the assumptions that the channel tracking library makes about always having called trackChannel() before calling trackChannelEvent()
   *
   * @param {string} titanFamilyKey: the Titan canonical product family key (see families from product-catalog.js)
   * @param {TrackingChannelUserInfo} userInfo: the information for the user doing the cancel
   * @returns {Promise} Promise that waits for channel tracking libraries to be loaded, and if that is successful, sends a cancel (turn off auto-renew) tracking event
   */
  trackCancel(titanFamilyKey, userInfo) {
    return this.getChannelTracker(titanFamilyKey).then(
      (tracker) => {
        tracker.trackChannel();
        tracker.trackChannelEvent(userInfo.key, userInfo.email, 'AUTO_RENEW_OFF', true);
      },
      () => { /* noop for failure */ }
    );
  }

  /**
   * Send channel tracking event for cancelling a subscription cancellation (turn back on auto-renew).
   * For safety, first call trackChannel() to ensure trackChannelEvent() works as expected;
   * This comes from some of the assumptions that the channel tracking library makes about always having called trackChannel() before calling trackChannelEvent()
   *
   * @param {string} titanFamilyKey: the Titan canonical product family key (see families from product-catalog.js)
   * @param {TrackingChannelUserInfo} userInfo: the information for the user doing the cancel cancel
   * @returns {Promise} Promise that waits for channel tracking libraries to be loaded, and if that is successful,
   *  sends a cancel the cancel (turn back on auto-renew) tracking event
   */
  trackCancelCancel(titanFamilyKey, userInfo) {
    return this.getChannelTracker(titanFamilyKey).then(
      (tracker) => {
        tracker.trackChannel();
        tracker.trackChannelEvent(userInfo.key, userInfo.email, 'AUTO_RENEW_ON', true);
      },
      () => { /* noop for failure */ }
    );
  }
}

export default __TEST__ ? {} : new ChannelTrackerWrapper();
