export const EXPERIMENT_LD_DEFAULT_FLAGS = {
  g2mgrMultiproduct: false
};

export const EE_CANCEL = 'EE_CANCEL';
export const EE_CANCEL_STORAGE_KEY = `${EE_CANCEL}.retention.flow`;

export const EE_CANCEL_CAMPAIGN_NONE = 'none';
export const EE_CANCEL_CAMPAIGN_USAGE = 'usage';
export const EE_CANCEL_CAMPAIGN_SEASONALITY = 'seasonality';

// G2M Campaign
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE12 = 'g2m_ProPlus_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE_12 = 'g2m_ProPlus_flatRate_12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUSGBP_FLATRATE12 = 'g2m_ProPlusGBP_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUSEUR_FLATRATE12 = 'g2m_ProPlusEUR_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUSAUD_FLATRATE12 = 'g2m_ProPlusAUD_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUSCAD_FLATRATE12 = 'g2m_ProPlusCAD_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE14 = 'g2m_ProPlus_flatRate14';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE10 = 'g2m_ProPlus_flatRate10';
export const EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE6 = 'g2m_ProPlus_flatRate6';

export const EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE8 = 'g2m_Starter_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE_8 = 'g2m_Starter_flatRate_8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTERGBP_FLATRATE8 = 'g2m_StarterGBP_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTEREUR_FLATRATE8 = 'g2m_StarterEUR_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTERAUD_FLATRATE8 = 'g2m_StarterAUD_flatrate8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTERCAD_FLATRATE8 = 'g2m_StarterCAD_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE10 = 'g2m_Starter_flatRate10';
export const EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE6 = 'g2m_Starter_flatRate6';
export const EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE4 = 'g2m_Starter_flatRate4';

export const EE_CANCEL_CAMPAIGN_G2M_FLATRATE12 = 'g2m_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE12 = 'g2m_GBP_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE12 = 'g2m_EUR_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE12 = 'g2m_AUD_flatrate12';
export const EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE12 = 'g2m_CAD_flatRate12';
export const EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE_12 = 'g2m_Business_flatRate_12';
export const EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE14 = 'g2m_Business_flatRate14';
export const EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE10 = 'g2m_Business_flatRate10';
export const EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE6 = 'g2m_Business_flatRate6';

export const EE_CANCEL_CAMPAIGN_G2M_FLATRATE8 = 'g2m_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE8 = 'g2m_GBP_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE8 = 'g2m_EUR_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE8 = 'g2m_AUD_flatrate8';
export const EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE8 = 'g2m_CAD_flatRate8';
export const EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE_8 = 'g2m_Professional_flatRate_8';
export const EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE10 = 'g2m_Professional_flatRate10';
export const EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE6 = 'g2m_Professional_flatRate6';
export const EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE4 = 'g2m_Professional_flatRate4';

// G2W Campaign
export const EE_CANCEL_CAMPAIGN_FLATRATE49_59 = 'flatRate49_59';
export const EE_CANCEL_CAMPAIGN_FLATRATEGBP_39_49 = 'flatRateGBP_39_49';
export const EE_CANCEL_CAMPAIGN_FLATRATEEUR_41_49 = 'flatRateEUR_41_49';
export const EE_CANCEL_CAMPAIGN_FLATRATECAD_64_72 = 'flatRateCAD_64_72';
export const EE_CANCEL_CAMPAIGN_FLATRATEAUD_65_73 = 'flatRateAUD_65_73';

export const EE_CANCEL_CAMPAIGN_G2W_LOWTIER_FLATRATE39_49 = 'g2w_lowtier_flatRate39_49';
export const EE_CANCEL_CAMPAIGN_G2W_LOWTIERGBP_FLATRATE29_39 = 'g2w_lowtierGBP_flatRate29_39';
export const EE_CANCEL_CAMPAIGN_G2W_LOWTIEREUR_FLATRATE33_41 = 'g2w_lowtierEUR_flatRate33_41';
export const EE_CANCEL_CAMPAIGN_G2W_LOWTIERCAD_FLATRATE51_65 = 'g2w_lowtierCAD_flatRate51_65';
export const EE_CANCEL_CAMPAIGN_G2W_LOWTIERAUD_FLATRATE52_66 = 'g2w_lowtierAUD_flatRate52_66';

export const EE_CANCEL_CAMPAIGN_G2W_MIDTIER_FLATRATE129 = 'g2w_midtier_flatRate129';
export const EE_CANCEL_CAMPAIGN_G2W_MIDTIERGBP_FLATRATE99 = 'g2w_midtierGBP_flatRate99';
export const EE_CANCEL_CAMPAIGN_G2W_MIDTIEREUR_FLATRATE108 = 'g2w_midtierEUR_flatRate108';
export const EE_CANCEL_CAMPAIGN_G2W_MIDTIERCAD_FLATRATE155 = 'g2w_midtierCAD_flatRate155';
export const EE_CANCEL_CAMPAIGN_G2W_MIDTIERAUD_FLATRATE164 = 'g2w_midtierAUD_flatRate164';

export const EE_CANCEL_CAMPAIGN_G2W_HIGHTIER_FLATRATE300 = 'g2w_hightier_flatRate300';
export const EE_CANCEL_CAMPAIGN_G2W_HIGHTIERGBP_FLATRATE200 = 'g2w_hightierGBP_flatRate200';
export const EE_CANCEL_CAMPAIGN_G2W_HIGHTIEREUR_FLATRATE251 = 'g2w_hightierEUR_flatRate251';
export const EE_CANCEL_CAMPAIGN_G2W_HIGHTIERCAD_FLATRATE390 = 'g2w_hightierCAD_flatRate390';
export const EE_CANCEL_CAMPAIGN_G2W_HIGHTIERAUD_FLATRATE398 = 'g2w_hightierAUD_flatRate398';

// G2MGR Campaign
export const EE_CANCEL_CAMPAIGN_GTARS_FLATRATE46_55 = 'gtars_flatRate46_55';
export const EE_CANCEL_CAMPAIGN_GTARS_FLATRATE36_45 = 'gtars_flatRate36_45';

// G2T Campaign
export const EE_CANCEL_CAMPAIGN_FLATRATE87_95 = 'flatrate87_95';
export const EE_CANCEL_CAMPAIGN_FLATRATE209_279 = 'flatrate209_279';
export const EE_CANCEL_CAMPAIGN_FLATRATECAD_87_95 = 'flatrateCAD_87_95';
export const EE_CANCEL_CAMPAIGN_FLATRATEAUD_92_123 = 'flatrateAUD_92_123';
export const EE_CANCEL_CAMPAIGN_FLATRATEGBP_55_75 = 'flatrateGBP_55_75';

export const EE_CANCEL_CAMPAIGN_G2T_LOWTIER_FLATRATE87_95 = 'g2t_lowtier_flatrate87_95';
export const EE_CANCEL_CAMPAIGN_G2T_LOWTIERAUD_92_123 = 'g2t_lowtierAUD_92_123';
export const EE_CANCEL_CAMPAIGN_G2T_LOWTIEREUR_87_95 = 'g2t_lowtierEUR_87_95';
export const EE_CANCEL_CAMPAIGN_G2T_LOWTIERGBP_55_75 = 'g2t_lowtierGBP_55_75';

export const EE_CANCEL_CAMPAIGN_G2T_MIDTIER_FLATRATE127_143 = 'g2t_midtier_flatrate127_143';
export const EE_CANCEL_CAMPAIGN_G2T_MIDTIERCAD_159_183 = 'g2t_midtierCAD_159_183';
export const EE_CANCEL_CAMPAIGN_G2T_MIDTIERAUD_172_191 = 'g2t_midtierAUD_172_191';
export const EE_CANCEL_CAMPAIGN_G2T_MIDTIEREUR_135_151 = 'g2t_midtierEUR_135_151';
export const EE_CANCEL_CAMPAIGN_G2T_MIDTIERGBP_107_119 = 'g2t_midtierGBP_107_119';

export const EE_CANCEL_CAMPAIGN_G2T_HIGHTIER_FLATRATE209_279 = 'g2t_hightier_flatrate209_279';
export const EE_CANCEL_CAMPAIGN_G2T_HIGHTIEREUR_252_279 = 'g2t_hightierEUR_252_279';
export const EE_CANCEL_CAMPAIGN_G2T_HIGHTIERGBP_199_223 = 'g2t_hightierGBP_199_223';

// downgrade offers
export const EE_CANCEL_CAMPAIGN_DOWNGRADE_OFFER = 'downgradeOffer';
export const EE_CANCEL_CAMPAIGN_OTHER_DOWNGRADE_OFFER = 'otherDowngradeOffer';
export const EE_CANCEL_CAMPAIGN_OTHER_NONE = 'otherNone';

export const EE_CANCEL_CAMPAIGN_FLATRATE = {
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE_12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE_12,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUSGBP_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUSGBP_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUSEUR_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUSEUR_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE14]: EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE14,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE10]: EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE10,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE6]: EE_CANCEL_CAMPAIGN_G2M_PROPLUS_FLATRATE6,
  [EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE_8]: EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE_8,
  [EE_CANCEL_CAMPAIGN_G2M_STARTERGBP_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_STARTERGBP_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_STARTEREUR_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_STARTEREUR_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE10]: EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE10,
  [EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE6]: EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE6,
  [EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE4]: EE_CANCEL_CAMPAIGN_G2M_STARTER_FLATRATE4,
  [EE_CANCEL_CAMPAIGN_G2M_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE_12]: EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE_12,
  [EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE14]: EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE14,
  [EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE10]: EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE10,
  [EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE6]: EE_CANCEL_CAMPAIGN_G2M_BUSINESS_FLATRATE6,
  [EE_CANCEL_CAMPAIGN_G2M_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_GBP_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_EUR_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE_8]: EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE_8,
  [EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE10]: EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE10,
  [EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE6]: EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE6,
  [EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE4]: EE_CANCEL_CAMPAIGN_G2M_PROFESSIONAL_FLATRATE4,
  [EE_CANCEL_CAMPAIGN_FLATRATE49_59]: EE_CANCEL_CAMPAIGN_FLATRATE49_59,
  [EE_CANCEL_CAMPAIGN_FLATRATEGBP_39_49]: EE_CANCEL_CAMPAIGN_FLATRATEGBP_39_49,
  [EE_CANCEL_CAMPAIGN_FLATRATEEUR_41_49]: EE_CANCEL_CAMPAIGN_FLATRATEEUR_41_49,
  [EE_CANCEL_CAMPAIGN_FLATRATECAD_64_72]: EE_CANCEL_CAMPAIGN_FLATRATECAD_64_72,
  [EE_CANCEL_CAMPAIGN_FLATRATEAUD_65_73]: EE_CANCEL_CAMPAIGN_FLATRATEAUD_65_73,
  [EE_CANCEL_CAMPAIGN_G2W_LOWTIER_FLATRATE39_49]: EE_CANCEL_CAMPAIGN_G2W_LOWTIER_FLATRATE39_49,
  [EE_CANCEL_CAMPAIGN_G2W_LOWTIERGBP_FLATRATE29_39]: EE_CANCEL_CAMPAIGN_G2W_LOWTIERGBP_FLATRATE29_39,
  [EE_CANCEL_CAMPAIGN_G2W_LOWTIEREUR_FLATRATE33_41]: EE_CANCEL_CAMPAIGN_G2W_LOWTIEREUR_FLATRATE33_41,
  [EE_CANCEL_CAMPAIGN_G2W_LOWTIERCAD_FLATRATE51_65]: EE_CANCEL_CAMPAIGN_G2W_LOWTIERCAD_FLATRATE51_65,
  [EE_CANCEL_CAMPAIGN_G2W_LOWTIERAUD_FLATRATE52_66]: EE_CANCEL_CAMPAIGN_G2W_LOWTIERAUD_FLATRATE52_66,
  [EE_CANCEL_CAMPAIGN_G2W_MIDTIER_FLATRATE129]: EE_CANCEL_CAMPAIGN_G2W_MIDTIER_FLATRATE129,
  [EE_CANCEL_CAMPAIGN_G2W_MIDTIERGBP_FLATRATE99]: EE_CANCEL_CAMPAIGN_G2W_MIDTIERGBP_FLATRATE99,
  [EE_CANCEL_CAMPAIGN_G2W_MIDTIEREUR_FLATRATE108]: EE_CANCEL_CAMPAIGN_G2W_MIDTIEREUR_FLATRATE108,
  [EE_CANCEL_CAMPAIGN_G2W_MIDTIERCAD_FLATRATE155]: EE_CANCEL_CAMPAIGN_G2W_MIDTIERCAD_FLATRATE155,
  [EE_CANCEL_CAMPAIGN_G2W_MIDTIERAUD_FLATRATE164]: EE_CANCEL_CAMPAIGN_G2W_MIDTIERAUD_FLATRATE164,
  [EE_CANCEL_CAMPAIGN_G2W_HIGHTIER_FLATRATE300]: EE_CANCEL_CAMPAIGN_G2W_HIGHTIER_FLATRATE300,
  [EE_CANCEL_CAMPAIGN_G2W_HIGHTIERGBP_FLATRATE200]: EE_CANCEL_CAMPAIGN_G2W_HIGHTIERGBP_FLATRATE200,
  [EE_CANCEL_CAMPAIGN_G2W_HIGHTIEREUR_FLATRATE251]: EE_CANCEL_CAMPAIGN_G2W_HIGHTIEREUR_FLATRATE251,
  [EE_CANCEL_CAMPAIGN_G2W_HIGHTIERCAD_FLATRATE390]: EE_CANCEL_CAMPAIGN_G2W_HIGHTIERCAD_FLATRATE390,
  [EE_CANCEL_CAMPAIGN_G2W_HIGHTIERAUD_FLATRATE398]: EE_CANCEL_CAMPAIGN_G2W_HIGHTIERAUD_FLATRATE398,
  [EE_CANCEL_CAMPAIGN_GTARS_FLATRATE46_55]: EE_CANCEL_CAMPAIGN_GTARS_FLATRATE46_55,
  [EE_CANCEL_CAMPAIGN_GTARS_FLATRATE36_45]: EE_CANCEL_CAMPAIGN_GTARS_FLATRATE36_45,
  [EE_CANCEL_CAMPAIGN_G2M_STARTERAUD_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_STARTERAUD_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_STARTERCAD_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_STARTERCAD_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUSAUD_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUSAUD_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_PROPLUSCAD_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_PROPLUSCAD_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE12]: EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE12,
  [EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_AUD_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE8]: EE_CANCEL_CAMPAIGN_G2M_CAD_FLATRATE8,
  [EE_CANCEL_CAMPAIGN_FLATRATE87_95]: EE_CANCEL_CAMPAIGN_FLATRATE87_95,
  [EE_CANCEL_CAMPAIGN_FLATRATE209_279]: EE_CANCEL_CAMPAIGN_FLATRATE209_279,
  [EE_CANCEL_CAMPAIGN_FLATRATECAD_87_95]: EE_CANCEL_CAMPAIGN_FLATRATECAD_87_95,
  [EE_CANCEL_CAMPAIGN_FLATRATEAUD_92_123]: EE_CANCEL_CAMPAIGN_FLATRATEAUD_92_123,
  [EE_CANCEL_CAMPAIGN_FLATRATEGBP_55_75]: EE_CANCEL_CAMPAIGN_FLATRATEGBP_55_75,
  [EE_CANCEL_CAMPAIGN_G2T_LOWTIER_FLATRATE87_95]: EE_CANCEL_CAMPAIGN_G2T_LOWTIER_FLATRATE87_95,
  [EE_CANCEL_CAMPAIGN_G2T_LOWTIERAUD_92_123]: EE_CANCEL_CAMPAIGN_G2T_LOWTIERAUD_92_123,
  [EE_CANCEL_CAMPAIGN_G2T_LOWTIEREUR_87_95]: EE_CANCEL_CAMPAIGN_G2T_LOWTIEREUR_87_95,
  [EE_CANCEL_CAMPAIGN_G2T_LOWTIERGBP_55_75]: EE_CANCEL_CAMPAIGN_G2T_LOWTIERGBP_55_75,
  [EE_CANCEL_CAMPAIGN_G2T_MIDTIER_FLATRATE127_143]: EE_CANCEL_CAMPAIGN_G2T_MIDTIER_FLATRATE127_143,
  [EE_CANCEL_CAMPAIGN_G2T_MIDTIERCAD_159_183]: EE_CANCEL_CAMPAIGN_G2T_MIDTIERCAD_159_183,
  [EE_CANCEL_CAMPAIGN_G2T_MIDTIERAUD_172_191]: EE_CANCEL_CAMPAIGN_G2T_MIDTIERAUD_172_191,
  [EE_CANCEL_CAMPAIGN_G2T_MIDTIEREUR_135_151]: EE_CANCEL_CAMPAIGN_G2T_MIDTIEREUR_135_151,
  [EE_CANCEL_CAMPAIGN_G2T_MIDTIERGBP_107_119]: EE_CANCEL_CAMPAIGN_G2T_MIDTIERGBP_107_119,
  [EE_CANCEL_CAMPAIGN_G2T_HIGHTIER_FLATRATE209_279]: EE_CANCEL_CAMPAIGN_G2T_HIGHTIER_FLATRATE209_279,
  [EE_CANCEL_CAMPAIGN_G2T_HIGHTIEREUR_252_279]: EE_CANCEL_CAMPAIGN_G2T_HIGHTIEREUR_252_279,
  [EE_CANCEL_CAMPAIGN_G2T_HIGHTIERGBP_199_223]: EE_CANCEL_CAMPAIGN_G2T_HIGHTIERGBP_199_223
};

export const IN_RETENTION_PRODUCT_MUTABLE_PROPERTIES_BY_USER = ['billingPeriod', 'productKey', 'quantity'];

/**
 * Currency Code relationship with Price for G2W Flex usage.
 * Right now, the prices for both annual and monthly are similar across the country.
*/
export const currencyCodeToPriceMapping = {
  USD: 149,
  AUD: 195,
  CAD: 190,
  CHF: 133,
  EUR: 123,
  GBP: 109,
  NZD: 206,
  SEK: 1246
};

export const currencyCodeToAttendeePriceMapping = {
  500: {
    USD: 149,
    AUD: 195,
    CAD: 190,
    CHF: 133,
    EUR: 123,
    GBP: 109,
    NZD: 206,
    SEK: 1246
  },
  1000: {
    USD: 299,
    AUD: 391,
    CAD: 382,
    CHF: 266,
    EUR: 246,
    GBP: 218,
    NZD: 414,
    SEK: 2500
  },
  5000: {
    USD: 499,
    AUD: 653,
    CAD: 638,
    CHF: 444,
    EUR: 411,
    GBP: 364,
    NZD: 690,
    SEK: 4171
  }
};
