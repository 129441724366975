import findIndex from 'lodash/findIndex';

/**
 * This will take an array of price objects and sort them by highest period/duration to lowest.
 * @param prices
 * @returns {Array}
 */
export const pricesSortedByFrequencyDescending = (prices) => {
  const billingPeriodNumericValue = (price) => {
    switch (price.billingPeriod) {
      case 'DAY':
        return 1;
      case 'MONTH':
        return 30;
      case 'YEAR':
        return 365;
      default:
        return 0;
    }
  };

  const billingFrequencyValue = (price) => price.billingDuration * billingPeriodNumericValue(price);

  return prices.sort((a, b) => billingFrequencyValue(b) - billingFrequencyValue(a));
};

/**
 * This is a filter that will take in an array of sorted price objects and the target price object.
 * It will remove any price objects that are considered to be "lower" than the target price object.
 * I.e., if the current price is YEAR then it will filter out MONTH and DAY
 * @param prices
 * @param targetPrice
 * @returns {Array}
 */
export const pricesFilteredByTargetOrHigher = (prices, targetPrice) => {
  const indexOfTargetPrice = findIndex(prices, { billingPeriod: targetPrice.billingPeriod, billingDuration: targetPrice.billingDuration });
  if (indexOfTargetPrice !== -1) {
    return prices.slice(0, indexOfTargetPrice + 1);
  }
  return prices;
};

export default {
  pricesSortedByFrequencyDescending,
  pricesFilteredByTargetOrHigher
};
