import { customClient as api } from 'dao/providers/axios';
import config from 'config/appconfig';

const scaTokenEndpoint = config.cog_endpoints.scaToken;

export const daoSCATokenPost = (payload) => api.post(scaTokenEndpoint(), payload);

export default {
  daoSCATokenPost
};
