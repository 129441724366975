import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cartItemCount,
  cartIsLoading,
  cartRequestGet
} from 'modules/cart-module';
import MainNavCartButtonView from './main-nav-cart-button-view';

export const mapStateToProps = (state) => ({
  cartItemCount: cartItemCount(state),
  cartIsLoading: cartIsLoading(state)
});

export const mapDispatchToProps = {
  cartRequestGet
};

export class MainNavCartButtonContainer extends Component {
  componentDidMount = () => {
    this.props.cartRequestGet();
  }

  render = () => (
    <MainNavCartButtonView
      cartIsLoading={this.props.cartIsLoading}
      cartItemCount={this.props.cartItemCount}
    />
  );
}

MainNavCartButtonContainer.propTypes = {
  cartIsLoading: PropTypes.bool.isRequired,
  cartRequestGet: PropTypes.func.isRequired,
  cartItemCount: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavCartButtonContainer);
