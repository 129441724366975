import LaunchDarkly from 'lib/launch-darkly';
import Amplitude from 'lib/amplitude';
import {
  EXPERIMENT_LD_DEFAULT_FLAGS,
  experimentSetFlags
} from 'modules/experiments';
import {
  meEmail,
  meKey
} from 'modules/me-module';

/**
 * Determines whether the user is a test account.
 *
 * @param {string} input email address of the user account
 * @returns {boolean}
 */
export const isTestAccount = (input) => /^bpor-.*@jedix\.com$/g.test(input);

/**
 * Returns a list of feature flags and their values.
 *
 * If the email matches a test account signature, parse the email address into
 * a map of feature flags, otherwise; return the default feature flags.
 *
 * eg: given an email address: bpor-ld.carduiv1.t-1234567890@jedix.com, return a map
 * { carduiv1: true }
 * @param {string} input email address of the account
 * @returns {object} map of flags and true or false.
 */
export const emailToFeatureFlags = (input) => {
  if (!isTestAccount(input)) {
    return EXPERIMENT_LD_DEFAULT_FLAGS;
  }

  // split the input and remove the prefix and suffix
  const items = input.split('-');
  items.pop();
  items.shift();

  /**
   * Separate each item into its domain, key and value.  If the domain
   * is launch darkly, update the feature flags to include the key and
   * value.
   */
  return items.reduce((acc, item) => {
    const [domain, key, value] = item.split('.');
    if (domain === 'ld' && ['t', 'f'].includes(value)) {
      acc[key] = value === 't';
    }
    return acc;
  }, {});
};

/**
 * Bootstrap the amplitude service.
 */
export const bootstrapAmplitude = () => (dispatch, getState) => {
  const state = getState();
  const userKey = meKey(state);
  const userEmail = meEmail(state);

  if (isTestAccount(userEmail)) {
    Amplitude.stub('reason: Test User');
    return;
  }

  Amplitude.initialize({ userKey });
};

/**
 * Bootstrap the LaunchDarkly integration and mutate enabled feature flags by reading
 * values in from the user's email address.
 *
 * @param {string} launchDarklyClientId string identifier used to identify the client
 * to the 3rd party service.
 */
export const bootstrapLaunchDarkly = (launchDarklyClientId) => (dispatch, getState) => {
  const state = getState();
  const userEmail = meEmail(state);
  const onFlagsChanged = (incomingFlags) => dispatch(experimentSetFlags(incomingFlags));

  /**
   * If the user is test account, don't initialize Launch Darkly.
   * Instead, determine whether the test account has Feature Flags encoded
   * into it and load -those- flags into state.
   */

  if (isTestAccount(userEmail)) {
    const requestedFlags = emailToFeatureFlags(userEmail);
    const runtimeFlags = Object.keys(EXPERIMENT_LD_DEFAULT_FLAGS).reduce((acc, flag) => {
      const requestedFlagValue = requestedFlags[flag.toLowerCase()];
      if (requestedFlagValue) {
        acc[flag] = requestedFlagValue;
      } else {
        acc[flag] = EXPERIMENT_LD_DEFAULT_FLAGS[flag];
      }
      return acc;
    }, {});
    onFlagsChanged(runtimeFlags);
  } else {
    /**
     * If the user is NOT a test account, go ahead and initialize Launch Darkly
     * and integrate with Amplitude.
     */
    const userKey = meKey(state);

    LaunchDarkly.initialize({
      clientId: launchDarklyClientId,
      user: {
        key: userKey
      },
      flags: EXPERIMENT_LD_DEFAULT_FLAGS,
      onChange: onFlagsChanged
    });
  }
};
