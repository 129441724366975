import React, { ReactNode, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: ReactNode;
  id: string;
  icon: ReactNode;
  label: ReactNode;
  ariaLabel: string;
}

export const NavDropdown: React.FC<Props> = (props) => {
  const [isDropDown, setDropDown] = useState(false);
  const dropdownRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!dropdownRef.current?.contains(event.target)) {
        setDropDown(false);
      }
    };
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  return (
    <li className={isDropDown ? 'dropdown show' : 'dropdown'} ref={dropdownRef}>
      <span
        role="button"
        aria-label={props.ariaLabel}
        tabIndex={0}
        className="dropdown-toggler dropdown-toggle-icon"
        id={props.id}
        data-toggle="dropdown"
        onClick={() => setDropDown(!isDropDown)}
      >
        {props.icon}
        <span className="icon-label-lite">{props.label}</span>
      </span>
      <ul className="dropdown-menu nav-dropdown-menu dropdown-menu-right" role="menu" aria-labelledby={props.id}>
        {props.children}
      </ul>
    </li>
  );
};

(NavDropdown as any).propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string
};

export default NavDropdown;
