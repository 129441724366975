import environment from 'lib/environment';
import bootstrapInvalidBrowser from 'lib/bootstrap-invalid-browser';

// We only load the app if they are using a supported browser
if (window.gtie10) {
  environment.fetch().then(() => {
    const { runApp } = require('main.js'); // eslint-disable-line
    runApp();
  });
} else {
  bootstrapInvalidBrowser();
}
