import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExternalAnchor from 'components/external-anchor';
import st from 'components/shared-translations';
import classes from './unauthorized.scss';

interface Props {
  accountUrl: string;
}

export const UnauthorizedView: React.FC<Props> = (props) => (
  <div id="unauthorized-view" className={classes['unauthorized-view']}>
    <div className="row">
      <div className="offset-sm-2 col-sm-8 col-12">
        <i className="togo-icon togo-icon-rocket" />
      </div>
    </div>
    <div className="row">
      <div className="offset-sm-2 col-sm-8 col-12">
        <h2><FormattedMessage {...st['common.forbidden']} /></h2>
      </div>
    </div>
    <div className="row">
      <div className="offset-sm-3 col-sm-6 col-12">
        <h5>
          <FormattedMessage
            {...st['common.contact-admin']}
            id="account-link"
            values={{
              'account-link': (msg: string) => <ExternalAnchor id="unauthorized-account" href={props.accountUrl}>{msg}</ExternalAnchor>
            }}
          />
        </h5>
      </div>
    </div>
  </div>
);

(UnauthorizedView as any).propTypes = {
  accountUrl: PropTypes.string.isRequired
};

export default UnauthorizedView;
