import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getProductFamilyEndpointByKey,
  productFamilyNameByKey,
  productIconClassesByProductKey,
  productFamilyHubEndpoint
} from 'lib/product-catalog';
import MainNavView from './main-nav-view';
import environment from 'lib/environment';
import {
  meFullName,
  meEmail,
  meEntitlements,
  scimMeRequestGet
} from 'modules/me-module';
import {
  productSettingRequestGet,
  productSettingFlag
} from 'modules/product-module';

export const mapStateToProps = (state) => ({
  fullName: meFullName(state),
  email: meEmail(state),
  myProducts: meEntitlements(state),
  isGotoAppEnabled: productSettingFlag(state, 'g2m', 'gotoAppEnabled')
});

export const mapDispatchToProps = {
  scimMeRequestGet,
  productSettingRequestGet
};

const closeMobileNav = () => {
  // When the mobile dropdown nav is enabled (screen size `s` or smaller) it doesn't close on its own.
  // A solution here is to just remove the `show` class to hide the mobile nav.
  document.getElementById('main-navbar-collapse').classList.remove('show');
};

export class MainNavContainer extends Component {
  constructor(props) {
    super(props);

    this.adminCenterUrl = environment.get('adminCenter.url');
    this.supportUrl = environment.get('support.url');
    this.faqUrl = environment.get('faq.url');
    this.myAccountUrl = environment.get('myAccount.url');
    this.personalInfoUrl = environment.get('personalInfo.url');
    this.signinSecurityUrl = environment.get('signinSecurity.url');
  }

  componentDidMount = async () => {
    if (this.props.isAuthorized) {
      await this.props.scimMeRequestGet()
        .catch(() => {});
      await this.props.productSettingRequestGet('g2m')
        .catch(() => {});
    }
  };

  componentDidUpdate = () => {
    closeMobileNav();
  }

  getProducts = () => (
    this.props.myProducts.map((productKey) => {
      const product = {
        name: productFamilyNameByKey(productKey),
        href: getProductFamilyEndpointByKey(productKey)
      };
      if (productKey === 'G2M' && this.props.isGotoAppEnabled) {
        product.href = productFamilyHubEndpoint[productKey];
      }
      return ({
        ...product,
        iconClasses: productIconClassesByProductKey(productKey)
      });
    })
  );

  render = () => (
    <MainNavView
      fullName={this.props.fullName}
      email={this.props.email}
      isAuthorized={this.props.isAuthorized}
      faqUrl={this.faqUrl}
      adminCenterUrl={this.adminCenterUrl}
      signinSecurityUrl={this.signinSecurityUrl}
      personalInfoUrl={this.personalInfoUrl}
      myAccountUrl={this.myAccountUrl}
      supportUrl={this.supportUrl}
      products={this.getProducts()}
    />
  );
}

MainNavContainer.propTypes = {
  fullName: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  myProducts: PropTypes.array.isRequired,
  scimMeRequestGet: PropTypes.func.isRequired,
  productSettingRequestGet: PropTypes.func.isRequired,
  isGotoAppEnabled: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavContainer);
