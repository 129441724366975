import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import st from 'components/shared-translations';

const SelectFieldView = (props) => (
  <div>
    <select
      id={props.id}
      {...props.input}
      className={props.fieldClasses}
      disabled={props.disabled}
    >
      {props.children}
    </select>
    {props.meta.touched && (
      (props.meta.error &&
        <span className="d-flex form-text-error">{st[props.meta.error] ? <FormattedMessage {...st[props.meta.error]} /> : ''}</span>) ||
      (props.meta.warning &&
        <span>{props.meta.warning}</span>
      )
    )}
  </div>
);

SelectFieldView.propTypes = {
  children: PropTypes.any,
  fieldClasses: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.object
  }),
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default SelectFieldView;
