import Auth from '@getgo/auth-client';
import environment from 'lib/environment';
import appconfig from 'config/appconfig';
import Session from 'lib/session';

/**
 * @typedef {object} OauthConfig
 *
 * The configuration required for the live oauth library
 *
 * @property {string} client_id: The client ID for the OAuth implicit grant flow
 * @property {string} url: The URL for the authentication service to use
 * @property {string} redirect_url: The URL to redirect to after successful authentication
 */

/**
 * @typedef {object} OauthOptions
 *
 * The options that can be passed to the live oauth library
 *
 * @property {boolean} enableTokenRefresh: Whether or not to let the library take care of requesting a new access token when needed;
 *                                         If this is true, then at the timestamp returned by tokenRefreshTimestamp(), run the specified
 *                                         onTokenRefresh function
 * @property {function} onTokenRefresh: What to do on token refresh
 * @property {function} tokenRefreshTimestamp: When the token should be refreshed
 */

/**
 * Commerce (Billing Portal) authentication services. Retrieves, stores, and validates an API access token,
 * checks for token refresh, stores token in session storage, and handles logging out.
 */
export class CommerceAuth {
  /**
   * @constructor
   * @param {Auth} AuthClass - External oauth library to use
   * @param {OauthConfig} config
   * @param {*} [storage]
   * @param {string} [storageKey]
   */
  constructor(AuthClass, config, storage, storageKey = appconfig.storage.oauth) {
    this.storage = storage;
    this.storageKey = storageKey;
    this.token = this.auth;
    this.warning_offset = 180000; // time (in ms) to warn user before the token expires
    this.auth_warning_timer = undefined;
    this.auth_expired_timer = undefined;

    const expirationOffset = 30000;

    /**
     * @type {OauthOptions}
     */
    const options = {
      enableTokenRefresh: false,

      onTokenRefresh: () => {
        this.clear();
        this.storage.setOriginalTarget();
        return Promise.resolve(); // returning resolved promise lets library do the rest of the refresh operation
      },

      // token.expires is in milliseconds; refresh 30 seconds before it expires
      tokenRefreshTimestamp: (token) => token.expires - expirationOffset
    };

    this.oauth = new AuthClass({...config, ...options});
  }

  /**
   * Retrieves the auth data from the storage interface
   */
  get auth() {
    let localToken = this.storage.getItem(this.storageKey) || undefined;
    if (localToken) {
      try {
        localToken = JSON.parse(localToken);
      } catch (e) {
        localToken = undefined;
      }
    }
    return localToken;
  }

  /**
   * Clears out the auth-related session storage values and removes the token from memory
   */
  clear() {
    this.storage.removeItem(this.storageKey);
    this.storage.removeOriginalTarget();
    window.clearTimeout(this.auth_warning_timer);
    window.clearTimeout(this.auth_expired_timer);
    this.token = undefined;
  }

  /**
   * Sets up the notification timer that will warn the user when the token is about to expire which will redirect them
   */
  setNotificationTimers({authWarningAction, authExpiredAction}) {
    // clear existing timers
    window.clearTimeout(this.auth_warning_timer);
    window.clearTimeout(this.auth_expired_timer);

    // This will alert the user when their token has expired
    const expiredTime = Math.max(0, this.token.expires - Date.now());
    // This will warn the user X milliseconds before the expired alert takes place, where x is this.warning_offset
    const warningTime = expiredTime - this.warning_offset;
    // Use warning offset only if we have the full offset remaining, otherwise use the difference between the offset and warning time
    const timeLeftBeforeExpiration = (warningTime < 0) ? this.warning_offset + warningTime : this.warning_offset;

    this.auth_warning_timer = window.setTimeout(() => this.store.dispatch(authWarningAction({warningTime: timeLeftBeforeExpiration})), warningTime);
    this.auth_expired_timer = window.setTimeout(() => this.store.dispatch(authExpiredAction()), expiredTime);
  }

  /**
   * Check for an existing token in sessionStorage, and if expired/invalid or not found, redirect to login/auth flow
   *
   * @param {object} store - An instance of a data store that provides a method for dispatching actions
   * @param {object} options - Catchall configuration object
   * @param {object} options.authWarningAction - Action to dispatch when token is about to expire
   * @param {object} options.authExpiredAction - Action to dispatch when token has expired
   * @returns {Promise} Resolves if valid token is found; remains unresolved if the oauth library is redirecting to login/auth flow
   */
  init(store, options) {
    this.store = store;
    this.storage.setOriginalTarget();
    this.token = this.oauth.init(this.token);

    if (this.token) {
      this.setNotificationTimers(options);
      this.storage.setItem(this.storageKey, JSON.stringify(this.token));
      return Promise.resolve();
    }

    return this.oauth.login();
  }

  /**
   * Log the user out and redirect to login screen
   */
  logout() {
    this.clear();
    this.oauth.logout();
  }
}

export default new CommerceAuth(Auth, environment.get('oauth'), Session);
