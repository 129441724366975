import {
  customClient as apiV2,
  default as api
} from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const cartEndpoint = appconfig.cog_endpoints.cart;

export const daoCartGet = (billingAccountId) => api.get(cartEndpoint(billingAccountId));
export const daoCartPatch = (billingAccountId, payload) => apiV2.patch(cartEndpoint(billingAccountId), payload);
export const daoCartItemPost = (billingAccountId, payload) => apiV2.post(`${cartEndpoint(billingAccountId)}/items`, payload);
export const daoCartItemPatch = (billingAccountId, cartItemKey, payload) => apiV2.patch(`${cartEndpoint(billingAccountId)}/items/${cartItemKey}`, payload);
export const daoCartItemDelete = (billingAccountId, cartItemKey) => api.delete(`${cartEndpoint(billingAccountId)}/items/${cartItemKey}`);
export const daoCartQuoteGet = (billingAccountId) => api.get(`${cartEndpoint(billingAccountId)}/quote?loadProduct=true`);

export default {
  daoCartGet,
  daoCartPatch,
  daoCartItemPost,
  daoCartItemPatch,
  daoCartItemDelete,
  daoCartQuoteGet
};
