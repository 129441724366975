import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlertTrySwitchGotoAdminView from './alert-try-switch-goto-admin-view';
import { billingAccountActiveAccountKey } from 'modules/billing-account';
import { accountIsLoading, accountRequestPut } from 'modules/account-module';
import environment from 'lib/environment';
import { errorsShowAlert } from 'modules/errors-module';
import { track } from 'lib/tracking';
import { DisplaySwitchToGTA } from 'modules/tracking-module';


export const mapStateToProps = (state) => ({
  accountKey: billingAccountActiveAccountKey(state),
  isPutAccountLoading: accountIsLoading(state)
});

// eslint-disable-next-line react-redux/mapDispatchToProps-prefer-shorthand
export const mapDispatchToProps = (dispatch) => ({
  accountRequestPut: (billingAccountKey, payload) => dispatch(accountRequestPut(billingAccountKey, payload)),
  errorsShowAlert: (message) => dispatch(errorsShowAlert(message)),
  track: (eventData) => dispatch(track(eventData))
});

const gotoAdminURL = environment.get('gotoAdmin.url');

class AlertTrySwitchGotoAdminContainer extends Component {
   state = {
     isModal: false
   };

  toggle = () => this.setState((prevState) => ({ isModal: !prevState.isModal }));

  componentDidMount = () => {
    this.props.track({
      [DisplaySwitchToGTA]: {}
    });
  }

  handleSwitchToGTA = () => {
    const payload = {
      unifiedAdmin: true,
      goToAdmin: true
    };

    this.props
      .accountRequestPut(this.props.accountKey, payload)
      .then(() => {
        window.location.replace(
          `${gotoAdminURL}/${this.props.accountKey}/billing/subscriptions`
        );
      })
      .catch(() => {
        this.props.errorsShowAlert('alert.try.switch.modal.something.wrong');
        this.toggle();
      });
  };

  trackGTA = (type) => {
    this.props.track({
      [type]: {}
    });
  };

  render = () => (
    <AlertTrySwitchGotoAdminView
      isModal={this.state.isModal}
      toggleModal={this.toggle}
      handleSwitchToGTA={this.handleSwitchToGTA}
      isPutAccountLoading={this.props.isPutAccountLoading}
      accountKey={this.props.accountKey}
      gotoAdminURL={gotoAdminURL}
      trackGTA={this.trackGTA}
    />
  );
}

AlertTrySwitchGotoAdminContainer.propTypes = {
  accountKey: PropTypes.string.isRequired,
  isPutAccountLoading: PropTypes.bool.isRequired,
  accountRequestPut: PropTypes.func.isRequired,
  errorsShowAlert: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertTrySwitchGotoAdminContainer);
