import sortBy from 'lodash/sortBy';

/**
 * @callback ErrorResponseInterceptorThunk
 *
 * A thunk whose returned function follows the semantics of ErrorResponseInterceptor.
 *
 * @param {object}  errorResponse: The error.response object from an HTTP response
 * @returns {function} A function that refers to errorResponse and returns a promise. If the function handles this particular error,
 *                     the returned promise should resolve with errorResponse, otherwise the returned promise should reject with
 *                     errorResponse.
 */

// ------------------------------------
// Constants
// ------------------------------------

export const ERRORS_SHOW_ALERT = 'ERRORS_SHOW_ALERT';
export const ERRORS_DISMISS_ALERT = 'ERRORS_DISMISS_ALERT';
export const ERRORS_RECORD_RESPONSE = 'ERRORS_RECORD_RESPONSE';
export const ERRORS_CLEAR_RESPONSE = 'ERRORS_CLEAR_RESPONSE';
export const ERRORS_QUOTE_ORDER_POST = 'ERRORS_QUOTE_ORDER_POST';

// ------------------------------------
// Utilities
// ------------------------------------

/**
 * Return a new {ErrorResponseInterceptor} that dispatches the given thunk with the error response
 *
 * @param {function} dispatch
 * @param {ErrorResponseInterceptorThunk} thunk: a function that returns a function that expects to be passed a dispatch
 * @returns {ErrorResponseInterceptor}
 */
export const thunkToErrorResponseInterceptor = (dispatch, thunk) => (errorResponse) => dispatch(thunk(errorResponse));

/**
 * Return the status code of a given recorded (via a ERRORS_RECORD_RESPONSE action) error response.
 *
 * @param {object} errorResponse: an error object created via ERRORS_RECORD_RESPONSE action
 * @returns {number} the status code of the error response
 */
export const getErrorStatus = (errorResponse) => errorResponse.status;

/**
 * Return the array of errors from a given recorded (via a ERRORS_RECORD_RESPONSE action) error response.
 *
 * @param {object} errorResponse: an error object created via ERRORS_RECORD_RESPONSE action
 * @returns {object[]} the list of specific errors for this response. An error has shape:
 *                     {
 *                       code: <string> a domain-specific identifier for what type of failure happened
 *                       [field]: <string> optional, an indicator of which field of a request had a problem/failure
 *                     }
 */
export const getErrorList = (errorResponse) => (errorResponse.data || {}).errors || [];

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  alerts: {},
  responses: {}
};

// ------------------------------------
// Selectors
// ------------------------------------
export const errorsTree = (state) => state.errors || {};
export const errorsAlerts = (state) => errorsTree(state).alerts || {};
export const errorsAlertsKeys = (state) => Object.keys(errorsAlerts(state));
export const errorsAlertsAreVisible = (state) => !!errorsAlertsKeys(state).length;
export const errorsAlertsMessagesSorted = (state) => {
  const alerts = errorsAlerts(state);
  return sortBy(Object.keys(alerts).map((alertMessageKey) => alerts[alertMessageKey]), ['timestamp']).reverse();
};
export const errorsResponses = (state) => errorsTree(state).responses || {};
export const errorsQuoteOrderPost = (state) => errorsResponses(state)[ERRORS_QUOTE_ORDER_POST];

// ------------------------------------
// Actions
// ------------------------------------

/**
 * @typedef {string|object} ErrorAlertPayload
 *
 * Payload shape for a ERRORS_SHOW_ALERT action.
 *
 * If a string:
 *   Identifier for a message which will be used as the key for this alert in redux, and also the
 *   message key for this translated message, which must be a react-intl message defined in alert-error-view.js.
 *
 * If an object:
 *  A full react-intl message object:
 *    @property {string} id: the react-intl message key and also will be the key for this alert in redux
 *    @property {string} defaultMessage: the react-intl message string
 *    @property {object} [values]: optional, any values to be inserted into the defaultMessage
 */

/**
 * Create a ERRORS_SHOW_ALERT action
 *
 * @param {ErrorAlertPayload} payload
 * @returns {{type: string, payload: {key: string, timestamp: number, [msgObj]: object}}}
 */
export const errorsShowAlert = (payload) => {
  const type = ERRORS_SHOW_ALERT;
  const timestamp = Date.now();

  if (payload && typeof payload === 'string') {
    return {
      type,
      payload: {
        key: payload,
        timestamp
      }
    };
  }

  if (payload && typeof payload === 'object') {
    return {
      type,
      payload: {
        key: payload.id,
        timestamp,
        msgObj: payload
      }
    };
  }

  return {
    type,
    payload: {}
  };
};

export const errorsDismissAlert = (payload = {}) => ({
  type: ERRORS_DISMISS_ALERT,
  payload
});

export const errorsDismissAlertsByPrefix = (keyPrefix) => (dispatch, getState) => {
  const keyPrefixRegex = new RegExp(`^${keyPrefix.replace(/\./g, '\\.')}`);

  errorsAlertsKeys(getState())
    .filter((key) => key.match(keyPrefixRegex))
    .forEach((key) => dispatch(errorsDismissAlert({key})));
};

// Quote Order Errors:
export const errorsRecordQuoteOrderPost = (payload = {}) => ({
  type: ERRORS_RECORD_RESPONSE,
  key: ERRORS_QUOTE_ORDER_POST,
  payload: {
    status: payload.status,
    data: {...payload.data}
  }
});

export const errorsClearQuoteOrderPost = () => ({
  type: ERRORS_CLEAR_RESPONSE,
  key: ERRORS_QUOTE_ORDER_POST
});

/**
 * Dispatch a banner error message if status is 403 permission denied.
 *
 * @callback {ErrorResponseInterceptorThunk}
 */
export const errorInterceptorHandlePermission = (errorResponse) => (dispatch) => {
  if (errorResponse.status === 403) {
    dispatch(errorsShowAlert('alert.error.permissiondenied'));
    return Promise.resolve(errorResponse);
  }
  return Promise.reject(errorResponse);
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [ERRORS_SHOW_ALERT]: (state, action) => {
    const alertObj = {
      key: action.payload.key,
      timestamp: action.payload.timestamp
    };

    if (action.payload.msgObj) {
      alertObj.msgObj = action.payload.msgObj;
    }
    return {
      ...state,
      alerts: {
        ...state.alerts,
        [action.payload.key]: alertObj
      }
    };
  },
  [ERRORS_DISMISS_ALERT]: (state, action) => {
    const alerts = {...state.alerts};
    delete alerts[action.payload.key];
    return {
      ...state,
      alerts
    };
  },
  [ERRORS_RECORD_RESPONSE]: (state, action) => ({
    ...state,
    responses: {
      ...state.responses,
      [action.key]: action.payload
    }
  }),
  [ERRORS_CLEAR_RESPONSE]: (state, action) => {
    const responses = {...state.responses};
    delete responses[action.key];
    return {
      ...state,
      responses
    };
  }
});

// ------------------------------------
// Reducer
// ------------------------------------
export const errorsReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default errorsReducer;
