import {
  IMPORTANT_DATES_RECEIVE_GET_SUCCESS,
  IMPORTANT_DATES_RECEIVE_GET_FAILURE,
  IMPORTANT_DATES_REQUEST_GET
} from './important-dates-action-types';

import ImportantDatesModel from './important-dates-model';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [IMPORTANT_DATES_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [IMPORTANT_DATES_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (Array.isArray(action.payload)) {
      return {
        ...state,
        items: action.payload.map(ImportantDatesModel.buildAppModel),
        isLoading: false
      };
    }
    return state;
  },
  [IMPORTANT_DATES_RECEIVE_GET_FAILURE]: (state) => ({...state, isLoading: false})
});

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  items: [],
  isLoading: false
};

// ------------------------------------
// Reducer
// ------------------------------------
export const importantDatesReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};
