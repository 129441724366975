import {
  daoProductFamiliesGet,
  daoProductRelationsGet,
  daoProductSettingGet
} from 'dao/product-dao';
import {
  billingAccountCountryCode,
  billingAccountActiveKey
} from 'modules/billing-account';
import {
  uiSubsConfigIsAddingNewSubscription,
  uiSubsConfigCurrentProductKey
} from 'modules/ui';
import ProductCatalog from 'lib/product-catalog';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCTS_REQUEST_BY_PRODUCT_KEYS_GET = 'PRODUCTS_REQUEST_BY_PRODUCT_KEYS_GET';
export const PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_SUCCESS = 'PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_SUCCESS';
export const PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_FAILURE = 'PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_FAILURE';

export const PRODUCTS_REQUEST_BY_SUBSCRIPTION_KEY_GET = 'PRODUCTS_REQUEST_BY_SUBSCRIPTION_KEY_GET';
export const PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS = 'PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS';
export const PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_FAILURE = 'PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_FAILURE';

export const PRODUCTS_REQUEST_BY_PRODUCT_FAMILY_KEY_GET = 'PRODUCTS_REQUEST_BY_PRODUCT_FAMILY_KEY_GET';
export const PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_SUCCESS = 'PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_SUCCESS';
export const PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_FAILURE = 'PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_FAILURE';

export const PRODUCT_FAMILIES_REQUEST_GET = 'PRODUCT_FAMILIES_REQUEST_GET';
export const PRODUCT_FAMILIES_RECEIVE_GET_SUCCESS = 'PRODUCT_FAMILIES_RECEIVE_GET_SUCCESS';
export const PRODUCT_FAMILIES_RECEIVE_GET_FAILURE = 'PRODUCT_FAMILIES_RECEIVE_GET_FAILURE';

export const PRODUCT_PURCHASABLE_PRODUCT_KEYS_RESET = 'PRODUCT_PURCHASABLE_PRODUCT_KEYS_RESET';

export const NO_AVAILABLE_COUNTRY = 'no.available.country';
export const DUPLICATE_PRODUCT_FAMILY = 'duplicate.productfamily';
export const RELATIONSHIP_CROSS_SELL_NEW_ONLY = 'CROSS_SELL_NEW_ONLY';
export const RELATIONSHIP_UPGRADE = 'UPGRADE_PRODUCTS';
export const RELATIONSHIP_DOWNGRADE = 'DOWNGRADE_PRODUCTS';
export const RELATIONSHIP_LESS_OR_EQUAL_QUANTITY = 'LESS_OR_EQUAL_QUANTITY';
export const RELATIONSHIP_REQUIRE_ANY_PRODUCTS = 'REQUIRE_ANY_PRODUCTS';
export const ADDON_RELATIONSHIPS = [
  RELATIONSHIP_CROSS_SELL_NEW_ONLY
];
export const AVAILABILITY_CATEGORY_DEFAULT = 'DEFAULT';
export const AVAILABILITY_CATEGORY_TEST = 'TEST';
export const ACTIVE_AVAILABILITY_CATEGORIES = [
  AVAILABILITY_CATEGORY_DEFAULT,
  AVAILABILITY_CATEGORY_TEST
];

// TODO: Needs to be removed once FlatRate is migrated to Global Call me.
export const ENHANCED_AUDIO_FLAT_RATE = 'Enhanced_Audio_Flat_Rate';
export const PRODUCT_RELATIONSHIP_RECEIVE_GET_SUCCESS = 'PRODUCT_RELATIONSHIP_RECEIVE_GET_SUCCESS';
export const PRODUCT_RELATIONSHIP_RECEIVE_GET_FAILURE = 'PRODUCT_RELATIONSHIP_RECEIVE_GET_FAILURE';

export const PRODUCT_SETTING_RECEIVE_GET_SUCCESS = 'PRODUCT_SETTING_RECEIVE_GET_SUCCESS';


// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  /**
   * products: Contains all the product data we've collected for the session
   */
  products: [],
  /**
   * purchasableProducts: The purchasable product keys before additional filtering for business rules has been applied.
   * Includes addon product keys
   */
  purchasableProductKeys: [],
  /**
   * productFamilies: The product families
   */
  productFamilies: [],
  isLoading: false,
  productSettings: {}
};

// ------------------------------------
// Selectors
// ------------------------------------
// Tree Object Selectors
const productsTree = (state) => state.product || {};
export const productIsLoading = (state) => productsTree(state).isLoading;

const productsData = (state) => productsTree(state).products || [];

export const productByKey = (state, productKey) => productsData(state).find((product) => product.key === productKey) || {};

export const productByKeyDescription = (state, productKey) => productByKey(state, productKey).productDescription || '';
export const productByKeyShortDescription = (state, productKey) => productByKey(state, productKey).productShortDescription || '';
const productByKeyRelationships = (state, productKey) => productByKey(state, productKey).relationships || [];
const productByKeyOperations = (state, productKey) => productByKey(state, productKey).operations || {};
export const productByKeyOperationsQuantityIsEditable = (state, productKey) => !!productByKeyOperations(state, productKey).editableQuantity;
export const productByKeyOperationsProductIsEditable = (state, productKey) => !!productByKeyOperations(state, productKey).editableProduct;

export const productByKeyOperationsSeatsReducible = (state, productKey) => !!productByKeyOperations(state, productKey).seatsReducible;

/**
 * For the purpose of showing the "up to N participants" and the "N participants
 * (in Webcast Mode)", maxattendees and avbroadcastmaxattendees have no difference.
 *
 * @param {ApplicationShape} state current (redux) state of the application
 * @param {string} productKey
 */
export const productByKeyMaxAttendees = (state, productKey) => {
  const product = productByKey(state, productKey);
  const { features = {} } = product;
  const {
    maxattendees = 0,
    avbroadcastmaxattendees = 0
  } = features[product.productFamilyKey] || {};

  return Math.max(maxattendees, avbroadcastmaxattendees);
};
export const productByKeyDisplayFeatures = (state, {productKey}) => productByKey(state, productKey).displayFeatures || [];
export const productByKeyRelationshipLessOrEqualQuantity = (state, productKey) => productByKeyRelationships(state, productKey)
  .find((relationship) => relationship.type === RELATIONSHIP_LESS_OR_EQUAL_QUANTITY) || {};
export const productByKeyLessOrEqualQuantityProductKeys = (state, productKey) => productByKeyRelationshipLessOrEqualQuantity(state, productKey).productKeys || [];
export const productByKeyRelationshipCrossSell = (state, productKey) => productByKeyRelationships(state, productKey)
  .find((relationship) => relationship.type === RELATIONSHIP_CROSS_SELL_NEW_ONLY) || {};
export const productByKeyCrossSellProductKeys = (state, productKey) => productByKeyRelationshipCrossSell(state, productKey).productKeys || [];
export const productByKeyRelationshipDowngrade = (state, productKey) => productByKeyRelationships(state, productKey)
  .find((relationship) => relationship.type === RELATIONSHIP_DOWNGRADE) || {};
export const productByKeyDowngradeProductKeys = (state, productKey) => productByKeyRelationshipDowngrade(state, productKey).productKeys || [];
export const productByKeyRelationshipRequireProducts = (state, productKey) => (
  productByKeyRelationships(state, productKey).find((relationship) => relationship.type === RELATIONSHIP_REQUIRE_ANY_PRODUCTS) || {}
);
export const productByKeyRequireProductsProductKeys = (state, productKey) => productByKeyRelationshipRequireProducts(state, productKey).productKeys || [];

export const productByKeyWinbackProduct = (state, productKey) => {
  const foundProduct = productByKey(state, productKey);
  // no found product
  if (!foundProduct.relationships) {
    return '';
  }

  const { productKeys: downgradeProductKeys = [] } = foundProduct
    .relationships
    .find((relationship) => relationship.type === RELATIONSHIP_DOWNGRADE) || {};

  // found product has no downgrades
  if (downgradeProductKeys.length === 0) {
    return '';
  }

  // found product has downgrades
  const downgrades = downgradeProductKeys.map((downgradeProductKey) => ({
    key: downgradeProductKey,
    downgrades: productByKeyRelationshipDowngrade(state, downgradeProductKey).productKeys
  }));

  if (downgrades.length > 1) {
    // sometimes COG may give us an undefined set of relationships, others COG
    // may give us an empty array of relationships.  This test helps protect us
    // from having multiple different kinds of relationships in the product models
    const hasEmptyDowngrade = (downgrade) => (
      downgrade.downgrades === undefined
      || downgrade.downgrades.length === 0
    );
    return (downgrades.find(hasEmptyDowngrade) || {}).key || '';
  }

  return downgrades[0].key || '';
};

// The priceZone for all products should be the same for customers, agent flows have the potential to have differing priceZones
export const productsPriceZone = (state) => productsData(state).length ? productsData(state)[0].prices[0].priceZoneKey : '';

export const productPurchasableProductKeys = (state) => productsTree(state).purchasableProductKeys || [];
// @NOTE added the logic of filtering out downgrade products here (when editing) per BPOR-611 which is supposedly temporary
export const purchasableProducts = (state) => {
  let products = productsData(state);

  // filter out products that aren't part of the purchaseable product lineup
  products = products.filter((product) => productPurchasableProductKeys(state).includes(product.key));

  // assume not adding means editing
  if (!uiSubsConfigIsAddingNewSubscription(state)) {
    const downgradeProducts = productByKeyDowngradeProductKeys(state, uiSubsConfigCurrentProductKey(state));
    products = products.filter((product) => downgradeProducts.indexOf(product.key) === -1);
  }
  return products.filter((product) => (product.prices || []).some((price) => !price.hidePrice));
};

export const purchasableProductByProductKey = (state, productKey) => purchasableProducts(state).find((product) => product.key === productKey);
export const productsByFamilyTrial = (state, productFamilyKey) => purchasableProducts(state)
  .filter((product) => ProductCatalog.productTypeIsTrial(product.productType) && product.productFamilyKey === productFamilyKey);
export const productsByFamilyPaid = (state, productFamilyKey) => purchasableProducts(state)
  .filter((product) => ProductCatalog.productTypeIsPaid(product.productType) && product.productFamilyKey === productFamilyKey);
export const productsByFamilyFreemium = (state, productFamilyKey) => purchasableProducts(state)
  .filter((product) => ProductCatalog.productTypeIsFree(product.productType) && product.productFamilyKey === productFamilyKey);
export const productFamiliesData = (state) => productsTree(state).productFamilies || [];
export const productFamiliesByCategory = (state, category = []) => productFamiliesData(state).filter((family) => category.includes(family.key));
export const productFamiliesByProductFamilyKey = (state, productFamilyKey) => productFamiliesData(state).find((productFamily) => productFamily.key === productFamilyKey) || {};
export const productFamiliesByProductFamilyKeyIsMultiproductEnabled = (state, productFamilyKey) => (
  !!productFamiliesByProductFamilyKey(state, productFamilyKey).isMultiproductEnabled
);
export const collaborateCommunicateProductFamilies = (state) => productFamiliesByCategory(state, ProductCatalog.getCollaborationFamilies());
export const accessSupportProductFamilies = (state) => productFamiliesByCategory(state, ProductCatalog.getSupportFamilies());

export const productByKeyFamily = (state, productKey) => productByKey(state, productKey).productFamilyKey;
export const productByKeyGroup = (state, productKey) => productByKey(state, productKey).productGroup;
export const productByKeyPrices = (state, productKey) => productByKey(state, productKey).prices || [];
export const productByKeyCurrencyCode = (state, productKey) => productByKeyPrices(state, productKey).length ? productByKeyPrices(state, productKey)[0].currencyCode : '';

export const productByKeyUsagePrice = (state, productKey) => {
  const prices = productByKeyPrices(state, productKey);
  if (prices.length) {
    // Our current use case only requires checking the first object in the prices array, selector might need a refactor if that changes
    return prices[0].usageCountryBandPrice || '';
  }
  return '';
};
export const productByKeyProductType = (state, productKey) => productByKey(state, productKey).productType || '';
export const productByKeyIsTrial = (state, productKey) => ProductCatalog.productTypeIsTrial(productByKeyProductType(state, productKey));
export const productByKeyIsFree = (state, productKey) => ProductCatalog.productTypeIsFree(productByKeyProductType(state, productKey));
export const productByKeyAvailabilityCategory = (state, {productKey}) => productByKey(state, productKey).availabilityCategory;
export const productByKeyIsActive = (state, {productKey}) => ACTIVE_AVAILABILITY_CATEGORIES.includes(productByKeyAvailabilityCategory(state, {productKey}));
export const productByKeyIsInDefaultLineup = (state, {productKey}) => productByKeyAvailabilityCategory(state, {productKey}) === AVAILABILITY_CATEGORY_DEFAULT;
export const productByKeyPaidAnnualPrices = (state, productKey) => {
  if (productByKey(state, productKey).prices) {
    return productByKey(state, productKey).prices.find((price) => ProductCatalog.productBillingPeriodIsYear(price.billingPeriod)) || {};
  }
  return {};
};
export const productByKeyHasPaidAnnualPrices = (state, productKey) => !!Object.keys(productByKeyPaidAnnualPrices(state, productKey)).length;
export const productByKeyPaidAnnualPricesSaveAmount = (state, productKey) => productByKeyPaidAnnualPrices(state, productKey).saveAmount || '0';
export const productByKeyPaidMonthlyPrices = (state, productKey) => {
  if (productByKey(state, productKey).prices) {
    return productByKey(state, productKey).prices.find((price) => ProductCatalog.productBillingPeriodIsMonth(price.billingPeriod)) || {};
  }
  return {};
};
export const productByKeyMonthlyMinCommitPrice = (state, productKey) => productByKeyPaidMonthlyPrices(state, productKey).minCommit || '0';
export const productByKeyPaidAnnualPerMonthEffectivePrice = (state, productKey) => productByKeyPaidAnnualPrices(state, productKey).perMonthEffectivePrice || '0';
export const productByKeyPaidMonthlyPerMonthEffectivePrice = (state, productKey) => productByKeyPaidMonthlyPrices(state, productKey).perMonthEffectivePrice || '0';
export const productByKeyPaidAnnualPerMonthSubscriptionPrice = (state, productKey) => productByKeyPaidAnnualPrices(state, productKey).perMonthSubscriptionPrice || '0';
export const productByKeyPaidMonthlyPerMonthSubscriptionPrice = (state, productKey) => productByKeyPaidMonthlyPrices(state, productKey).perMonthSubscriptionPrice || '0';
export const productByKeyPaidAnnualPerYearEffectivePrice = (state, productKey) => productByKeyPaidAnnualPrices(state, productKey).effectivePrice || '0';
export const productByKeyIsDiscounted = (state, productKey) => (
  parseFloat(productByKeyPaidAnnualPerMonthEffectivePrice(state, productKey), 10) < parseFloat(productByKeyPaidAnnualPerMonthSubscriptionPrice(state, productKey), 10) ||
  parseFloat(productByKeyPaidMonthlyPerMonthEffectivePrice(state, productKey), 10) < parseFloat(productByKeyPaidMonthlyPerMonthSubscriptionPrice(state, productKey), 10)
);
export const productByKeyIsUsage = (state, productKey) => !!productByKeyUsagePrice(state, productKey).length;
export const productByKeyIsOnlyUsage = (state, productKey) => (
  // @NOTE Currently we are ignoring annual billing periods for usage products.
  //       This is because all of our current usage products are monthly-only.
  //       If in the future, we want to have a usage product with annual billing, we will need to refactor this selector
  //       and potentially many others.
  productByKeyIsUsage(state, productKey) && (parseFloat(productByKeyPaidMonthlyPerMonthEffectivePrice(state, productKey)) === 0)
);
export const productByKeyAvailableBillingPeriods = (state, productKey) => {
  const prices = productByKey(state, productKey).prices || [];
  return prices.map((price) => ({
    billingDuration: price.billingDuration,
    billingPeriod: price.billingPeriod
  }));
};
// use purchasableProducts so that we don't make decisions based on products that aren't being shown
export const productFamilyHasQuantity = (state, productFamilyKey) => purchasableProducts(state)
  .filter((product) => product.productFamilyKey === productFamilyKey)
  .some((product) => product.minQuantity !== product.maxQuantity);
export const productByFamilyRelationships = (state, productFamilyKey) => purchasableProducts(state)
  .filter((product) => product.productFamilyKey === productFamilyKey && product.relationships.length > 0)
  .map((product) => product.relationships);
// takes cross-sell relationships across many products, merges them, and returns only the unique productKeys as an array
export const productByFamilyRelationshipCrossSellProductKeys = (state, productFamilyKey) => {
  if (productByFamilyRelationships(state, productFamilyKey).length === 0) {
    return [];
  }
  const relevantRelationships = productByFamilyRelationships(state, productFamilyKey)
    .reduce((a, b) => a.concat(b))
    .filter((relationship) => relationship.type === RELATIONSHIP_CROSS_SELL_NEW_ONLY)
    .map((relationship) => relationship.productKeys);

  return (relevantRelationships.length > 0) ? uniq(relevantRelationships.reduce((a, b) => a.concat(b))) : [];
};

// Currently addons assume a no-configuration flow so we just look at the first price to get the fields we'll need to add to cart.
export const productByKeyMinQuantity = (state, productKey) => productByKey(state, productKey).minQuantity || 1;
export const productByKeyDefaultBillingPeriod = (state, productKey) => productByKeyPrices(state, productKey)[0].billingPeriod;
export const productByKeyDefaultBillingDuration = (state, productKey) => productByKeyPrices(state, productKey)[0].billingDuration;

export const productByKeyQuantityConfigurable = (state, productKey) => {
  const foundProduct = productByKey(state, productKey);
  return productByKeyOperationsQuantityIsEditable(state, productKey) && foundProduct.minQuantity < foundProduct.maxQuantity;
};
export const productByKeyPerUnitMonthlyEffectivePrice = (state, productKey, billingPeriod) => {
  const annualPlanPerMonthEffectivePrice = productByKeyPaidAnnualPerMonthEffectivePrice(state, productKey);
  const monthlyPlanPerMonthEffectivePrice = productByKeyPaidMonthlyPerMonthEffectivePrice(state, productKey);

  return ProductCatalog.productBillingPeriodIsYear(billingPeriod)
    ? annualPlanPerMonthEffectivePrice
    : monthlyPlanPerMonthEffectivePrice;
};

export const productByKeyAddonRelationships = (state, productKey) => (
  productByKeyRelationships(state, productKey).filter((relationship) => ADDON_RELATIONSHIPS.includes(relationship.type))
);
export const productByKeyAddons = (state, productKey) => productByKeyAddonRelationships(state, productKey)
  .reduce((acc, element) => {
    const flattenedProductKeys = element.productKeys.map((key) => (
      {
        productKey: key,
        type: element.type
      }
    ));
    return acc.concat(flattenedProductKeys);
  }, []);

/**
 * Get the totals for all quotes and/or subscriptions that match the given product key,
 * preferring first the quote new item quantity and then the subscription quantity.
 * NOTE: Passing quoteItems and subsForProductKey in here to prevent new circular dependencies; the
 *       trade-off being that this selector has knowledge of the internal shape of quote items.
 *
 * @param {object} state
 * @param {string} productKey
 * @param {Array} quoteItems All items in quote including corresponding product data.
 *                           Result of calling quoteItems(state) inside of the container.
 * @param {Array} subsForProductKey Active subscriptions matching the given productKey.
 *                                  Result of calling subscriptionsActiveByProductKey(state, productKey) inside of the container.
 */
export const productsByKeyQuoteOrSubsQuantityTotal = (state, productKey, quoteItems, subsForProductKey) => {
  let quantity = 0;

  // get all quote items where productKey is in new sub
  const matchingQuotes = quoteItems.filter((item) => item.newSubscription.productKey === productKey);

  // Gather the correct quantity for each subscription
  quantity += subsForProductKey.reduce((acc, sub) => {
    const matchingQuote = matchingQuotes.find((quote) => quote.subscriptionKey === sub.key);
    if (matchingQuote) {
      // We only care about new subscriptions. If a product key was only in currentSubscription, it is a tier change
      if (matchingQuote.newSubscription.productKey === productKey) {
        return acc + matchingQuote.newSubscription.quantity;
      }
      // Product key matched only old subscription, ignore
      return acc;
    }
    // This is a pre-existing subscription on the account that is not currently being edited
    return acc + sub.quantity;
  }, 0);

  // Gather quantities for any completely new subscriptions
  const newQuotes = matchingQuotes.filter(({quoteItemChangeType}) => quoteItemChangeType === 'NEW' || quoteItemChangeType === 'CONVERT_TRIAL' || quoteItemChangeType === 'REACTIVATE');
  quantity += newQuotes.reduce((acc, quote) => acc + quote.newSubscription.quantity, 0);
  return quantity;
};

export const productSettingFlag = (state, productFamilyKey, flag) => productsTree(state).productSettings[productFamilyKey]?.[flag] || false;

// ------------------------------------
// Actions
// ------------------------------------
export const productsRequestByProductKeysGetSuccess = (payload = []) => ({
  type: PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_SUCCESS,
  payload
});

export const productsRequestByProductKeysGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const productsRequestBySubscriptionKeyGetSuccess = (subscriptionKey, payload = []) => ({
  type: PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS,
  subscriptionKey,
  payload
});

export const productsRequestBySubscriptionKeyGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const productsRequestByProductFamilyKeyGetSuccess = (payload = []) => ({
  type: PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_SUCCESS,
  payload
});

export const productsRequestByProductFamilyKeyGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const productFamiliesReceiveGetSuccess = (payload = []) => ({
  type: PRODUCT_FAMILIES_RECEIVE_GET_SUCCESS,
  payload
});

export const productFamiliesReceiveGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PRODUCT_FAMILIES_RECEIVE_GET_FAILURE});
  // continue the fail chain so outside code can react to failure as needed
  return Promise.reject(payload);
};

export const productFamiliesRequestGet = () => (dispatch, getState) => {
  dispatch({
    type: PRODUCT_FAMILIES_REQUEST_GET
  });

  return daoProductFamiliesGet(billingAccountCountryCode(getState()), billingAccountActiveKey(getState()))
    .then(
      (response) => dispatch(productFamiliesReceiveGetSuccess(response.data)),
      (ex) => dispatch(productFamiliesReceiveGetFailure(ex))
    );
};

export const productPurchasableProductKeysReset = () => ({
  type: PRODUCT_PURCHASABLE_PRODUCT_KEYS_RESET
});

/**
 * Product realtion ship module.
 * TODO: this will be removed once the migration is done for existing customer from flat rate to global callMe.
 */
export const productRelationshipGetSuccess = (payload = []) => ({
  type: PRODUCT_RELATIONSHIP_RECEIVE_GET_SUCCESS,
  payload
});

export const productRelationshipGetFailure = (payload = {}) => (dispatch) => {
  dispatch({type: PRODUCT_RELATIONSHIP_RECEIVE_GET_FAILURE});
  return Promise.reject(payload);
};

export const productRelationshipRequestGet = (productKey) => (dispatch) => daoProductRelationsGet(productKey)
  .then(
    (response) => dispatch(productRelationshipGetSuccess(response.data)),
    (ex) => dispatch(productRelationshipGetFailure(ex))
  );

export const productSettingReceiveGetSuccess = (payload = {}, product) => ({
  type: PRODUCT_SETTING_RECEIVE_GET_SUCCESS,
  product,
  payload
});

export const productSettingReceiveGetFailure = (payload = {}) => () => Promise.reject(payload);

export const productSettingRequestGet = (product) => (dispatch) => daoProductSettingGet(product)
  .then(
    (response) => dispatch(productSettingReceiveGetSuccess(response.data, product)),
    (err) => dispatch(productSettingReceiveGetFailure(err))
  );


// ------------------------------------
// Action Handlers
// ------------------------------------
const productsGetSuccessHandler = (state, action) => {
  const products = (Array.isArray(action.payload) ? action.payload : []);

  // note: the order of merged arrays should be newest to oldest as that is what uniqBy will favor when picking/discarding
  const mergedProductsData = Array.prototype.concat(products, state.products);
  const uniqueProductsData = uniqBy(mergedProductsData, 'key');

  return {
    ...state,
    products: sortBy(uniqueProductsData, ['displayOrder']),
    purchasableProductKeys: state.purchasableProductKeys.concat(products.map((product) => product.key)),
    isLoading: false
  };
};

const ACTION_HANDLERS = () => ({
  [PRODUCTS_REQUEST_BY_PRODUCT_KEYS_GET]: (state) => ({...state, isLoading: true}),
  [PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_SUCCESS]: productsGetSuccessHandler,
  [PRODUCTS_RECEIVE_BY_PRODUCT_KEYS_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [PRODUCTS_REQUEST_BY_SUBSCRIPTION_KEY_GET]: (state) => ({...state, isLoading: true}),
  [PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_SUCCESS]: productsGetSuccessHandler,
  [PRODUCTS_RECEIVE_BY_SUBSCRIPTION_KEY_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [PRODUCTS_REQUEST_BY_PRODUCT_FAMILY_KEY_GET]: (state) => ({...state, isLoading: true}),
  [PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_SUCCESS]: productsGetSuccessHandler,
  [PRODUCTS_RECEIVE_BY_PRODUCT_FAMILY_KEY_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [PRODUCT_FAMILIES_REQUEST_GET]: (state) => ({...state, isLoading: true}),
  [PRODUCT_FAMILIES_RECEIVE_GET_SUCCESS]: (state, action) => {
    const productFamilies = (Array.isArray(action.payload) ? action.payload : []).map((productFamily) => ({
      key: productFamily.key,
      hideProductFamily: productFamily.hideProductFamily,
      isDuplicateProductFamily: productFamily.hideProductFamilyReason === DUPLICATE_PRODUCT_FAMILY,
      isNotAvailableInCountry: productFamily.hideProductFamilyReason === NO_AVAILABLE_COUNTRY,
      isMultiproductEnabled: productFamily.allowDuplicates
    }));

    // only store families we explicitly support in the ProductCatalog, and store in the desired order
    const productFamilySortOrder = ProductCatalog.getCollaborationFamilies().concat(ProductCatalog.getSupportFamilies());
    const storeProductFamilies = productFamilies.filter((family) => productFamilySortOrder.includes(family.key))
      .sort((a, b) => {
        if (a.hideProductFamily === b.hideProductFamily) {
          return productFamilySortOrder.indexOf(a.key) - productFamilySortOrder.indexOf(b.key);
        }

        return a.hideProductFamily ? 1 : -1;
      });

    return {
      ...state,
      productFamilies: storeProductFamilies,
      isLoading: false
    };
  },
  [PRODUCT_FAMILIES_RECEIVE_GET_FAILURE]: (state) => ({...state, isLoading: false}),
  [PRODUCT_PURCHASABLE_PRODUCT_KEYS_RESET]: (state) => ({...state, purchasableProductKeys: []}),
  [PRODUCT_SETTING_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (action.payload) {
      return {
        ...state,
        productSettings: {
          ...state.productSettings,
          [action.product]: action.payload
        }
      };
    }
    return {...state};
  }
});

// ------------------------------------
// Reducer
// ------------------------------------
const productReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default productReducer;
