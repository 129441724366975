import { daoEeCancelGet } from 'dao/engagement-engine-dao';
import { productFamilyKeyIsInEeCancelRetentionCampaign } from 'lib/product-catalog';
import { Session } from 'lib/session';
import { track } from 'lib/tracking';
import {
  billingAccountCurrencyIsUSD,
  billingAccountCurrencyIsEUR,
  billingAccountCurrencyIsGBP,
  billingAccountCurrencyIsAUD,
  billingAccountCurrencyIsCAD,
  billingAccountRestrictChangeOrder
} from 'modules/billing-account';
import { productByKeyFamily} from 'modules/product-module';
import { subscriptionsAccountKey } from 'modules/subscription';
import {
  FlowExit,
  RetentionComplete
} from 'modules/tracking-module';
import {
  EXPERIMENT_INIT,
  EXPERIMENT_SET_FLAGS,
  EE_REQUEST_GET,
  EE_RECEIVE_GET_SUCCESS,
  EE_CANCEL_RETENTION_FLOW_SET
} from './experiments-action-types';
import {
  EE_CANCEL,
  EE_CANCEL_CAMPAIGN_NONE,
  EE_CANCEL_CAMPAIGN_OTHER_DOWNGRADE_OFFER,
  EE_CANCEL_CAMPAIGN_OTHER_NONE,
  EE_CANCEL_STORAGE_KEY
} from './experiments-constants';
import {
  experimentCancelBySubscriptionKeyIsLoading,
  experimentCancelInRetentionFlow,
  experimentCancelCampaignBySubscriptionKey,
  experimentCancelTestGroupBySubscriptionKey,
  experimentCancelDataBySubscriptionKey,
  experimentCancelInRetentionFlowProductKeys,
  experimentCancelInRetentionFlowSubscriptionKeys
} from './experiments-selectors';

import { meLocale } from 'modules/me-module';

const localStorageEE = new Session(window.localStorage); // we need to use local storage for retention flows
const parseLocalStorageEEData = (localStorageEEData) => {
  let localStorageEEDataParsed;
  try {
    localStorageEEDataParsed = JSON.parse(localStorageEEData);
  } catch (e) {
    localStorageEEDataParsed = false;
  }

  return localStorageEEDataParsed || false;
};

// Check if the experiment is available for following currency : USD, EUR, GBP, CAD, AUD
const isExperimentAvailableForCurrency = (state) => billingAccountCurrencyIsUSD(state)
  || billingAccountCurrencyIsEUR(state)
  || billingAccountCurrencyIsGBP(state)
  || billingAccountCurrencyIsAUD(state)
  || billingAccountCurrencyIsCAD(state);

// ------------------------------------
// Actions
// ------------------------------------
export const experimentsInit = () => ({
  type: EXPERIMENT_INIT,
  payload: parseLocalStorageEEData(localStorageEE.getItem(EE_CANCEL_STORAGE_KEY))
});

export const experimentSetFlags = (payload = {}) => ({
  type: EXPERIMENT_SET_FLAGS,
  payload
});

export const experimentCancelReceiveGetSuccess = (productFamilyKey, subscriptionKey, payload = {campaign: EE_CANCEL_CAMPAIGN_NONE}) => ({
  type: EE_RECEIVE_GET_SUCCESS,
  experimentId: EE_CANCEL,
  productFamilyKey,
  subscriptionKey,
  payload: {
    [subscriptionKey]: {
      ...payload
    }
  }
});

export const experimentCancelRequestGet = (subscriptionModel) => (dispatch, getState) => {
  const state = getState();
  const {
    productFamilyKey,
    key: subscriptionKey,
    isActive: subscriptionIsActive,
    isPaid: subscriptionIsPaid,
    product
  } = subscriptionModel;

  // if we already have requested for this product family, don't do anything else
  if (experimentCancelBySubscriptionKeyIsLoading(state, productFamilyKey, subscriptionKey)) {
    return Promise.resolve();
  }

  dispatch({
    type: EE_REQUEST_GET,
    experimentId: EE_CANCEL,
    subscriptionKey,
    productFamilyKey
  });
  // this experiment is only for:
  // - (cancelling a G2W subscription --or-- cancelling G2M subscription -- or -- cancelling G2T subscription) --and--
  // - a billing account's currency for which the expermient is available  -- and --
  // - the subscription is a paid subscription -- and --
  // - the subscription is active
  if (
    productFamilyKeyIsInEeCancelRetentionCampaign(productFamilyKey) &&
    isExperimentAvailableForCurrency(state) &&
    subscriptionIsPaid &&
    subscriptionIsActive
  ) {
    // Product Family Key `G2MGR` is sent as G2A to Engagement Engine.
    return daoEeCancelGet(subscriptionsAccountKey(state), productFamilyKey === 'G2MGR' ? 'G2A' : productFamilyKey, subscriptionKey, meLocale(state))
      .then(
        (response) => dispatch(experimentCancelReceiveGetSuccess(productFamilyKey, subscriptionKey, response.data)),
        () => dispatch(experimentCancelReceiveGetSuccess(productFamilyKey, subscriptionKey)) // on any failure, return default cancel campaign
      );
  }

  if (product.winback
    && !billingAccountRestrictChangeOrder(state)
  ) {
    return Promise.resolve(dispatch(experimentCancelReceiveGetSuccess(productFamilyKey, subscriptionKey, {
      campaign: EE_CANCEL_CAMPAIGN_OTHER_DOWNGRADE_OFFER
    })));
  }

  return Promise.resolve(dispatch(experimentCancelReceiveGetSuccess(productFamilyKey, subscriptionKey, { campaign: EE_CANCEL_CAMPAIGN_OTHER_NONE })));
};

export const experimentCancelRetentionSet = (productKey, subscriptionKey, storageEE = localStorageEE) => (dispatch, getState) => {
  // Save to local storage so we can track the user being in retention flow across page loads
  const state = getState();
  const productFamilyKey = productByKeyFamily(state, productKey);
  const inRetentionFlow = experimentCancelInRetentionFlow(state);
  let inRetentionFlowData = [];

  if (inRetentionFlow.length) {
    inRetentionFlowData = inRetentionFlow.filter((item) => item.subscriptionKey !== subscriptionKey);
  }

  inRetentionFlowData.push({
    productKey,
    subscriptionKey,
    campaign: experimentCancelCampaignBySubscriptionKey(state, productFamilyKey, subscriptionKey),
    mlTestingGroup: experimentCancelTestGroupBySubscriptionKey(state, productFamilyKey, subscriptionKey),
    data: experimentCancelDataBySubscriptionKey(state, productFamilyKey, subscriptionKey)
  });
  // TODO we need to wrap setItem in try/catch in case it throws an error
  storageEE.setItem(EE_CANCEL_STORAGE_KEY, JSON.stringify(inRetentionFlowData));

  return dispatch({
    type: EE_CANCEL_RETENTION_FLOW_SET,
    inRetentionFlow: inRetentionFlowData
  });
};

export const experimentCancelRetentionClear = (storageEE = localStorageEE) => (dispatch) => {
  // Clear from local storage
  storageEE.removeItem(EE_CANCEL_STORAGE_KEY);

  // Clear (set to false) in the store
  return dispatch({
    type: EE_CANCEL_RETENTION_FLOW_SET,
    inRetentionFlow: false
  });
};

export const experimentCancelOnOrderSuccess = (orderSubscriptionKeys) => (dispatch, getState) => {
  const state = getState();
  const retentionFlowProductKeys = experimentCancelInRetentionFlowProductKeys(state);
  const retentionFlowSubscriptionKeys = experimentCancelInRetentionFlowSubscriptionKeys(state);
  // if we are in the retention flow and the subscription key for that flow matches one of the subscription keys
  // from the order that is currently being completed, then treat this as a successful completion of the retention flow
  const tracking = [];
  if (experimentCancelInRetentionFlow(state).length) {
    for (let i = 0; i < retentionFlowSubscriptionKeys.length; i++) {
      if (orderSubscriptionKeys.includes(retentionFlowSubscriptionKeys[i])) {
        tracking.push({
          [RetentionComplete]: {
            productFamilyKey: productByKeyFamily(state, retentionFlowProductKeys[i]),
            subscriptionKey: retentionFlowSubscriptionKeys[i]
          }
        });
      }
      // if there is a retention flow subscription key, placement of any order should count as the "flow exit"
      tracking.push({
        [FlowExit]: {
          productFamilyKey: productByKeyFamily(state, retentionFlowProductKeys[i]),
          subscriptionKey: retentionFlowSubscriptionKeys[i]
        }
      });
    }
  }
  tracking.forEach((eventData) => dispatch(track(eventData)));
  // in all cases, clear out the retention flow value once an order has been completed
  return dispatch(experimentCancelRetentionClear());
};
