import countriesData from './countries-data';

export class Countries {
  constructor(countriesList = {}) {
    this.countriesList = countriesList;
  }

  getCountryKeys() {
    return Object.keys(this.countriesList);
  }

  getStateCodesByCountryKey(countryKey) {
    return this.countriesList[countryKey] || [];
  }
}

export default new Countries(countriesData);
