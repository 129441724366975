import {
  daoSubscriptionGet,
  daoSubscriptionActionDelete,
  daoSubscriptionActionPost
} from 'dao/subscription-dao';
import {
  Cancel,
  CancelCancel
} from 'modules/tracking-module';
import { withTracking } from 'lib/tracking';
import {
  SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS,
  SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE,
  SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE,
  SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS,
  SUBSCRIPTION_ACTION_REQUEST_DELETE,
  SUBSCRIPTION_ACTION_REQUEST_POST,
  SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH,
  SUBSCRIPTION_CHANGES_LOADING_KEYS_START,
  SUBSCRIPTION_EDIT_END,
  SUBSCRIPTION_EDIT_START,
  SUBSCRIPTION_EDIT_UPDATE,
  SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS,
  SUBSCRIPTION_RECEIVE_GET_FAILURE,
  SUBSCRIPTION_RECEIVE_GET_SUCCESS,
  SUBSCRIPTION_REQUEST_GET
} from './subscription-action-types';
import { subscriptionByKeyProductFamily } from './subscription-selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const subscriptionReceiveGetSuccess = (payload = []) => ({
  type: SUBSCRIPTION_RECEIVE_GET_SUCCESS,
  payload
});

export const subscriptionReceiveGetFailure = (payload = {}) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_RECEIVE_GET_FAILURE,
    payload
  });
  return Promise.reject(payload);
};

export const subscriptionRequestGet = (subscriptionKey, billingAccountKey) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_REQUEST_GET
  });

  return daoSubscriptionGet(billingAccountKey, subscriptionKey)
    .then(
      (response) => dispatch(subscriptionReceiveGetSuccess(response.data)),
      (ex) => dispatch(subscriptionReceiveGetFailure(ex))
    );
};

export const subscriptionGroupsReceiveGetSuccess = (payload = []) => ({
  type: SUBSCRIPTION_GROUPS_RECEIVE_GET_SUCCESS,
  payload
});

export const subscriptionActionReceiveDeleteSuccess = (subscriptionKey) => (dispatch, getState) => {
  const state = getState();

  const subscriptionActionReceiveDeleteRequest = {
    type: SUBSCRIPTION_ACTION_RECEIVE_DELETE_SUCCESS
  };

  const tracking = {
    [CancelCancel]: {
      productFamilyKey: subscriptionByKeyProductFamily(state, subscriptionKey)
    }
  };

  dispatch(withTracking(subscriptionActionReceiveDeleteRequest, tracking));
  return Promise.resolve();
};

export const subscriptionActionReceiveDeleteFailure = () => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_ACTION_RECEIVE_DELETE_FAILURE
  });
  return Promise.reject();
};

export const subscriptionActionRequestDelete = (subscriptionKey, billingAccountKey) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_ACTION_REQUEST_DELETE
  });

  return daoSubscriptionActionDelete(billingAccountKey, subscriptionKey)
    .then(
      () => dispatch(subscriptionActionReceiveDeleteSuccess(subscriptionKey)),
      () => dispatch(subscriptionActionReceiveDeleteFailure())
    );
};

export const subscriptionActionReceivePostSuccess = (subscriptionKey) => (dispatch, getState) => {
  const state = getState();

  const subscriptionActionReceivePostSuccessRequest = {
    type: SUBSCRIPTION_ACTION_RECEIVE_POST_SUCCESS
  };

  const tracking = {
    [Cancel]: {
      productFamilyKey: subscriptionByKeyProductFamily(state, subscriptionKey)
    }
  };

  dispatch(withTracking(subscriptionActionReceivePostSuccessRequest, tracking));
  return Promise.resolve();
};

export const subscriptionActionReceivePostFailure = () => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_ACTION_RECEIVE_POST_FAILURE
  });
  return Promise.reject();
};

export const subscriptionActionRequestPost = (subscriptionKey, billingAccountKey) => (dispatch) => {
  dispatch({
    type: SUBSCRIPTION_ACTION_REQUEST_POST
  });

  const payload = {
    action: 'CANCEL'
  };

  return daoSubscriptionActionPost(billingAccountKey, subscriptionKey, payload)
    .then(
      () => dispatch(subscriptionActionReceivePostSuccess(subscriptionKey)),
      () => dispatch(subscriptionActionReceivePostFailure())
    );
};

export const subscriptionEditStart = (subscriptionKey) => ({
  type: SUBSCRIPTION_EDIT_START,
  payload: {
    subscriptionKey
  }
});

export const subscriptionEditUpdate = (subscriptionKey, data) => ({
  type: SUBSCRIPTION_EDIT_UPDATE,
  payload: {
    subscriptionKey,
    data
  }
});

export const subscriptionEditEnd = (subscriptionKey) => ({
  type: SUBSCRIPTION_EDIT_END,
  payload: {
    subscriptionKey
  }
});

export const subscriptionChangesLoadingKeysStart = (subscriptionKey, loadingKeys = []) => ({
  type: SUBSCRIPTION_CHANGES_LOADING_KEYS_START,
  payload: {
    subscriptionKey,
    loadingKeys
  }
});

export const subscriptionChangesLoadingKeysFinish = (subscriptionKey, loadingKeys = []) => ({
  type: SUBSCRIPTION_CHANGES_LOADING_KEYS_FINISH,
  payload: {
    subscriptionKey,
    loadingKeys
  }
});
