import React from 'react';
import PropTypes from 'prop-types';

const ExternalAnchorView = (props) => (
  <a
    href={props.href}
    target={props.target}
    className={props.className}
    rel={props.rel}
    type={props.type}
    download={props.download}
    onClick={props.onClick}
    id={props.id}
    role={props.role}
    tabIndex="0"
  >
    {props.children}
  </a>
);

ExternalAnchorView.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  download: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  children: PropTypes.node,
  role: PropTypes.string
};

export default ExternalAnchorView;
