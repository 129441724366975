import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExternalAnchor from 'components/external-anchor';
import st from 'components/shared-translations';
import classes from './footer.scss';
import AppDate from 'components/app-date';

export const FooterView = (props) => (
  <footer className={classes['footer-container']}>
    <div className="container-fluid">
      <div className={`row mt-3 ${classes['links-copyright']}`}>
        <div className="col-12 col-md-6">
          <ExternalAnchor className="ml-2" href={props.aboutUsUrl} id="footer-about-us">
            <FormattedMessage {...st['footer.aboutus']} />
          </ExternalAnchor>
          <ExternalAnchor className="ml-3" href={props.privacyPolicyUrl} id="footer-privacy-policy">
            <FormattedMessage {...st['footer.legalinfo']} />
          </ExternalAnchor>
          <ExternalAnchor className="ml-3" href={props.supportUrl} id="footer-support">
            <FormattedMessage {...st['shared.help.support']} />
          </ExternalAnchor>
        </div>
        <span className="col-12 col-md-6 pr-4 text-right">
          <span id="bporversion" className="pr-4">{props.version}</span>
          <FormattedMessage
            {...st['footer.copyright']}
            values={{
              year: <AppDate value={props.currentDate} format={{year: 'numeric'}} />
            }}
          />
        </span>
      </div>
      <span className={`row ml-2 mt-3 small ${classes['openvoice-legal-text']}`}>
        <FormattedMessage {...st['footer.openvoice']} />
      </span>
    </div>
  </footer>
);

FooterView.propTypes = {
  aboutUsUrl: PropTypes.string.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  supportUrl: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
};

export default FooterView;
