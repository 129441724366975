import { scimClient as api } from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const meEndpoint = appconfig.scim_endpoints.me;

export const daoScimMeGet = () => api.get(meEndpoint);


export default {
  daoScimMeGet
};
