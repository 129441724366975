/* eslint-disable consistent-return */
import React from 'react';
import ReactDOM from 'react-dom';
import store from 'store/create-store';
import AppContainer from 'components/app';
import commerceAuth from 'lib/commerce-auth';
import {
  meLanguage,
  meLocale,
  meRequestGet
} from 'modules/me-module';
import {
  loadReactIntlMessages,
  loadIntlPolyfills
} from 'modules/intl';
import { billingAccountActiveAccountKey, bootstrapBillingAccount } from 'modules/billing-account';
import BootstrapFail from 'components/bootstrap-fail';
import {
  responseInterceptorFallbackErrorHandler,
  addResponseInterceptorErrorHandlers,
  finalizeInterceptorChain,
  customClient,
  default as fallbackClient
} from 'dao/providers/axios';
import {
  errorInterceptorHandlePermission,
  thunkToErrorResponseInterceptor
} from 'modules/errors-module';
import {
  alertAuthenticationWarningToggle,
  alertAuthenticationExpiredToggle
} from 'modules/alert-module';
import { experimentsInit } from 'modules/experiments';
import {
  bootstrapAmplitude,
  bootstrapLaunchDarkly
} from 'store/bootstrap-thunks';
import environment from 'lib/environment';
import { getBrowserLocale } from 'lib/window-helpers';
import { track } from 'lib/tracking';
import {
  SessionInformation,
  UserInformation
} from 'modules/tracking-module';
import { accountRequestGet, accountUnifiedAdmin } from 'modules/account-module';


// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

const bootstrapAxiosInterceptors = () => {
  addResponseInterceptorErrorHandlers(
    fallbackClient,
    thunkToErrorResponseInterceptor(store.dispatch, errorInterceptorHandlePermission),
    responseInterceptorFallbackErrorHandler
  );
  finalizeInterceptorChain(fallbackClient);
  addResponseInterceptorErrorHandlers(
    customClient,
    thunkToErrorResponseInterceptor(store.dispatch, errorInterceptorHandlePermission)
  );
  finalizeInterceptorChain(customClient);
};
// First, Look up the user's browser locale in case we're unable to retrieve the me locale from `meRequestGet` API call
let userLocale = getBrowserLocale();
let userLanguageCode = userLocale.split('_')[0];

const renderApp = (App, reactIntlMessages, languageCode) => (
  ReactDOM.render(<App store={store} reactIntlMessages={reactIntlMessages} languageCode={languageCode} />, MOUNT_NODE)
);

let render = async () => {
  try {
    await store.dispatch(meRequestGet());
    userLocale = meLocale(store.getState());
    userLanguageCode = meLanguage(store.getState());

    const accountKey = billingAccountActiveAccountKey(store.getState());
    await store.dispatch(accountRequestGet(accountKey));
    const unifiedAdminFlag = accountUnifiedAdmin(store.getState());
    if (unifiedAdminFlag) {
      window.location.replace(`${environment.get('gotoAdmin.url')}/${accountKey}/billing/subscriptions`);
      return;
    }
    /**
     * bootstrapping amplitude and launch darkly should NOT be in critical path
     *
     * @NOTE The `experimentsInit` should be dispatched before dispatching `bootstrapLaunchDarkly` since the
     * `experimentsInit` sets the default LD flags. Otherwise, the default flags will override the flag values we get from LD
     */
    store.dispatch(experimentsInit());
    store.dispatch(bootstrapAmplitude());
    store.dispatch(bootstrapLaunchDarkly(environment.get('launchdarkly.clientId')));
    const [reactIntlMessages] = await Promise.all([
      // @NOTE Since we're destructuring the reactIntlMessages from the first index of the promise.all returned values array,
      // the loadReactIntlMessages promise should be always at the first index.
      loadReactIntlMessages(userLanguageCode, userLocale),
      loadIntlPolyfills(userLanguageCode),
      store.dispatch(bootstrapBillingAccount())
    ]);
    store.dispatch(track({
      [SessionInformation]: {},
      [UserInformation]: {}
    }));
    bootstrapAxiosInterceptors();
    return renderApp(AppContainer, reactIntlMessages, userLanguageCode);
  } catch (e) {
    const reactIntlMessages = await loadReactIntlMessages(userLanguageCode, userLocale);
    return renderApp(BootstrapFail, reactIntlMessages, userLanguageCode);
  }
};

// ========================================================
// HMR (Hot Module Rendering) Setup
// ========================================================
/* istanbul ignore next */
if (__DEV__) { // This code is excluded from production bundle
  if (module.hot) {
    // Development render functions
    const renderHotApp = render;
    const renderError = (error) => {
      // eslint-disable-next-line global-require, import/no-extraneous-dependencies
      const RedBox = require('redbox-react').default;

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderHotApp();
      } catch (error) {
        renderError(error);
      }
    };
  }
} else {
  // Load any non-dev libraries here (tracking, analytics, etc)
}

export const runApp = () => (
  // ========================================================
  // Go!
  // @NOTE We need runApp so we can separate commerceAuth.init() from render(), since we only
  // want to call commerceAuth.init() once, but HMR may call render() many times
  // ========================================================
  commerceAuth.init(store, {
    authWarningAction: alertAuthenticationWarningToggle,
    authExpiredAction: alertAuthenticationExpiredToggle
  }).then(render)
);

export default runApp;
