import {
  PAYER_AUTH_REQUEST_POST,
  PAYER_AUTH_REQUEST_POST_SUCCESS,
  PAYER_AUTH_REQUEST_POST_FAILURE
} from './payer-auth-action-types';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  isLoading: false
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = () => ({
  [PAYER_AUTH_REQUEST_POST]: (state) => ({
    ...state, isLoading: true
  }),
  [PAYER_AUTH_REQUEST_POST_SUCCESS]: (state) => ({
    ...state,
    isLoading: false
  }),
  [PAYER_AUTH_REQUEST_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  })
});

// ------------------------------------
// Reducer
// ------------------------------------
export const payerAuth = (state = initialState, action) => {
  const handler = ACTION_HANDLERS()[action.type];

  return handler ? handler(state, action) : state;
};

export default payerAuth;
